import React, { useState } from 'react';
import { jsonData } from './Card';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';


const Slider = () => {
  const [startIndex, setStartIndex] = useState(0); // Index of the first visible item
  const [showAll, setShowAll] = useState(false); // State to track whether to show all items
  const [showSearch, setShowSearch] = useState(false); // State to track whether to show the search field
  const [searchTitle, setSearchTitle] = useState(''); // State to store the search title

  const handleNext = () => {
    setStartIndex((prevStartIndex) => Math.min(prevStartIndex + 1, jsonData.length - 3));
  };

  const handlePrev = () => {
    setStartIndex((prevStartIndex) => Math.max(prevStartIndex - 1, 0));
  };

  const handleViewAll = () => {
    setShowAll(true);
    setShowSearch(true); // Show search field when View All is clicked
  };

  const handleSearchChange = (e) => {
    setSearchTitle(e.target.value);
  };

  return (
    <div className="bg-[#EAF7FF] mx-[40px]">
      <div className="mx-[60px] h-auto mt-6">
        {/* <h2 className="text-lg font-bold mt-10">Saved Projects</h2> */}

        <div className="flex items-center justify-between ">
          {showSearch && ( // Render search field if showSearch is true
          <div className='m-5 ml-36'>
            <div className="flex  ">
              <input
                type="text"
                placeholder="Search projects..."
                className="border border-gray-300 rounded-md  py-2 px-4 w-[600px]"
                value={searchTitle}
                onChange={handleSearchChange}
              />
              <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold ml-2 py-2 px-4 rounded">
                filter
              </button>
            </div>
            </div>
          )}

          <button
            className={`bg-blue-500 hover:bg-blue-700 text-white font-bold relative top-32 right-12 py-2 px-2 elative rounded-[50%] ${
              startIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            onClick={handlePrev}
            disabled={startIndex === 0}
          >
            <ChevronLeftIcon></ChevronLeftIcon>
          </button>
          {!showAll && (
            <button
              className={`bg-blue-500 hover:bg-blue-700 text-white font-bold relative top-32 left-10 py-2 px-2 rounded-[50%] ${
                startIndex === jsonData.length - 4 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              onClick={handleNext}
              disabled={startIndex === jsonData.length - 4}
            >
              <ChevronRightIcon></ChevronRightIcon>
            </button>
          )}
        </div>
        <div className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 2xl:ml-10 mb-6 gap-2">
          {showAll
            ? jsonData
                .filter((item) => item.title.toLowerCase().includes(searchTitle.toLowerCase()))
                .map((item, index) => (
                  <div key={index} className="">
                    <img  src={item.image} className="w-[260px] h-[180px] brightness-100 rounded-[10px]" alt="dynamic" />
                    <div className="bg-white pl-8 w-[220px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] h-[120px] relative bottom-[90px] left-5 rounded-[10px]">
                      <h1 className="text-[16px] pt-4 text-black font-[500]">{item.title}</h1>
                      <p className="text-[14px] font-[400] text-slate-400 py-2">{item.description}</p>
                      <p className="text-[14px] font-[400] text-slate-400">{item.price}</p>
                    </div>
                  </div>
                ))
            : jsonData.slice(startIndex, startIndex + 4).map((item, index) => (
                <div key={index} className="">
                  <img  src={item.image} className="w-[260px] h-[180px] brightness-100 rounded-[10px]" alt="dynamic" />
                  <div className="bg-white pl-8 w-[220px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] h-[120px] relative bottom-[90px] left-5 rounded-[10px]">
                    <h1 className="text-[16px] pt-4 text-black font-[500]">{item.title}</h1>
                    <p className="text-[14px] font-[400] text-slate-400 py-2">{item.description}</p>
                    <p className="text-[14px] font-[400] text-slate-400">{item.price}</p>
                  </div>
                </div>
              ))}
        </div>
        {!showAll && startIndex >= 7 && (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold text-[12px] py-2 px-3 rounded  relative bottom-10"
            onClick={handleViewAll}
          >
            View All
          </button>
        )}
      </div>
    </div>
  );
};
export default Slider;
