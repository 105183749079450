import React from 'react';


const TeamMember = () => {
  return (
    <>
      <div className="mb-10 bg-[#EDF2F0] p-10 ">
        <h1 className="text-[#BD5123] font-[700] pl-10 text-[30px] text-left leading-[26px] capitalized 2xl:text-[40px] lg:text-[40px] sm:text-[20px] md:text-[30px] ">
          The Team
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 mt-4">
          <div
            className=""
            
          >
            <img src="https://squarebric-media-files.squarebric.com/Numan.png" height={350} width={350} className="mt-3 border-t-2 border-l-2 border-[#1E2858] p-2 " alt="Numan Acar" />
          </div>
          <div className="mt-4 flex justify-center items-center flex-col">
            <h1 className="text-[#1E2858] font-[700] leading-[36px] sm:text-[25px] md:text-[30px] lg:text-[32px] text-[25px] text-center md:text-left pl-10">Numan Acar</h1>
            <h2 className="text-[#1E2858] font-[700] text-[20px] leading-[26px] mt-2 tmd:text-left  pl-10">CEO</h2>
            <div className="text-[#2E4374]  font-[500] lg:text-[16px] 2xl:text-[16px] sm:text-[14px] md:text-[15px] leading-[25px] mt-3 text-start lg:pl-10">
              To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a
              home, and digitally enabling them throughout their journey. We do that with data, design, technology, and
              above all, the passion of our people while delivering value to our shareholders.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamMember;
