import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../index.css';

const HeroPage = () => {
  const isMobileOrTablet = useMediaQuery('(max-width:1050px)');

  const containerClass = isMobileOrTablet
    ? 'flex flex-col items-center text-center'
    : 'flex flex-col md:flex-row justify-between items-start';

  const imgContainerClass = isMobileOrTablet
    ? 'flex flex-col items-center text-center w-full'
    : 'flex items-start justify-end text-left w-full';

  const borderStyle = {
    borderBottom: '2px solid #BFBCBC',
    width: '200px'
  };

  return (
    <div className="">
      <div className="flex justify-center items-center mt-20 ml-7">
        <div className={`${containerClass} w-full max-w-full lg:p-0 p-2  rounded-lg`}>
          <div className="max-w-lg mx-auto md:mx-0">
            <div className="flex justify-center items-center text-center mt-0 mr-0 sm:mr-0 md:mr-0 lg:mr-44 xl:mr-52 2xl:mr-60   ">
              <div className="text-[#C5C5C5] font-customFont text-base sm:text-lg md:text-2xl lg:text-2xl xl:text-xl">
                PROPERTIES
              </div>
              <span style={borderStyle}></span>
            </div>

            <h2 className="relative inline-block mb-4 pb-2 text-xl sm:text-lg md:text-3xl lg:text-2xl xl:text-3xl font-[700] text-[#2E4374] font-fifth mr-4 ">
              <span className="underlineresponsive">LATEST</span> LAUNCHES
            </h2>

            <p className="mb-4 text-[#2E4374] font-sixth capitalize  text-[10px] sm:text-[12px] md:text-[20px] w-full sm:w-full md:w-full lg:w-4/5 font-[400] tracking-normal">
              Discover Squarebric's latest luxury property. Combining modern design, prime location, and top-notch
              amenities, this development offers unparalleled living for you and your family. Discover exceptional
              living spaces with Squarebric today.
            </p>
            <button className="mt-4 px-6 py-2 text-sm md:text-[15px] sm:text-[15px] border-2 border-[#2E4374] rounded font-thirdCustom hover:bg-[#2E4374] hover:text-white text-[#2E4374]  transform hover:-translate-y-1 transition-all duration-300">
              VIEW ALL PROPERTIES
            </button>
          </div>

          <div className={imgContainerClass}>
            <div className="text-white rounded-md w-full flex justify-center items-center relative md:p-2 p-0">
              <div
                className="bg-[#C3D4E3] rounded-md absolute top-[6px] sm:top-[2px] md:top-[25px] xs:left-[15px] sm:left-[37px] md:left-[37px]
           transform -translate-x-1/2 w-[112px] h-[208px] md:w-[144px] md:h-[288px] sm:w-[80px] sm:h-[208px] 3xl:left-[65px] 4xl:left-[100px]"
              ></div>

              <div className="relative mt-[10px] sm:mt-[6px] md:mt-[40px]">
                <img
                  src="https://squarebric-media-files.squarebric.com/img2.png"
                  alt="img"
                  className="object-cover rounded-md w-[384px] h-[256px] sm:h-[256px] md:h-[400px] md:w-[384px] lg:h-[384px] lg:w-[384px]"
                />
              </div>
              <div className="ml-[16px] md:ml-[40px] mt-[10px] sm:mt-[6px] md:mt-[40px]">
                <img
                  src="https://squarebric-media-files.squarebric.com/rounded1.png"
                  alt="img"
                  className="object-cover rounded-md w-[384px] h-[256px] sm:h-[256px] md:h-[400px] md:w-[384px] lg:h-[384px] lg:w-[384px]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
