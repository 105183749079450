import React from 'react';
import HomeData from './HomeData';

function HomeServices() {
  return (
    <div>
      <HomeData data={nestedData} dataItem={availbilityData} />
    </div>
  );
}

export default HomeServices;

const nestedData = {
  Outer1: {
    Inner1: 'Value 1',
    Inner2: 'Value 2'
  },
  Outer2: {
    Inner3: 'Value 3',
    Inner4: 'Value 4'
  }
};


const availbilityData=[
    {
      "country": "Country 1",
      "states": [
        {
          "name": "State 1",
          "cities": ["City 1", "City 2"]
        },
        {
          "name": "State 2",
          "cities": ["City 3", "City 4"]
        }
      ]
    },
    {
      "country": "Country 2",
      "states": [
        {
          "name": "State 3",
          "cities": ["City 5", "City 6"]
        },
        {
          "name": "State 4",
          "cities": ["City 7", "City 8"]
        }
      ]
    }
  ]
  
