export const plansData=[
    {
      "name": "Basic",
      "features": [
        "Increase in buyer response",
        "Free verification of property",
        "Plan duration 3 months",
        "More visible to buyers"
      ],
      "price": "$40/mo",
      "buttonText": "Buy now"
    },
    {
      "name": "Premium",
      "features": [
        "Increase in buyer responses",
        "Free verification and photoshoot of property",
        "Relationship manager assistance in selling",
        "Premium listing, stand out with large photos and slide show"
      ],
      "price": "$9.99/mo",
      "buttonText": "Buy Now"
    },
    {
      "name": "Platinum",
      "features": [
        "Increase in buyer responses",
        "Free verification and photoshoot of property",
        "Relationship manager assistance in selling",
        "Premium listing, stand out with large photos and slide show",
        "Reach buyers on Facebook and Google platform"
      ],
      "price": "$9.99/mo",
      "buttonText": "Buy Now"
    }
  ]
  