import React from 'react'

function OrderHistory() {
  const memberships = [
    {
      planName: "Premium",
      orderId: "Sq123456",
      orderDate: "10/10/2023",
      status: "Active",
      amount: "$99.1",
      paymentMode: "Debit Card",
      validity: "12/12/2023",
      validityText: "2 Months Left",
    },
    {
      planName: "Platinum",
      orderId: "Sq123456",
      orderDate: "10/10/2023",
      status: "Expire",
      amount: "$79.1",
      paymentMode: "Debit Card",
      validity: "12/12/2023",
      validityText: "9 Months Ago",
    },
    {
      planName: "Platinum",
      orderId: "Sq123456",
      orderDate: "10/10/2023",
      status: "Expire",
      amount: "$79.1",
      paymentMode: "Debit Card",
      validity: "12/12/2023",
      validityText: "9 Months Ago",
    },
  ];
  return (
    <div className="border w-[100%]   rounded-lg shadow-lg h-auto ml-5 ">
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-[#2E4374]">
        <tr>
          <th className="px-6 py-3 text-left text-[18px] font-[600] leading-[18px]  sixth text-white uppercase tracking-wider">
            Plan Name / Order Id
          </th>
          <th className="px-6 py-3 text-left text-[18px] font-[600] leading-[18px]  sixth text-white uppercase tracking-wider">
            Order Date
          </th>
          <th className="px-6 py-3 text-left text-[18px] font-[600]  leading-[18px] sixth text-white uppercase tracking-wider">
            Order Status
          </th>
          <th className="px-6 py-3 text-left text-[18px] font-[600] leading-[18px]  sixth text-white uppercase tracking-wider">
            Amount
          </th>
          <th className="px-6 py-3 text-left text-[18px] font-[600]  leading-[18px] sixth text-white uppercase tracking-wider">
            Payment Mode
          </th>
          <th className="px-6 py-3 text-left text-[18px] font-[600] leading-[18px]  sixth text-white uppercase tracking-wider">
            Validity
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        {memberships.map((membership, index) => (
          <tr key={index}>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-medium text-gray-900">
                {membership.planName}
              </div>
              <div className="text-sm text-gray-500">
                Order Id - {membership.orderId}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm  font-[600] leading-[18px]   sixth text-[16px] text-[#2E4374]">
                {membership.orderDate}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <span
                className={`px-2 inline-flex text-xs  font-[600] leading-[18px]   sixth text-[16px]  rounded-full ${
                  membership.status === "Active"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {membership.status}
              </span>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-[600] leading-[18px] text-[#2E4374]  sixth text-[16px]">
                {membership.amount}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-[600] leading-[18px] text-[#2E4374]  sixth text-[16px]">
                {membership.paymentMode}
              </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="text-sm font-[600] leading-[18px] text-[#2E4374]  sixth text-[16px]">
                {membership.validity}
              </div>
              <div className="text-sm text-green-500 font-[600] leading-[18px]   sixth text-[16px]">
                {membership.validityText}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  )
}

export default OrderHistory