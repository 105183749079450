import React from 'react';
import { Link } from 'react-router-dom';

function PasswordSecurity() {
  return (

      <div className="p-8 bg-gray-50  ">
      <div className="w-full max-w-2xl bg-white p-6 rounded-lg shadow-md">
        <h1 className="text-2xl font-semibold text-gray-800 mb-2">
          Login & Recovery
        </h1>
        <p className="text-gray-600 mb-4">
          Manage Your Passwords, Login Preferences
        </p>

        <div className="relative mb-6">
          <select className="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
            <option>Change Password</option>
            {/* Add more options here if needed */}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"></div>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold text-gray-700 mb-2">
            Change Password
          </h2>
          <p className="text-gray-500 mb-4">
            Your password must be at least 6 characters and should include a
            combination of numbers, letters and special characters (!@$%).
          </p>
          <input
            type="password"
            placeholder="Current Password"
            className="mb-4 w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            placeholder="New Password"
            className="mb-4 w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            placeholder="Retype New Password"
            className="mb-4 w-full px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <Link to="#" className="text-blue-500 hover:underline">
            Forgotten Your Password?
          </Link >
        </div>

        <div className="mb-6">
          <label className="flex justify-start">
            <input
              type="radio"
              name="logout"
              className="form-radio mt-[6px] h-4 w-4 text-blue-600"
            />
            <span className="ml-2 text-gray-700">
              Log out of other devices. Choose this if someone else used your
              account.
            </span>
          </label>
        </div>

        <button className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-colors duration-300">
          Change Password
        </button>
      </div>
    </div>
  
  );
}

export default PasswordSecurity;
