// selectedBuildingSlice.js

import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
  selectedOptions: [],
};

// Create a slice
const selectedBedroomSlice = createSlice({
  name: 'selectedBedroom',
  initialState,
  reducers: {
    setSelectedOptions(state, action) {
      state.selectedOptions = action.payload;
    },
  },
});

// Export actions
export const { setSelectedOptions } = selectedBedroomSlice.actions;

// Export reducer
export default selectedBedroomSlice.reducer;
