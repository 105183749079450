import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
};
export const Card = ({ buttonLabel, title, description, image }) => {
  return (
    <div className="rounded overflow-hidden flex justify-center items-center flex-col bg-white shadow-lg m-0 p-2 border-2 w-full border-[#E5EFFF]">
      <img className="w-full h-48 mt-2" src={image} alt={buttonLabel} />
      <div className="px-6 py-6 text-center flex flex-col justify-between h-48">
        <div className="font-sixth text-[#1E2858] font-semibold text-base md:text-base lg:text-base">{title}</div>
        <p className="font-sixth text-sm md:text-sm lg:text-sm overflow-hidden overflow-ellipsis text-[#1E2858] mt-2">
          {description}
        </p>
        <button
          className="font-thirdCustom text-[#1E2858]  py-1  hover:bg-[#2E4374] font-semibold text-sm md:text-sm lg:text-base  hover:text-white  hover:border-[#1E2858] hover:border-2 mt-4 transition-transform transform hover:-translate-y-1 transition-all duration-300"
          style={{ borderRadius: '4px', border: '2px solid rgba(46, 67, 116, 0.70)' ,}}
        >
          {buttonLabel}
        </button>
      </div>
    </div>
  );
};

const BrowserProperty = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isTabletScreen = useMediaQuery('(max-width: 1100px)');
  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  const cards = [
    {
      description:
        'No matter what path you take to sell your home, we can help you navigate a successful sale.',
      image: "https://squarebric-media-files.squarebric.com/vecter1.png",
      buttonLabel: 'Browse home',
      title: 'Browse Home'
    },
    {
      description:
        'No matter what path you take to sell your home, we can help you navigate a successful sale.',
      image: "https://squarebric-media-files.squarebric.com/vecter2.png",
      buttonLabel: 'Find rental',
      title: 'Rent a Home'
    },
    {
      description: 'No matter what path you take to sell your home, we can help you navigate a successful sale.',
      image: "https://squarebric-media-files.squarebric.com/vecter3.png",
      buttonLabel: 'See your option',
      title: 'Sell a Home'
    }
  ];

  const goToPrevSlide = () => {
    const numCardsToShow = isMobileScreen ? 1 : isTabletScreen ? 2 : 3;
    const newIndex = currentIndex === 0 ? cards.length - numCardsToShow : currentIndex - numCardsToShow;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const numCardsToShow = isMobileScreen ? 1 : isTabletScreen ? 2 : 3;
    const newIndex = currentIndex === cards.length - numCardsToShow ? 0 : currentIndex + numCardsToShow;
    setCurrentIndex(newIndex);
  };

  const getCardsToShow = () => {
    const numCardsToShow = isMobileScreen ? 1 : isTabletScreen ? 2 : 3;
    const startIndex = currentIndex % cards.length;
    return cards
      .slice(startIndex, startIndex + numCardsToShow)
      .concat(cards.slice(0, Math.max(0, numCardsToShow - (cards.length - startIndex))));
  };

  return (
    <div className="container mx-auto relative">
      <div className="flex justify-center flex-wrap">
        {getCardsToShow().map((card, index) => (
          <div key={index} className={`w-full sm:w-full md:w-1/2 lg:w-1/3 p-4`}>
            <Card buttonLabel={card.buttonLabel} description={card.description} image={card.image} title={card.title} />
          </div>
        ))}
      </div>
      <button
        className={`absolute top-1/2 transform -translate-y-1/2 left-3 bg-white p-2 opacity-60  ${
          isTabletScreen || isMobileScreen ? '' : 'hidden'
        }`}
        onClick={goToPrevSlide}
      >
        <ArrowBackIosNewIcon />
      </button>
      <button
        className={`absolute top-1/2 transform -translate-y-1/2 right-3 bg-white p-2 opacity-60 ${
          isTabletScreen || isMobileScreen ? '' : 'hidden'
        }`}
        onClick={goToNextSlide}
      >
        <ArrowForwardIosIcon />
      </button>
    </div>
  );
};

export default BrowserProperty;
