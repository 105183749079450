import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { ProductSliderData } from '../../componenet/layout/UI/PropertyServices/ReviewData';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const SliderPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ProductSliderData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + ProductSliderData.length) % ProductSliderData.length);
  };

  let numberOfCardsToShow = 1;

  const visibleProperties = [];
  for (let i = 0; i < numberOfCardsToShow; i++) {
    const index = (currentIndex + i) % ProductSliderData.length;
    const review = ProductSliderData[index];
    const isLeftImage = (index % 2 === 0); // Alternating between left and right images
    visibleProperties.push({ review, isLeftImage });
  }

  return (
    <div className="mt-[px] w-full mb-10 md:mb-10 sm:mb-0">
      <div className="flex justify-center items-center">
        <Card className="w-full mt-4 relative sm:w-full sm:max-w-8xl flex flex-col">
          {visibleProperties.map(({ review, isLeftImage }, i) => (
            <div
              key={review.id}
              className={`grid w-full h-[670px] sm:h-auto ${isLeftImage ? 'md:grid-cols-2' : 'md:grid-cols-2 md:grid-flow-dense'} justify-center items-center gap-6`}
              style={{
                background: `url(${review.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundColor: review.backgroundImage || '#FFFFFF',
              }}
            >
              {isLeftImage ? (
                <>
                  <div className="flex justify-center items-center h-auto w-full p-5 ">
                    <img
                      className="object-contain w-full h-[300px] mb-5 max-w-full max-h-96"
                      src={review.image}
                      alt={`Review ${i + 1}`}
                    />
                  </div>
                  <div className="text-white pl-8 sm:w-[100%] pt- lg:w-[100%] h-auto ">
                    <h1 className="font-[600] text-[25px] leading-[20px] text-white ">
                      {review.Title}
                    </h1>
                    <p className="h-auto overflow-y-auto font-[400] lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px]  text-start leading-[30px] mr-4 text-white mt-3">
                      {review.description}
                    </p>
                  </div>
                </>
              ) : (
                <>
                  <div className="text-white pl-8 sm:w-[100%] pt- lg:w-[100%] h-auto ">
                    <h1 className="font-[600] text-[25px] leading-[20px] text-white ">
                      {review.Title}
                    </h1>
                    <p className="h-auto overflow-y-auto font-[00] text-[#2E4374]     lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px]  text-start leading-[30px] mr-4 text-white mt-3">
                      {review.description}
                    </p>
                  </div>
                  <div className="flex justify-center items-center h-auto w-full p-5 ">
                    <img
                      className="object-contain w-full h-[300px] mb-5 max-w-full max-h-96"
                      src={review.image}
                      alt={`Review ${i + 1}`}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
          <div className="flex justify-center items-center absolute inset-y-0 right-2 top-10">
            <div className="cursor-pointer text-sm text-white">
              <ArrowForwardIosIcon onClick={nextSlide} />
            </div>
          </div>
          <div className="flex justify-center items-center absolute inset-y-0 left-2 cursor-pointer text-sm text-white top-10">
            <ArrowBackIosNewIcon onClick={prevSlide} />
          </div>
        </Card>
      </div>
      <div className="flex justify-center mt-4">
        {ProductSliderData.map((_, index) => (
          <div
            key={index}
            className={`w-4 h-4 border-2 rounded-full mx-1 cursor-pointer ${currentIndex === index ? 'bg-gray-600' : ''}`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default SliderPage;
