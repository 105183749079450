import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchPropertyDetails = createAsyncThunk(
  'property/fetchPropertyDetails',
  async (property_id) => {
    const response = await fetch(`https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`);
    console.log(response.data.property_details);
    const data = await response.json();
    console.log(data.property_details);
    return data.property_details;
    
  }
);

const propertySlice = createSlice({
  name: 'property3',
  initialState: {
    propertyDetails: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPropertyDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.propertyDetails = action.payload;
      })
      .addCase(fetchPropertyDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default propertySlice.reducer;
