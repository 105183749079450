import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});

const MapViewUpdater = ({ position }) => {
  const map = useMap();
  map.setView(position, 13);
  return null;
};

const ServiceMap = () => {
  const [position, setPosition] = useState([28.6139, 77.209]);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);

  const provider = new OpenStreetMapProvider();

  const searchLocation = async () => {
    try {
      const results = await provider.search({ query: searchQuery });
      if (results && results.length > 0) {
        const { x, y, label } = results[0];
        setPosition([y, x]);
        setSearchQuery(label);
        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    searchLocation();
  };

  const handleMarkerDragEnd = async (event) => {
    const marker = event.target;
    const newPosition = marker.getLatLng();

    setPosition([newPosition.lat, newPosition.lng]);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newPosition.lat}&lon=${newPosition.lng}`
      );
      const data = await response.json();
      if (data && data.display_name) {
        setSearchQuery(data.display_name);
        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  return (
    <div className="mt-20">
      <div style={{ position: 'relative', height: '500px', zIndex: -100, margin: '10px' }}>
        <div style={{ height: '500px', width: '100%' }}>
          <MapContainer center={position} zoom={13} style={{ height: '100%', width: '100%' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker
              position={position}
              draggable={true}
              eventHandlers={{
                dragend: handleMarkerDragEnd
              }}
            >
              <Popup>{searchQuery || 'Selected Location'}</Popup>
            </Marker>
            <MapViewUpdater position={position} />
          </MapContainer>
        </div>
      </div>
    </div>
  );
};

export default ServiceMap;
