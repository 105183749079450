import React, { useState } from 'react';

function Notification() {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOptions, setSelectedOptions] = useState('');
  const options = [
    { id: 'content-inquiries', label: 'Content Inquiries' },
    { id: 'new-property-alert', label: 'New Property Alert' },
    { id: 'message', label: 'Message' }
  ];
  const optionreminder = [
    { id: 'Do not notifly me', label: 'Do not notifly me' },
    { id: 'important  reminders only', label: 'important  reminders only' },
    { id: 'All  reminders ', label: 'All  reminders ' }
  ];
  return (
    <div className="bg-[#FAFBFF] h-auto  ">
      <div className="border-b h-auto p-3 border-[#979797]">
        <h1 className="text-[#1E2858] font-[600] text-[24px]  fifth m-2 leading-[28px]">Setting</h1>
        <h5 className="m-2 text-[#2E4374] font-[600]   sixth leading-[30px] text-[20px]">Email Notification</h5>
        <p className="m-2 text-[#7D7D7D] text-[18px] sixth font-[600] leading-[27px] capitalize">
          get emails to find out what’s going on when you’re not online .you can turn them off anytime
        </p>
      </div>

      <div className="grid grid-cols-2 h-auto p-3  mt-6 border-b border-[#979797]">
        <div className="">
          <h1 className="m-2 text-[#2E4374] font-[600]   sixth leading-[30px] capitalize text-[20px]">
            {' '}
            notification from us
          </h1>
          <p className="m-2 text-[#7D7D7D] text-[18px] sixth font-[600] leading-[27px] capitalize">
            receive the latest update about inquires,
            <br /> new property alerts and messages.
          </p>
        </div>
        <div className="">
          {options.map((option) => (
            <div key={option.id} className="flex flex-row justify-start ">
              <input
                type="radio"
                id={option.id}
                name="options"
                value={option.id}
                checked={selectedOption === option.id}
                onChange={() => setSelectedOption(option.id)}
                className="form-radio mt-1 h-4 w-4 text-blue-600"
              />
              <label
                htmlFor={option.id}
                className="ml-2 text-[#2E4374] font-[600] capitalize  sixth leading-[30px] text-[16px]"
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-2 h-[200px] p-3  mt-6 border-b border-[#979797]">
        <div className="">
          <h1 className="m-2 text-[#2E4374] font-[600]   sixth leading-[30px] capitalize text-[20px]">reminders</h1>
          <p className="m-2 text-[#7D7D7D] text-[18px] sixth font-[600] leading-[27px] capitalize">
            These are notification to remind you of updates you might have missed
          </p>
        </div>
        <div className="">
          {optionreminder.map((option, index) => (
            <div key={option.id} className="flex flex-col mb-2">
              <div className="flex flex-row justify-start">
                <input
                  type="radio"
                  id={option.id}
                  name="options"
                  value={option.id}
                  checked={selectedOptions === option.id}
                  onChange={() => setSelectedOptions(option.id)}
                  className="form-radio mt-1 h-4 w-4 text-blue-600"
                />
                <label
                  htmlFor={option.id}
                  className="ml-2 text-[#2E4374] font-[600] capitalize  sixth leading-[30px] text-[16px]"
                >
                  {option.label}
                </label>
              </div>
              {index === 1 && (
                <p className="ml-6 text-[#7D7D7D] text-[14px] sixth font-[600] leading-[27px] capitalize">
                  Only notify me if the reminder is tagged as important.
                </p>
              )}
              {index === 2 && (
                <p className="ml-6 text-[#7D7D7D] text-[14px] sixth font-[600] leading-[27px] capitalize">
                  Notify me for all reminders.
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Notification;
