import React from 'react';
import circleagent from '../assets/circleagent.png';
import agentcircle1 from '../assets/agentcircle1.png';

const BecomAgent = () => {
  return (
    <div className="flex justify-center items-center mt-10">
      <div className="bg-[#1E2858] flex flex-col md:flex-row justify-center items-center w-full rounded-lg mt-12 relative">
        <img
          src="https://squarebric-media-files.squarebric.com/circleagent.png"
          alt="circleagent"
          className="absolute top-0 md:right-6 right-0 w-20 h-20 md:w-24 md:h-24 -mt-9 md:-mt-8"
        />

        <div className="w-full md:w-2/5 flex justify-center md:justify-end mb-4 md:mb-0 relative sm:h-[280px] lg:h-[250px] md:h-[250px] xl:h-[400px] md:-mt-4 lg:-mt-12 -mt-10">
          <img
            src="https://squarebric-media-files.squarebric.com/Agent.png"
            alt="agent"
            className="w-full max-w-xs md:max-w-none h-auto md:mr-8 lg:mr-1 object-cover "
          />
        </div>

        <div className="flex flex-col md:flex-row text-center w-full md:w-1/2">
          <div className="md:w-1/2 w-full mb-6 md:mb-0">
            <h1 className="text-3xl sm:text-2xl md:text-2xl lg:3xl xl:3xl 2xl:text-4xl 3xl:text-4xl font-fifth text-white mb-4">
              Become an Agent
            </h1>
            <p className="text-white font-sixth text-base m-4  md:m-4 md:text-base lg:text-base xl:text-lg mb-6 -tracking-tighter">
              Join our team and embark on a rewarding journey in real estate as an agent today
            </p>
            <button className="md:hidden font-SecondCustom text-sm sm:text-sm md:text-lg lg:text-lg bg-white hover:bg-[#343cb1d0] mt-0 sm:mt-0 hover:text-white px-3 py-2 rounded text-[#3A0CA3]">
              Register Now
            </button>
          </div>
          <div className="hidden md:flex items-center md:w-1/2 w-full justify-center">
            <button className="font-SecondCustom text-sm sm:text-sm md:text-sm lg:text-base bg-white  mt-4 sm:mt-4 md:mt-4 ml-2 md:px-0 px-2 py-2 rounded text-[#3A0CA3] mr-0 sm:mr-0 md:mr-10 text-center transition-transform transform hover:-translate-y-2 transition-all duration-400 md:w-44 ">
              Register Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomAgent;