
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
export const fetchServiceData = createAsyncThunk(
  'service/fetchServiceData',
  async ({ serviceType, currentPage, cardsPerPage = 5 }, thunkAPI) => {
   
    const skip = (currentPage - 1) * cardsPerPage;
    console.log('Fetching service data pankajfor:',skip,cardsPerPage);
    try {
      const response = await axios.get(
        `https://square-bric-backend-api.squarebric.com/home-service/home-service-most-demanding?country=India&service_type=${encodeURIComponent(
          serviceType
        )}&skip=${skip}&limit=${cardsPerPage}`
      );
      return response.data.service_details;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    totalCount: 0,
  },
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.documents;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchServiceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setCurrentPage } = serviceSlice.actions;

export default serviceSlice.reducer;
