import { useState } from 'react';
import {
  Button,
  Box,
  Typography,
  Container,
  Card,
  FormControl,
  TextField,
  IconButton,
  InputAdornment,
  CssBaseline
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../page/main.css';

const defaultTheme = createTheme();

export default function Resetpassword() {
  const [showPassword, setShowPassword] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const ismediumScreen = useMediaQuery('(min-width:1024px)');

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="flex justify-center items-center mt-36 mb-24 relative">
        {!isSmallScreen && (
          <>
            <img src="https://squarebric-media-files.squarebric.com/login2.png" alt="Slide" className="absolute top-0 right-0" width={300} />
            <img src="https://squarebric-media-files.squarebric.com/login1.png" alt="Review" className="absolute -bottom-24 left-0 b" width={300} />
          </>
        )}
        {isSmallScreen && (
          <>
            <img src="https://squarebric-media-files.squarebric.com/login7.png" alt="Slide" className="absolute -top-2  right-0" width={150} />
            <img src="https://squarebric-media-files.squarebric.com/login6.png" alt="Review" className="absolute -bottom-24  -left-1  " width={150} />
          </>
        )}

        <ThemeProvider theme={defaultTheme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Card
              sx={{ marginTop: 14, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              className="p-8"
            >
              <Typography style={{ color: '#2e4374', fontFamily: 'Libre Caslon Text' }} component="h1" variant="h5">
                Reset Your Password ?
              </Typography>
              <Typography
                style={{
                  color: '#2e4374',
                  fontFamily: 'Segoe UI',
                  justifyContent: 'center',
                  display: 'flex',
                  marginTop: '20px',
                  textAlign: 'center'
                }}
              >
                Strong Password include numbers,letters,and Punctuation marks
              </Typography>

              <Box component="form" noValidate sx={{ mt: 3, width: '100%' }}>
                <div className="mt-6">
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#20206F', fontFamily: 'Segoe UI', fontWeight: 'bold' }}
                  >
                    Enter New Password
                  </Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="password"
                      id="password"
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      className=""
                      // variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </div>

                <div className="mt-6">
                  <Typography
                    variant="subtitle1"
                    style={{ color: '#20206F', fontFamily: 'Segoe UI', fontWeight: 'bold' }}
                  >
                    Confirm New Password
                  </Typography>
                  <FormControl fullWidth>
                    <TextField
                      name="password"
                      id="password"
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      className=""
                      // variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </FormControl>
                </div>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold'
                  }}
                >
                  <Link to='/'> Reset Password </Link>
                </Button>
              </Box>
            </Card>
          </Container>
        </ThemeProvider>
      </div>
    </>
  );
}
