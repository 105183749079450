import React from 'react';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ScrollToTop from './common/ScrollToTop';
import ProtectedRoute from './componenet/ProtectedRoute';

// Import components directly
import Root from './componenet/Root';
import MainPage from './page/MainPage';
import Login from './page/auth/Login';
import Service from './componenet/layout/UI/PropertyServices/Service';
import Mainblog from './page/Blog/Mainblog';
import About from './page/AboutPage/About';
import Featured from './page/FeaturedProject/Featured';
import Sign from './page/auth/Sign';
import Forgetpassword from './page/auth/Forgetpassword';
import Resetpassword from './page/auth/Resetpassword';
import SignUp from './page/auth/Sign';
import SinglePropertyPage from './componenet/layout/UI/PropertyServices/SinglePropertyPage';
import AllPropertyPage from './componenet/layout/UI/PropertyServices/AllPropertyPage';
import Layout from './DashboardAdmin/Layout/Layout';
import SingleSearchProperty from './page/SearchProperty/SingleSearchProperty';
import Property from './DashboardAdmin/Property/Property';
import HomeServices from './DashboardAdmin/Listing/HomeServices/HomeServices';
import PropertyListinfService from './DashboardAdmin/Propertyhomeservice/PropertyListinfService';
import AllSearchProperty from './page/SearchProperty/AllSearchProperty';
import Setting from './DashboardAdmin/Setting/Setting';
import MemberShip from './DashboardAdmin/membership/MemberShip';
import InquiryData from './DashboardAdmin/InqueryData/InquiryData';
import PropertyInquery from './DashboardAdmin/InqueryData/PropertyInquery';
import ServiceInquery from './DashboardAdmin/InqueryData/ServiceInquery';
import MobileFilter from './componenet/layout/UI/SelectFilter/MobileFilter';
import DemandingService from './componenet/layout/UI/PropertyServices/DemandingService';
import ServiceDetail from './componenet/layout/UI/PropertyServices/ServiceDetails';
import Myunits from './DashboardAdmin/Myunit/Myunits';
import EditProperty from './DashboardAdmin/EditMyunits/EditProperty';
import Board from './DashboardAdmin/Board/Board';
import Cookies from './page/Footer/Cookies';
import TermsConditions from './page/Footer/TermsConditions';
import Faq from './page/Footer/Faq';
import Membership from './page/Footer/Membership';
import Privacypolicy from './page/Footer/Privacypolicy';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      { path: '/', element: <MainPage /> },
      { path: '/login', element: <Login /> },
      { path: '/sign', element: <Sign /> },
      { path: '/forgetpassword', element: <Forgetpassword /> },
      { path: '/resetpassword', element: <Resetpassword /> },
      { path: '/signup', element: <SignUp /> },
      { path: '/servicepage', element: <Service /> },
      { path: '/search', element: <AllPropertyPage /> },
      { path: '/singleproperty/:index', element: <SinglePropertyPage /> },
      { path: '/blog', element: <Mainblog /> },
      { path: '/about-us', element: <About /> },
      { path: 'service-detail/:serviceType', element: <ServiceDetail /> },
      { path: '/singlesearchproperty', element: <SingleSearchProperty /> },
      { path: '/buy', element: <MobileFilter /> },
      { path: '/featured', element: <Featured /> },
      { path: '/searchproperty', element: <AllSearchProperty /> },
      { path: '/searchproperty/:property_id', element: <SingleSearchProperty /> },
      { path: '/cookies', element: <Cookies /> },
      { path: '/termsconditions', element: <TermsConditions /> },
      {path:'/faq',element:<Faq/>},
      {path:'/membership',element:<Membership/>},
      {path:'/privacypolicy',element:<Privacypolicy/>},

      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        ),
        children: [
          { path: '', element: <Navigate to="property" replace /> },
          {
            path: 'property',

            element: (
              <ProtectedRoute>
                <Property />
              </ProtectedRoute>
            )
          },
          {
            path: 'homeservices',
            element: (
              <ProtectedRoute>
                <HomeServices />
              </ProtectedRoute>
            )
          },
          {
            path: 'propertylisting',
            element: (
              <ProtectedRoute>
                <PropertyListinfService />
              </ProtectedRoute>
            )
          },
          {
            path: 'setting',
            element: (
              <ProtectedRoute>
                <Setting />
              </ProtectedRoute>
            )
          },
          {
            path: 'membership',
            element: (
              <ProtectedRoute>
                <MemberShip />
              </ProtectedRoute>
            )
          },
          {
            path: 'inquiry',
            element: (
              <ProtectedRoute>
                <InquiryData />
              </ProtectedRoute>
            )
          },
          {
            path: 'board',
            element: (
              <ProtectedRoute>
                <Board />
              </ProtectedRoute>
            )
          },
          {
            path: 'myunits',
            element: (
              <ProtectedRoute>
                <Myunits />
              </ProtectedRoute>
            )
          },
          {
            path: 'edit-property/:property_id',
            element: (
              <ProtectedRoute>
                <EditProperty />
              </ProtectedRoute>
            )
          }
        ]
      }
    ]
  }
]);

function App() {
  return (
    <RouterProvider router={router}>
      <ScrollToTop />
    </RouterProvider>
  );
}

export default App;
