import React from 'react';
import StateAndCity from '../SearchFunctionality/StateAndCity';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MobileAllfilter from '../SelectFilter/MobileAllfilter';
import Bedroom from '../multiselcter/Bedroom';
import MuiltiselectArea from '../multiselcter/MultiselecterArea';
import Area from '../multiselcter/Area';
import Bathroom from '../multiselcter/Bathroom';

const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%)'
};

const MobileFilter = () => {
  return (
    <div className="mt-20 ml-4 ">
      <div className="flex justify-around items-center bg-[#EFEFED]">
        <div className="flex items-center">
          <div>
            <KeyboardArrowLeftIcon />
          </div>
          <StateAndCity />
        </div>
        <div className="px-6 font-sixth py-1 h-10 flex justify-center items-center text-sm" style={headerStyle}>
          Search
        </div>
      </div>
      <div>
        <div>
          <button>Buy</button>
          <button>Sell</button>
        </div>
      </div>
      <div>
    <div className="w-[80vw] mt-6">
        <MobileAllfilter />
      </div>
      <div className="w-[80vw] mt-6 -ml-1">
        <h1 className='text-[#737579;] font-[500] font-thirdCustom text-xl mb-2'>
          Price Range
        </h1>
        <MuiltiselectArea />
      </div>
      <div className="w-[80vw] mt-6">
        <h1 className='ml-2 text-[#737579;] font-[500] font-thirdCustom text-xl mb-2'>
          <img src="https://d34yb5orp91dp6.cloudfront.net/area5.svg" alt="Icon" className="inline-block mr-2" />
          Area
        </h1>
        <Area />
      </div>
      <div className="w-[80vw] mt-6">
        <h1 className='text-[#737579;] font-[500] font-thirdCustom text-xl mb-2'>
          <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED.svg" alt="Icon" className="inline-block mr-2" />
          Bedroomd
        </h1>
        <Bedroom />
      </div>
      <div className="w-[80vw] mt-6">
        <h1 className='text-[#737579;] font-[500] font-thirdCustom text-xl mb-2'>
          <img src="https://d34yb5orp91dp6.cloudfront.net/MGBED2.svg" alt="Icon" className="inline-block mr-2" />
          Bathroom
        </h1>
        <Bathroom />
      </div>
      </div>
    </div>
  );
};

export default MobileFilter;
