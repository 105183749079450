import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  

  propertyType: '',
  subPropertyType: [],
  selectedMin: null,
  selectedMax: null,
  selectedCurrency: '',
  min_total_area: null,
  max_total_area: null,
  selectedAreaOption: '',
  min_Bathrooms: null,
  max_Bathrooms: null,
  min_Bedrooms: null,
  max_Bedrooms: null,
};

// Create slice
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    clearSelectedValues: (state) => {
    
      state.propertyType = '';
      state.subPropertyType = [];
      state.selectedMin = null;
      state.selectedMax = null;
      state.selectedCurrency = '';
      state.min_total_area = null;
      state.max_total_area = null;
      state.selectedAreaOption = '';
      state.min_Bathrooms = null;
      state.max_Bathrooms = null;
      state.min_Bedrooms = null;
      state.max_Bedrooms = null;
    },
    updateSelectedValues: (state, action) => {
      const {
       
        propertyType,
        subPropertyType,
        selectedMin,
        selectedMax,
        selectedCurrency,
        min_total_area,
        max_total_area,
        selectedAreaOption,
        min_Bathrooms,
        max_Bathrooms,
        min_Bedrooms,
        max_Bedrooms,
      } = action.payload;

     
      state.propertyType = propertyType;
      state.subPropertyType = subPropertyType;
      state.selectedMin = selectedMin;
      state.selectedMax = selectedMax;
      state.selectedCurrency = selectedCurrency;
      state.min_total_area = min_total_area;
      state.max_total_area = max_total_area;
      state.selectedAreaOption = selectedAreaOption;
      state.min_Bathrooms = min_Bathrooms;
      state.max_Bathrooms = max_Bathrooms;
      state.min_Bedrooms = min_Bedrooms;
      state.max_Bedrooms = max_Bedrooms;
    },
  },
});

// Export actions
export const { clearSelectedValues, updateSelectedValues } = filterSlice.actions;

// Export reducer
export default filterSlice.reducer;
