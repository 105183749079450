import React, { useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../index.css';

const bg = {
  background: 'rgba(217, 217, 217, 0.80)'
};

const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
};

const Card = ({ title, description, image, tag }) => (
  <div className="bg-white shadow-sm flex flex-col md:flex-row mb-4 -mt-1 md:mt-0 sm:mt-1 lg:mt-1 xl:mt-1 2xl:mt-1 3xl:mt-1 w-full">
    <div className="w-full md:w-1/2 mb-2 md:mb-0 relative">
      <img src={image} alt={title} className="w-full md:h-[220px] h-[190px] object-cover" />
      <span
        className={`absolute top-10 -left-0 px-2 py-1 text-xs font-semibold font-thirdCustom text-[#1E2858] rounded-sm uppercase ${
          tag === 'Residential' ? 'bg-green-500' : 'bg-blue-500'
        }`}
        style={bg}
      >
        {tag}
      </span>
    </div>
    <div className="w-full md:w-1/2 h-full pl-2 pr-2">
      <h2 className="text-base font-sixth text-[#1e2858] font-bold mb-1 md:text-left text-center">{title}</h2>
      <p className="text-gray-700 font-sixth text-[15px] md:text-sm md:text-left text-center">{description}</p>
      <div className="flex justify-center items-center mt-4 mb-4">
        <div className="grid grid-cols-2 gap-6 ">
          <div className="flex items-center ">
            {/* <img src="https://squarebric-media-files.squarebric.com/Map_Pin.png" alt="icon1" className="h-5 w-5 md:mb-0" /> */}
            <div className=" flex-shrink-0 -ml-1">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.63609 4.63627C2.12139 8.15092 2.12129 13.8493 5.63587 17.3641L9.87998 21.6072C11.0516 22.7776 12.9494 22.7776 14.1208 21.6075L18.3641 17.3641C21.8787 13.8493 21.8786 8.15092 18.3639 4.63627C14.8492 1.12163 9.1508 1.12163 5.63609 4.63627ZM16.9497 6.0505C19.6834 8.78411 19.6834 13.2162 16.9499 15.9499L12.7069 20.1929C12.3168 20.5826 11.6842 20.5826 11.2937 20.1925L7.05002 15.9498C4.31656 13.2162 4.31664 8.78411 7.05029 6.0505C9.78396 3.31688 14.216 3.31688 16.9497 6.0505ZM12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7ZM12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9Z"
                  fill="#2e4374"
                />
              </svg>
            </div>
            <p className="text-base mt-1 mr-5 font-sixth">Istanbul</p>
          </div>
          <div className="flex items-center ml-10">
            <img src="https://squarebric-media-files.squarebric.com/MGBED.svg" alt="icon4" className="h-5 w-5" />
            <p className="mr-6 mt-1 text-base font-sixth">2 </p>
          </div>
          <div className="flex items-center -mt-5">
            <img src="https://squarebric-media-files.squarebric.com/Area6.svg" alt="icon1" className="h-5 w-5 md:mb-0" />
            <p className="text-base mr-5 mt-1 font-sixth">50 sq.m</p>
          </div>
          <div className="flex items-center -mt-4 ml-10">
            <img src="https://squarebric-media-files.squarebric.com/MGBED2.svg" alt="icon4" className="h-5 w-5" />
            <p className=" mt-1 -mb-1 mr-6 ml-2 text-base font-sixth"> 3 </p>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-6 mr-1">
        <button className="text-[#1E2858] font-semibold text-sm -mt-1 md:text-sm lg:text-sm px-3 py-0 md:text-[15px] sm:text-[15px] border-2 border-[#2E4374] rounded hover:bg-[#2E4374] hover:text-white transition-transform transform hover:-translate-y-1 transition-all duration-300 mb-4 md:mb-0">
          View Details 
          {/* <ArrowForwardIcon className="" style={{ fontSize: '16px' }} /> */}
        </button>
      </div>
    </div>
  </div>
);

const BestResidential = () => {
  const images = [
    'https://squarebric-media-files.squarebric.com/bestRes.png',
    'https://squarebric-media-files.squarebric.com/imageSlider.jpg'
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [activeButton, setActiveButton] = useState(null);

  const handlePrevImage = () => {
    setActiveButton('prev');
    setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextImage = () => {
    setActiveButton('next');
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const isSmallScreen = useMediaQuery('(max-width:768px)');

  const buttonStyles = (button) => ({
    backgroundColor: activeButton === button ? '#1E2858' : 'white',
    borderColor: '#e5e7eb',
    color: activeButton === button ? 'white' : 'currentColor'
  });
  return (
    <div className="flex justify-center mt-5 sm:mt-5 md:mt-16 w-full sm:w-full lg:ml-6">
      <div className="grid sm:grid-cols-1 grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-5 w-full p-0 sm:p-0">
        <div className="text-center relative ">
          <h1
            className=" text-sm sm:text-sm md:text-3xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-[700] text-start text-[#1E2858] font-fifth uppercase mb-4 w-full"
            style={{ textAlign: isSmallScreen ? 'left' : '' }}
          >
            {/* Best Residential Properties for You */}
            <span className="underlineresponsive">Best Residential</span> Properties for You
            {/* <span
              className="absolute w-1/3 h-1 lg:mt-10 md:mt-8 mt-5 md:left-0 left-0 bg-[#4BA3DB]"
              style={{ marginBottom: '2px' }}
            ></span> */}
          </h1>
          <div className="relative h-64 2xl:h-[650px] xl:h-[630px] lg:h-[600px] md:h-[500px] w-full overflow-hidden">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt="Residential Property"
                className={`absolute inset-0 h-full w-full transition-opacity duration-500 ${
                  currentImageIndex === index ? 'opacity-100' : 'opacity-0'
                }`}
              />
            ))}
            <div className="absolute bottom-0 flex justify-end w-full">
              <button
                onClick={handlePrevImage}
                className="focus:outline-none border-2 p-2 hover:bg-[#1E2858] opacity-55"
                style={buttonStyles('prev')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke={activeButton === 'prev' ? 'white' : 'currentColor'}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button
                onClick={handleNextImage}
                className="focus:outline-none border-2 p-2 hover:bg-[#1E2858] opacity-55"
                style={buttonStyles('next')}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke={activeButton === 'next' ? 'white' : 'currentColor'}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className="p-0 sm:p-4 md:p-2 w-full sm:w-full xl:w-full lg ">
          <Card
            title="Tranquil Oaks Estates: Your Gateway to Elevated Living"
            description="Discover Tranquil Oaks Estates: where luxury and peace meet."
            image="https://squarebric-media-files.squarebric.com/res1.png"
            tag="Residential"
          />
          <Card
            title="The Grand Residences at Sunset Park Residence"
            description="Experience the Majesty and Comfort of Royal Living at Harmony Place"
            image="https://squarebric-media-files.squarebric.com/res2.png"
            tag="Residential"
          />
          <Card
            title="The Elite Residences at Prestige Point"
            description="Enjoy Signature Living with Breathtaking Views and Top-Tier Amenities"
            image="https://squarebric-media-files.squarebric.com/res3.png"
            tag="Residential"
          />
        </div>
      </div>
    </div>
  );
};

export default BestResidential;
