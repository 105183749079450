import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PropertyInquiriesChart = () => {
  // Data for different time periods
  const [selectedOption, setSelectedOption] = useState('Monthly');
  const [data, setData] = useState({
    monthly: {
      labels: ['1 week', '2 week', '3 week', '4 week'],
      dataPoints: [100, 150, 120, 400]
    },
    yearly: {
      labels: ['Q1', 'Q2', 'Q3', 'Q4'],
      dataPoints: [1200, 900, 1700, 1500]
    },
    weekly: {
      labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      dataPoints: [80, 100, 130, 140, 110, 90, 120]
    }
  });

  // Handle dropdown change
  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const selectedData = data[selectedOption.toLowerCase()];

  // Chart.js data and options
  const chartData = {
    labels: selectedData.labels,
    datasets: [
      {
        label: 'Inquiries',
        data: selectedData.dataPoints,
        fill: true, // Ensures the area beneath the line is filled, making it an area graph
        backgroundColor: 'rgba(75, 192, 192, 0.2)', // The area fill color
        borderColor: 'rgba(75, 192, 192, 1)', // Line color
        pointBackgroundColor: 'rgba(75, 192, 192, 1)', // Color of the points
        pointBorderColor: '#fff', // Border color of the points
        borderWidth: 2,
        tension: 0.4, // Curve the line
        pointHoverRadius: 5
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
    },
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false
        }
      }
    }
  };

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg h-[400px] ">
      {/* Title and Dropdown */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-blue-900">Properties Inquires</h2>
        <div>
          <select
            value={selectedOption}
            onChange={handleDropdownChange}
            className="bg-gray-100 text-gray-600 text-sm py-1 px-3 rounded-lg"
          >
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
            <option value="Weekly">Weekly</option>
          </select>
        </div>
      </div>

      {/* Subtitle */}
      <p className="text-gray-500 text-sm mt-1">
        Overall average inquiries of {selectedOption === 'Monthly' ? 'April 2024' : 'this year'}
      </p>

      {/* Line Chart (now Area Graph) */}
      <div className="h-64 mt-4">
        <Line data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default PropertyInquiriesChart;
