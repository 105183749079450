import React, { useState } from 'react';
import TotalPropertyListing from './TotalPropertyListing';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import PropertyInquiry from './PropertyInquiry';
import PropertyListing from "./PropertyListing"
import ServiceListing from './ServiceListing';

ChartJS.register(ArcElement, Tooltip, Legend);

function PropertyListingApproval() {
  const [approvalData, setApprovalData] = useState({
    approve: 60,
    disapprove: 30,
    pending: 10
  });

  // Function to update approval data dynamically
  const updateApprovalData = (newApprovalData) => {
    setApprovalData(newApprovalData);
  };

  const [data1, setData1] = useState({
    labels: ['Approve', 'Disapprove', 'Pending'],
    datasets: [
      {
        data: [approvalData.approve, approvalData.disapprove, approvalData.pending],
        backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
      }
    ]
  });

  const [data2, setData2] = useState({
    labels: ['Approve', 'Disapprove', 'Pending'],
    datasets: [
      {
        data: [50, 35, 15],
        backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
      }
    ]
  });

  const [data3, setData3] = useState({
    labels: ['Approve', 'Disapprove', 'Pending'],
    datasets: [
      {
        data: [40, 40, 20],
        backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
      }
    ]
  });

  return (
    <div className="ml-[8%] grid grid-cols-1 md:grid-cols-2  gap-4 px-5">
      <div className="bg-[#DFE4F4] p-5 rounded-lg shadow-lg">
        <TotalPropertyListing/>
      </div>

      <div className="bg-[#DFE4F4] p-5 rounded-lg shadow-lg">
           <PropertyInquiry/>
      </div>

      <div className="bg-[#DFE4F4] p-5 rounded-lg shadow-lg">
     <PropertyListing/>
      </div>
      <div className="bg-[#DFE4F4] p-5 rounded-lg shadow-lg">
     <ServiceListing/>
      </div>

    </div>
  );
}

export default PropertyListingApproval;
