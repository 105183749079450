import React, { useState, useRef, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import bedroom from '../../../../assets/bedroom2.svg';
const Bathroom = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Reset values when component mounts
    setMinValue('');
    setMaxValue('');

    // Clean up values when component unmounts
    return () => {
      setMinValue('');
      setMaxValue('');
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const incrementMin = () => {
    setMinValue((prevMin) => {
      const newMin = prevMin === '' ? 1 : prevMin + 1;

      // Ensure minValue does not exceed 19 (to keep it less than maxValue)
      if (newMin >= 20) {
        return 19;
      }

      // Reset maxValue if newMin becomes equal to or greater than maxValue
      if (maxValue !== '' && newMin >= maxValue) {
        setMaxValue(''); // Reset max if min >= max
      }

      return newMin;
    });
  };

  const decrementMin = () => {
    setMinValue((prevMin) => {
      if (prevMin <= 1) {
        return ''; // Set to empty string if minValue goes below 1
      }
      const newMin = prevMin - 1;
      if (newMin === '' && maxValue <= prevMin) {
        setMaxValue(''); // Reset max if min is empty and max <= old min
      }
      return newMin;
    });
  };

  const incrementMax = () => {
    setMaxValue((prevMax) => {
      // Calculate new max value
      const newMax = prevMax === '' ? (minValue !== '' ? minValue + 1 : 1) : prevMax + 1;

      // Ensure maxValue does not exceed 20
      return newMax > 20 ? 20 : newMax;
    });
  };

  const decrementMax = () => {
    setMaxValue((prevMax) => {
      if (prevMax <= 1) {
        return ''; // Set to empty string if maxValue goes below 1
      }

      const newMax = prevMax - 1;

      // If minValue becomes greater than newMax, reset minValue to empty string
      if (minValue !== '' && minValue >= newMax) {
        setMinValue(''); // Reset minValue if it's greater than maxValue
      }

      return newMax;
    });
  };

  const displayText = minValue === '' && maxValue === '' ? 'Bedrooms' : `Min: ${minValue} Max: ${maxValue}`;

  return (
    <div className="border-2 border-gray-300 rounded h-10">
      <div className="relative inline-block w-[170px]" ref={dropdownRef}>
        <div onClick={toggleDropdown} className="  px-1 border-none mt-1 border-2 flex justify-between items-center">
          <div className="mr-2 mt-0.5 flex justify-between items-center w-[170px]">
            <div className="flex items-center mt-1">
              <img src="https://squarebric-media-files.squarebric.com/bedroom2.svg" alt="bedroom" className="h-5 w-5 " />
              <span className="ml-2 font-sixth text-[#2E4374] font-[600] text-[14px] pl-2 ">
                {isDropdownOpen ? (
                  <div className="flex">
                    <span className="min-w-[40px] text-ellipsis overflow-hidden whitespace-nowrap pr-1">
                      Min: {minValue}
                    </span>
                    <span className="min-w-[40px]  text-ellipsis overflow-hidden whitespace-nowrap">
                      Max: {maxValue}
                    </span>
                  </div>
                ) : (
                  displayText
                )}
              </span>
            </div>

            <KeyboardArrowDownIcon
              style={{
                fontSize: '25px', // Reduced size to fit
                transition: 'transform 0.3s ease-in-out',
                transform: `rotate(${isDropdownOpen ? 180 : 0}deg)`
              }}
            />
          </div>
        </div>

        {isDropdownOpen && (
          <div className="absolute top-9 mt-0 right-0 bg-white border rounded shadow-lg h-auto w-40 z-10">
            <div className="p-2">
              {/* Min Value Controls */}
              <div className="flex items-center mb-2">
                <span className="mr-2 text-[14px] font-[600] font-sixth ml-0.5">Min:</span>
                <button
                  onClick={decrementMin}
                  className={`bg-gray-500 text-white w-8 rounded-l border ${
                    minValue === '' ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={minValue === ''} // Disable if min value is empty string
                >
                  -
                </button>
                <span className=" w-6 mr-2 text-center">{minValue === '' ? '' : minValue}</span>
                <button onClick={incrementMin} className="bg-gray-500 text-white w-8 rounded-r border">
                  +
                </button>
              </div>

              {/* Max Value Controls */}
              <div className="flex items-center">
                <span className="mr-2 text-[14px] font-[600] font-sixth">Max:</span>
                <button
                  onClick={decrementMax}
                  className={`bg-gray-500 text-white w-8 rounded-l border ${
                    maxValue === '' ? 'opacity-50 cursor-not-allowed' : ''
                  }`}
                  disabled={maxValue === ''} // Disable if max value is empty string
                >
                  -
                </button>
                <span className="mr-2 w-6 text-center">{maxValue === '' ? '' : maxValue}</span>
                <button onClick={incrementMax} className="bg-gray-500 text-white w-8 rounded-r border">
                  +
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Bathroom;
