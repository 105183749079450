import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { usersData } from './PersinalInfo';
import Notification from './Notification';
import PasswordSecurity from './PasswordSecurity';
import EditNoteIcon from '@mui/icons-material/EditNote';

function Setting() {
  const [userData, setUserData] = useState(null);
  const [show, setShow] = useState(0);
  let locations = useLocation();
  // let pathnames=locations.pathname .split('/').filter(Boolean) .join(' > '); // Remove empty elements

  const pathnames = locations.pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1];
  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem('userData');
    console.log('name', storedUserData);
    if (storedUserData) {
      // Parse the JSON string into a JavaScript object
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, [userData]);
  const name = userData ? userData.name : '';

  console.log('we', usersData);
  const handleNotification = (current) => {
    console.log('notification');
    setShow(current);
  };
  const handlePasswordSecurity = (current) => {
    console.log('password');
    setShow(current);
  };
  console.log('show', show);
  return (
    <div className="ml-[7%]">
      <div class="flex justify-end bg-[#D9D9D9] p-3  ">
        <div class="flex ">
          <PersonIcon className="mr-[20px]"></PersonIcon>

          <p className="pr-6"> {name}</p>
        </div>
      </div>
      <div className=" mt-4">
        <span className="text-[#1E2858] ml-5 font-[400] mt-6 text-[16px]  capitalize">
          <Link to="/dashboard" className="text-[#7D7D7D]  font-[400] text-[18px] capitalize">
            Dashboard
          </Link>
          <NavigateNextIcon className=" block text-[14px] mb-[2px] h-3" />
          <span className='text-[#2E4374]'>  {`${lastSegment}`}</span>
        </span>
      </div>

      <div className=" mx-auto  px-4 py-8">
        <div className="flex justify-between">
          <h1 className="text-[28px] font-[500] text-[#2E4374] leading-[36px] mb-4">Personal Information</h1>
          <button className='text-[#061E35] text-[18px] font-[400] leading-[21px] capitalize'>
            edit
            <EditNoteIcon></EditNoteIcon>
          </button>
        </div>

        <div className="overflow-x-auto bg-white border border-gray-200 pb-20 h-auto shadow ">
          <table className="min-w-full ">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Personal Information
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact Information
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 ">
              {usersData.map((user, index) => (
                <tr key={index} className="bg-white">
                  {/* Column 1: Image */}
                  <td className="px-6 py-4  flex items-center justify-center flex-col ">
                    <img className="h-[150px] w-[150px] rounded-full" src={user.image} alt={user.name} />
                    <p className="  text-[16px] text-[#061E35] font-[500] leading-[16.94px] capitalize  mb-1 pl-6 text-left">{`${user.name}`}</p>
                    <p className="pl-5  text-[#818EAE] capitalize">{`User id: ${user.id}`}</p>
                  </td>

                  {/* Column 2: Personal Information */}
                  <td className="px-3 py-2">
                    <div className="  flex justify-between gap-5 border  border-gray-400 p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">First Name</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.name}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Middle Name</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.middleName}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Last Name</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.lastName}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Dispaly Name</p>
                      <p className="text-[#4BA3DB] leading-[16.94px] text-[16px] capitalize">{`${user.name}${user.middleName}${user.lastName}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">DOB</p>
                      <p className="text-[#4BA3DB]  leading-[16.94px] text-[16px] capitalize">{`${user.dob}`}</p>
                    </div>
                    <div className="flex justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Gender</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.gender}`}</p>
                    </div>
                  </td>

                  {/* Column 3: Contact Information */}
                  <td className="px-3 py-2 ">
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Country</p>
                      <p className="text-[#4BA3DB]  leading-[16.94px] text-[16px] capitalize">{`${user.country}`}</p>
                    </div>
                    <div className="flex justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">State</p>
                      <p className="text-[#4BA3DB]  leading-[16.94px] text-[16px] capitalize">{`${user.state}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">City</p>
                      <p className="text-[#4BA3DB]  leading-[16.94px] text-[16px] capitalize">{`${user.city}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Email</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.email}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-3">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize">Phone</p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize">{`${user.phone}`}</p>
                    </div>
                    <div className="flex  justify-between gap-5 border  border-gray-400  p-5">
                      <p className="text-[#818EAE] leading-[16.94px] text-[16px] capitalize"></p>
                      <p className="text-[#061E35] leading-[16.94px] text-[16px] capitalize"></p>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" px-4 ">
        <div className="flex justify-start gap-5 ">
          <button
            onClick={() => handleNotification(0)}
            className={`border-b-2 ${show === 0 ? ' border-b-[#4BA3DB] transition duration-300' : ''} `}
          >
            Notification
          </button>
          <button
            onClick={() => handlePasswordSecurity(1)}
            className={`border-b-2  ${show === 1 ? 'border-b-[#4BA3DB] transition duration-300' : ''} `}
          >
            Password & Security
          </button>
        </div>
        <div className="transition-opacity duration-300 mt-5">
          {show === 0 ? <Notification /> : <PasswordSecurity />}
        </div>
      </div>
    </div>
  );
}

export default Setting;
