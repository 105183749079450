import React, { useState } from 'react';
import { Card, CardContent } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Videodata } from './Videodata';

const Video = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = isSmallScreen ? 4 : 8; // Number of cards to display per page

  // Logic to calculate start and end index of cards to display
  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const displayedCards = Videodata.slice(startIndex, endIndex);

  const pageCount = Math.ceil(Videodata.length / cardsPerPage); // Total number of pages

  // Logic to render pagination buttons
  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const pageNumbers = Math.min(maxVisiblePages, pageCount);
    const pagesBeforeEllipsis = Math.min(currentPage - 1, Math.floor((maxVisiblePages - 1) / 2));
    const pagesAfterEllipsis = Math.min(pageCount - currentPage, Math.floor((maxVisiblePages - 1) / 2));
    const startPage = Math.max(1, currentPage - pagesBeforeEllipsis);
    const endPage = Math.min(pageCount, currentPage + pagesAfterEllipsis);

    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i ? 'text-white rounded-full bg-[#2e4374]' : 'bg-gray-200 text-[#2e4374] rounded-full'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(1)}
              className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-[#2e4374] text-white' : 'bg-gray-200'}`}
            >
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {buttons}
        {endPage < pageCount - 1 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {endPage < pageCount && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(pageCount)}
              className={`px-3 py-1 rounded ${currentPage === pageCount ? 'bg-[#2e4374] text-white' : 'bg-gray-200'}`}
            >
              {pageCount}
            </button>
          </li>
        )}
      </>
    );
  };

  return (
    <div className='mx-auto max-w-7xl'>
      {/* Main Content */}
      <div className="flex justify-center  flex-col md:p-10 p-6 md:ml-16 ">
        <h1 className="text-[#1e2858] md:text-xl text-base font-semibold font-thirdCustom">Video Gallery</h1>
        <p className="text-[#1e2858] md:text-base text-xs mt-4 font-semibold font-thirdCustom">
          Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled
          by its experience and expertise in managing residential and commercial properties.
        </p>
      </div>

      <div className="flex mt-6 justify-center mb-10 ">
        <div className="grid gap-10 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:w-11/12 ">
          {displayedCards.map((item) => (
            <Card
              key={item.id}
              style={{ border: '1px solid #bdbdbd', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
            >
              <CardContent>
                <video className="w-full object-fill p-1" title={item.title} src={item.video} controls></video>
                <div className="mt-4">
                  <h2 className="font-thirdCustom text-[#1E2858] text-sm md:text-lg font-semibold">{item.title}</h2>
                  <p className="font-thirdCustom text-[#1E2858] text-xs mt-2">{item.description}</p>
                </div>

                <div className="text-[#1E2858] text-xs font-semibold mt-3 mb-0">
                  <VisibilityIcon />
                  200 views
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>

      {/* Pagination Component for Smaller Screens */}
      {isSmallScreen && (
        <div className="flex justify-center items-center mt-4 ">
               <button
          onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 text-lg font-bold mr-2 ${currentPage === 1 ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &lt;&lt;
        </button>
          <ul className="flex">{renderPaginationButtons()}</ul>
          <button
          onClick={() => setCurrentPage(currentPage === pageCount ? pageCount : currentPage + 1)}
          disabled={currentPage === pageCount}
          className={`px-3 py-1 mx-1 text-lg font-bold ml-2 ${currentPage === pageCount ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &gt;&gt;
        </button>
        </div>
      )}
    </div>
  );
};

export default Video;
