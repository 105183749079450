import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectState, selectCity, fetchLocationData } from '../../../../../redux/LocationReducer';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Map from '../../../../../assets/Map_Pin.png';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// Utility function to truncate text
const truncateText = (text, maxLength = 10) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};


const MobileLocation = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStateLocal, setSelectedStateLocal] = useState('');
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [selectedCityLocal, setSelectedCityLocal] = useState('');
  const [showStates, setShowStates] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  const { states, cities, selectedState, selectedCity, loading, dataFetched, country } = useSelector(
    (state) => state.location
  );

  useEffect(() => {
    if (!dataFetched) {
      dispatch(fetchLocationData());
    }
  }, [dispatch, dataFetched]);

  useEffect(() => {
    if (country) {
      setSelectedStateLocal('');
      setSelectedCityLocal('');
      setShowStates(true);

     
      if (states && states.length > 0) {
        setSelectedStateLocal(states[0]);
        setShowStates(false); 
      }
    }
  }, [country, states]);

  useEffect(() => {
    if (!showStates && cities && cities.length > 0) {
      setSelectedCityLocal(cities[0]);
    }
  }, [cities, showStates]);

  // Dispatch the selected state and city
  useEffect(() => {
    if (selectedStateLocal) {
      dispatch(selectState(selectedStateLocal));
    }
  }, [selectedStateLocal, dispatch]);

  useEffect(() => {
    if (selectedCityLocal) {
      dispatch(selectCity(selectedCityLocal));
    }
  }, [selectedCityLocal, dispatch]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (value) => {
    if (showStates) {
      setSelectedStateLocal(value);
      setShowStates(false); 
    } else {
      setSelectedCityLocal(value);
      setIsOpen(false); 
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const contryvalid = ()=>{
    if (!selectedCountry) {
      setAlertMessage('Please select a country before proceeding');
      setAlertOpen(true);
      return;
    }

  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log('Alert closed');
    setAlertOpen(false);
  };

  const handleCloseSelection = () => {
    if (!showStates) {
      setSelectedCityLocal('');
      setShowStates(true);
    } else {
      setSelectedStateLocal('');
      setSelectedCityLocal('');
      setShowStates(true); 
    }
    setIsOpen(false); 
    setTimeout(() => {
      setIsOpen(true); 
    }, 0);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const currentOptions = showStates ? ['Select State', ...states] : ['Select City', ...cities];
  const displayValue = selectedCityLocal
    ? truncateText(selectedCityLocal, 10)
    : selectedStateLocal
      ? 'Select City'
      : 'Select Your State';

  return (
    <div className="relative inline-block w-auto max-w-full " ref={dropdownRef} onClick={contryvalid}>
      <div
        className="flex justify-start items-center text-[#2E4374]  w-[142px] h-10 rounded-[5px]  cursor-pointer"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-haspopup="true"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img src={Map} alt="map" className='w-5 h-5 -ml-[20px]' />
        <span className="font-[400] leading-[12px] text-[14px] text-[#2E4374] pl-1 font-sixth">
          {displayValue}
        </span>
        {isHovered && (selectedStateLocal || selectedCityLocal) && (
          <IconButton size="small" onClick={handleCloseSelection}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}

      </div>
     {selectedCountry &&(

       <div
        className={`absolute bg-white border-gray-300 p-2 rounded-md shadow-lg w-full text-gray-600 text-xs sm:text-sm md:text-base font-sixth z-10 ${isOpen ? 'block' : 'hidden'
          }`}
        aria-expanded={isOpen}
        aria-labelledby="dropdown-button"

      >
        {loading ? (
          <div className="flex items-center justify-center py-2">
            <CircularProgress size={20} />
          </div>
        ) : currentOptions.length > 0 ? (
          currentOptions.map((option) => (
            <div
              key={option}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-200 text-[14px] text-[#2E4374]  ${option === 'Select State' || option === 'Select City' ? 'font-[400]' : ''
                }`}
              onClick={() => {
                if (option === 'Select State') {
                  setSelectedStateLocal('');
                  setSelectedCityLocal('');
                  setShowStates(true);
                  setIsOpen(false);
                } else if (option === 'Select City') {
                  setSelectedCityLocal('');
                } else {
                  handleSelect(option);
                }
              }}
            >
              {option}
            </div>
          ))
        ) : (
          <div className="px-4 py-2 text-gray-500 text-xs sm:text-sm md:text-base">No options available</div>
        )}
      </div>
     )}

      <Snackbar
  key={alertMessage} // Add this line
  open={alertOpen}
  autoHideDuration={1000}
  onClose={handleAlertClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <MuiAlert elevation={6} variant="filled" severity="warning">
    {alertMessage}
  </MuiAlert>
</Snackbar>

    </div>
  );
};

export default MobileLocation