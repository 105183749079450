export const Videodata = [
    {
      id: 1,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 2,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 3,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 4,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 5,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 6,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 7,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },
    {
      id: 8,
      video: 'https://codingyaar.com/wp-content/uploads/video-in-bootstrap-card.mp4',
      title: 'Squarebric’s Ground house Property',
      description: 'Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is unrivalled by'
    },

  ];