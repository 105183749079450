export const DemandingServiceData = [
  {
    image: "https://squarebric-media-files.squarebric.com/kitchenCleaningService.jpg",
    name: 'Kitchen cleaning Service',
    rating: '4.87(457.9k)',
    price: '$ 50',
    discount: '$ 30'
  },
  { image: "https://squarebric-media-files.squarebric.com/PaintingService.jpg", name: 'Painting Service', rating: '4.87(457.9k)', price: '$ 50', discount: '$ 30' },
  { image: "https://squarebric-media-files.squarebric.com/moverspackers.png", name: 'Movers & Packers', rating: '4.87(457.9k)', price: '$ 40', discount: '$ 20' },
  { image: "https://squarebric-media-files.squarebric.com/InteriorService.jpg", name: 'Interior Design Services', rating: '4.87(457.9k)', price: '$ 50', discount: '$ 30' },
  { image: "https://squarebric-media-files.squarebric.com/PaintingService.jpg", name: 'Painting Service', rating: '4.87(457.9k)', price: '$50', discount: '$30' }
];

export const ServiceData = [
  {
    image1: "https://squarebric-media-files.squarebric.com/servicevideo.mp4",
    image2: "https://squarebric-media-files.squarebric.com/serviceImg1.png",
    image3: "https://squarebric-media-files.squarebric.com/serviceImg2.png",
    heading: 'Intense bathroom cleaning',
    subheading: 'keshav puram-new delhi , Delhi -110052 ',
    rating: '4.87',
    serviceCharge: '150$'
  }
];

export const AllserviceData = [
  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: 'https://squarebric-media-files.squarebric.com/serviceImg2.png',
    button: 'Inquiry '
  },
  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceImg2.png",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceImg2.png",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceImg2.png",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    image1: "https://squarebric-media-files.squarebric.com/servicevideo.mp4",
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceImg2.png",
    button: 'Inquiry '
  }
];

export const MoreServicedata = [
  { image: 'https://squarebric-media-files.squarebric.com/TopService.svg', name: 'Top Service' },
  { image: 'https://squarebric-media-files.squarebric.com/bathroom1.svg', name: 'Bathroom Cleaning' },
  { image: 'https://squarebric-media-files.squarebric.com/Movers.svg', name: ' Mover & Packers' },
  { image: 'https://squarebric-media-files.squarebric.com/KitchenCleaning.svg', name: 'Kitchen Cleaning' },
  { image: 'https://squarebric-media-files.squarebric.com/FurnitureCleaning.svg', name: 'Furniture Cleaning' },
  { image: 'https://squarebric-media-files.squarebric.com/ElectricianServices.svg', name: 'Electrician Services' },
  { image: 'https://squarebric-media-files.squarebric.com/Archi.svg', name: 'Architecture Service' },
  { image: 'https://squarebric-media-files.squarebric.com/Security.jpg', name: 'Security Service' },
  { image: "https://squarebric-media-files.squarebric.com/PaintingService.jpg", name: 'Painting Service' }
];

export const AllSearchFilter = [
  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceimg1",
    button: 'Inquiry '
  },
  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceimg1",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceimg1",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceimg1",
    button: 'Inquiry '
  },

  {
    heading1: 'Classic Cleaning  bathroom ',
    subheading1: 'Charge- 150$ per Bathroom',
    image1: "https://squarebric-media-files.squarebric.com/servicevideo.mp4",
    rating: '4.87 (15k People Enquired)',
    price: 'Service Charge -',
    paragraph: 'Hand cleaning of all areas: toilet pot, tiles, basin, exhaust etc',
    like: 'Like',
    share: 'Share',
    image: "https://squarebric-media-files.squarebric.com/serviceimg1",
    button: 'Inquiry '
  }
];
