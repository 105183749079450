import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
export default function YourMemberShip() {
  const membershipData = {
    planName: 'Premium',
    amount: '$9.99/mo',
    validity: '3 Months',
    daysLeft: '20',
    orderId: 'Sq123456',
    paymentMode: 'Debit Card (Sbi******12)'
  };

  return (
    <div className=" border max-w-[90%] border-gray-500 h-auto ml-5 ">
      <table className="bg-white w-full">
        <tbody>
          <tr className="grid grid-cols-3 ">
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] leading-[18px] thirdCustom border-gray-500 text-gray-700">
              Plan Name
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 thirdCustom text-xl font-semibold text-[#2E4374]">
              {membershipData.planName}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-right">
              <button className="px-2 py-1 rounded text-[#818EAE] text-[18px]  thirdCustom leading-[18px] hover:bg-blue-100 flex items-center">
                Compare Plan <CompareArrowsIcon className="ml-1" />
              </button>
            </td>
          </tr>
          <tr className="grid grid-cols-3 ">
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px]  thirdCustom border-gray-500 text-gray-700">
              Amount
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom leading-[18px] border-gray-500 text-xl font-semibold text-[#2E4374] ">
              {membershipData.amount}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-right">
              <button className="px-2 py-1 rounded  hover:bg-blue-100 text-[#818EAE] thirdCustom text-[18px] leading-[18px] flex items-center">
                Renew Plan <AutorenewIcon className="ml-1" />
              </button>
            </td>
          </tr>
          <tr className="grid grid-cols-3 ">
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom  border-gray-500 text-gray-700">
              Validity
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom leading-[18px] border-gray-500 text-xl font-semibold text-[#2E4374]">
              {membershipData.validity}{' '}
              <span className="text-green-500 text-[18px] thirdCustom leading-[18px]">
                ({membershipData.daysLeft} Days Left)
              </span>
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500 text-right">
              <button className="px-2 py-1 rounded  hover:bg-blue-100 thirdCustom text-[#818EAE] text-[18px] leading-[18px] flex items-center">
                End Plan <CloseIcon className="ml-1" />
              </button>
            </td>
          </tr>
          <tr className="grid grid-cols-3 ">
            <td className="px-6 py-4 whitespace-no-wrap text-[18px] border-b thirdCustom border-gray-500 text-gray-700">
              Order Id
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom leading-[18px] border-gray-500 text-xl font-semibold text-[#2E4374]">
              {membershipData.orderId}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500" />
          </tr>
          <tr className="grid grid-cols-3 ">
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom border-gray-500 text-gray-700">
              Payment Mode
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b text-[18px] thirdCustom leading-[18px] border-gray-500 text-xl font-semibold text-[#2E4374]">
              {membershipData.paymentMode}
            </td>
            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500" />
          </tr>
        </tbody>
      </table>
    </div>
  );
}
