import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  data: [],
  loading: false,
  selectedCountry: '',
  selectedState: '',
  selectedCity: '',
  selectedUniqueService: ''
};

export const fetchPropertyData = createAsyncThunk('property/fetchData', async () => {
  try {
    const response = await axios.get('https://square-bric-backend-api.squarebric.com/query-result');
    console.log('Fetch Property Data Success:', response.data);
    return response.data;
  } catch (error) {
    console.error('Fetch Property Data Error:', error);
    throw error;
  }
});

const propertySlice = createSlice({
  name: 'property',
  initialState,
  reducers: {
    setSelectedCountry(state, action) {
      state.selectedCountry = action.payload;
      state.selectedState = '';
      state.selectedCity = '';
      state.selectedUniqueService = '';
    },
    setSelectedState(state, action) {
      state.selectedState = action.payload;
      state.selectedCity = '';
      state.selectedUniqueService = '';
    },
    setSelectedCity(state, action) {
      state.selectedCity = action.payload;
      state.selectedUniqueService = '';
    },
    setSelectedUniqueService(state, action) {
      state.selectedUniqueService = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPropertyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPropertyData.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchPropertyData.rejected, (state) => {
        state.loading = false;
      });
  }
});

export const { setSelectedCountry, setSelectedState, setSelectedCity, setSelectedUniqueService } =
  propertySlice.actions;

export const selectPropertyData = (state) => state.property;

export default propertySlice.reducer;
