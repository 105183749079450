import React, { useState, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { privacyData } from './Cookiesdata';

const Privacypolicy = () => {
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const contentRefs = useRef([]);

  const toggleReadMore = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const getContentHeight = (id) => {
    const content = contentRefs.current[id];
    return content ? content.scrollHeight : 0; // Get the scrollable height of the content
  };

  return (
    <div>
      <div className="bg-[url('https://squarebric-media-files.squarebric.com/Agreement.jpg')] mx-auto max-w-7xl bg-cover bg-center h-[550px] w-full flex flex-col mt-20">
        <ul className="flex p-5">
          <li>
            <Link to="/" className=" font-semibold capitalize text-[#1E2858]">
              Home <KeyboardArrowRightIcon />
              <span className="text-[#1E2858] text-[14px] font-[500]"> {pathnameWithoutSlash}</span>
            </Link>
          </li>
        </ul>
        <h1 className="text-[30px] w-[35%] md:text-[45px] mb-[200px] ml-[30px] xl:ml-[70px] font-[700] capitalize font-fifth mt-[280px] md:bg-slate-300/80 inline-block text-black">
          Agreement and rules
        </h1>
      </div>

      <div className="mt-5 mx-auto max-w-7xl p-10">
        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">Introduction</h1>
          <p className="text-[#2E4374] md:font-[500] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            Welcome to Squarebric. By accessing or using our Website, you agree to comply with and be bound by the
            following terms and conditions ("Terms"). These Terms apply to all users, including buyers, sellers,
            renters, and real estate agents. Please read them carefully before using our Website.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            1. Acceptance of Terms
          </h1>
          <p className="text-[#2E4374] md:font-[500] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            BY using our Website, you acknowledge that you have read, understood, and agree to be bound by these Terms.
            If you do not agree to these Terms, you should not use our Website.
          </p>
        </div>

        <div className="flex flex-col">
          {privacyData.map((cookie, index) => (
            <div key={index} className="">
              <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-5">
                {cookie.id}. {cookie.title}
              </h1>
              <ul className="text-[#2E4374] list-disc pl-5 mt-2 md:mt-5">
                {cookie.content.slice(0, expandedIndex === index ? cookie.content.length : 1).map((content, idx) => (
                  <li
                    key={idx}
                    className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify"
                  >
                    {content}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-5">
            6. Indemnification
          </h1>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            You agree to indemnify, defend, and hold harmless Squarebric, its affiliates, and their respective officers,
            directors, employees, and agents from any claims, liabilities, damages, losses and expenses, including
            reasonable attorney's fees, arising out of or in connection with your use of our Website or any violation of
            these Terms.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            7. Changes to Terms
          </h1>
          <p className="text-[#2E4374] md:font-[500] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon
            posting on our Website. Your continued use of the Website constitutes acceptance of the modified Terms.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">8. Termination</h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            We may terminate or suspend your access to our Website at any time, with or without cause, and with or
            without notice, for any reason,including a breach of these Terms." Upon termination, your right to use the
            Website will immediately cease. If you wish to terminate this Agreement or your SquareBric account, you may
            simply discontinue using our Website. All provisions of this Agreement which by their nature should survive
            termination shall survive termination, including, without limitation, ownership provisions, warranty
            disclaimers, indemnity, and limitations of liability."
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            9. Governing Law
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            This Agreement shall be governed by and construed in accordance with the laws of our Country without regard
            to its conflict of law principles.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">10. Contact Us</h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2   md:mt-5">
            If you have any questions or concerns regarding this Agreement, please contact us at:
          </p>
          <ul className="mt-2 ml-5 md:font-[500] text-[#2E4374] list-disc">
            <li> Email: squarebric.com</li>
            <li> Phone: [Your Contact Number]</li>
            <li> Address: [Your Address]</li>
          </ul>

          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3">
            Certainly! Here's a sample Membership Agreement content for a real estate website named SquareBric. You can
            customize it based on your specific services and legal requirements.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacypolicy;
