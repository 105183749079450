import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setSelectedOption } from '../../../../../redux/MobileButtonreducer';

const MobileButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const options = ['Buy', 'Rent'];
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.selectedOption.value);

  const handleOptionClick = (option) => {
    const internalOption = option === 'Buy' ? 'Sell' : option;
    dispatch(setSelectedOption(internalOption));
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative " ref={dropdownRef}>
      <div
        className=" flex justify-start items-center text-[#2E4374]  text-[14px] w-[90px] border-r-2 h-10 mr-6 cursor-pointer "
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-[400] leading-[12px] text-[14px] font-sixth text-[#2E4374]">
          {selectedOption === 'Sell' ? 'Buy' : selectedOption}
        </span>
        <span>
          <KeyboardArrowDownIcon
          className='ml-[10px]'
            style={{
              transition: 'transform 0.3s ease-in-out',
              transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)'
            }}
          />
        </span>
      </div>

      {isOpen && (
        <div className="absolute bg-white border border-gray-300 rounded mt-1 w-full top-full shadow-lg left-0 z-10">
          {options.map((option) => (
            <div
              key={option}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer text-[14px] font-[400] text-[#2E4374]"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MobileButton;