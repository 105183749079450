// selectedDataSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const selectedDataSlice = createSlice({
  name: 'selectedData',
  initialState: {
    categorySelections: {},
    propertySelections: {},
  },
  reducers: {
    setCategorySelections: (state, action) => {
      state.categorySelections = action.payload;
    },
    setPropertySelections: (state, action) => {
      state.propertySelections = action.payload;
    },
  },
});

export const { setCategorySelections, setPropertySelections } = selectedDataSlice.actions;

export const selectCategorySelections = (state) => state.selectedData ? state.selectedData.categorySelections : {};
export const selectPropertySelections = (state) => state.selectedData ? state.selectedData.propertySelections : {};

export default selectedDataSlice.reducer;
