import React, { useState, useEffect, useRef } from 'react';

import PersonIcon from '@mui/icons-material/Person';
import { useNavigate, Link } from 'react-router-dom';
import companyLogo from '../../assets/Logo_3.png';
import Drawer from '@mui/material/Drawer';
import CountryList from './UI/SearchFunctionality/countryselection';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import './footer.css';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LoginIcon from '@mui/icons-material/Login';

import CloseIcon from '@mui/icons-material/Close';

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import HandleLaunguag from '../HandleLaunguag';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import StoreIcon from '@mui/icons-material/Store';
import HouseIcon from '@mui/icons-material/House';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [currentPage, setCurrentPage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isLogged, setIsLogged] = useState(false);
  const [isDashboardDropdown, setIsDashboardDropdown] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('ENG');
  const [activeButton, setActiveButton] = useState('');
  const popoverRef = useRef(null);
  const dropdownRef = useRef(null);
  const [userData, setUserData] = useState(null);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleLogout = () => {
    // Your logout logic here
    logout();
    setIsDialogOpen(false);
    setIsDrawerOpen(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setShowMenu(false);
  };

  useEffect(() => {
    const checkUserLogin = () => {
      const storedUserData = localStorage.getItem('userData');
      if (storedUserData) {
        const parsedUserData = JSON.parse(storedUserData);
        setUserData(parsedUserData);
        setIsLogged(true);
      }
    };
    checkUserLogin();
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  // const checkUserLogin = () => {
  // const userData = localStorage.getItem('userData');
  // setIsLogged(!!userData);
  // };
  // checkUserLogin();
  // document.addEventListener('click', handleClickOutside);
  // return () => {
  // document.removeEventListener('click', handleClickOutside);
  // };
  // }, []);

  const dashboardPage = () => {
    navigate('/dashboard');
  };

  const logout = () => {
    localStorage.removeItem('userData');
    setIsLogged(false);
    navigate('/login');
  };

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const homeService = () => {
    navigate('/ServicePage');
  };

  const toggleDropdown = () => setIsDashboardDropdown(!isDashboardDropdown);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);
  const togglePopover = () => setShowPopover((prev) => !prev);
  const CloseSide = () => setIsDrawerOpen(false);
  const CloseSides = () => setIsDrawerOpen(false);

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setShowPopover(false);
  };

  // const handleClickOutside = (event) => {
  //   if (popoverRef.current && !popoverRef.current.contains(event.target)) {
  //     setShowPopover(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  const feature = () => {
    navigate('/Featured');
  };
  const HomeNvigate = () => {
    navigate('/');
  };

  useEffect(() => {
    const storedUserData = localStorage.getItem('username');
    console.log('name', storedUserData);
    if (storedUserData) {
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, []);
  const handleAddProperty = () => {
    const userData = localStorage.getItem('userData');
    const token = userData ? JSON.parse(userData).access_token : null;

    if (token) {
      navigate('/dashboard/propertylisting');
    } else {
      navigate('/login');
    }
  };

  return (
    <header className="fixed w-[100%] border-b-2  top-0 z-50 bg-gradient-to-r from-[#7EB3DF] to-[#9FC7EA]">
      <div className="flex  justify-between items-center h-[70px] p-3 px-5 ">
        <div className="flex justify-between items-center gap-3">
          <div className="hover:underline text-4xl bg-none">
            <Link to="/">
              <img src={companyLogo} alt="logo" className="ml-[5px] bg-none h-10 w-10" />
            </Link>
          </div>
          <div className="lg:flex hidden  gap-4 mt-0">
            <button
              onClick={HomeNvigate}
              className="text-white font-thirdCustom  hover:underline font-[400] leading-[20px] active:underline text-[16px]"
            >
              {t('Home')}
            </button>
            <button
              onClick={feature}
              className="text-white font-thirdCustom    hover:underline active:underline font-[400] leading-[20px]    text-[16px]"
            >
              {t('Feature Property')}
            </button>
            <button
              onClick={homeService}
              className="text-white font-thirdCustom   hover:underline font-[400] leading-[20px]   active:underline text-[16px]"
            >
              {t('Service')}
            </button>
          </div>
        </div>

        <div className="flex   gap-2 justify-between  items-center ">
          <div className=" ">
            <CountryList />
          </div>
          <div className="">
            <HandleLaunguag />
          </div>

          <div className="hidden md:block mr-3 mt-1 relative">
            <div
              className={`group flex items-center  justify-center ${
                !isLogged ? 'hover:bg-[#C4614C] hover:border-[#C4614C] border-2 px-4 py-1' : ''
              } rounded-md`}
              onClick={() => {
                if (!isLogged) {
                  navigate('/login');
                }
              }}
              onMouseEnter={() => {
                if (isLogged) {
                  setDropdownVisible(true);
                }
              }}
              onMouseLeave={() => {
                setTimeout(() => {
                  if (isLogged && !isDropdownVisible) {
                    setDropdownVisible(false);
                  }
                }, 200);
              }}
            >
              <PersonIcon
                className={`${isLogged ? 'text-black !w-[19px] !h-[19px]' : 'bg-white text-blue-300'} rounded-full`}
              />
              {isLogged && userData && (
                <>
                  <p className=" capitalize mt-0 text-[#2e4374] font-[400] ml-1 text-[16px] font-sixth">
                    {userData.name}
                  </p>
                  {isDropdownVisible && (
                    <div
                      ref={dropdownRef}
                      className="absolute z-10 mt-1 w-24 ml-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      style={{ top: '100%' }}
                      onMouseEnter={() => setDropdownVisible(true)}
                      onMouseLeave={() => setDropdownVisible(false)}
                    >
                      <div className="py-1" role="none">
                        <button
                          className="block px-4 py-2 text-sm text-[16px] font-[400] text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          onClick={() => navigate('/dashboard')}
                        >
                          {t('Dashboard')}
                        </button>
                        <button
                          className="block px-4 py-2 text-sm text-gray-700 text-[16px] font-[400] hover:bg-gray-100 hover:text-gray-900 w-full text-left"
                          onClick={handleLogout} // Updated here
                        >
                          {t('Logout')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
              {!isLogged && <span className="text-white text-[16px] ml-2 font-[400]">{t('Login')}</span>}
            </div>
          </div>

          <div className=" ">
            <button
              onClick={handleAddProperty}
              className=" items-center border text-[16px] font-[400] bg-[#A44531] border-[#A44531] rounded-[20px] text-[#DDC252]  py-1 px-4 lg:flex hidden"
            >
              {t('Add Property')}
              <span className="bg-white ml-2 px-2  rounded-full">
                <ArrowOutwardIcon />
                {t('Business')}
              </span>
            </button>
          </div>

          <div className=" px-2 py-1  mt-4 mr-2 xs:block md:hidden">
            {/* {isMobile && ( */}
            <div className="" onClick={toggleDrawer}>
              <button>
                <img src="https://d34yb5orp91dp6.cloudfront.net/Burger.svg" alt="burger-img" className="w-6 h-6" />
              </button>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            backgroundColor: 'white',
            width: '250px',
            height: '90%',
            marginTop: '70px',

            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            // background: 'linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%)',
            backgroundColor: '#1E2858'
          }
        }}
      >
        <div className={` p-5   text-white `}>
          <div className="w-6 h-6 bg-white rounded-full  flex justify-center items-center ">
            <IconButton className="  !text-red-500 ">
              <CloseIcon onClick={toggleDrawer} className="!w-5 !h-5" />
            </IconButton>
          </div>
          {/* Profile Avatar */}
          <div className="flex flex-col items-center">
            <p className=" w-16 h-16 bg-white rounded-full flex justify-center items-center">
              <PersonIcon className=" text-blue-300 !w-10 !h-10" />
            </p>

            <h2 className="mt-4 text-[24px]  font-[400] py-2 leading-[20px]">Welcome</h2>
            <button className="bg-white text-[#1E2858] text-[16px] font-[400] w-[100%] leading-[20px] px-4 py-4 mt-4 rounded ">
              Login / Register now
            </button>
            <button className="bg-red-500 text-[#DDC252] text-[16px] font-[400] capitalize font-thirdCustom w-[100%] leading-[20px] px-4 mt-4 py-4 rounded ">
              Add Property
            </button>
          </div>

          {/* Navigation Links */}
          <nav className="mt-8 space-y-8">
            <div className="flex justify-start space-x-4">
              <HomeIcon />
              <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">Buy</span>
            </div>
            <div className="flex justify-start space-x-4">
              <AttachMoneyIcon />
              <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">Sell</span>
            </div>
            <div className="flex justify-start space-x-4">
              <StoreIcon />
              <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">Rent</span>
            </div>
            <div className="flex justify-start space-x-4">
              <HouseIcon />
              <Link to="/Featured" onClick={CloseSide}>
                <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">
                  Features Property
                </span>
              </Link>
            </div>
            <div className="flex justify-start space-x-4">
              <HouseIcon />
              <Link to="/ServicePage" onClick={CloseSides}>
                <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">
                  Property Service
                </span>
              </Link>
            </div>
            <div className="flex justify-start space-x-4">
              <SettingsIcon />
              <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">Setting</span>
            </div>
            <div className="flex justify-start space-x-4">
              <HelpIcon />
              <span className="text-[16px] font-[400] leading-[20px] capitalize font-fourth mt-1">Help</span>
            </div>
          </nav>
        </div>
      </Drawer>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center', fontSize: '20px', color: '#2E4374', fontSize: '16px' }}>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div
            onClick={() => setIsDialogOpen(false)}
            className="text-[#2E4374] border-2 px-6 py-2 text-[16px] border-[#2E4374] hover:text-white hover:bg-[#2E4374]"
          >
            Cancel
          </div>
          <div
            onClick={handleLogout}
            className="text-[#2E4374] border-2 px-6 py-2 border-[#2E4374] text-[16px] hover:text-white hover:bg-red-500 hover:border-red-500"
          >
            Yes
          </div>
        </DialogActions>
      </Dialog>
    </header>
  );
};

export default Header;
