import React from 'react';

const Investment = () => {
  return (
    <div className='mb-10 mt-20 mx-[0px] hidden sm:block'>
      <div className=" ">
        <h1 className='text-[#1E2858] font-[700] text-[25px] font-SecondCustom text-start  leading-[37px]'>Investment Philosophy</h1>
      </div>

      <div className="border-2 border-[#2E4374] mt-4 md:p-10  p-2  ">
        <div className="flex flex-col md:flex-row">
          <p className="md:w-4/6  text-[#2E4374]  font-[500]  lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px] leading-[30px] mt-5 text-justify">
            At Squarebric, we understand that people everywhere are searching for a home to call their own. A home is a
            cherished memory that lasts forever, where the walls embrace memories, the ceilings shelter love and
            laughter, where the quiet corners offer a much-needed pause and life itself becomes a reason to celebrate.
            We want to make the journey as joyful as the moment when you finally find the perfect home. So we begin by
            partnering with our customers from the start and being there when it matters the mo- right from online
            search to brokers to home loans to paperwork to finally finding that perfect home. At ,Squarebric we help
            you find joy.
          </p>

          <div className="flex lg:w-1/4 md:w-1/3 w-3/4 md:ml-10 ml-7 mt-1">
            <div className="bg-[#D2D8F1] lg:w-48 md:w-24 w-32 md:h-32 h-20 rounded-3xl absolute md:mt-1 mt-1 md:ml-0 ml-2"></div>
            <div className="bg-[#D2D8F1] lg:w-48 md:w-24 w-32 md:h-32 h-20 rounded-3xl absolute md:right-80 3xl:right-[647px] md:mt-24 mt-28 right-16"></div>

            <div className="relative mt-4 ml-5 ">
              <img src="https://squarebric-media-files.squarebric.com/cardimg1.jpg" alt="philosophy-img" className="rounded-2xl w-[400px] h-[200px]" />
            </div>
          </div>
        </div>

        <p className="text-[#2E4374] font-[500]  lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px]  leading-[30px] mt-5 text-justipfy">
          At Squarebric, we understand that people everywhere are searching for a home to call their own. A home is a
          cherished memory that lasts forever, where the walls embrace memories, the ceilings shelter love and laughter,
          where the quiet corners offer a much-needed pause and life itself becomes a reason to celebrate. We want to
          make the journey as joyful as the moment when you finally find the perfect home. So we begin by partnering
          with our customers from the start and being At Squarebric, we understand that people everywhere are
          searching for a home to call their own. A home is a cherished
        </p>

        <p className="text-[#2E4374]  font-[500]  lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px] leading-[25px] leading-[30px] mt-5 text-justify">
          At Squarebric, we understand that people everywhere are searching for a home to call their own. A home is a
          cherished memory that lasts forever, where the walls embrace memories, the ceilings shelter love and laughter,
          where the quiet corners offer a much-needed pause and life itself becomes a reason to celebrate. We want to
          make the journey as joyful as the moment when you finally find the perfect home. So we begin by partnering
          with our customers from the start and being At Squarebric, we understand that people everywhere are
          searching for a home to call their own. A home is a cherished
        </p>
      </div>
    </div>
  );
};

export default Investment;
