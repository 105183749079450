import React, { useState, useEffect } from 'react';

import OrderHistory from './OrderHistory';
import YourMembership from './YourMemberShip';
import { Outlet, Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PersonIcon from '@mui/icons-material/Person';
import Plan from './Plan';
function MemberShip() {
  const [activeTab, setActiveTab] = useState('membership');
  const [userData, setUserData] = useState(null);
  let locations = useLocation();
  // let pathnames=locations.pathname .split('/').filter(Boolean) .join(' > '); // Remove empty elements

  const pathnames = locations.pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1];
  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem('userData');
    console.log('name', storedUserData);
    if (storedUserData) {
      // Parse the JSON string into a JavaScript object
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, [userData]);
  const name = userData ? userData.name : '';
  return (
    <div className="ml-[7%]">
        <div class="flex justify-end bg-[#D9D9D9] p-3  ">
        <div class="flex ">
          <PersonIcon className="mr-[20px]"></PersonIcon>

          <p className="pr-6"> {name}</p>
        </div>
        </div>
      <div className=" mt-5 ml-5">
        <span className="text-[#1E2858] ml-0 font-[400] mt-6 text-[16px]  capitalize">
          <Link to="/dashboard" className="text-[#7D7D7D] font-[400] text-[18px] capitalize">
            Dashboard
          </Link>
          <NavigateNextIcon className=" block text-[14px] mb-[2px] h-3" />
          <span className='text-[#2E4374]'>  {`${lastSegment}`}</span>
        </span>
      </div>
      <div className=" flex justify-start gap-5 mt-4 ml-5">
        <button
          className={`py-2 border-t-4 transition text-[22px] leading-[25px] font-[700] duration-300 text-[#2E4374] ${
            activeTab === 'membership' ? 'border-blue-500' : 'border-transparent'
          }`}
          onClick={() => setActiveTab('membership')}
        >
          YOUR MEMBERSHIP
        </button>
        <button
          className={`py-2 border-t-4 text-[22px] text-[#2E4374] leading-[25px] font-[700] transition duration-300 ${
            activeTab === 'order-history' ? 'border-blue-500' : 'border-transparent'
          }`}
          onClick={() => setActiveTab('order-history')}
        >
          ORDER HISTORY
        </button>
      </div>
      <div className=" ">
        <div className="">{activeTab === 'membership' && <YourMembership />}</div>
        <div className="">{activeTab === 'membership' && <Plan />}</div>
    
      <div className="">{activeTab === 'order-history' && <OrderHistory />}
      </div>
      </div>
    </div>
  );
}

export default MemberShip;
