import React, { useState, useEffect } from 'react';
import './Property.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPropertyData,
  selectPropertyData,
  setSelectedCountry,
  setSelectedState,
  setSelectedCity,
  setSelectedUniqueService
} from '../../../../redux/HomeserviceReducer/HomeServiceReducer';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import RoomIcon from '@mui/icons-material/Room';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomDropdown = ({ options, value, onChange, placeholder, icon, isOpen, setIsOpen, errorMessage, onClick }) => {
  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const truncate = (str) => (str.length > 10 ? `${str.slice(0, 10)}...` : str);

  return (
    <div className="custom-dropdown w-1/2 md:w-1/2 sm:w-1/4">
      <div
        className="selected-option flex p-1"
        onClick={() => {
          onClick();
          setIsOpen(!isOpen);
        }}
      >
        <span className="-mt-1 text-[#1E2858] font-light">{icon && <span className="icon">{icon}</span>}</span>
        <div className="flex justify-between w-full">
          <div className="text-[#1E2858] text-sm ml-0">{truncate(value || placeholder)}</div>
          <div>
            <KeyboardArrowUpIcon style={{ color: '#1E2858', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="options ml-0 p-1 w-40">
          {options.map((option) => (
            <div key={option} className="option" onClick={() => handleOptionClick(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
      {errorMessage && <div className="error-message text-red-500 text-xs mt-1 ml-1">{errorMessage}</div>}
    </div>
  );
};

const Propertymain = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, selectedCountry, selectedState, selectedCity, selectedUniqueService } =
    useSelector(selectPropertyData);

  const [openDropdown, setOpenDropdown] = useState(null);
  const [countryErrorMessage, setCountryErrorMessage] = useState('');
  const [stateErrorMessage, setStateErrorMessage] = useState('');
  const [cityErrorMessage, setCityErrorMessage] = useState('');
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    dispatch(fetchPropertyData());
  }, [dispatch]);

  const handleCountryChange = (country) => {
    dispatch(setSelectedCountry(country));
    setCountryErrorMessage('');
    setStateErrorMessage('');
    setCityErrorMessage('');
  };

  const handleStateChange = (state) => {
    dispatch(setSelectedState(state));
    setStateErrorMessage('');
    setCityErrorMessage('');
  };

  const handleCityChange = (city) => {
    dispatch(setSelectedCity(city));
    setCityErrorMessage('');
  };

  const handleUniqueServiceChange = (service) => {
    dispatch(setSelectedUniqueService(service));
  };

  const handleSearch = () => {
    setIsActive(!isActive);
    if (!selectedCountry || !selectedState || !selectedCity || !selectedUniqueService) {
      if (!selectedCountry) {
        setCountryErrorMessage('Please select a country.');
        toast.error('Please select a country.');
      }
      if (!selectedState) {
        setStateErrorMessage('Please select a state.');
        toast.error('Please select a state.');
      }
      if (!selectedCity) {
        setCityErrorMessage('Please select a city.');
        toast.error('Please select a city.');
      }

      return;
    }

    const url = `https://square-bric-backend-api.squarebric.com/home-service/home-service-search?country=${encodeURIComponent(
      selectedCountry
    )}&state=${encodeURIComponent(selectedState)}&sub_city=${encodeURIComponent(
      selectedCity
    )}&service_type=${encodeURIComponent(selectedUniqueService)}&skip=0&limit=10`;

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        navigate(
          `/Search?country=${encodeURIComponent(selectedCountry)}&state=${encodeURIComponent(
            selectedState
          )}&city=${encodeURIComponent(selectedCity)}&service_type=${encodeURIComponent(selectedUniqueService)}`,
          { state: { searchResults: data.documents } }
        );
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
        toast.error('There was a problem with the search. Please try again later.');
      });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const headerStyle = {
    color: 'white',
    background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
  };

  return (
    <div className="w-full h-full flex flex-col items-center">
      <div className="servicemain relative w-full flex flex-col items-center">
        <div className="absolute top-1/4 flex flex-col items-center ml-20">
          <h1 className="text-white md:text-5xl text-2xl font-customFont text-center md:mr-44  ml-10">
            Making Tech Smarter
          </h1>
          <h1 className="text-white md:text-5xl text-2xl font-customFont text-center ml-16">
            Around The World.
          </h1>
        </div>
        <div className="absolute md:top-1/2  flex flex-col md:flex-row bg-white p-2 rounded-md shadow-md md:mr-0 mr-32 md:mt-0 mt-36">
        {/* <div className="absolute top-1/3 md:left-5 left-9 flex flex-col md:flex-row sm:mb-10 bg-white p-2 ml-2 mt-0 md:mt-32 rounded-md "></div> */}
          <div className="flex flex-col md:flex-row w-full md:ml-0 ">
            <div className="flex flex-col">
              <CustomDropdown
                options={data.map((country) => country.country_name)}
                value={selectedCountry}
                onChange={handleCountryChange}
                placeholder="Country"
                icon={<RoomIcon style={{ color: ' #2E4374' }} className="font-light" />}
                isOpen={openDropdown === 'country'}
                setIsOpen={(isOpen) => setOpenDropdown(isOpen ? 'country' : null)}
                errorMessage={countryErrorMessage}
                onClick={() => {}}
              />
            </div>
            <div className="flex flex-col">
              <CustomDropdown
                options={
                  selectedCountry
                    ? data
                        .find((country) => country.country_name === selectedCountry)
                        ?.states.map((state) => state.state) || []
                    : []
                }
                value={selectedState}
                onChange={handleStateChange}
                placeholder="Location"
                icon={<RoomIcon style={{ color: '#2E4374;' }} />}
                isOpen={openDropdown === 'state'}
                setIsOpen={(isOpen) => setOpenDropdown(isOpen ? 'state' : null)}
                errorMessage={stateErrorMessage}
                onClick={() => {
                  if (!selectedCountry) {
                    toast.error('Please select a country first.');
                  }
                }}
              />
            </div>
            <div className="flex flex-col">
              <CustomDropdown
                options={
                  selectedState
                    ? data
                        .find((country) => country.country_name === selectedCountry)
                        ?.states.find((state) => state.state === selectedState)?.sub_cities
                      ? Object.keys(
                          data
                            .find((country) => country.country_name === selectedCountry)
                            ?.states.find((state) => state.state === selectedState).sub_cities
                        )
                      : []
                    : []
                }
                value={selectedCity}
                onChange={handleCityChange}
                placeholder="City"
                icon={<RoomIcon style={{ color: ' #2E4374' }} />}
                isOpen={openDropdown === 'city'}
                setIsOpen={(isOpen) => setOpenDropdown(isOpen ? 'city' : null)}
                errorMessage={cityErrorMessage}
                onClick={() => {
                  if (!selectedState) {
                    toast.error('Please select a state first.');
                  } else if (!selectedCountry) {
                    toast.error('Please select a country first.');
                  }
                }}
              />
            </div>
            <div className="flex flex-col">
              <CustomDropdown
                options={
                  selectedCity
                    ? data
                        .find((country) => country.country_name === selectedCountry)
                        ?.states
                        .find((state) => state.state === selectedState)?.sub_cities[selectedCity] || []
                    : []
                }
                value={selectedUniqueService}
                onChange={handleUniqueServiceChange}
                placeholder="Select for Service"
                isOpen={openDropdown === 'service'}
                setIsOpen={(isOpen) => setOpenDropdown(isOpen ? 'service' : null)}
                onClick={() => {
                  if (!selectedCity) {
                    toast.error('Please select a city first.');
                  } else if (!selectedState) {
                    toast.error('Please select a state first.');
                  } else if (!selectedCountry) {
                    toast.error('Please select a country first.');
                  }
                }}
              />
            </div>
          </div>
          <button
            onClick={handleSearch}
            style={headerStyle}
            className={`px-2 md:px-9 text-center mt-0 rounded-md font-thirdCustom text-base md:h-12 h-10 
              ${isActive ? 'transition duration-300 ease-in-out transform hover:bg-[#1e2f54] hover:scale-105' : 'bg-[#2e4374]'}
            `}
          >
            SEARCH
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Propertymain;
