import React, { useState, useEffect, useRef } from 'react';
// import Maps from "./Maps";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});
const MapViewUpdater = ({ position }) => {
  const map = useMap();
  map.setView(position, 13); // Adjust zoom level as needed
  return null;
};

// import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
function HomeData() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [openingHour, setOpeningHour] = useState(0);
  const [openingMinute, setOpeningMinute] = useState(0);
  const [openingIsAM, setOpeningIsAM] = useState('AM');
  const [closingHour, setClosingHour] = useState(0);
  const [closingMinute, setClosingMinute] = useState(0);
  const [closingIsAM, setClosingIsAM] = useState('AM');
  const [openingDay, setOpeningDay] = useState('');
  const [closingDay, setClosingDay] = useState('');
  const [locality, setLocality] = useState('');
  const [location, setLocation] = useState('');

  const [hasAddedForm, setHasAddedForm] = useState(false);
  const [showpercentageone, setShowPercentageOne] = useState(0);
  const [position, setPosition] = useState([28.6139, 77.209]); // Default to New Delhi
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(null);
  const [indexs, setIndexs] = useState(0);
  const [firstInvalidIndexs, setFirstInvalidIndexs] = useState(-1);
  const { t, i18n } = useTranslation();
  const [form, setForm] = useState([
    {
      serviceName: '',
      Catgory: ['one', 'two', 'three'],
      description: ''
    }
  ]);

  const [formDataOne, setFormDataOne] = useState([{ serviceName: '', serviceCharge: '', description: '' }]);

  const [formDatas, setFormDatas] = useState({
    contactInformation: [
      {
        name: '',
        phone: '',
        whatsappNo: '',
        consultant: ''
      }
    ]
  });
  const [formDataes, setFormDataes] = useState({
    serviceImage: {
      image: '',
      youtubeLink: ''
    }
  });
  const [availability, setAvailability] = useState({
    countries: [
      {
        name: 'India',
        states: [
          {
            name: 'Uttar Pradesh',
            cities: ['Lucknow', 'Kanpur', 'Varanasi']
          },
          {
            name: 'Bihar',
            cities: ['Patna', 'Gaya', 'Bhagalpur']
          },
          {
            name: 'Madhya Pradesh',
            cities: ['Bhopal', 'Indore', 'Gwalior']
          }
        ]
      },
      {
        name: 'Turkey',
        states: [
          {
            name: 'Istanbul',
            cities: ['Istanbul', 'Kadıköy', 'Üsküdar']
          },
          {
            name: 'Ankara',
            cities: ['Ankara', 'Çankaya', 'Keçiören']
          },
          {
            name: 'Izmir',
            cities: ['Izmir', 'Konak', 'Bornova']
          }
        ]
      },
      {
        name: 'USA',
        states: [
          {
            name: 'California',
            cities: ['Los Angeles', 'San Francisco', 'San Diego']
          },
          {
            name: 'Texas',
            cities: ['Houston', 'Dallas', 'Austin']
          },
          {
            name: 'New York',
            cities: ['New York City', 'Buffalo', 'Rochester']
          }
        ]
      },
      {
        name: 'Canada',
        states: [
          {
            name: 'Ontario',
            cities: ['Toronto', 'Ottawa', 'Hamilton']
          },
          {
            name: 'Quebec',
            cities: ['Montreal', 'Quebec City', 'Laval']
          },
          {
            name: 'British Columbia',
            cities: ['Vancouver', 'Victoria', 'Richmond']
          }
        ]
      }
    ],
    openingHours: {
      hour: 0,
      minute: 0,
      isAM: true
    },
    closingHours: {
      hour: 0,
      minute: 0,
      isAM: true
    },
    workingDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false
    }
  });

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const handleAddForm = () => {
    setFormDataOne([...formDataOne, { serviceName: '', serviceCharge: '', description: '' }]);
    setHasAddedForm(true);
  };
  const [filledPercentage, setFilledPercentage] = useState(0);
  let locations = useLocation();
  // let pathnames=locations.pathname .split('/').filter(Boolean) .join(' > '); // Remove empty elements

  const pathnames = locations.pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1];
  // locations lat,long
  const provider = new OpenStreetMapProvider();
  const searchLocation = async () => {
    try {
      const results = await provider.search({ query: searchQuery });
      if (results && results.length > 0) {
        const { x, y, label } = results[0];
        setPosition([y, x]);
        setSearchQuery(label);
        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    searchLocation();
  };

  const handleMarkerDragEnd = async (event) => {
    const marker = event.target;
    const newPosition = marker.getLatLng();

    setPosition([newPosition.lat, newPosition.lng]);
    console.log('positions', position);
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${newPosition.lat}&lon=${newPosition.lng}`
      );
      const data = await response.json();
      if (data && data.display_name) {
        setSearchQuery(data.display_name);

        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  // locationshandle for add more functionality basic info
  const [firstInvalidIndex, setFirstInvalidIndex] = useState(-1);
  const scrollRef = useRef(null);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newList = [...form];
    let valid = true;

    // Validation rules
    switch (name) {
      case 'serviceName':
        if (value.trim().length < 3) {
          valid = false;
        }
        break;
      case 'category':
        if (!value) {
          valid = false;
        }
        break;
      case 'description':
        if (value.length > 70) {
          valid = false;
        }
        break;
      default:
        break;
    }

    if (!valid && firstInvalidIndex === -1) {
      setFirstInvalidIndex(index);
    } else if (firstInvalidIndex === index) {
      setFirstInvalidIndex(-1);
    }

    newList[index] = {
      ...newList[index],
      [name]: value
    };
    setForm(newList);
  };
  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const newData = [...formDataOne];
    let valid = true;

    // Validation rules
    switch (name) {
      case 'serviceName':
        // Example: Require at least 3 characters
        if (value.trim().length < 3) {
          valid = false;
        }
        break;
      case 'serviceCharge':
        // Example: Ensure numeric value
        if (isNaN(value)) {
          valid = false;
        }
        break;
      case 'description':
        // Example: Limit description to 70 characters
        if (value.length > 70) {
          valid = false;
        }
        break;
      default:
        break;
    }

    if (!valid && firstInvalidIndex === -1) {
      setFirstInvalidIndex(index);
    } else if (firstInvalidIndex === index) {
      setFirstInvalidIndex(-1);
    }

    newData[index] = {
      ...newData[index],
      [name]: value
    };

    setFormDataOne(newData);
  };

  const handleRemoveForm = (index) => {
    if (formDataOne.length === 1) {
      return;
    }
    const updatedFormData = formDataOne.filter((_, i) => i !== index);
    setFormDataOne(updatedFormData);
  };

  const calculateTotalPercentage = () => {
    const allFields = [...form, ...formDataOne];
    const totalFields = allFields.reduce(
      (acc, item) => acc + Object.keys(item).filter((key) => key !== 'Catgory').length,
      0
    );
    const filledFields = allFields.reduce(
      (acc, item) => acc + Object.values(item).filter((value) => value !== '' && value !== item.Catgory).length,
      0
    );
    return Math.round((filledFields / totalFields) * 100);
  };

  let hundred = calculateTotalPercentage();
  console.log('100', hundred);
  const calculateTotalPercentage25 = () => {
    const allFields = [...form, ...formDataOne];
    const totalFields = allFields.reduce(
      (acc, item) => acc + Object.keys(item).filter((key) => key !== 'Catgory').length,
      0
    );
    const filledFields = allFields.reduce(
      (acc, item) => acc + Object.values(item).filter((value) => value !== '' && value !== item.Catgory).length,
      0
    );
    return Math.round((filledFields / totalFields) * 25);
  };
  let twentyFive = calculateTotalPercentage25();
  console.log('25', twentyFive);

  // Availbility

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setSelectedState('');
    setSelectedCity('');
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setSelectedCity('');
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleOpeningHourChange = (event) => {
    setOpeningHour(event.target.value);
  };

  const handleOpeningMinuteChange = (event) => {
    setOpeningMinute(event.target.value);
  };

  const toggleOpeningAMPM = () => {
    // setOpeningIsAM(!openingIsAM);
    setOpeningIsAM((prevPeriod) => (prevPeriod === 'AM' ? 'PM' : 'AM'));
  };

  const handleClosingHourChange = (event) => {
    setClosingHour(event.target.value);
  };

  const handleClosingMinuteChange = (event) => {
    setClosingMinute(event.target.value);
  };

  const toggleClosingAMPM = () => {
    //  setClosingIsAM((prevIsAM) => !prevIsAM);
    // setClosingIsAM(!closingIsAM);
    setClosingIsAM((prevPeriod) => (prevPeriod === 'AM' ? 'PM' : 'AM'));
    console.log('gg', closingIsAM);
  };

  const handleOpeningDayChange = (event) => {
    setOpeningDay(event.target.value);
  };

  const handleClosingDayChange = (event) => {
    setClosingDay(event.target.value);
  };
  const handleLocalityChange = (event) => {
    setLocality(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const calculateFilledPercentage = () => {
    const totalFields = 11; // Number of input fields
    let filledFields = 0;

    if (selectedCountry) filledFields++;
    if (selectedState) filledFields++;
    if (selectedCity) filledFields++;
    if (locality) filledFields++;
    if (location) filledFields++;
    if (openingHour) filledFields++;
    if (openingMinute) filledFields++;
    if (closingHour) filledFields++;
    if (closingMinute) filledFields++;
    if (openingDay) filledFields++;
    if (closingDay) filledFields++;

    setFilledPercentage(Math.round((filledFields / totalFields) * 100)); // 100% percentage
    setShowPercentageOne(Math.round((filledFields / totalFields) * 25)); // 25 % percentage
  };

  useEffect(() => {
    calculateFilledPercentage();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    locality,
    location,
    openingHour,
    openingMinute,
    closingHour,
    closingMinute,
    openingDay,
    closingDay
  ]);

  // contact infoermationzz
  const handleContactChange = (index, event) => {
    const { name, value } = event.target;
    const updatedContacts = [...formDatas.contactInformation];
    updatedContacts[index][name] = value;
    setFormDatas({ ...formDatas, contactInformation: updatedContacts });
  };

  const calculateCompletionPercentage = () => {
    const totalFields = formDatas.contactInformation.length * 4; // 4 fields per contact
    let filledFields = 0;

    formDatas.contactInformation.forEach((contact) => {
      if (contact.name) filledFields++;
      if (contact.phone) filledFields++;
      if (contact.whatsappNo) filledFields++;
      if (contact.consultant) filledFields++;
    });

    return Math.round((filledFields / totalFields) * 100);
  };

  let contactHundred = calculateCompletionPercentage();
  const calculateCompletionPercentagetw = () => {
    const totalFields = formDatas.contactInformation.length * 4; // 4 fields per contact
    let filledFields = 0;

    formDatas.contactInformation.forEach((contact) => {
      if (contact.name) filledFields++;
      if (contact.phone) filledFields++;
      if (contact.whatsappNo) filledFields++;
      if (contact.consultant) filledFields++;
    });

    return Math.round((filledFields / totalFields) * 25);
  };

  let contactTwntyfive = calculateCompletionPercentagetw();

  // Service Image

  useEffect(() => {
    calculateCompletionPercentages();
  }, [formDataes]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setFormDataes({
      ...formDataes,
      serviceImage: {
        ...formDataes.serviceImage,
        image: file
      }
    });
  };

  const handleYouTubeLinkChange = (event) => {
    setFormDataes({
      ...formDataes,
      serviceImage: {
        ...formDataes.serviceImage,
        youtubeLink: event.target.value
      }
    });
  };

  const calculateCompletionPercentages = () => {
    const totalFields = 2;
    let completedFields = 0;

    if (formDataes.serviceImage.image) completedFields += 1;
    if (formDataes.serviceImage.youtubeLink) completedFields += 1;

    return (completedFields / totalFields) * 100;
  };
  let imgservicehund = calculateCompletionPercentages();

  const calculateCompletionPercentagestw = () => {
    const totalFields = 2;
    let completedFields = 0;

    if (formDataes.serviceImage.image) completedFields += 1;
    if (formDataes.serviceImage.youtubeLink) completedFields += 1;

    return (completedFields / totalFields) * 25;
    // setCompletionPercentage(percentage);
  };
  let imgservicetw = calculateCompletionPercentagestw();
  // for scror
  const handleClick = (id) => {
    TopToBottom(id);
  };
  const TopToBottom = (current) => {
    if (current === 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    if (current === 1) {
      window.scrollTo({
        top: 1050,
        behavior: 'smooth'
      });
    }
    if (current === 2) {
      window.scrollTo({
        top: 1750,
        behavior: 'smooth'
      });
    }
    if (current === 3) {
      window.scrollTo({
        top: 2000,
        behavior: 'smooth'
      });
    }
  };
  const getProgressColor = (percentage) => {
    if (percentage >= 0 && percentage <= 30) {
      return 'bg-yellow-500';
    } else if (percentage > 30 && percentage <= 50) {
      return 'bg-orange-500';
    } else if (percentage > 50 && percentage <= 75) {
      return 'bg-pink-500';
    } else if (percentage > 75 && percentage <= 100) {
      return 'bg-green-500';
    } else {
      return ''; // Default color if percentage is out of range
    }
  };
  const items = [
    { id: 0, label: 'Basic Information', percentage: hundred },
    { id: 1, label: 'availibilit', percentage: filledPercentage },
    { id: 2, label: 'contact', percentage: contactHundred },
    { id: 3, label: 'imageService', percentage: imgservicehund }
  ];
  let finaloutput = 0;
  finaloutput = twentyFive + showpercentageone + contactTwntyfive + imgservicetw;
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (finaloutput / 100) * circumference;
  let locationData = {
    locationData: {
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      locality: locality,
      locations: searchQuery,
      geo_location: {
        Lat: position[0],
        Long: position[1]
      }
    }
  };

  let opearationasl_details = {
    Working_day: openingDay,
    Closing_day: closingDay,
    Working_hours: ` ${openingHour}${openingIsAM}-${closingHour}${closingIsAM}}`
  };
  var jsonData = { form, formDataOne, formDatas, formDataes, locationData, opearationasl_details };
  jsonData = JSON.stringify(jsonData);
  console.log('availibility', jsonData);
  // console.log("all data",opearationasl_details);
  // console.log("we",locationData)
  const scrollToInvalidField = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const subMitSerData = () => {
    const hasErrors = form.some((item, index) => {
      if (item.serviceName.trim().length < 3) {
        setFirstInvalidIndex(index);
        return true;
      }
      if (!item.category) {
        setFirstInvalidIndex(index);
        return true;
      }
      if (item.description.length > 70) {
        setFirstInvalidIndex(index);
        return true;
      }
      return false;
    });

    // Scroll to the first invalid field if errors exist
    if (hasErrors) {
      scrollToInvalidField();
    } else {
      // Submit logic here
      console.log('Form submitted successfully!');
    }
    const hasErrorss = formDataOne.some((formData, index) => {
      if (formData.serviceName.trim().length < 3) {
        setFirstInvalidIndex(index);
        return true;
      }
      if (isNaN(formData.serviceCharge)) {
        setFirstInvalidIndex(index);
        return true;
      }
      if (formData.description.length > 70) {
        setFirstInvalidIndex(index);
        return true;
      }
      return false;
    });

    // Scroll to the first invalid field if errors exist
    if (hasErrorss) {
      const firstInvalidField = document.querySelector('.invalid-field');
      if (firstInvalidField) {
        firstInvalidField.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    } else {
      // Submit logic here
      console.log('Form submitted successfully!');
    }
  };

  return (
    <div className="ml-[8%] mt-5">
      <span className="text-[#1E2858]  font-[400] text-[16px] mb-6 capitalize">
        <Link to="/dashboard" className="text-[#1E2858]  font-[400] text-[18px] mb-6 capitalize">

          {t('Dashboard')}
        </Link>
        <NavigateNextIcon className=" block text-[14px] mb-[2px] h-3" />
        {`${lastSegment}`}
      </span>

      <h1 className=" p-2 mt-4 bg-[#EAF7FF] font-[600] mr-4 text-[#1E2858] text-[20px] thirdCustom">
      {t('Service Listing')}</h1>

      <div className="grid grid-cols-5 mt-6">
        <div className="col-span-4">
          <div className="border  border-gray-300">
            <h1 className="p-4 bg-[#D9D9D9] text-[#1E2858] thirdCustom">  {t('basicInformation')}</h1>
            <div className="p-10">
              <div className="mt-[-30px]">
                {form.map((item, index) => (
                  <div key={index} className="my-4 p-4 rounded-md" ref={index === firstInvalidIndex ? scrollRef : null}>
                    <div>
                      <label className="block text-sm font-medium text-gray-700">   {t('serviceName')}</label>
                      <input
                        type="text"
                        name="serviceName"
                        value={item.serviceName}
                        onChange={(e) => handleChange(index, e)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                      {item.serviceName.trim().length < 3 && (
                        <p className="text-sm text-red-500">Service Name must be at least 3 characters.</p>
                      )}
                    </div>

                    <div className="mt-6">
                      <label className="block text-sm font-medium text-gray-700">     {t('category')} </label>
                      <select
                        name="category"
                        value={item.category}
                        onChange={(e) => handleChange(index, e)}
                        className="mt-1 block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Select a category</option>
                        {['one', 'two', 'three'].map((cat, catIndex) => (
                          <option key={catIndex} value={cat}>
                            {cat}
                          </option>
                        ))}
                      </select>
                      {!item.category && <p className="text-sm text-red-500">Please select a category.</p>}
                    </div>

                    <div className="mt-6">
                      <label className="block text-sm font-medium text-gray-700 capitalize">  {t('description')} </label>
                      <textarea
                        name="description"
                        value={item.description}
                        placeholder="Description"
                        onChange={(e) => handleChange(index, e)}
                        className="mt-1 block w-full px-3 py-2 border h-20 border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      ></textarea>
                      {item.description.length > 100 && (
                        <p className="text-sm text-red-500">Description should not exceed 70 characters.</p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-[-34px] p-5 relative">
                {formDataOne.map((formData, index) => (
                  <div
                    key={index}
                    className={`flex flex-col gap-4 py-0 ${index === firstInvalidIndex ? 'invalid-field' : ''}`}
                  >
                    <div className="mt-6">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                      {t('serviceName')}
                      </label>
                      <input
                        type="text"
                        name="serviceName"
                        value={formData.serviceName}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Service Name"
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                      />
                      {formData.serviceName.trim().length < 3 && (
                        <p className="text-sm text-red-500">Service Name must be at least 3 characters.</p>
                      )}
                    </div>
                    <div className="mt-6">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                       {t('serviceCharge')}
                      </label>
                      <input
                        type="text"
                        name="serviceCharge"
                        value={formData.serviceCharge}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Service Charge"
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                      />
                      {isNaN(formData.serviceCharge) && (
                        <p className="text-sm text-red-500">Service Charge must be a number.</p>
                      )}
                    </div>
                    <div className="mt-6">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                       {t('description')}
                      </label>
                      <textarea
                        name="description"
                        value={formData.description}
                        onChange={(e) => handleInputChange(index, e)}
                        placeholder="Description"
                        className="w-full p-2 border-[1px] border-gray-300 h-28 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                      ></textarea>
                      {formData.description.length > 70 && (
                        <p className="text-sm text-red-500">Description should not exceed 70 characters.</p>
                      )}
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                      <button type="button" onClick={handleAddForm} className='bg-[#1E2858] capitalize mb-4 className="block w-full max-w-[200px] p-2
           text-white font-[400] mr-10 rounded-[7px] shadow-lg transform transition duration-300  ease-in-out hover:scale-105'>
                   {t('addMore')}
                      </button>
                      {index > 0 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveForm(index)}
                          className='bg-[#1E2858] capitalize mb-4 className="block w-full max-w-[200px] p-2
           text-white font-[400] mr-10 rounded-[7px] shadow-lg transform transition duration-300 ease-in-out hover:scale-105'
                        >
                       {t('Remove')}
                        </button>
                      )}
                    </div>
                  </div>
                ))}
               
              </div>
            </div>

            {/*Availibility*/}
            <div className="">
              <h1 className="p-4 bg-[#D9D9D9] text-[#1E2858] thirdCustom">      {t('Availbility')} </h1>
              <div className="m-20">
                <div className="grid grid-cols-2 gap-5 ">
                  <div className="flex flex-col">
                    <label>   {t('Country')}</label>
                    <select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                      className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                    >
                      <option value="">Select Country</option>
                      {availability.countries.map((country) => (
                        <option key={country.name} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    {selectedCountry && (
                      <div className="flex flex-col">
                        <label>Select State:</label>
                        <select
                          value={selectedState}
                          onChange={handleStateChange}
                          className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        >
                          <option value="">Select State</option>
                          {availability.countries
                            .find((country) => country.name === selectedCountry)
                            .states.map((state) => (
                              <option key={state.name} value={state.name}>
                                {state.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div>
                    {selectedState && (
                      <div className="flex flex-col">
                        <label>{t('City')}</label>
                        <select
                          value={selectedCity}
                          onChange={handleCityChange}
                          className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        >
                          <option value="">Select City</option>
                          {availability.countries
                            .find((country) => country.name === selectedCountry)
                            .states.find((state) => state.name === selectedState)
                            .cities.map((city) => (
                              <option key={city} value={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col">
                    <label>  {t('Locality')}</label>
                    <input
                      type="text"
                      placeholder="locality"
                      value={locality}
                      onChange={handleLocalityChange}
                      className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                    />
                  </div>

                  {/* <div className="col-span-2">
                  
               
                  </div> */}

                  <div className="col-span-2">
                    <div>
                      <label className="block  text-[14px] font-[600] leading-[30px] capitalize text-[#1E2858]">
                      {t('location')}<sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                        <input
                          type="text"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none mt-1.5 p-1 w-full"
                          placeholder="Search location"
                        />
                      </label>
                      <button onClick={handleSearchClick} className="p-2 bg-black text-white my-4">
                      {t('Search')}
                      </button>
                      {error && <p style={{ color: 'red' }}>{error}</p>}
                      <MapContainer center={position} zoom={13} style={{ height: '500px', width: '100%' }}>
                        <TileLayer
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker
                          position={position}
                          draggable={true}
                          eventHandlers={{
                            dragend: handleMarkerDragEnd
                          }}
                        >
                          <Popup>{searchQuery || 'Selected Location'}</Popup>
                        </Marker>
                        <MapViewUpdater position={position} />
                      </MapContainer>
                    </div>
                  </div>
                </div>
                <div className="grid grid-rows-2 gap-4 mt-5 ">
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <TextField
                        label="Working Hour"
                        type="number"
                        variant="outlined"
                        className="w-full"
                        value={openingHour === 0 ? '' : openingHour}
                        onChange={handleOpeningHourChange}
                        inputProps={{ min: 1, max: 12 }}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Working Minute"
                        type="number"
                        className="w-full"
                        variant="outlined"
                        value={openingMinute}
                        onChange={handleOpeningMinuteChange}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    <div>
                      <button
                        onClick={toggleOpeningAMPM}
                        className="text-[#2E4374] p-2 m-1 border-2 border-[#2E4374] hover:bg-[#2E4374] transition duration-300 hover:text-white font-customFont"
                      >
                        {openingIsAM}
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    <div>
                      <TextField
                        label="Closing Hour"
                        type="number"
                        className="w-full"
                        variant="outlined"
                        value={closingHour === 0 ? '' : closingHour}
                        onChange={handleClosingHourChange}
                        inputProps={{ min: 1, max: 12 }}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Closing Minute"
                        className="w-full"
                        variant="outlined"
                        value={closingMinute}
                        onChange={handleClosingMinuteChange}
                        inputProps={{ min: 0, max: 59 }}
                      />
                    </div>
                    <div>
                      <button
                        onClick={toggleClosingAMPM}
                        className="text-[#2E4374] p-2 m-1 border-2 border-[#2E4374] hover:bg-[#2E4374] transition duration-300 hover:text-white font-customFont"
                      >
                        {closingIsAM}
                      </button>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-5 mt-5">
                  <div className="w-full">
                    <label>  {t('workingDay')}</label>
                    <select
                      value={openingDay}
                      onChange={handleOpeningDayChange}
                      label="Working Day"
                      className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                    >
                      {daysOfWeek.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="w-full">
                    <label>  {t('closingDay')}</label>
                    <select
                      value={closingDay}
                      onChange={handleClosingDayChange}
                      label="Closing Day"
                      className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                    >
                      {daysOfWeek.map((day) => (
                        <option key={day} value={day}>
                          {day}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/*contact information*/}
            <div className="mt-6  ">
              <h1 className="p-4 bg-[#D9D9D9] text-[#1E2858] thirdCustom">Contact information  {t('description')}</h1>
              <div className="">
                {formDatas.contactInformation.map((contact, index) => (
                  <div key={index} className="grid grid-cols-2 m-20 gap-4">
                    <div className="">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                        {t('Name')}
                        <sup className="text-red-500 text-[12px] relative top-0 left-0">*</sup>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={contact.name}
                        onChange={(e) => handleContactChange(index, e)}
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        placeholder="Enter name"
                      />
                    </div>
                    <div className="">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                {t('phone')}
                        <sup className="text-red-500 text-[12px] relative top-0 left-0">*</sup>
                      </label>
                      <input
                        type="text"
                        name="phone"
                        value={contact.phone}
                        onChange={(e) => handleContactChange(index, e)}
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        placeholder="Enter phone"
                      />
                    </div>
                    <div className="">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858] mt-4">
                       {t('whatsappNo')}
                      </label>
                      <input
                        type="text"
                        name="whatsappNo"
                        value={contact.whatsappNo}
                        onChange={(e) => handleContactChange(index, e)}
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        placeholder="Enter WhatsApp number"
                      />
                    </div>
                    <div className="">
                      <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858] capitalize mt-4">
                       {t('consultant')}
                      </label>
                      <input
                        type="text"
                        name="consultant"
                        value={contact.consultant}
                        onChange={(e) => handleContactChange(index, e)}
                        className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        placeholder="Enter consultant"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/*Image Services*/}
            <div className="mt-6  ">
              <h1 className="p-4 bg-[#D9D9D9] text-[#1E2858] thirdCustom">Image Service</h1>

              <div className="m-20">
                <div className="mt-5">
                  <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                  {t('imageUpload')}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    className="p-2 border-[1px] w-full border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                  />
                </div>

                <div className="mt-5">
                  <label className="block mb-2 text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                  {t('youtubeLink')}
                  </label>
                  <input
                    type="text"
                    value={formDataes.serviceImage.youtubeLink}
                    onChange={handleYouTubeLinkChange}
                    className="w-full p-2 border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                    placeholder="Enter YouTube link"
                  />
                </div>
              </div>
            </div>
            <div className='flex justify-end'>
            <button onClick={subMitSerData} className='bg-[#1E2858] capitalize mb-4 className="block w-full max-w-[200px] p-2
           text-white font-[400] mr-10 rounded-[7px] shadow-lg transform transition duration-300 ease-in-out hover:scale-105'>
             Add Service
            </button>
            </div>
          
          </div>
        </div>

        {/*Right side*/}
        <div className="col-span-1   fixed right-4 w-1/6 bg-gray-200 shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)] h-auto">
          <div class="bg-white flex flex-row justify-center gap-6 p-6 ">
            <ul className="list-disc relative ">
              {items.map((item) => (
                <li key={item.id} className={`  relative mt-0 ${item.id !== 0 && 'mt-2'}`}>
                  <div
                    className={`text-[#1E2858] font-[600] text-[16px] py-2  thirdCustom cursor-pointer${
                      item.id === 4 ? '' : 'r'
                    }`}
                    onClick={() => handleClick(item.id)}
                  >
                      {t(`${item.label}`)}
                    {item.percentage !== undefined && (
                      <div className="flex flex-row gap-5   ">
                        <div className="w-[130px] mt-3     h-2  bg-gray-500 rounded-[10px]">
                          <span
                            className={` h-2 rounded-full block transition-width duration-500 ${getProgressColor(
                              item.percentage
                            )}`}
                            style={{ width: `${item.percentage}%` }}
                          ></span>
                          <span className="text-[12px] text-gray-400 font-[400]">{item.percentage}%/100%</span>
                        </div>

                        <div className="relative">
                          <button className="cursor-pointer text-[8px] text-[#7BCFF8]">
                            <EditNoteIcon></EditNoteIcon>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-row  gap-3 bg-white mt-3">
            <svg className="w-24 h-24 relative" viewBox="0 0 120 120">
              <circle
                className="text-gray-300"
                strokeWidth="10"
                stroke="currentColor"
                fill="transparent"
                r="50"
                cx="60"
                cy="60"
              />
              <circle
                className="text-green-500"
                strokeWidth="10"
                strokeDasharray={circumference}
                strokeDashoffset={progress}
                strokeLinecap="round"
                stroke="currentColor"
                fill="transparent"
                r="50"
                cx="60"
                cy="60"
              />
              <foreignObject x="10" y="10" width="100" height="100">
                <div xmlns="http://www.w3.org/1999/xhtml" className="flex items-center justify-center h-full">
                  <p className="text-center text-black">{finaloutput}%</p>
                </div>
              </foreignObject>
            </svg>
            <p className="mt-5 leading-[20px]">
              <span className="text-[#1E2858] font-[600] text-[16px]  thirdCustom  cursor-pointer">{t('Property score')}</span>
              <br />
              <span className="text-[11px] font-[400] leading-[10px] text-slate-900 thirdCustom ">
                {' '}
                {t('Better your property score,greater your visibility')}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeData;
