import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  totalItems: 0,
  cardsPerPage: 5,
};

const paginationSlice = createSlice({
  name: 'pagination',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setTotalItems(state, action) {
      state.totalItems = action.payload;
    },
    setCardsPerPage(state, action) {
      state.cardsPerPage = action.payload;
    },
    resetPagination(state) {
      state.currentPage = 1;
    },
  },
});

export const { setCurrentPage, setTotalItems, setCardsPerPage, resetPagination } = paginationSlice.actions;

export default paginationSlice.reducer;
