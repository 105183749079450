import React from 'react';
import SliderPage from './SliderPage';
import Carrer from './Carrer';
import TeamMember from './TeamMember';
import InvestmentPage from './Investment';
import Location from './Location';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const About = () => {
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');

  return (
    <>
      <div className=" mt-20 mx-auto ">
        <ul className="flex p-5">
          <li className="">
            <Link
              to="/"
              className='text-[#1E2858] font-semibold capitalize '
            >
              Home  <KeyboardArrowRightIcon />
              <span className='text-[#2E4374] text-[14px] font-[500]'> {pathnameWithoutSlash}</span>
             
            </Link>
          </li>
          
        </ul>
      </div>
      <div className="mt-5 mx-auto max-w-7xl p-5 ">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-10 ">
          <div className=" flex  flex-col">
            <div className='flex flex-col'>
              <h1 className="text-[#1E2858] font-[600] text-[25px] leading-[20px] ">About US</h1>
              <p
                className="text-[#2E4374] font-[500] lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px] leading-[25px] text-justify mt-5 "
                
              >
                At Squarebric, we understand that people everywhere are searching for a home to call their own. A home
                is a cherished memory that lasts forever, where the walls embrace memories, the ceilings shelter love
                and laughter, where the quiet corners offer a much-needed pause and life itself becomes a reason to
                celebrate. We want to make the journey as joyful as the moment when you finally find the perfect home.
                So we begin by partnering with our customers from the start and being there when it matters the mo-
                right from online search to brokers to home loans to paperwork to finally finding that perfect home. At
                Squarebric, we help you find joy.
              </p>
            </div>

            <div className='flex flex-col'>
              <h1 className="text-[#1E2858] font-[600] text-[25px] leading-[20px] mt-4">Our Vision</h1>
              <p className="text-[#2E4374] font-[500] sm:text-[14px] md:text-[15px] lg:text-[16px] 2xl:text-[16px]  leading-[25px] mt-5 text-justify">
                Changing the way India experiences property.Changing the way India experiences property.
              </p>
            </div>
            <div className='flex flex-col'>
              <h1 className="text-[#1E2858] font-[600] text-[25px] leading-[20px] mt-4">Our Missions</h1>
              <p
                className="text-[#2E4374] font-[500] lg:text-[16px] 2xl:text-[16px] sm:text-[14px] md:text-[15px] leading-[25px] mt-5 text-justify "
         
              >
                To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a
                home, and digitally enabling them throughout their journey. We do that with data, design, technology,
                and above all, the passion of our people while delivering value to our shareholders.
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <LazyLoadImage src="https://squarebric-media-files.squarebric.com/homeicon2.png"
             alt="homeIcon" className='w-[97%] h-[400px]' />
          </div>
        </div>
        <div>
          <SliderPage />
        </div>
        <div className="">
          <Carrer />
        </div>
        <div>
          <TeamMember />
        </div>
        <div>
          <InvestmentPage />
        </div>
        <div>
          <Location />
        </div>
      </div>
    </>
  );
};

export default About;
