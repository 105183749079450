// apiClient.js
import axios from 'axios';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: 'https://square-bric-backend-api.squarebric.com', // Use your base URL
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor to add token to headers
apiClient.interceptors.request.use(
  (config) => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const token = JSON.parse(userData).access_token;
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default apiClient;
