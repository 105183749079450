// import Logo from "../asset/Logo.png";

import business from '../asset/business .png';
import Group from '../asset/Group.png';
import Clipboard from '../asset/Clipboard.png';
import Line from '../asset/Line.png';
import Vector from '../asset/Vector.png';
import ListSearch from '../asset/ListSearch.png';
import Rectangle from '../asset/Rectangle.png';

import Location from '../asset/Location.png';
export const jsonData = [
  {
    id: 1,
    title: 'Green park city',
    description: 'Land,LalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 2,
    title: 'Sahara state city',
    description: 'Land,LalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 3,
    title: 'Green park city',
    description: 'Land,AlamBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 4,
    title: 'Blue state city',
    description: 'Banglore,KalBag Lucknow',
    price: '$300-$400',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 5,
    title: 'Lank state city',
    description: 'Gujrat,KalBag Lucknow',
    price: '$600-$800',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 6,
    title: 'Sahara state city',
    description: 'Land,KalBag Ghazipur',
    price: '$500-$700',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 7,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$700-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 8,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$900-$900',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 9,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$40-$600',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 10,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 11,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 12,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 13,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 14,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 15,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 16,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 17,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 18,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 19,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 20,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 21,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 22,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  },
  {
    id: 23,
    title: 'Sahara state city',
    description: 'Land,KalBag Lucknow',
    price: '$200-$500',
    image:
      'https://images.pexels.com/photos/1974596/pexels-photo-1974596.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
  }
];

export const sidebarData = [
  {
    title: 'Dashboard',
    img: business,
    link: 'property'
  },
  
  {
    title: ' Service Listing',
    img: Clipboard,
    link: 'homeservices'
  },
  {
    title: 'property Listing',
    img: Line,
    link: 'propertylisting'
  },
  {
    title: 'Membership',
    img: business,
    link: 'membership'
  },
  {
    title: 'Inquery',
    img: ListSearch,
    link: 'inquiry'
  },
  {
    title: 'Myunits',
    img: ListSearch,
    link: 'myunits'
  },
  {
    title: 'Board',
    img: ListSearch,
    link: 'board'
  },
  {
    title: 'Setting',
    img: Vector,
    link: 'setting'
  }
];

export const data = [
  {
    title: 'Rent Received ',
    description: 'Get instant live results for only ',
    para: 'this is lorem picjhhjd i am something like that',
    price: '23344.90',
    img: Rectangle
  }
];

export const InquiryData = [
  {
    id: 1,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  },
  {
    id: 2,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  },
  {
    id: 3,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  },
  {
    id: 4,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  },
  {
    id: 4,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  },
  {
    id: 4,
    title: 'unmissable offer queen bomonti residence',
    subtitle: '2 wala quadar road lalbhag,lucknow,226017',
    img: Location,
    img1: business
  }
];
