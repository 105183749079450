export const ReviewData = [
  {
    name: 'Akram shaikh',
    location: 'Dubai new town',
    service: 'Kitchen Services',

    description:
      "Squarebrics offers valuable insights and resources for both buyers and sellers. From market trends and property valuation tools to tips for staging and marketing homes, the platform equips users with the knowledge and tools they need to make informed decisions and achieve their real estate goals",
    image: 'https://squarebric-media-files.squarebric.com/review2.jpg'
  },
  {
    name: 'Abhishek Singh',
    location: 'Dubai new town',
    service: 'Paint Services',

    description:
      "Squarebrics offers valuable insights and resources for both buyers and sellers. From market trends and property valuation tools to tips for staging and marketing homes, the platform equips users with the knowledge and tools they need to make informed decisions and achieve their real estate goals",
    image: 'https://squarebric-media-files.squarebric.com/review1.jpg'
  },
  {
    name: 'Mayra Siddiqui',

    location: 'Dubai new town',
    service: 'Kitchen Services',
    description:
      'They exceeded my expectations at every step of my bathroom renovation. From their attentive consultation to their impeccable craftsmanship, they delivered a stunning result. Their communication and dedication to customer satisfaction were outstanding.',

    image: 'https://squarebric-media-files.squarebric.com/review2.jpg'
  },
  {
    name: 'Aryan Khan',

    location: 'Dubai new town',
    service: 'Paint Services',

    description:
      "I'm thoroughly impressed with  Paint Service. Their professionalism, attention to detail, and excellent customer service made the entire process a breeze. The final result exceeded my expectations, and I highly recommend them to anyone in need of painting services.",
    image: 'https://squarebric-media-files.squarebric.com/review1.jpg'
  }
];

export const ProductSliderData = [
  {
    Title: 'Our Values',
    description:
      'We find our joy in breaking rules and making some of our own. we are a team of enthusiastic game changers, quiet geniuses and hands-on revolutionaries eager to take on the world. We are helping india find not just their perfect homes, but find their joy.',
    image: 'https://squarebric-media-files.squarebric.com/VALUE.png',
    backgroundImage: '#505D9E'
  },
  {
    Title: 'Innovation',
    description:
      'To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a home, and digitally enabling them throughout their journey. We do that with data, design, technology, and above all, the passion of our people while delivering value to our shareholders.',
    image: 'https://squarebric-media-files.squarebric.com/objects.png',
    backgroundImage: '#A44531'
  },
  {
    Title: 'Excellence',
    description:
      'To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a home, and digitally enabling them throughout their journey. We do that with data, design, technology, and above all, the passion of our people while delivering value to our shareholders.',
    image: 'https://squarebric-media-files.squarebric.com/Exxcellence.png',
    backgroundImage: '#F0BB74'
  },
  {
    Title: 'Respect',
    description:
      'To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a home, and digitally enabling them throughout their journey. We do that with data, design, technology, and above all, the passion of our people while delivering value to our shareholders.',
    image: 'https://squarebric-media-files.squarebric.com/hpme.svg',
    backgroundImage: '#A5C2EB'
  },

  {
    Title: 'Ownership',
    description:
      'We dont work in a company. We are the company! Its face, its soul, its voice. Its flesh and bones.  So, lets embrace it.Make it personal. Make it heart.',
    image: 'https://squarebric-media-files.squarebric.com/OwnerShip.png',
    backgroundImage: '#F0BB74'
  },

  {
    Title: 'Trust & Integrity ',
    description:
      'To be the first choice of our consumers and partners in discovering, renting, buying, selling, financing a home, and digitally enabling them throughout their journey. We do that with data, design, technology, and above all, the passion of our people while delivering value to our shareholders.',
    image: 'https://squarebric-media-files.squarebric.com/Trust.png',
    backgroundImage: '#51C386'
  }
];
