import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import './footer.css';

const futer = [
  {
    links: [
      { name: 'Cookies Management', url: '/Cookies' },
      { name: 'FAQ', url: '/faq' },
      { name: 'Privacy Policy', url: '/privacypolicy' },
      { name: 'Terms and condition', url: '/termsconditions' },
      { name: 'Membership Agreement', url: '/membership' }
    ]
  }
];

const enquiry = [
  {
    links: [
      { icon: 'https://squarebric-media-files.squarebric.com/Enquiry.svg', name: 'Enquiry' },
      { icon: 'https://squarebric-media-files.squarebric.com/WhatsApp.svg', name: 'WhatsApp' },
      { icon: 'https://squarebric-media-files.squarebric.com/Line.svg', name: 'Call' },
      { icon: 'https://squarebric-media-files.squarebric.com/Tour.svg', name: '3D Tour' }
    ]
  }
];

const images = [
  'https://squarebric-media-files.squarebric.com/Ficon1.svg',
  'https://squarebric-media-files.squarebric.com/Ficon2.svg',
  'https://squarebric-media-files.squarebric.com/Ficon3.svg',
  'https://squarebric-media-files.squarebric.com/Ficon4.svg',
  'https://squarebric-media-files.squarebric.com/Ficon5.svg'
];

const existingEmails = new Set(); // To store existing emails (simulating a backend check)

const Footer = () => {
  const [message, setMessage] = useState('');
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('Enter your Email...');
  const location = useLocation();
  const { t } = useTranslation();

  const handleInputChange = (e) => {
    setMessage(e.target.value);
    setIsInvalidEmail(!validateEmail(e.target.value)); // Set the invalid state based on email validity
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSendClick = () => {
    if (!message) {
      setPlaceholderText('Please enter your email ID');
      return;
    }

    if (!validateEmail(message)) {
      setMessage(''); // Clear the input value
      setPlaceholderText('Enter a valid email ID'); // Update the placeholder text
      setIsInvalidEmail(true); // Set the invalid state
      return;
    }

    if (existingEmails.has(message)) {
      setMessage(''); // Clear the input value
      setPlaceholderText('Email already exists'); // Update the placeholder text
      setIsInvalidEmail(true); // Set the invalid state
      return;
    }

    // Email is valid and not already existing
    existingEmails.add(message);
    setPlaceholderText('Thank you for subscribing'); // Update the placeholder text
    setMessage(''); // Clear the input value
    setIsInvalidEmail(false); // Reset the invalid state

    // Reset the placeholder text after 3 seconds
    setTimeout(() => {
      setPlaceholderText('Enter your Email...');
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  const footerLinks = [
    {
      title: 'ABOUT_SQUAREBRIC',
      links: [
        { name: 'Contact Us', url: '/contact-us' },
        { name: 'About Us', url: '/about-us' },
        { name: 'Our Values' },
        { name: 'Investment Philosophy' },
        { name: 'Careers' }
      ]
    },
    {
      title: 'MEDIA_CENTRE',
      links: [
        { name: 'News & Press Releases', url: '/news-press-releases' },
        { name: 'Blog', url: '/blog' },
        { name: 'Photo Gallery', url: '/photo-gallery' },
        { name: 'Video Gallery', url: '/video-gallery' }
      ]
    },
    {
      title: 'E_SERVICES',
      links: [
        { name: 'Properties', url: '/properties' },
        { name: 'Land', url: '/land' },
        { name: 'Community Management', url: '/community-management' }
      ]
    }
  ];

  return (
    <footer className="text-white pt-14">
      <div className="w-full">
        <div className="h-16 md:h-44 sm:h-16 bg-[#DEECFB]">
          <svg className="" xmlns="http://www.w3.org/2000/svg" viewBox="20 40 1400 160" x="0" y="0">
            <path
              fill="#2e4374"
              fillOpacity="1"
              d="M1440,96L1392,106.7C1344,117,1248,129,1152,140C1056,151,960,153,864,137.3C768,122,672,110,576,98C480,86,384,64,288,64C192,64,96,96,48,112L0,128L0,0L48,0C96,0,192,0,288,0C384,0,480,0,576,0C672,0,768,0,864,0C960,0,1056,0,1152,0C1248,0,1344,0,1392,0L1440,0Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="md:hidden block bg-[#DEECFB] mt-0 md:mt-20 sm:mt-0 p-4">
        <>
          <h1 className="text-2xl font-semibold font-thirdCustom text-center text-[#2e4374] md:-mt-0 -mt-7">
            Never Miss Out Any Update
          </h1>
        </>
        <div className="flex mt-6 justify-center items-center">
          <input
            type="text"
            value={message}
            onChange={handleInputChange}
            placeholder={placeholderText}
            className={`border-2 ${
              isInvalidEmail ? 'border-red-400' : 'border-[#ffffff]'
            } bg-[#2e4374] p-2 w-48 rounded-md text-white`}
          />
          <button
            onClick={handleSendClick}
            disabled={isInvalidEmail || !message}
            className={`md:mt-2 lg:ml-2 ml-2 p-2 w-48 rounded-md text-white ${
              isInvalidEmail ? 'bg-[#4460a4]' : 'bg-[#2e4374]'
            } transition duration-300 ease-in-out transform hover:scale-105`}
          >
            SUBSCRIBE
          </button>
        </div>
        <div className="flex justify-between flex-col mt-5">
          <div className="flex justify-center mt-2 tracking mb-3">
            {images.map((image, index) => (
              <img key={index} src={image} alt="social" className="w-7 h-7 mr-3" />
            ))}
          </div>
          <div className="mb-4">
            <div className="flex justify-center mt-2 tracking">
              <button className="">
                <a href="https://play.google.com/" className="app-link">
                  <img src="https://squarebric-media-files.squarebric.com/apple.png" alt="Play Store" height={40} width={40} />
                </a>
              </button>

              <button className="ml-2">
                <a href="https://apps.apple.com/" className="">
                  <img src="https://squarebric-media-files.squarebric.com/play.png" alt="Apple Store" height={40} width={40} />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#DEECFB]">
        <div className="flex flex-col md:flex-row justify-around">
          {/* <div className="-mt-12 md:-mt-0">
            <div className="flex p-10 flex-col md:flex-row">
              {footerLinks.map((section, sectionIndex) => (
                <div key={sectionIndex} className="font-thirdCustom text-[#1E2858] text-center md:text-left">
                  <h3 className="mb-2 text-lg uppercase font-[600] pl-3">
                    Translate section title
                    {t(`footerLinks.${section.title}.title`)}
                  </h3>
                  <ul className="">
                    {section.links.map((link, linkIndex) => (
                      <li key={linkIndex} className="mb-1 md:text-xl text-sm font-thirdCustom pl-3 text-[#2e4374]">
                        <Link
                          to={link.url || '#'}
                          className={`${location.pathname === link.url ? 'text-[#FF6347]' : ''} hover:underline`}
                        >
                          Translate link names
                          {t(`footerLinks.${section.title}.links.${linkIndex}.name`)}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div> */}
          <div className="flex p-10 flex-col md:flex-row">
      {footerLinks.map((section, sectionIndex) => (
        <div key={sectionIndex} className="font-thirdCustom text-[#1E2858] text-center md:text-left">
          <h3 className="mb-2 text-lg uppercase font-[600] pl-3">
            {/* Translate section title */}
            {t(`footerLinks.${section.title}.title`)} {/* Ensure title translation exists */}
          </h3>
          <ul className="">
            {section.links.map((link, linkIndex) => (
              <li key={linkIndex} className="mb-1 md:text-xl text-sm font-thirdCustom pl-3 text-[#2e4374]">
                <Link
                  to={link.url || '#'}  
                  className={`${location.pathname === link.url ? 'text-[#FF6347]' : ''} hover:underline`}
                >
                  {/* Translate link names */}
                  {t(`footerLinks.${section.title}.links.${linkIndex}.name`)} {/* Ensure link translation exists */}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
          <div className="mt-10 hidden md:block">
            <>
              <h1 className="text-2xl font-thirdCustom text-[#2e4374] font-semibold"> {t('title')}</h1>
            </>

            <div className="lg:flex-row md:flex-col mt-6">
              <input
                type="text"
                value={message}
                onChange={handleInputChange}
                placeholder={t('placeholder')}
                className={`border-2 ${
                  isInvalidEmail ? 'border-red-400' : 'border-[#2e4374]'
                }  p-2 rounded-lg w-52 text-[#2e4374] bg-[#DEECFB]`}
              />
              <button
                onClick={handleSendClick}
                disabled={isInvalidEmail || !message}
                className={`md:mt-2 lg:ml-2 p-2 w-52 rounded-md text-white ${
                  isInvalidEmail ? 'bg-[#4460a4]' : 'bg-[#2e4374]'
                } transition duration-300 ease-in-out transform hover:scale-105`}
              >
                {t('button')}
              </button>
            </div>
            <div className="flex lg:flex-row md:flex-col lg:mt-10 md:mt-4">
              <div className="flex justify-center mt-5 tracking md:mr-16 lg:mr-0">
                {images.map((image, index) => (
                  <img key={index} src={image} alt="social" className="w-7 h-7 mr-3" />
                ))}
              </div>

              <div className="sm:flex sm:gap-2 sm:mt-2">
                <button className="mt-2">
                  <a href="https://play.google.com/" className="app-link">
                    <img
                      src="https://squarebric-media-files.squarebric.com/googleplay.svg"
                      alt="Play Store"
                      height={40}
                      width={110}
                    />
                  </a>
                </button>

                <button className="mt-2">
                  <a href="https://apps.apple.com/" className="">
                    <img
                      src="https://squarebric-media-files.squarebric.com/Appstore.svg"
                      alt="Apple Store"
                      height={40}
                      width={105}
                    />
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          {futer.map((item, index) => (
            <div
              key={index}
              className="flex flex-col md:flex-row justify-around p-5 bg-[#334978] w-full font-thirdCustom text-center"
            >
              {item.links.map((link, linkIndex) => (
                <Link key={linkIndex} to={link.url}className="text-[#ffffff] md:mt-0 mt-4 hover:underline">
                  {t(`${link.name}`)} 
                </Link>
              ))}
            </div>
          ))}
        </div>


        <div className="">
          {enquiry.map((item, index) => (
            <div key={index} className="grid grid-cols-4 p-5">
              {item.links.map((link, linkIndex) => (
                <Link key={linkIndex} className="text-[#1E2858]">
                  <div
                    className={`text-[#1E2858] flex justify-center ${
                      index === enquiry.length - 1 && 'sm:border-r-4 border-black'
                    }`}
                  >
                    <img src={link.icon} alt="" />
                  </div>
                  <div className="text-[#1E2858] flex justify-center font-semibold">
                    {/* {link.name} */}
                    {t(`links.${link.name}`)}
                  </div>
                </Link>
              ))}
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
