import React, { useTransition,useState,useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PropertyInquery from './PropertyInquery';
import ServiceInquery from './ServiceInquery';
export default function InquiryData() {
  const [userData, setUserData] = useState(null);
  const [activeTab, setActiveTab] = useState('membership');
  const location = useLocation();
  const [isPending, startTransition] = useTransition();

  
  
  let locations = useLocation();
 

  const pathnames = locations.pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1];
  useEffect(() => {
    // Retrieve user data from localStorage
    const storedUserData = localStorage.getItem('username');
    console.log('name', storedUserData);
    if (storedUserData) {
      // Parse the JSON string into a JavaScript object
      const parsedUserData = JSON.parse(storedUserData);
      setUserData(parsedUserData);
    }
  }, [userData]);

  return (
    <div className="mt-5">
      <div className="ml-[8%] px-4">
      <div className="">
        <span className="text-[#1E2858] ml-0 font-[400] mt-6 text-[16px]  capitalize">
          <Link to="/dashboard" className="text-[#7D7D7D] font-[400] text-[18px] capitalize">
            Dashboard
          </Link>
          <NavigateNextIcon className=" block text-[14px] mb-[2px] h-3" />
          <span className='text-[#2E4374]'>  {`${lastSegment}`}</span>
        </span>
      </div>

        <div className="flex justify-start gap-5  mt-4">
        
           <button
              style={{ textDecoration: 'none', color: 'black' }}
         className={`relative pb-2 text-[#2E4374] text-[20px] font-[300] leading-[24px] border-t-2 ${activeTab==='PropertyInquery'? 'border-[#4BA3DB]' : 'border-transparent'}`}
          onClick={() => setActiveTab('PropertyInquery')}
        >
             PropertyInquery
        </button>
        <button
      className={`relative pb-2 text-[#2E4374] text-[20px] font-[300] leading-[24px]  border-t-2 ${activeTab==='ServiceInquery'? 'border-[#4BA3DB]' : 'border-transparent'}`}
      style={{ textDecoration: 'none', color: 'black' }}
          onClick={() => setActiveTab('ServiceInquery')}
        >
        ServiceInquery
        </button>
        </div>

        <div className="mt-4">
          {activeTab === 'PropertyInquery' ? (
            <PropertyInquery />
          ) : (
            <ServiceInquery />
          )}
        </div>
      </div>
    </div>
  );
}
