import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dollar from '../../../../assets/dollar.svg';
import area from '../../../../assets/Area1.svg';
import bedroom from '../../../../assets/bedroom2.svg';
import bathroom from '../../../../assets/Bathroom2.svg';
import MobileBathroom from '../SelectFilter/MobileFilter/MobileBathroom';
import MobileBedroom from '../SelectFilter/MobileFilter/MobileBedroom';
import MobileAllFilter from '../SelectFilter/MobileAllfilter';
import MobileLocation from './MobileFilter/MobileLocation';
import MobileArea from '../SelectFilter/MobileFilter/MobileArea';
import MobilePrice from '../SelectFilter/MobileFilter/MobilePrice';
import MobileButton from './MobileFilter/MobileButton';

const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))',
  transition: 'all 0.3s ease'
};
const buildQuery = (params) => {
  let queryParams = [];
  queryParams.push(`country=${encodeURIComponent(params.selectedCountry)}`);
  queryParams.push(`state=${encodeURIComponent(params.selectedState)}`);
  queryParams.push(`sub_city=${encodeURIComponent(params.selectedCity)}`);
  queryParams.push(`purpose=${encodeURIComponent(params.purpose)}`);
  queryParams.push(`property_type=${encodeURIComponent(params.propertyType)}`);
  queryParams.push(`currency=${encodeURIComponent(params.currency)}`);
  if (params.subPropertyType && params.subPropertyType.length > 0) {
    params.subPropertyType.forEach((type) => {
      queryParams.push(`sub_property_type=${encodeURIComponent(type)}`);
    });
  }

  if (params.selectedMin != null) {
    queryParams.push(`min_price=${encodeURIComponent(params.selectedMin)}`);
  }
  if (params.selectedMax != null) {
    queryParams.push(`max_price=${encodeURIComponent(params.selectedMax)}`);
  }

  console.log('Inside buildQuery - selectedMin:', params.selectedMin);
  console.log('Inside buildQuery - selectedMax:', params.selectedMax);
  if (params.min_total_area) {
    queryParams.push(`min_total_area=${encodeURIComponent(params.min_total_area)}`);
  }
  if (params.max_total_area) {
    queryParams.push(`max_total_area=${encodeURIComponent(params.max_total_area)}`);
  }
  if (params.selectedAreaOption) {
    queryParams.push(`area_unit=${encodeURIComponent(params.selectedAreaOption)}`);
  }
  if (params.min_Bathrooms) {
    queryParams.push(`min_bathrooms=${encodeURIComponent(params.min_Bathrooms)}`);
  }
  if (params.max_Bathrooms) {
    queryParams.push(`max_bathrooms=${encodeURIComponent(params.max_Bathrooms)}`);
  }
  if (params.min_Bedrooms) {
    queryParams.push(`min_bedrooms=${encodeURIComponent(params.min_Bedrooms)}`);
  }
  if (params.max_Bedrooms) {
    queryParams.push(`max_bedrooms=${encodeURIComponent(params.max_Bedrooms)}`);
  }

  queryParams.push(`skip=${params.skip || 0}`);
  queryParams.push(`limit=${params.limit || 500}`);

  return `https://square-bric-backend-api.squarebric.com/property-search/property-search?${queryParams.join('&')}`;
};


const MobileMainPageFilter = () => {
  const [isStateAndCityVisible, setIsStateAndCityVisible] = useState(true);
  const [activeOption, setActiveOption] = useState('Buy');
  const [alertOpen, setAlertOpen] = useState(false);
  const [areExtraFiltersVisible, setAreExtraFiltersVisible] = useState(false);
  const [activeComponent, setActiveComponent] = useState('');
  const [selectedButton, setSelectedButton] = useState('');
  const [isMobileAllFilterOpen, setIsMobileAllFilterOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const activeComponentRef = useRef(null);

  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  const selectedState = useSelector((state) => state.location.selectedState);
  const selectedCity = useSelector((state) => state.location.selectedCity);
  const purpose = useSelector((state) => state.filter.selectedButton);
  const propertyType = useSelector((state) => state.properties.propertyType);
  const subPropertyType = useSelector((state) => state.properties.subPropertyType);
  let { selectedMin, selectedMax, selectedCurrency } = useSelector((state) => state.multiselect);
  const min_total_area = useSelector((state) => state.selectedPrice.min) || '';
  const max_total_area = useSelector((state) => state.selectedPrice.max) || '';
  const min_Bathrooms = useSelector((state) => state.bathroom.minValue);
  const max_Bathrooms = useSelector((state) => state.bathroom.maxValue);
  const min_Bedrooms = useSelector((state) => state.bedroom.minValue);
  const max_Bedrooms = useSelector((state) => state.bedroom.maxValue);
  const buttonsContainerRef = useRef(null);

  console.log('selectedmin', selectedMin)
  console.log('selectedMax', selectedMax)
  useEffect(() => {
    const handleClickOutside = (event) => {
     
      if (buttonsContainerRef.current && !buttonsContainerRef.current.contains(event.target)) {
        setSelectedButton(null);
      }
    };

    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
   
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const selectedAreaOption = useSelector((state) => state.area.selectedAreaOption);
  useEffect(() => {
    const isValid = selectedCountry && selectedState && selectedCity && propertyType && subPropertyType.length > 0;
    setIsButtonDisabled(!isValid);
  }, [selectedCountry, selectedState, selectedCity, propertyType, subPropertyType]);



  const handleSearch = () => {
    // if (!selectedCountry) {
    
    //     setAlertMessage('Pleasdgdfffgh select a country before proceeding');


    //   setAlertOpen(true);
 
    //   return;
    // }

    if (!selectedState) {
      setAlertMessage('Please select a state before proceeding');
      setAlertOpen(true);
      return;
    }

    if (!selectedCity) {
      setAlertMessage('Please select a city before proceeding');
      setAlertOpen(true);
      return;
    }

    if (!propertyType) {
      setAlertMessage('Please select a category before proceeding');
      setAlertOpen(true);
      return;
    }
    console.log('12345', subPropertyType)


    if (!subPropertyType) {
      setAlertMessage('Please select a type of property before proceeding');
      setAlertOpen(true);
      console.log('12345', subPropertyType)
      return;
    }


    let currency = selectedCurrency.label === 'Dollar' ? 'usd' : selectedCurrency.label;
    console.log('currency', currency)

    const params = {
      selectedCountry,
      selectedState,
      selectedCity,
      purpose,
      propertyType,
      subPropertyType,
      selectedMin,
      selectedMax,
      currency,
      min_total_area,
      max_total_area,
      selectedAreaOption,
      min_Bathrooms,
      max_Bathrooms,
      min_Bedrooms,
      max_Bedrooms,
      skip: 0,
      limit: 500
    };
    console.log('Params passed to buildQuery:', params);
    const url = buildQuery(params);
    console.log('search url', url);

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        if (response.status === 204) {
          throw new Error('No data found');
        }
        return response.json();
      })
      .then((data) => {
        navigate(`/SearchProperty?${url.split('?')[1]}`, { state: { searchResults: data.documents } });
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };







  
  const openFilter = () => {
    setIsFilterVisible(true);
  };

  const closeFilter = () => {
    setIsFilterVisible(false);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  const handleOptionChange = (option) => {
    setActiveOption(option);
    setIsStateAndCityVisible(true);
  };

  const handleMoreVertClick = () => {
    if (!subPropertyType || subPropertyType.length === 0) {
      setAlertMessage('Please select a type of property before proceeding');
      setAlertOpen(true);
      return;
    }
    
    setAreExtraFiltersVisible((prev) => !prev);
    if (areExtraFiltersVisible) {
      setActiveComponent(true);
    }
    setIsMobileAllFilterOpen(false);
  };


  const handleButtonClick = (component) => {
    if (activeComponent === component) {
      setActiveComponent(false);
    } else {
      setActiveComponent(component);
    }
   
    setSelectedButton((prev) => (prev === component ? null : component));
  };


  const handleMobileAllFilterClick = () => {
    setIsMobileAllFilterOpen((prev) => !prev);
    if (!isMobileAllFilterOpen) {
      setAreExtraFiltersVisible(false);
      setActiveComponent(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !(activeComponentRef.current && activeComponentRef.current.contains(event.target))
      ) {
        setAreExtraFiltersVisible(false);
        setIsMobileAllFilterOpen(false);
        setActiveComponent(true)
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // const handleButtonClick = (buttonName) => {
  //   // Toggle dropdown open/close
   
  // };
  return (
    <div
      className="flex flex-col items-center justify-center mt-10 relative p-4 rounded-md max-w-md  h-auto "
      style={{ borderTopRightRadius: '10px', borderTopLeftRadius: '10px' }}
    >
      <div className="flex justify-center gap-4 items-center h-10 rounded-t-lg bg-white w-[300px] ">
        <div className="">
          <MobileButton />
        </div>

        <div className="">
          <MobileLocation />
        </div>
      </div>

      <div className="flex justify-evenly items-center gap-2 h-14 w-[100%] bg-white max-w-md rounded-t-lg">
        <div className="">
          <MobileAllFilter onClick={handleMobileAllFilterClick} />
        </div>



        <div className="">
          <button
            className="block text-[#2E4374]  w-[100px] h-10 text-[15px] rounded-[7px] bg-[#5B8BC5]"
            onClick={handleSearch}
            style={headerStyle}
          >
            Search
          </button>
        </div>

        <div className="">
          <MoreVertIcon className="text-[#2e4374] -ml-4" style={{ fontSize: '40px' }} onClick={handleMoreVertClick} />
        </div>
      </div>






      <div className="w-[100%] flex justify-center items-center ">
        {areExtraFiltersVisible && (
          <div
            className=" w-[100%] flex justify-evenly h-14 items-center border-2 border-gray-100 gap-2  mr-0 text-[#2E4374]  p-2 max-w-md  bg-white"
            ref={buttonsContainerRef}
          >
            <div>
              <button
                onClick={() => handleButtonClick('Currency')}
                className="flex items-center text-[14px] font-[600] text-[#2E4374] font-sixth gap-1"
              >
                <img src="https://squarebric-media-files.squarebric.com/dollar.svg" alt="Currency" height={15} width={15} />
                Currency
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: selectedButton === 'Currency' ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                />
              </button>
            </div>
            <div>
              <button
                onClick={() => handleButtonClick('Area')}
                className="flex items-center text-[14px] font-[600] font-sixth text-[#2E4374]  gap-1"
              >
                <img src="https://squarebric-media-files.squarebric.com/Area1.svg" alt="Area" height={15} width={15} />
                Area
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: selectedButton === 'Area' ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                />
              </button>
            </div>
            <div className="">
              <button
                onClick={() => handleButtonClick('Bathroom')}
                className="flex items-center text-[14px] font-[600] font-sixth text-[#2E4374]  gap-1"
              >
                <img src="https://squarebric-media-files.squarebric.com/Bathroom2.svg" alt="Bathroom" height={15} width={15} />
                Bath
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: selectedButton === 'Bathroom' ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                />
              </button>
            </div>
            <div>
              <button
                onClick={() => handleButtonClick('Bedroom')}
                className="flex items-center text-[14px] font-[600] text-[#2E4374] font-sixth gap-1"
              >
                <img src="https://squarebric-media-files.squarebric.com/bedroom2.svg" alt="Bedroom" height={15} width={15} />
                Bed
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: selectedButton === 'Bedroom' ? 'rotate(180deg)' : 'rotate(0deg)'
                  }}
                />
              </button>
            </div>
          </div>
        )}
      </div>




      <div  className='w-full'>
        {activeComponent === 'Currency' && (
          <div className="z-">
            <MobilePrice />
          </div>
        )}
        {activeComponent === 'Area' && (
          <div className="w-[100%]    mt-0 bg-white max-w-md">
            <MobileArea />
          </div>
        )}
        {activeComponent === 'Bathroom' && (
          <div className="w-[100%] mt-0 bg-white max-w-md">
            <MobileBathroom />
          </div>
        )}
        {activeComponent === 'Bedroom' && (
          <div className="w-[100%]  mt-0 bg-white max-w-md">
            <MobileBedroom />
          </div>
        )}
      </div>



      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MuiAlert elevation={6} variant="filled" severity="warning">
          {alertMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default MobileMainPageFilter;