import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedButton: 'Sell', 
  contentVisible: true,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSelectedButton: (state, action) => {
      
      state.selectedButton = action.payload;
      state.contentVisible = true; 
    },
    hideContent: (state) => {
      state.contentVisible = false;
    },
    showContent: (state) => {
      state.contentVisible = true;
    },
  },
});

export const { setSelectedButton, hideContent, showContent } = filterSlice.actions;
export default filterSlice.reducer;
