import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { fetchServiceDetails } from '../../../../redux/HomeserviceReducer/ServiceReducer';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InquiryPopup = ({ onClose, serviceUuid }) => {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.service1.service); // Adjust selector based on your slice name

  useEffect(() => {
    dispatch(fetchServiceDetails(serviceUuid))
      .then((response) => {
        setServiceData(response); // Assuming response contains service details
      })
      .catch((error) => {
        console.error('Error fetching service details:', error);
      });
  }, [dispatch, serviceUuid]);

  const [serviceData, setServiceData] = useState(null);

  const initialFormData = [
    { label: 'Service Name', options: [], value: '', error: '' },
    { label: 'Name', placeholder: 'John Doe', value: '', error: '' },
    { label: 'Email', placeholder: 'john@domain.com', value: '', error: '' },
    { label: 'Phone', placeholder: 'xxxxxxxxx', value: '', error: '' },
    { label: 'Address', placeholder: 'doller colony near payblebay pinCode-841426 banglore', value: '', error: '' },
    { label: 'Description', placeholder: 'Describe your requirement', value: '', error: '' }
  ];

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (index, e) => {
    const { value } = e.target;
    const updatedFormData = [...formData];
    updatedFormData[index].value = value;

    if (value.trim() === '') {
      updatedFormData[index].error = '';
    } else {
      if (formData[index].label === 'Email') {
        const lowercasedEmail = value.toLowerCase();
        const emailRegex = /^([\w-][A-Za-z0-9._%+-]{3,64})@[A-Za-z]{3,}\.(org|in|com|co)$/i;
        const dotCount = lowercasedEmail.split('.').length - 1;
        updatedFormData[index].value = lowercasedEmail;
        updatedFormData[index].error =
          !emailRegex.test(lowercasedEmail) || dotCount !== 1 ? 'Invalid email format' : '';
      } else if (formData[index].label === 'Phone') {
        const phoneRegex = /^\+?[1-9]\d{1,12}$/;
        updatedFormData[index].error = !phoneRegex.test(value) ? 'Invalid phone number format' : '';
      } else if (formData[index].label === 'Name') {
        const textRegex = /^[a-zA-Z\s]*$/;
        updatedFormData[index].error = !textRegex.test(value) ? 'Only alphabetic characters allowed' : '';
      } else {
        updatedFormData[index].error = '';
      }
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    formData.forEach((data, index) => {
      if (!data.value || data.error) {
        isValid = false;
        console.log(`${data.label} is required or has errors.`);
      }
    });

    if (isValid) {
      const selectedService = service.service_details;

      const requestData = {
        service_name: selectedService ? selectedService.id : '',
        service_id: serviceUuid,
        enquiry_Details: {
          name: formData[1].value,
          email: formData[2].value,
          phone: formData[3].value,
          address: formData[4].value,
          description: formData[5].value
        }
      };

      try {
        const response = await fetch('https://square-bric-backend-api.squarebric.com/home-service/service-enquiry/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });

        if (!response.ok) {
          console.error(response.ok);
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        const responseDataWithUuid = { ...responseData, service_uuid: serviceUuid };
        console.log('Success:', responseDataWithUuid);
        toast.success('Thanks for submitting your inquiry!');

        // Reset form fields
        setFormData(initialFormData);
      } catch (error) {
        console.error('Error submitting the form:', error);
      }
    } else {
      console.log('Please fill in all fields correctly.');
    }
  };

  const bg = {
    background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
  };

  const servicePriceKeys = Object.keys(service?.service_details?.service_price || {});
  const serviceName = servicePriceKeys.join(', ');

  return (
    <>
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-1 rounded-lg w-[370px] relative mt-16  ">
          <div onClick={(e) => e.stopPropagation()}>
            <button
              onClick={onClose}
              className="absolute hover:bg-red-500 right-0 mt-2 mr-2 bg-gray-500 text-white rounded-full p-1"
            >
              <CloseIcon />
            </button>
          </div>
          <h2 className="text-xl font-semibold text-[#2E4374] mb-4 text-center">Inquiry</h2>
          <form onSubmit={handleSubmit} className="flex flex-col gap-2 justify-center items-center">
            {formData.map((field, index) => (
              <label key={index} className="flex flex-col text-sm font-semibold font-thirdCustom text-[#2E4374]">
                {field.label}:
                {field.label === 'Description' ? (
                  <textarea
                    name={field.label}
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e) => handleInputChange(index, e)}
                    onInput={(e) => {
                      if (e.target.value.length > 100) {
                        e.target.value = e.target.value.slice(0, 100);
                        handleInputChange(index, e);
                      }
                    }}
                    className="border border-[#8C97A7] rounded-sm px-2 py-2 w-80"
                  />
                ) : field.label === 'Service Name' ? (
                  <select
                    name={field.label}
                    value={field.value}
                    onChange={(e) => handleInputChange(index, e)}
                    className="border border-[#8C97A7] rounded-sm px-2 py-2 w-80"
                  >
                    <option value="" disabled>
                      Select Service
                    </option>
                    {servicePriceKeys.map((key, idx) => (
                      <option key={idx} value={key}>
                        {key}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    name={field.label}
                    type={field.label === 'Email' ? 'email' : field.label === 'Phone' ? 'tel' : 'text'}
                    placeholder={field.placeholder}
                    value={field.value}
                    onChange={(e) => handleInputChange(index, e)}
                    className="border border-[#8C97A7] rounded-sm px-2 py-2 w-80"
                  />
                )}
                {field.error && <span className="text-red-500">{field.error}</span>}
              </label>
            ))}
            <button
              type="submit"
              className=" px-4 py-2 w-28 rounded-md hover:bg-[#2E4374] text-[#2E4374] hover:text-white  border-2 border-[#2E4374] transition duration-300 flex justify-center items-center"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default InquiryPopup;
