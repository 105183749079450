import React, { useState, useRef, useEffect } from 'react';
import { faqData } from './Cookiesdata';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const Faq = () => {
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');
  const [openFaq, setOpenFaq] = useState(-1);

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? -1 : index);
  };


    // Separate states for each section
    // const [openFaqGeneral, setOpenFaqGeneral] = useState(-1);
    // const [openFaqProperty, setOpenFaqProperty] = useState(-1);
  
    // const toggleFaqGeneral = (index) => {
    //   setOpenFaqGeneral(openFaqGeneral === index ? -1 : index);
    // };
  
    // const toggleFaqProperty = (index) => {
    //   setOpenFaqProperty(openFaqProperty === index ? -1 : index);
    // };
  
  return (
    <>
      <div className="bg-[url('https://squarebric-media-files.squarebric.com/FAq.png')] mx-auto max-w-7xl bg-cover bg-center h-[550px] w-full flex flex-col mt-20">
        <ul className="flex p-5">
          <li>
            <Link to="/" className="text-white font-semibold capitalize">
              Home <KeyboardArrowRightIcon />
              <span className="text-white text-[14px] font-[500]"> {pathnameWithoutSlash}</span>
            </Link>
          </li>
        </ul>
        <div className="mt-[280px]">
          <h1 className="text-white text-[40px] md:text-[45px] ml-[30px] xl:ml-[70px] font-[700] capitalize font-fifth">
            Frequently Asked Questions
          </h1>
          <button className="p-2 bg-[#ffffff33] border backdrop-blur-sm rounded-lg text-white capitalize font-[400] text-[18px] md:text-[20px] font-sixth ml-[30px] xl:ml-[70px] md:w-[22%] w-[70%] text-start tracking-wider">
            Search Any Query
          </button>
        </div>
      </div>

      <div className="mt-5 mx-auto max-w-7xl p-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 md:h-[340px]">
          <div>
            <h1 className="text-[#1E2858] font-[600] text-[25px] leading-[20px]">General FAQs</h1>
            <p className="text-[#2E4374] font-[500] text-[15px] leading-[25px] text-justify mt-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>

          <div>
            {faqData.slice(0, 3).map((faq, index) => (
              <div className="mb-4" key={index}>
                <button
                  className="w-full text-left bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                  onClick={() => toggleFaq(index)}
                >
                  <h2 className="text-[#1E2858] font-[600] text-[16px] md:text-[20px] leading-[20px]">
                    {faq.question}
                  </h2>
                  {openFaq === index ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
                <div
                  className={`transition-all overflow-hidden duration-1000 ease-in-out ${
                    openFaq === index ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="bg-gray-50 p-4 rounded-lg mt-2 shadow-inner">
                    <ul className="list-disc pl-5 text-[#2E4374] font-[400] text-[14px] md:text-[16px] leading-[25px] text-justify">
                      {faq.answer.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-20">
          <div>
            <h1 className="text-[#1E2858] font-[600] text-[25px] leading-[20px]">About Property</h1>
            <p className="text-[#2E4374] font-[500] text-[15px] leading-[25px] text-justify mt-5">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </p>
          </div>

          <div>
            {faqData.slice(3).map((faq, index) => (
              <div className="mb-4" key={index + 3}>
                <button
                  className="w-full text-left bg-white p-4 rounded-lg shadow-md flex items-center justify-between"
                  onClick={() => toggleFaq(index + 3)}
                >
                  <h2 className="text-[#1E2858] font-[600] text-[16px] md:text-[20px] md:leading-[20px]">
                    {faq.question}
                  </h2>
                  {openFaq === index + 3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </button>
                <div
                  className={`transition-all overflow-hidden duration-1000 ease-in-out ${
                    openFaq === index + 3 ? 'max-h-[200px] opacity-100' : 'max-h-0 opacity-0'
                  }`}
                >
                  <div className="bg-gray-50 p-4 rounded-lg mt-2 shadow-inner">
                    <ul className="list-disc pl-5 text-[#2E4374] font-[400] text-[14px] md:text-[16px] leading-[25px] text-justify">
                      {faq.answer.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;