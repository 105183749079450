import React from 'react';

import { InquiryData } from './Card';
import SearchIcon from '@mui/icons-material/Search';
import BathroomIcon from '@mui/icons-material/Bathroom';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';

const Inquriry = () => {
  return (
    <section>
      <div className="mx-[40px] mt-6 bg-[#EAF7FF] p-5">
        <div className="grid grid-cols-2 gap-4 m-5">
          <div className="bg-white ">
            <div className="flex flex-col md:flex-row lg:flex-row justify-between items-center mx-5 my-4 bg-[#AED3F7] p-2">
              <span className="text-[16px] md:text-[18px] lg:text-[20px] text-black font-[500] mb-2 md:mb-0">
                Property Inquiry
              </span>
              <input
                type="text"
                placeholder="Search..."
                className="flex-grow w-full md:w-auto mx-4 px-2 py-1 rounded border border-gray-300"
              />
              <span className="text-[16px] md:text-[18px] lg:text-[20px]">
                <SearchIcon fontSize="inherit" />
              </span>
            </div>

            <div className="overflow-y-scroll h-[300px] scrollbar-width-thin ">
              {InquiryData.map((locateItem, index) => (
                <div className="border-b  border-slate-700 pt-1  mr-[30px] ml-[30px]" key={index}>
                  <div className=" flex flex-col h-auto  ">
                    <img src={locateItem.img1} className="bg-black mt-2  w-[18px] h-[18px]" alt="nav-itemsa"></img>
                    <h1 className="text-slate-900 text-[17px] ml-8 font-[400] mt-[-22px]  uppercase thirdCustom  ">
                      {locateItem.title}
                    </h1>
                  </div>
                  <div className="flex flex-col">
                    <img src={locateItem.img} className=" w-[14px] mt-2 h-[14px]" alt="sub-titles"></img>
                    <h1 className="text-[#4BA3DB] text-[14px] ml-8 mt-[-22px] mb-1   font-[400] capitalize  thirdCustom ">
                      {locateItem.subtitle}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white">
            <div className="flex flex-col md:flex-row justify-between items-center mx-5 my-4 bg-[#AED3F7] p-2">
              <span className="text-[16px] md:text-[18px] lg:text-[20px] text-black font-[500] mb-2 md:mb-0">
                Service Inquiry
              </span>
              <input
                type="text"
                placeholder="Search..."
                className="flex-grow w-full md:w-auto mx-4 px-2 py-1 rounded border border-gray-300"
              />
              <span className="text-[16px] md:text-[18px] lg:text-[20px]">
                <SearchIcon fontSize="inherit" />
              </span>
            </div>
            <div className="overflow-y-scroll h-[300px] scrollbar-width-thin ">
              <div className="  border-slate-700">
                <div className="border-b  border-slate-700 ml-[30px] mr-[30px] ">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom">
                    <BathroomIcon className="mr-1"></BathroomIcon>clasic bathroom cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 ">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
                <div className="mt-4 border-b  border-slate-700 ml-[30px] mr-[30px] ">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom ">
                    <SoupKitchenIcon className="mr-1"></SoupKitchenIcon>kitchen cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 thirdCustom ">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
                <div className="mt-4 border-b  border-slate-700 ml-[30px] mr-[30px] ">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom ">
                    <BathroomIcon className="mr-1"></BathroomIcon>clasic bathroom cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 thirdCustom">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
                <div className="mt-3 border-b  border-slate-700 ml-[30px] mr-[30px]">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom">
                    <SoupKitchenIcon className="mr-1"></SoupKitchenIcon>deep floor cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 thirdCustom ">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
                <div className="mt-3 border-b  border-slate-700 ml-[30px] mr-[30px]">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom">
                    <SoupKitchenIcon className="mr-1"></SoupKitchenIcon>deep floor cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 thirdCustom ">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
                <div className="mt-3 border-b  border-slate-700 ml-[30px] mr-[30px]">
                  <h1 className="text-slate-900 text-[14px] capitalize thirdCustom">
                    <SoupKitchenIcon className="mr-1"></SoupKitchenIcon>deep floor cleaning
                  </h1>
                  <p className="text-[#4BA3DB] text-[10px] capitalize mb-2 thirdCustom">
                    hand cleaning of all area toilet poll,titles,basin,exhaust Etc
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inquriry;
