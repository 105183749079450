import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CloseIcon from '@mui/icons-material/Close';
import Vector from '../../asset/Frame.svg';
import bedroom from '../../asset/bedroom.svg';
import bathroom from '../../asset/bathroom.svg';
import area from '../../asset/area.svg';
import live from '../../asset/live.svg';
import inquery from '../../asset/inquery.svg';
import view from '../../asset/view.svg';
import SearchIcon from '@mui/icons-material/Search';
import filters from '../../asset/filters.svg';
import useProperties from './useProperties';
import PropertyDetailShow from './PropertyDetailShow';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PropertyCard = () => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousData, setPreviousData] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState(previousData);
  const [isChecked, setIsChecked] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [filterShow,setFilterShow]=useState(false)
  const [edit,setEdit]=useState(false)
  const [update,setUpdate]=useState(null)
  const [selectedTypes, setSelectedTypes] = useState({
    commercial: false,
    residential: false,
    others: false
  });
  const [selectedOptions, setSelectedOptions] = useState({
    sell: false,
    rent: false
  });
  const [checkedOptions, setCheckedOptions] = useState({
    verified: false,
    nonVerified: false
  });

  const itemsPerPage = 5;
 
  const { pathname } = useLocation();
  const navigate = useNavigate();
 
  const pathnames = pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1];

  // Fetch properties and total items from the API
  const { properties, loading, error, totalItems, refetch } = useProperties(currentPage, itemsPerPage);
  console.log('refetch', properties);
  useEffect(()=>{
    setSearchResults(previousData);
   },[searchQuery,previousData])
  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const name = userData?.name || '';

  const handlePropertyClick = (property_id) => {
    setSelectedProperty(property_id);
    navigate(`${pathname}?propertyId=${property_id}`);
  };

  useEffect(() => {
    if (!loading) {
      refetch(); // Refetch data when currentPage or itemsPerPage change
    }
  }, [currentPage, itemsPerPage, refetch]);

  // Log the properties after refetch
  useEffect(() => {
    console.log('Properties after refetch:', properties);
    setPreviousData(properties);
  }, [properties]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(page);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="ml-8">Error: {error}</div>;
  console.log('prpviousadata', previousData);

  const currentItems = previousData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  console.log('currentItems', currentItems);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const getPageNumbers = () => {
    let pages = [];
    if (totalPages <= 5) {
      // Show all page numbers if there are 5 or fewer pages
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show a range of pages with ellipses
      if (currentPage <= 3) {
        // Show pages at the start
        for (let i = 1; i <= 4; i++) {
          pages.push(i);
        }
        pages.push('...');
        pages.push(totalPages);
      } else if (currentPage >= totalPages - 2) {
        // Show pages at the end
        pages.push(1);
        pages.push('...');
        for (let i = totalPages - 3; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        // Show pages around the current page
        pages.push(1);
        pages.push('...');
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push('...');
        pages.push(totalPages);
      }
    }
    return pages;
  };

  const handleSearch = () => {
    const results = previousData.filter(item =>
      item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    // useEffect(()=>{
    //   setSearchResults(results);
    // },[results])
    setSearchResults(results);

    console.log('searchResults');
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };


  const handleToggle = () => {
    setIsOn(!isOn);
  };

  const handleChange = () => {
    setIsChecked(!isChecked);
  };
  const handleChanges = (type) => {
    setSelectedTypes((prevState) => ({
      ...prevState,
      [type]: !prevState[type]
    }));
  };
  const handlePurpose = (option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [option]: !prevState[option]
    }));
  };
  const handleverified = (option) => {
    setCheckedOptions((prevState) => ({
      ...prevState,
      [option]: !prevState[option]
    }));
  };
  const handleFilter=()=>{
    setFilterShow(!filterShow)
  }
  const handlefilterClose=()=>{
    setFilterShow(false)
  }
  const handleClick=(id)=>{
    setEdit(!edit);
    setUpdate(id);
     console.log("clcicked")
  }
  const handleDelete=(id)=>{
    setEdit(false)
  }
  const handleEdit=(id)=>{
    console.log("edit",id)
    setEdit(false)
    navigate(`/dashboard/edit-property/${id}`)

  }

  return (
    <div className="ml-[7%]">
      {/* User Info */}
      <div className="flex justify-end bg-[#D9D9D9] p-3">
        <div className="flex">
          <PersonIcon className="mr-[20px]" />
          <p className="pr-6">{name}</p>
        </div>
      </div>

      {/* Breadcrumb Navigation */}
      <div className="mt-5">
        <span className="text-[#1E2858] ml-5 font-[400] mt-6 text-[16px] capitalize">
          <Link to="/dashboard" className="text-[#7D7D7D] font-[400] text-[18px] capitalize">
            Dashboard
          </Link>
          <NavigateNextIcon className="block text-[14px] mb-[2px] h-3" />
          <span className="text-[#2E4374]">{lastSegment}</span>
        </span>
      </div>

      {/* Properties List */}
      <div className="mb-4 ml-6 flex  w-[98%] bg-[#DCE0F1]">
        <input
          type="text"
          placeholder="Search properties..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          className="border border-gray-300 bg-[#DCE0F1] border-l-0 p-2 w-full focus:outline-none focus:border-transparent"
        />
        <div className="bg-[#DCE0F1] flex justify-center  items-center p-2">
          <SearchIcon className=" text-gray-500" onClick={handleSearch} />
        </div>
        <div className="bg-[#DCE0F1] p-2 flex justify-center  items-center ">
          <LazyLoadImage src={filters} alt="filters" className="w-8 h-8" onClick={handleFilter} />
        </div>
      </div>
      <div className='flex justify-end'>
      {
        filterShow && ( 
          <div className='   mt-[0px] absolute  z-20 '>
            <div className="w-[250px] ml-7  bg-white p-4 shadow-lg h-auto">
          <div className="">
            <div className='flex justify-between'>   <h1 className='text-[#28B3F7] text-[16px] font-[700] capitalize'>Listing</h1>
             <CloseIcon onClick={handlefilterClose} className='cursor-pointer' /></div>
         
            <label className='flex items-center'>
            <button
              onClick={handleToggle}
              className={`flex items-center  rounded-full ${
                isOn ? 'bg-blue-500 text-white' : 'bg-gray-300 text-gray-800'
              } transition-colors duration-300`}
            >
              <span className={`mr-2 font-semibold`}>{/* {isOn ? 'On' : 'Off'} */}</span>
              <span
                className={`block w-10 h-4 rounded-full ${
                  isOn ? 'bg-white translate-x-4' : 'bg-gray-600 translate-x-0'
                } transform transition-transform duration-300`}
              ></span>
       </button>
       Service Listing
          </label>
            <label className={`flex items-center  cursor-pointer`}>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
                className="form-checkbox h-3 w-3 text-blue-500 border-gray-300 rounded"
              />
              <span className={`text-[14px] font-[500] ${isChecked ? 'text-blue-500' : 'text-gray-500'}`}>Most Viewed</span>
            </label>
           
          </div>
          <div className="">
            <h1 className='text-[#28B3F7] text-[16px] font-[700] capitalize'>Type of property</h1>
            <div className="">
              {Object.keys(selectedTypes).map((type) => (
                <label key={type} className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedTypes[type]}
                    onChange={() => handleChanges(type)}
                    className="form-checkbox h-3 w-3 text-blue-500 border-gray-300 rounded"
                  />
                  <span className={`text-[14px] font-[500] ${selectedTypes[type] ? 'text-blue-500' : 'text-gray-500'}`}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </span>
                </label>
              ))}
            </div>
            <h1 className='text-[#28B3F7] text-[16px] font-[700] capitalize'>Purpose</h1>
            <div className="">
              {Object.keys(selectedOptions).map((option) => (
                <label key={option} className="flex items-center  cursor-pointer">
                  <input
                    type="checkbox"
                    checked={selectedOptions[option]}
                    onChange={() => handlePurpose(option)}
                    className="form-checkbox h-3 w-3 text-blue-500 border-gray-300 rounded"
                  />
                  <span className={`text-[14px] font-[500] ${selectedOptions[option] ? 'text-blue-500' : 'text-gray-500'}`}>
                    {option.charAt(0).toUpperCase() + option.slice(1)}
                  </span>
                </label>
              ))}
            </div>
            <h1 className='text-[#28B3F7] text-[16px] font-[700] capitalize'>Verification</h1>
            <div className="space-y-4">
              {Object.keys(checkedOptions).map((option) => (
                <label key={option} className="flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={checkedOptions[option]}
                    onChange={() => handleverified(option)}
                    className="form-checkbox h-3 w-3 text-green-500 border-gray-300 rounded"
                  />
                  <span className={`text-[14px] font-[500] ${checkedOptions[option] ? 'text-green-500' : 'text-gray-500'}`}>
                    {option.charAt(0).toUpperCase() + option.slice(1).replace(/([A-Z])/g, ' $1')}
                  </span>
                </label>
              ))}
            </div>
          </div>
             </div>
          </div>)
      }
   </div>







      <div className="flex mt-5 w-[100%] h-[100%] z-0 overflow-hidden gap-5">
        <div className="w-[70%] ml-6">
          {searchResults.length > 0 ? (
          searchResults.map((item, index) => (
              <div key={index} className="mb-6 w-[100%]  ">
                <div
                  className="border border-gray-300 rounded-lg  flex shadow-lg w-[100%] px-4 py-4 h-[240px] cursor-pointer"
                 
                >
                  {/* Image Section */}
                  <div className="relative w-[30%]   ">
                    <img
                      className="h-[150px] w-full sm:w-full md:w-[500px] md:h-[200px]  object-cover rounded-md"
                      src={`https://squarebric-media-files.squarebric.com/${item.default_image}`}
                      alt="Property"
                      onError={(e) => {
                        e.target.onerror = null; // Prevent infinite loop in case fallback image also fails
                        e.target.src = 'https://d34yb5orp91dp6.cloudfront.net/no_images.png';
                      }}
                    />
                    {item.isVerified && (
                      <div className="absolute top-2 left-2 capitalize bg-green-500 text-white text-xs font-semibold px-2 py-1 rounded">
                        Verified
                      </div>
                    )}
                  </div>

                  {/* Details Section */}
                  <div className="p-4 w-[70%] flex flex-col">
                    <div className="flex justify-between ml-1">
                      <h2 className="font-[600] text-[16px] leading-[20px] capitalize text-[#2E4374]">{item.title}</h2>
                      <p className="px-4">
                        <FavoriteBorderIcon style={{ color: 'red', marginRight: '10px' }} />
                    
                        < MoreVertIcon onClick={()=>handleClick(index)}/>


                        {
                          edit && update===index  && (
                            <div className='flex flex-col z-50 absolute bg-gray-200 shadow-lg p-3 w-[90px] h-[100px]'>
                              <label className='cursor-pointer text-[11px] text-[#1E2858] font-[400]' onClick={()=>handleEdit(item.property_id)}>
                              <EditNoteIcon style={{ color: 'gray', marginRight: '10px' }} />
                                Edit 
                              </label>
                              <label className='cursor-pointer text-[10px] text-[#1E2858] font-[400]' onClick={()=>handleDelete(item.property_id)}>
                              <DeleteIcon style={{ color: 'gray', marginRight: '10px' }} />
                             Delete 
                              </label>
                            
                            </div>
                          )
                        }
                     
                      </p>
                    </div>
                    <div className="grid grid-cols-4 mt-4 ml-1">
                      <span className="text-[#1E2858] leading-[11px] flex justify-start capitalize font-[400] text-[14px]">
                        <img src={bedroom} className="w-4 h-4 mr-2" alt="property" /> Bedroom {item.bedroom}
                      </span>
                      <span className="text-[#1E2858] leading-[11px] flex justify-start capitalize font-[400] text-[14px]">
                        <img src={bathroom} className="w-4 h-4 mr-2" alt="property" /> {item.bath} Bathroom
                      </span>
                      <span className="text-[#1E2858] leading-[11px] flex justify-start capitalize font-[400] text-[14px]">
                        <img src={area} className="w-4 h-4 mr-2" alt="property" />{' '}
                        {item.super_area?.value?.super_area?.value} {item.super_area?.value?.super_area?.unit}
                      </span>
                      <div className="flex items-center space-x-2">
                        {item.isLive ? (
                          <span className="bg-[#C6FBD2] text-[#2E4374] leading-[8px] font-[400] flex text-[10px] px-2 py-1 rounded">
                            <img src={live} className="w-4 h-4 mr-2" alt="property" /> Live on website
                          </span>
                        ) : (
                          <span className="bg-red-100 text-[#2E4374] leading-[8px] font-[400] text-[10px] px-2 py-1 rounded">
                            Not Live
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="font-[400] text-[14px] text-[#1E2858] grid grid-cols-2 leading-[13px] mt-4">
                      <p>
                        <LocationOnIcon style={{ width: '20px', height: '20px' }} />
                        {item.location.address}
                      </p>
                      <p className="text-[#1E2858] font-[400] leading-[14px] flex justify-start text-[14px] capitalize sixth">
                        <img src={view} className="w-4 h-4 mr-2" alt="property" /> Viewed by {item.views}
                      </p>
                    </div>

                    <div className="grid grid-cols-4  mt-4 ml-1 ">
                      <div className="flex justify-start">
                        <img src={Vector} className="w-4 h-4 mr-2" alt="property" />
                        <p className="text-[#1E2858] font-[400] leading-[12px] text-[14px] capitalize">Used Property</p>
                      </div>

                      <div className="flex justify-start">
                        <img src={inquery} className="w-4 h-4 mr-2" alt="property" />
                        <p className="text-[#1E2858] font-[400] leading-[12px] text-[14px] capitalize">
                          Inquired by {item.inquiries}
                        </p>
                      </div>

                      <div className="flex justify-start">
                        <p className="bg-[#2E4374] text-white px-2 py-1 text-center text-[10px] rounded-[4px]"  onClick={() => handlePropertyClick(item.property_id)}>
                          View Details
                        </p>
                      </div>
                      <div className="flex justify-end">
                      <p className="text-[20px]  leading-[14px] font-[700]  text-[#3246D3]">${item.currency?.USD?.value?.usd}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No properties available</p>
          )}
        </div>

 
        <div className="w-[30%] mt-0">{selectedProperty && <PropertyDetailShow propertyId={selectedProperty} />}</div>
      </div>

 
      <div className="mt-6 flex justify-center items-center  shadow-lime-100 rounded-[10px">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-[#2E4374] rounded-[30px] text-white px-4 py-2  mr-2"
        >
          <NavigateBeforeIcon />
        </button>
        {getPageNumbers().map((number, index) =>
          number === '...' ? (
            <span key={index} className="px-4 py-2   bg-white text-blue">
              {number}
            </span>
          ) : (
            <button
              key={index}
              onClick={() => handlePageChange(number)}
              className={`px-4 py-2 rounded-[40px]  ${
                currentPage === number ? 'bg-[#2E4374]  text-white' : 'bg-white  text-blue'
              }`}
            >
              {number}
            </button>
          )
        )}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="bg-[#2E4374] rounded-[30px] text-white px-4 py-2 ml-2"
        >
          <NavigateNextIcon />
        </button>
      </div>
    </div>
  );
};

export default PropertyCard;
