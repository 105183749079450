// redux/slices/clearAllSlice.js
import { createSlice } from '@reduxjs/toolkit';

const clearAllSlice = createSlice({
  name: 'clearAll',
  initialState: {},
  reducers: {
    clearAll: (state) => {
      return {
        // Set default values for each piece of state
        location: {
          selectedCity: null,
        },
        filter: {
          selectedButton: null,
        },
        properties: {
          propertyType: null,
          subPropertyType: [],
        },
        multiselect: {
          selectedMin: null,
          selectedMax: null,
          selectedCurrency: 'Dollar',
        },
        selectedPrice: {
          min: null,
          max: null,
        },
        bathroom: {
          minValue: null,
          maxValue: null,
        },
        bedroom: {
          minValue: null,
          maxValue: null,
        },
        area: {
          selectedAreaOption: null,
        },
      };
    },
  },
});

export const { clearAll } = clearAllSlice.actions;
export default clearAllSlice.reducer;
