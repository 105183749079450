import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import 'tailwindcss/tailwind.css';

const divContent = [
  { title: 'popular search', text: 'Lorem ipsum lorem lorem ipsum' },
  { title: 'POPULAR 4BHK', text: 'Lorem ipsum lorem lorem ipsum' },
  { title: 'popular FLATS', text: 'Lorem ipsum lorem lorem ipsum' },
  { title: 'POPULAR PLOTS', text: 'Lorem ipsum lorem lorem ipsum' },
  { title: 'POPULAR PLOTS', text: 'Lorem ipsum lorem lorem ipsum' },
  { title: 'POPULAR PLOTS', text: 'Lorem ipsum lorem lorem ipsum' }
];

const BuyPropertyPage = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isTablet, setIsTablet] = useState(window.innerWidth > 768 && window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1024);
      if (window.innerWidth > 768 && window.innerWidth <= 1024) {
        setCurrentIndex(0); // Reset currentIndex on resize to show initial cards
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % divContent.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + divContent.length) % divContent.length);
  };

  const totalProperties = divContent.length;
  const hasPrevSlide = currentIndex > 0;
  const hasNextSlide = currentIndex < totalProperties - (isMobile ? 1 : isTablet ? 2 : 4);

  let numColumns = 1;
  if (isTablet) numColumns = 2;
  else if (!isMobile) numColumns = 4;

  const startIndex = currentIndex;
  const endIndex = Math.min(startIndex + numColumns, totalProperties);
  const visibleItems = divContent.slice(startIndex, endIndex);

  return (
    <div className="flex flex-col items-center mt-2">
      <div className="flex overflow-hidden space-x-6 w-full h-full relative">
        {(isMobile || isTablet) && hasPrevSlide && (
          <button
            className="absolute left-6 top-1/2 transform -translate-y-1/2 z-10 flex items-center justify-center h-8 w-8 bg-white rounded-md shadow-lg opacity-55"
            onClick={prevSlide}
            disabled={!hasPrevSlide}
          >
            <ArrowBackIosNewIcon className={`text-2xl ${!hasPrevSlide ? 'text-gray-400' : 'text-black'}`} />
          </button>
        )}
        {visibleItems.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-lg p-5 shadow-md flex-1 h-full w-[300px]"
            style={{
              borderRadius: '5px',
              background: '#FFFFFF',
              border: '2px solid #E5EFFF',
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)'
            }}
          >
            <h3 className="text-lg font-fifth text-center underline uppercase text-[#2E4374] font-[700]">
              {item.title}
            </h3>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400] mt-2">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
            <p className="whitespace-normal font-sixth text-center text-[#2E4374] font-[400]">{item.text}</p>
          </div>
        ))}

        {(isMobile || isTablet) && hasNextSlide && (
          <button
            className="absolute right-0 top-1/2 transform -translate-y-1/2 flex items-center justify-center h-8 w-8 bg-white rounded-md shadow-lg opacity-55"
            onClick={nextSlide}
            disabled={!hasNextSlide}
          >
            <ArrowForwardIosIcon className={`text-2xl  ${!hasNextSlide ? 'bg-gray-400' : 'text-black'}`} />
          </button>
        )}
      </div>
    </div>
  );
};

export default BuyPropertyPage;
