import React, { useState } from 'react';
import Mediacenter from './Mediacenter';
import NewsPressRelease from './NewsPressRelease';
import Photo from './Photo';
import Video from './Video';

const Allselecter = () => {
  const [selectedOption, setSelectedOption] = useState('Mediacenter');

  const handleButtonClick = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div className="md:mt-44 mt-28 ">
     
        <div className="bg-blue-200  flex justify-around font-thirdCustom ">
          <button
            onClick={() => handleButtonClick('Mediacenter')}
            className={`uppercase font-bold text-xs md:text-base p-1 ml-5 md:p-2 ${
                selectedOption === 'Mediacenter' 
                ? 'bg-white rounded-t-2xl' : ''
              }`}
              style={{
                color: '#233876',
                marginRight: '20px',
                position: 'relative',
                display: 'inline-block'
              }}
          >
            Blog
          </button>

          <button
            onClick={() => handleButtonClick('NewsPressRelease')}
            className={` uppercase font-bold text-xs md:text-base p-1 md:p-3 ${
                selectedOption === 'NewsPressRelease' 
                ? 'bg-white rounded-t-2xl' : ''
              }`}
              style={{
                color: '#233876',
                marginRight: '20px',
                position: 'relative',
                display: 'inline-block'
              }}
              >
            News & Press Release
          </button>

          <button
            onClick={() => handleButtonClick('Photo')}
            className={` uppercase font-bold text-xs md:text-base p-1 md:p-3 ${
                selectedOption === 'Photo' 
                ? 'bg-white rounded-t-2xl' : ''
              }`}
              style={{
                color: '#233876',
                marginRight: '20px',
                position: 'relative',
                display: 'inline-block'
              }}
          >
            Photo Gallery
          </button>
          
          <button
            onClick={() => handleButtonClick('Video')}
            className={` uppercase font-bold text-xs md:text-base p-1 md:p-3 ${
                selectedOption === 'Video' 
                ? 'bg-white rounded-t-2xl' : ''
              }`}
              style={{
                color: '#233876',
                marginRight: '20px',
                position: 'relative',
                display: 'inline-block'
              }}
          >
            Video Gallery
          </button>
        </div>
        <div className="mt-4 flex justify-between items-center ">
          {selectedOption === 'Mediacenter' && <Mediacenter />}
          {selectedOption === 'NewsPressRelease' && <NewsPressRelease />}
          {selectedOption === 'Photo' && <Photo/>}
          {selectedOption === 'Video' && <Video/>}
        </div>
     
    </div>
  );
};

export default Allselecter;
