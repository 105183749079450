
export const propertyData = [
  {
    id: 1,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: "https://squarebric-media-files.squarebric.com/commercial.png",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli.',
    location: 'Istanbul',
    buttonLabel: 'View Details',
    prize: ' 1,000,000',
    area: '45 sq.m',
    tag: "Residential"
  },
  {
    id: 2,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: "https://squarebric-media-files.squarebric.com/commercial1.png",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 2',
    buttonLabel: 'View Details',
    prize: ' 800,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Commercial"
  },
  {
    id: 3,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: "https://squarebric-media-files.squarebric.com/commercial2.png",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 3',
    buttonLabel: 'View Details',
    prize: ' 600,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Commercial"
  },

  {
    id: 4,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: "https://squarebric-media-files.squarebric.com/cardimg4.jpg",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 3',
    buttonLabel: 'View Details',
    prize: ' 600,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Residential"
  },

  {
    id: 5,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: 'https://squarebric-media-files.squarebric.com/cardimg1.png',
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 3',
    buttonLabel: 'View Details',
    prize: '600,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Residential"
  },
  {
    id: 6,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image:"https://squarebric-media-files.squarebric.com/cardimg6.jpg",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 3',
    buttonLabel: 'View Details',
    prize: ' 600,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Residential"
  },
  {
    id: 7,
    title: 'UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE',
    image: "https://squarebric-media-files.squarebric.com/cardimg7.jpg",
    description: 'Enjoy huge rental potential when you invest at the crown of bomonti in Sisli',
    area: 'Area 3',
    buttonLabel: 'View Details',
    prize: '600,000',
    location: 'Istanbul',
    area: '45 sq.m',
    tag: "Residential"
  }
];

export const dummyImage = [
  {
    image: "https://squarebric-media-files.squarebric.com/Group.png"
  },

  {
    image: "https://squarebric-media-files.squarebric.com/Group1.png"
  },

  {
    image: "https://squarebric-media-files.squarebric.com/Group2.png"
  },

  {
    image: "https://squarebric-media-files.squarebric.com/Group2.png"
  }
];

export const  blogDta  = [
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'The investment impact of Germany in Turkey',
    date: '21 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'France’s Strategic investment in Turkey',
    date: '22 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Shell: The Netherland’s investment in Turkey',
    date: '23 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'UK’s Investment in Turkish Market',
    date: '24 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Spain’s Impact on Turkey’s Real Estate Sector',
    date: '25 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Italy’s Trade Relations with Turkey',
    date: '26 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Switzerland’s Economic Cooperation with Turkey',
    date: '27 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Sweden’s Renewable Energy Investments in Turkey',
    date: '28 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Norway’s Tech Startups in Turkey',
    date: '29 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Denmark’s Sustainability Initiatives in Turkey',
    date: '30 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Finland’s Education Partnerships with Turkey',
    date: '31 Dec 2023',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Austria’s Cultural Exchanges with Turkey',
    date: '1 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Portugal’s Tourism Growth in Turkey',
    date: '2 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Poland’s Manufacturing Investments in Turkey',
    date: '3 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Hungary’s Agro-Industry Developments in Turkey',
    date: '4 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Czech Republic’s IT Sector in Turkey',
    date: '5 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Slovakia’s Automotive Investments in Turkey',
    date: '6 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Greece’s Maritime Collaborations with Turkey',
    date: '7 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage3.png",
    description: 'Russia’s Energy Partnerships with Turkey',
    date: '8 Jan 2024',
    buttonLabel: 'Read more',
  },
  {
    image: "https://squarebric-media-files.squarebric.com/Blogimage1.png",
    description: 'Ukraine’s Agricultural Exports to Turkey',
    date: '9 Jan 2024',
    buttonLabel: 'Read more',
  },
];
