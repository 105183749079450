// src/multiselectSlice.js
import { createSlice } from '@reduxjs/toolkit';
import dollarIcon from '../assets/dollar.svg';

const initialState = {
  selectedMin: '',
  selectedMax: '',
  selectedCurrency: { label: 'Dollar', icon: dollarIcon },
  isMinDropdownOpen: false,
  isMaxDropdownOpen: false,
  isCurrencyDropdownOpen: false,
};

const multiselectSlice = createSlice({
  name: 'multiselect',
  initialState,
  reducers: {
    setSelectedMin: (state, action) => {
      state.selectedMin = action.payload;
    },
    setSelectedMax: (state, action) => {
      state.selectedMax = action.payload;
    },
    setSelectedCurrency: (state, action) => {
      state.selectedCurrency = action.payload;
      state.selectedMin = '';
      state.selectedMax = '';
    },
    toggleMinDropdown: (state) => {
      state.isMinDropdownOpen = !state.isMinDropdownOpen;
    },
    toggleMaxDropdown: (state) => {
      state.isMaxDropdownOpen = !state.isMaxDropdownOpen;
    },
    toggleCurrencyDropdown: (state) => {
      state.isCurrencyDropdownOpen = !state.isCurrencyDropdownOpen;
    },
    removeSelectedMin(state) {
      state.selectedMin = null;
    },
    removeSelectedMax(state) {
      state.selectedMax = null;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    }
  },
});

export const {
  setSelectedMin,
  setSelectedMax,
  setSelectedCurrency,
  toggleMinDropdown,
  toggleMaxDropdown,
  toggleCurrencyDropdown,
  resetState,
  removeSelectedMin,
  removeSelectedMax
} = multiselectSlice.actions;

export const selectCurrency = (currency) => (dispatch) => {
  let payload = currency;
  if (currency.label === 'Dollar') {
    payload = { ...currency, value: 'USD' };
  }
  dispatch(setSelectedCurrency(payload));
};

export default multiselectSlice.reducer;
