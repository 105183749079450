import { createSlice } from '@reduxjs/toolkit';



export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {
      name: '',
      email: '',
      password: '',
      phone_number: '',
      // countryCode: '',
      user_type: 'buyer'
    },
    errors: {},
    isLoggedIn: false
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
      // Save userData to localStorage
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    clearUserData: (state) => {
      state.userData = {
        name: '',
        email: '',
        password: '',
        phone_number: '',
        // countryCode: '',
        user_type: 'buyer'
      };
    },
    clearErrors: (state) => {
      state.errors = {};
    },
    loginUserSuccess: (state) => {
      state.isLoggedIn = true;
    },
    loginUserFailure: (state) => {
      state.isLoggedIn = false;
    }
  }
});

export const { setUserData, setErrors, clearUserData, clearErrors, loginUserSuccess, loginUserFailure } =
  userSlice.actions;

export default userSlice.reducer;
