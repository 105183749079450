// src/components/Bathroom.js
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  incrementMin,
  decrementMin,
  incrementMax,
  decrementMax,
  resetValues
} from '../../../../../redux/BathroomReducer';

const MobileBathroom = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const minValue = useSelector((state) => state.bathroom.minValue);
  const maxValue = useSelector((state) => state.bathroom.maxValue);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    // Reset values when the component mounts
    dispatch(resetValues());

    // Optionally, reset values when the component unmounts
    return () => {
      dispatch(resetValues());
    };
  }, [dispatch]);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMinDecrement = () => {
    dispatch(decrementMin());
  };

  const handleMinIncrement = () => {
    dispatch(incrementMin());
  };

  const handleMaxDecrement = () => {
    dispatch(decrementMax());
  };

  const handleMaxIncrement = () => {
    dispatch(incrementMax());
  };

  return (
    <div className="border-2 border-gray-300   w-full p-2">

      <div
        onClick={toggleDropdown}
        className="text-[#2E4374] font-sixth font-[600] flex justify-between items-center text-sm " ref={dropdownRef }
      >
        <div>Bathroom</div>
        <div className="flex items-center ">
          <span className="mr-2">Min:</span>
          <button
            onClick={handleMinDecrement}
            className={`bg-gray-500 text-white px-2 py-1 rounded-l border ${minValue === 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            disabled={minValue === 0}
          >
            -
          </button>
          <span className="px-4">{minValue}</span>
          <button
            onClick={handleMinIncrement}
            className={`bg-gray-500 text-white px-2 py-1 rounded-r border ${minValue === 19 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            disabled={minValue === 19}
          >
            +
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Max:</span>
          <button
            onClick={handleMaxDecrement}
            className={`bg-gray-500 text-white px-2 py-1 rounded-l border ${maxValue <= 0 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            disabled={maxValue <= 0}
          >
            -
          </button>
          <span className="px-4">{maxValue}</span>
          <button
            onClick={handleMaxIncrement}
            className={`bg-gray-500 text-white px-2 py-1 rounded-r border ${maxValue === 20 ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            disabled={maxValue === 20}
          >
            +
          </button>
        </div>
      </div>
    </div>

  );
};

export default MobileBathroom;