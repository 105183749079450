import React from 'react';

const Photo = () => {
  return (
    <div className="w-full mt-20">
      <div className="flex lg:flex-row flex-col justify-center items-center gap-3  rounded-lg">
        <div className="p-4 md:p-0">
          <img
            className="border-8 rounded-lg"
            src="https://squarebric-media-files.squarebric.com/photo1.png"
            alt="photo1"
            style={{ borderColor: '#96A1B9' }}
          />
        </div>
        <div className="">
          <img
            className="border-8 rounded-lg"
            src="https://squarebric-media-files.squarebric.com/photo2.png"
            alt="photo2"
            width={406}
            style={{ borderColor: '#96A1B9' }}
          />
          <img
            className="border-8 mt-4 rounded-lg"
            src="https://squarebric-media-files.squarebric.com/photo3.png"
            alt="photo3"
            style={{ borderColor: '#96A1B9' }}
          />
        </div>
      </div>

      <div className="flex lg:flex-row flex-col justify-center items-center mt-3 gap-5 ">
        <img
          className="border-8 rounded-lg "
          src="https://squarebric-media-files.squarebric.com/photo4.png"
          alt="photo1"
          width={431}
          style={{ borderColor: '#96A1B9' }}
        />
        <img
          className="border-8 rounded-lg"
          src="https://squarebric-media-files.squarebric.com/photo5.png"
          alt="photo2"
          width={431}
          style={{ borderColor: '#96A1B9' }}
        />
        <img
          className="border-8 rounded-lg"
          src="https://squarebric-media-files.squarebric.com/photo6.png"
          alt="photo3"
          width={431}
          style={{ borderColor: '#96A1B9' }}
        />
      </div>
    </div>
  );
};

export default Photo;
