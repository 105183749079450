import React, { useState, useEffect } from "react";

const OtpForm = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [timeLeft, setTimeLeft] = useState(30); // Resend OTP timer
  const [canResend, setCanResend] = useState(false);

  // Timer function for resending OTP
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    } else {
      setCanResend(true);
    }
  }, [timeLeft]);

  // Handle input change for each OTP input field
  const handleChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1 && /^[0-9]*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  // Handle Resend OTP click
  const handleResend = () => {
    if (canResend) {
      setTimeLeft(30); // Reset timer
      setCanResend(false);
      // Logic for resending OTP goes here
    }
  };

  return (
    <div className="max-w-md mx-auto p-4">
      <div className="flex justify-evenly items-center border-2 border-gray-300 p-4 rounded-lg">
        {/* OTP Input Fields */}
        <div className="flex space-x-4">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              className="w-12 h-12 border-2 border-gray-300 rounded-lg text-center text-xl focus:border-blue-500 focus:outline-none"
            />
          ))}
        </div>

        
       
      </div>

      {/* Resend OTP and Timer */}
      <div className="mt-2 flex justify-end items-center">
        <button
          onClick={handleResend}
          disabled={!canResend}
          className={`text-blue-500 text-sm ${!canResend ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          Resend OTP
        </button>
        <span className="text-gray-500 text-sm ml-2">
          {timeLeft > 0 ? `in ${timeLeft < 10 ? `0${timeLeft}` : timeLeft} sec` : ""}
        </span>
      </div>
      {/* Confirm OTP Button */}
      <button className="  bg-[#3C8E51] w-[100%] mt-2 h-[100%] p-2 text-white xs:text-[13px] sm:text-[13px]  md:text-[16px] lg:text-[16px]  xl:text-[16px] 2xl:text-[16px] rounded-lg focus:outline-none hover:bg-green-600">
          Confirm OTP
        </button>
    </div>
  );
};

export default OtpForm;
