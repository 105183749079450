// redux/slices/areaSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAreaOption: 'sqft', // Default value
};

const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setSelectedAreaOption(state, action) {
      state.selectedAreaOption = action.payload;
    },
  },
});

export const { setSelectedAreaOption } = areaSlice.actions;
export default areaSlice.reducer;
