import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPropertyType, clearPropertyType } from '../../../redux/propertyReducer';
import './Property.css';

const PropertyType = () => {
  const dispatch = useDispatch();
  const selectedPropertyType = useSelector((state) => state.properties.propertyType);
  const [checkedOption, setCheckedOption] = React.useState(selectedPropertyType);

  const mainOptions = [
    { label: 'Commercial', icon: 'https://squarebric-media-files.squarebric.com/Coomercial.svg', backendLabel: 'Commercial' },
    { label: 'Residential', icon: 'https://squarebric-media-files.squarebric.com/Residence.svg', backendLabel: 'Residential' },
    { label: 'Other', icon: 'https://squarebric-media-files.squarebric.com/Other.svg', backendLabel: 'Other' }
  ];

  useEffect(() => {
    // Sync component state with Redux state
    setCheckedOption(selectedPropertyType);
  }, [selectedPropertyType]);

  const handleCheckboxChange = (backendLabel) => {
    setCheckedOption(backendLabel);
    dispatch(setPropertyType(backendLabel));
  };

  return (
    <div className="w-full mb-4">
      <ul className="list-none p-0">
        {mainOptions.map((option) => (
          <li
            key={option.backendLabel}
            className={`flex items-center p-2 cursor-pointer text-[#2E4374] font-sixth font-[400] text-[16px] ${
              checkedOption === option.backendLabel ? 'bg-gray-200' : ''
            }`}
          >
            <label className="flex items-center cursor-pointer w-full">
              <img src={option.icon} alt={option.label} className="w-4 h-4 mr-3" />
              <span className="flex-1 ">{option.label}</span>
              <span>
                <input
                  type="checkbox"
                  checked={checkedOption === option.backendLabel}
                  onChange={() => handleCheckboxChange(option.backendLabel)}
                  className="gradient-checkbox text-[#2E4374] mr-2"
                />
              </span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PropertyType;
