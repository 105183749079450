import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import locationReducer from './redux/LocationReducer';
import selectedDataReducer from './redux/selectedDataReducer';
import ButtonsSlice from './redux/ButtonsReducer';
import selectedBuildingReducer from './redux/selectedBuildingReducer';
import selectedPriceReducer from './redux/selectedPriceReducer';
import SelectedBedroomReducer from './redux/SelectedBedroomReducer';
import selectedBathroomReducer from './redux/selectedBathroomReducer';
import propertyReducer from './redux/propertyReducer';
import SignUpReducer from './redux/authReducer/SignUpReducer';
import { forgotPassword } from './redux/authReducer/forgotPasswordReducer';
import HomeServiceReducer from './redux/HomeserviceReducer/HomeServiceReducer';
import SinglePropertyReducer from './redux/PropertySlice/SinglePropertyReducer';
import SinglehomeserviceReducer from './redux/HomeserviceReducer/SinleHomeserviceReducer';
import ServiceReducer from './redux/HomeserviceReducer/ServiceReducer';
import bedroomReducer from '../src/redux/BedroomReducer';
import PriceReducer from './redux/PriceReducer';
import multiselectRedcucer from './redux/multiselectRedcucer';
import BathroomReducer from './redux/BathroomReducer';
import searchPropertyReducer from './redux/SearchProperty/searchPropertyReducer';
import Buyreducer from './redux/Buyreducer';
import mobileButtonReducer from './redux/MobileButtonreducer';
import SingleSearchPropertyReducer from './redux/SingleSearchPropertyReducer';
import unitReducer from './redux/unitReducer';
import PaginationReducer from './redux/PaginationReducer';
import SearchReducer from './redux/SearchReducer';
import FilterReducer from './redux/FilterReducer';

import ClearAllReducer from './redux/ClearAllReducer';
import UrlUpdatereducer from './redux/UrlUpdatereducer';

const store = configureStore({
  reducer: {
    location: locationReducer,
    selectedData: selectedDataReducer,
    button: ButtonsSlice,
    selectedOptions: selectedBuildingReducer,
    selectedPrice: selectedPriceReducer,
    selectedBedroom: SelectedBedroomReducer,
    selectedBathroom: selectedBathroomReducer,
    serviceData: propertyReducer,
    user: SignUpReducer,
    forgotPassword: forgotPassword,
    property: HomeServiceReducer,
    service: SinglehomeserviceReducer,
    property3: SinglePropertyReducer,
    serviceDetails: SinglehomeserviceReducer,
    service1: ServiceReducer,
    bedroom: bedroomReducer,
    selectedArea: PriceReducer,
    multiselect: multiselectRedcucer,
    bathroom: BathroomReducer,
    SearchProperty: searchPropertyReducer,
    filter: Buyreducer,
    properties: propertyReducer,
    selectedOption: mobileButtonReducer,
    propertyDetails: SingleSearchPropertyReducer,
    area: unitReducer,
    pagination: PaginationReducer,
    search: SearchReducer,
    SearchFilters:FilterReducer,
    clearAll: ClearAllReducer, // Add this line
  

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

setupListeners(store.dispatch);

export default store;
