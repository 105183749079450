import React, { useState, useEffect } from 'react';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const VerifyPropertyPage = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const checkScreenSize = () => {
    setIsMobile(window.innerWidth <= 768);
    setIsTablet(window.innerWidth > 768 && window.innerWidth <= 1050);
  };

  useEffect(() => {
    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === builders.length - 2 ? 0 : prevSlide + 2));
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide === 0 ? builders.length - 2 : prevSlide - 2));
  };

  const builders = [
    {
      image: 'https://squarebric-media-files.squarebric.com/diller4.svg',
      name: 'GOLD BUILDINGS',
      totalProjects: '150 Total Projects | 43 in this city'
    },
    {
      image: 'https://squarebric-media-files.squarebric.com/diller3.png',
      name: 'BRIGADE GROUP',
      totalProjects: '150 Total Projects | 17 in this city'
    },
    {
      image: 'https://squarebric-media-files.squarebric.com/diller2.svg',
      name: 'HOME BUILDERS',
      totalProjects: '162 Total Projects | 31 in this city'
    },
    {
      image: 'https://squarebric-media-files.squarebric.com/diller.svg',
      name: 'BRIGADE GROUP',
      totalProjects: '150 Total Projects | 17 in this city'
    }
  ];

  return (
    <div className="flex justify-center items-center h-auto mt-4 ">
      <div className="flex flex-col items-center w-full ">
        <div className="bg-white flex flex-col justify-between sm:flex-row items-center md:m-0 m-10 w-full">
          <div className="flex flex-col items-center sm:items-start  w-full sm:w-full md:w-1/4 font-semibold">
          {/* mb-0 sm:mb-0 -mt-0 sm:-mt-0 md:-mt-14  lg:-mt-20 */}
            <h1 className="text-[#1E2858] text-lg sm:text-xl md:text-lg lg:text-xl xl:text-2xl font-fifth ml-0 sm:ml-10 text-center sm:text-left relative 2xl:-mt-14 xl:-mt-20 lg:-mt-24 md:-mt-10 ">
              <span className='underlineresponsive'>Verified</span> builders
              {/* <span className="relative ">
                Verified
                <span className="absolute w-full h-1 bg-[#4BA3DB] left-0 " style={{ bottom: '-2px' }}></span>
              </span>
              <span className="ml-2">builders</span> */}
            </h1>

            <h2 className="text-[#69768C] mt-2 font-sixth ml-0 sm:ml-10 text-center sm:text-left">
              Near Your location
            </h2>
            <div className="text-[#69768C] font-customFont ml-5 sm:ml-10 mt-4 text-center sm:text-left">
              All Builders <ArrowForward />
            </div>
          </div>

          {isMobile || isTablet ? (
            <div className="relative m-5 sm:m-10">
              <button
                className="absolute -left-10 bg-white p-2 rounded-md shadow-md opacity-60"
                onClick={handlePrevSlide}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <ArrowBackIosNewIcon />
              </button>
              <div className="grid grid-cols-2 gap-4">
                {[0, 1].map((index) => (
                  <div
                    key={index}
                    className="md:p-2 p-4 flex flex-col items-center justify-center rounded-md text-center"
                    style={{
                      border: '1.15px solid #4BA3DB',
                      background:
                        'linear-gradient(187deg, rgba(255, 255, 255, 0.50) 21.22%, rgba(115, 115, 115, 0.50) 241.2%)'
                    }}
                  >
                    <img
                      src={builders[(currentSlide + index) % builders.length].image}
                      alt="Builder Logo"
                      className="w-10 h-10 sm:w-16 sm:h-16 mb-2"
                    />
                    <div className="text-[#314260] font-customFont font-semibold text-md sm:text-sm">
                      {builders[(currentSlide + index) % builders.length].name}
                    </div>
                    <div className="text-[#9AA2B1] font-sans text-xs sm:text-sm">
                      {builders[(currentSlide + index) % builders.length].totalProjects}
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="absolute -right-10 bg-white p-2 rounded-md shadow-md opacity-60"
                onClick={handleNextSlide}
                style={{ top: '50%', transform: 'translateY(-50%)' }}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 m-5 sm:m-10 w-full md:w-3/4 sm:w-full">
              {builders.map((builder, index) => (
                <div
                  key={index}
                  className="p-2 sm:p-4 flex flex-col items-center justify-center rounded-md text-center"
                  style={{
                    border: '1.15px solid #4BA3DB',
                    background:
                      'linear-gradient(187deg, rgba(255, 255, 255, 0.50) 21.22%, rgba(115, 115, 115, 0.50) 241.2%)'
                  }}
                >
                  <img src={builder.image} alt="Builder Logo" className="w-10 h-10 sm:w-16 sm:h-16 mb-2" />
                  <div className="text-[#314260] font-customFont text-[15px] uppercase font-semibold">
                    {builder.name}
                  </div>
                  <div className="text-[#9AA2B1] font-sans text-xs sm:text-sm">{builder.totalProjects}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPropertyPage;
