import React from 'react'
import Header from '../Header'
import Inquriry from '../Inquiry'
import SliderItem from '../SliderItem'



function Property() {
  return (
    <div className='ml-[6%]'>
        <Header/>
    <Inquriry/>
    <SliderItem/>
    </div>
  )
}

export default Property