import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceDetails } from '../../../../redux/HomeserviceReducer/ServiceReducer';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ServiceCard from './ServiceCard';
import Map from '../../../../page/AboutPage/Map';
import ReviewCard from './ReviewCard';
import InquiryPopup from './InquiryPop';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import ShareIcon from '@mui/icons-material/Share';
import ServiceMap from './ServiceMap';

const images = [
  'https://squarebric-media-files.squarebric.com/serviceImg1.png',
  'https://squarebric-media-files.squarebric.com/serviceImg2.png',
  'https://squarebric-media-files.squarebric.com/serviceImg1.png',
  'https://squarebric-media-files.squarebric.com/serviceImg2.png'
];

const SinglePropertyPage = ({ serviceUuid, onClose }) => {
  const dispatch = useDispatch();
  const service = useSelector((state) => state.service1.service);
  const status = useSelector((state) => state.service1.status);
  const error = useSelector((state) => state.service1.error);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [liked, setLiked] = useState(false);
  const [clicked, setClicked] = useState(false);

  console.log('Service UUID:', serviceUuid);
  console.log('Service:', service);

  useEffect(() => {
    if (serviceUuid) {
      dispatch(fetchServiceDetails(serviceUuid));
    }
  }, [dispatch, serviceUuid]);

  useEffect(() => {
    if (status === 'failed') {
      console.error('Error fetching service details:', error);
    }
  }, [status, error]);

  if (status === 'failed') {
    return <div>Error: {error}</div>;
  }

  if (!service || !service.service_details) {
    return <div></div>;
  }

  const serviceDetails = service.service_details;

  const handleShareClick = () => {
    setClicked(!clicked);
  };

  const handleClick = () => {
    setLiked(!liked);
  };

  const handleInquiryClick = () => {
    setShowInquiryPopup(true);
  };

  const handleClosePopup = () => {
    setShowInquiryPopup(false);
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50 ">
      <div className="bg-white p-4 rounded-md w-full max-w-3xl overflow-y-auto max-h-[90vh] mt-20">
        <div className="flex justify-end items-end mb-4">
          <button onClick={onClose} className="bg-gray-500 hover:bg-red-500 h-8 w-8 rounded-full">
            <CloseIcon />
          </button>
        </div>
        <div className="single-property-container">
          <div className="flex flex-col mt-4">
            <div className="relative">
              {serviceDetails.verified && (
                <div className="absolute top-0 right-0 bg-green-200 text-[#3C8E51] px-4 py-1 rounded-tr-md flex items-center">
                Verified  
                  <CheckCircleIcon style={{ color: 'green', width: '15px', marginLeft: '5px' }} />
                </div>
              )}
              <div className="absolute top-0 bg-[#9BC4E9] text-white p-2 rounded-bl-md">
                <span className="text-sm">{`Photos ${currentImageIndex + 1}/${images.length}`}</span>
              </div>
              <img src={images[currentImageIndex]} alt="Service" className="w-full h-auto" />
              <div className="flex justify-center absolute left-1/2 transform -translate-x-1/2" style={{ top: '90%' }}>
                {images.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => handleDotClick(index)}
                    className={`h-3 w-3 rounded-full mx-1 ${
                      currentImageIndex === index ? 'bg-[#B4D4E9]' : 'bg-sky-500'
                    }`}
                  />
                ))}
              </div>
            </div>
            <div className="flex flex-col items-center mt-4">
              <div className="max-w-screen-sm w-full">
                <h2 className="text-2xl font-semibold capitalize text-[#2E4374]">
                  {serviceDetails.service_provider_name}
                </h2>
                <div className="flex flex-row items-center">
                  <h3 className="text-xl font-semibold capitalize text-[#2E4374]">{serviceDetails.service_type}</h3>
                  <div className="flex items-center ml-2">
                    <p className="text-yellow-500 text-2xl">&#9733;</p>
                    <span className="font-semibold text-[#2E4374] text-sm ml-1 mt-1">
                      {serviceDetails?.average_rating?.toFixed(1)} ({serviceDetails?.rating_count} Ratings)
                    </span>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row  mt-2">
                  <span className="flex ">
                    <img src="https://squarebric-media-files.squarebric.com/fluent.svg" alt="growth" className="w-6 h-6" />
                    <p className="ml-2 text-sm text-[#4BA3DB] font-semibold">
                      {serviceDetails.enquired} People recently enquired
                    </p>
                  </span>
                  <div className="flex items-center space-x-2 mt-2  md:ml-1 md:mt-0">
                    <button className="flex items-center text-[#2E4374] font-semibold text-sm" onClick={handleClick}>
                      {liked ? (
                        <FavoriteIcon style={{ color: 'red', height: '20px' }} />
                      ) : (
                        <FavoriteBorderIcon style={{ height: '20px' }} />
                      )}
                      <span className="ml-1 font-thirdCustom">Like</span>
                    </button>
                    <button
                      className="flex items-center text-[#2E4374] font-semibold text-sm"
                      onClick={handleShareClick}
                    >
                      <ShareIcon style={{ color: clicked ? 'blue' : 'inherit', width: '20px' }} />

                      <span className="ml-1 font-thirdCustom">Share</span>
                    </button>
                  </div>
                </div>
                <div className="flex flex-col md:flex-row gap-2 mt-2">
                  <span className="flex items-center">
                    <CalendarMonthIcon style={{ color: '#2E4374' }} />
                    <p className="ml-2 text-[#2E4374] font-thirdCustom text-sm ">
                      <span className="text-[#FFA500] font-thirdCustom">Open</span>
                      {` -Mon-Fri`}
                    </p>
                  </span>
                  <span className="flex items-center">
                    <HistoryToggleOffIcon style={{ color: '#2E4374' }} />
                    <p className="ml-2 text-[#2E4374] font-thirdCustom text-sm">
                      <span className="text-[#FFA500] font-thirdCustom text-sm">Timing</span>-{`10:00 AM - 5:00 PM`}
                    </p>
                  </span>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-4">
                  <button className="flex justify-center items-center border-2 border-[#2e4374] hover:text-white hover:bg-[#2E4374] transform transition-transform duration-300 rounded-md hover:scale-105 h-10 w-full overflow-hidden whitespace-nowrap">
                    <CallIcon className="m-1" />
                    <span className="font-thirdCustom overflow-ellipsis">{serviceDetails.phone_number}</span>
                  </button>
                  <button className="flex justify-center items-center border-2 border-[#2e4374] hover:bg-[#2E4374] transform transition-transform duration-300 rounded-md hover:scale-105 hover:text-white h-10 w-full overflow-hidden whitespace-nowrap">
                    <PersonIcon className="m-1" />
                    <span className="text-[#2E4374] font-thirdCustom hover:text-white overflow-ellipsis">
                      {serviceDetails.concern_persons}
                    </span>
                  </button>

                  <button
                    className="flex justify-center items-center border-2 border-[#2e4374] hover:text-white hover:bg-[#2E4374] transform transition-transform duration-300 rounded-md hover:scale-105 h-10 w-full overflow-hidden whitespace-nowrap"
                    onClick={handleInquiryClick}
                  >
                    Inquiry
                  </button>
                  <button className="flex justify-center items-center text-sm w-full border-2 border-[#2e4374] hover:bg-[#2E4374] text-[#2e4374] hover:text-white rounded-md h-10 overflow-hidden whitespace-nowrap">
                    <WhatsAppIcon className="m-2" />
                    Chat with us
                  </button>
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-start ml-0 md:ml-10 text-[#2E4374] font-thirdCustom font-semibold text-2xl mt-4">
                Our Services
              </h1>
            </div>
            <div className="flex flex-col">
              <div className="mt-6">
                <ServiceCard />
              </div>
            </div>

            <div className="flex justify-center items-center">
              <ReviewCard />
            </div>
            {/* <div className="flex flex-col justify-center items-center">
              <h1 className="text-start md:mr-96 mr-10 text-[#2E4374] font-thirdCustom font-semibold text-2xl mt-4">
                Where We are located
              </h1>
              <div className="w-full">
                <ServiceMap />
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {showInquiryPopup && <InquiryPopup serviceUuid={serviceUuid} onClose={handleClosePopup} />}
    </div>
  );
};

export default SinglePropertyPage;
