// selectedPriceReducer.js
import { createSlice } from '@reduxjs/toolkit';

const selectedPriceSlice = createSlice({
  name: 'selectedPrice',
  initialState: {
    min: '',
    max: '',
  },
  reducers: {
    setMinPrice: (state, action) => {
      state.min = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.max = action.payload;
    },
    clearMin: (state) => {
      state.min = '';
    },
    clearMax: (state) => {
      state.max = '';
    },
    resetPrices: (state) => {
      state.min = '';
      state.max = '';
    },
  },
});

export const { setMinPrice, setMaxPrice, clearMin, clearMax, resetPrices } = selectedPriceSlice.actions;
export default selectedPriceSlice.reducer;