// redux/slices/selectedOptionSlice.js
import { createSlice } from '@reduxjs/toolkit';

const selectedOptionSlice = createSlice({
    name: 'selectedOption',
    initialState: {
        value: 'Buy', // Default value
    },
    reducers: {
        setSelectedOption: (state, action) => {
            state.value = action.payload;
        },
    },
});

export const { setSelectedOption } = selectedOptionSlice.actions;
export default selectedOptionSlice.reducer;
