export const featureimagedata=["https://squarebric-media-files.squarebric.com/featured1.png","https://squarebric-media-files.squarebric.com/featured2.png","https://squarebric-media-files.squarebric.com/featured3.png","https://squarebric-media-files.squarebric.com/featured4.png","https://squarebric-media-files.squarebric.com/featured5.png","https://squarebric-media-files.squarebric.com/featured6.png"]

export const lifestyledata = [
    {
        id: 1,
        image: "https://squarebric-media-files.squarebric.com/featured1.png",
     
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: 'Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
    {
        id: 2,
        image: "https://squarebric-media-files.squarebric.com/featured2.png",
       
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: ' Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
    {
        id: 3,
        image: "https://squarebric-media-files.squarebric.com/featured3.png",
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: 'Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
    {
        id: 4,
        image:"https://squarebric-media-files.squarebric.com/featured4.png",
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: 'Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
    {
        id: 5,
        image: "https://squarebric-media-files.squarebric.com/featured5.png",
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: 'Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
    {
        id: 6,
        image: "https://squarebric-media-files.squarebric.com/featured6.png",
        title: 'RIVERSIDER CRESCENT',
        description: 'This Better Than New Former Model Has Been Recently Upgraded And Is Move In Ready',
        location: 'Gomti Nagar Sector- 9 Lucknow Enclave city',
        prize:'275,000'  
    },
];