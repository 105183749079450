import { createSlice } from '@reduxjs/toolkit';

const bathroomSlice = createSlice({
  name: 'bathroom',
  initialState: {
    minValue: 0,
    maxValue: 20
  },
  reducers: {
    incrementMin: (state) => {
      if (state.minValue < 19) {
        state.minValue += 1;
        if (state.minValue >= state.maxValue) {
          state.maxValue = state.minValue + 1;
        }
      }
    },
    decrementMin: (state) => {
      if (state.minValue > 1) {
        state.minValue -= 1;
        if (state.minValue >= state.maxValue) {
          state.maxValue = state.minValue + 1;
        }
      }
    },
    incrementMax: (state) => {
      if (state.maxValue < 20) {
        state.maxValue += 1;
      }
    },
    decrementMax: (state) => {
      if (state.maxValue > 2) {
        state.maxValue -= 1;
        if (state.minValue >= state.maxValue) {
          state.minValue = state.maxValue - 1;
        }
      }
    },
    resetValues: (state) => {
      state.minValue = 0;
      state.maxValue = 0;
    },
    clearMinValue: (state) => {
      state.minValue = 0;
    },
    clearMaxValue: (state) => {
      state.maxValue = 0;
    }
  }
});

export const { 
  incrementMin, 
  decrementMin, 
  incrementMax, 
  decrementMax, 
  resetValues, 
  clearMinValue, 
  clearMaxValue 
} = bathroomSlice.actions;

export default bathroomSlice.reducer;
