export const usersData = [
    {
      image:
        "https://images.pexels.com/photos/25758/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=400&h=100&dpr=2",
      name: "Preeti Singh",
      id: "SQB12345",
      firstName: "Siddharth Art",
      middleName: "",
      lastName: "Singh",
      displayName: "Birla Builders",
      country: "India",
      state: "Uttar Pradesh",
      city: "Lucknow",
      email: "SiddharthSingh@Gmail.Com",
      phone: "+911234567891",
      dob: "10/09/1992",
      gender: "Male",
    },
    
];