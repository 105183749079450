import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCountry: '',
  selectedState: '',
  selectedCity: '',
  purpose: '',
  propertyType: '',
  subPropertyType: [],
  selectedMin: null,
  selectedMax: null,
  currency: 'USD',
  min_total_area: '',
  max_total_area: '',
  selectedAreaOption: '',
  min_Bathrooms: '',
  max_Bathrooms: '',
  min_Bedrooms: '',
  max_Bedrooms: '',
  skip: 0,
  limit: 500,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setCountry(state, action) {
      state.selectedCountry = action.payload;
    },
    setState(state, action) {
      state.selectedState = action.payload;
    },
    setCity(state, action) {
      state.selectedCity = action.payload;
    },
    setPurpose(state, action) {
      state.purpose = action.payload;
    },
    setPropertyType(state, action) {
      state.propertyType = action.payload;
    },
    setSubPropertyType(state, action) {
      state.subPropertyType = action.payload;
    },
    setPriceRange(state, action) {
      const { min, max } = action.payload;
      state.selectedMin = min;
      state.selectedMax = max;
    },
    setCurrency(state, action) {
      state.currency = action.payload;
    },
    setAreaRange(state, action) {
      const { minArea, maxArea } = action.payload;
      state.min_total_area = minArea;
      state.max_total_area = maxArea;
    },
    setAreaOption(state, action) {
      state.selectedAreaOption = action.payload;
    },
    setBathrooms(state, action) {
      const { min, max } = action.payload;
      state.min_Bathrooms = min;
      state.max_Bathrooms = max;
    },
    setBedrooms(state, action) {
      const { min, max } = action.payload;
      state.min_Bedrooms = min;
      state.max_Bedrooms = max;
    },
    setPagination(state, action) {
      const { skip, limit } = action.payload;
      state.skip = skip;
      state.limit = limit;
    },
  },
});

export const {
  setCountry,
  setState,
  setCity,
  setPurpose,
  setPropertyType,
  setSubPropertyType,
  setPriceRange,
  setCurrency,
  setAreaRange,
  setAreaOption,
  setBathrooms,
  setBedrooms,
  setPagination,
} = searchSlice.actions;

export default searchSlice.reducer;
