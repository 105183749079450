import { title } from "process";

export const cookiesData = [
  {
    id: 1,
    name: 'Functional Cookies',
    description:
      "and where they come from, allowing us to measure and improve the site's performance. They also enable us to remember your preferences and provide helpful tools, such as videos and currency converters. By analyzing which pages are most and least popular and how users navigate through the Rightmove website, we gain valuable insights to enhance your overall experience.Read more 2. The information collected by these cookies is aggregated and completely anonymous. If you choose to disable these cookies, we won't be able to track your visit, and this may limit our ability to monitor and optimize the website's performance."
  },
  {
    id: 2,
    name: 'Targeting Cookies',
    description:
      "1.These cookies capture detailed information about your activity on the Right move website, including the specific pages you visit, the links you click, and the type of device you're using. This data enables us to personalise your experience, ensuring the content and advertisements you encounter are tailored to your preferences and interests. Read more 2. In addition, our trusted partners may use this information to show you ads they believe are most relevant to you when browsing other sites. They can also analyse the effectiveness of their campaigns by tracking how well the ads perform based on your interactions. 3. If you disable these cookies , the ads and content you encounter will be morw generic and less customise to your interests, which may impact the relevance of your browsing experience."
  },
  {
    id: 3,
    name: 'Security, Fraud Prevention, and Error Correction',
    description:
      '1. We use your data to detect and prevent suspicious or fraudulent activities, such as bot-driven ad clicks, ensuring our systems and processes operate securely and efficiently. This data also helps us identify and resolve any issues that may arise in the delivery of content and advertisements, enhancing your overall experience on the website.Read more 2. Our Network of 137 partners supports these critical functions, working togather to maintain a secure and seamless environment for all users.'
  },
  {
    id: 4,
    name: 'Linking Your Devices',
    description:
      '1. To enhance your experience and support the purposes outlined in this notice, we may link your devices that are associated with you or your household. This can occur when you are logged into the same service on multiple devices, such as your phone and computer, or when both devices connect through the same internet network.Read more 2. This capability, utilised by 117 partners, ensures a seamless and consistent exprience across all your devices.'
  },
  {
    id: 5,
    name: 'Combine and Match Data from Various Sources',
    description:
      '1. To enhance the services we provide, information about your activity on this platform may be matched and combined with data from other sources related to you. This could include your interactions with other online services, use of loyalty cards in-store, or responses to surveys. This integration helps us deliver a more tailored experience in line with the purposes outlined in this notice. Read more 2. This feature is utilized by 118 partners to provide you with relevant and cohesive service across different touchpoints.'
  },
  {
    id: 6,
    name: 'Linking Your Devices Across Platforms',
    description:
      "1. To ful fill the purposes outlined in this notice, your device may be linked to others that belong to you or your household. This can happen if you're logged into the same service across multiple devices, such as your phone and computer, or if both devices share the same internet connection. Read more 2. This feature,supported by 117 partners, ensures a seamless and consistent experience across all your devices. "
  }
];

export const termsData = [
  {
    name: 'Your Rights and Choices',
    features: [
      'Request details about how Squarebric collects and uses your personal information.',
      'Access your personal information in a portable format.',
      'Correct any inaccuracies in your personal information.',
      'Object to the processing of your personal data.',
      'Request the deletion of your personal information, provided there are legitimate grounds to do so.'
    ]
  }
];

// FAQ data
export const faqData = [
  {
    question: 'How to Search for Properties',
    answer: [
      'Step 1: Navigate to the homepage and use the search bar at the top.',
      'Step 2: Enter the location, property type, or specific features youre looking for.',
      'Step 3: Use filters (price, property type, number of bedrooms) to narrow down results.',
      'Step 4: Click on any listing to view more details, photos, and contact options.'
    ]
  },
  {
    question: 'How to Create an Account',
    answer: [
      'Step 1: Click on the "Sign Up" button in the top right corner.',
      'Step 2: Fill in the required details: name, email, and password.',
      'Step 3: Verify your email by following the link sent to your inbox.',
      'Step 4: Log in to access saved searches, favorite listings, and personalized recommendations.'
    ]
  },
  {
    question: ' How to List a Property',
    answer: [
      'Step 1: Create an account or log in if you already have one.',
      'Step 2: Go to the "List Your Property" section in the main menu.',
      'Step 3: Fill in the property details such as address, price, description, and photos.',
      'Step 4: Review and publish your listing. Your property will appear in search results for buyers to view.'
    ]
  },
  {
    question: ' How to Contact an Agent?',
    answer: [
      'Step 1: While browsing listings, click the heart icon on any property you wish to save.',
      'Step 2: Log in to your account to access your saved listings.',
      'Step 3: Visit your “Favorites” section from the dashboard to view or manage saved properties.'
    ]
  },
  {
    question: ' How to Filter Search Results',
    answer: [
      'Step 1: Perform a property search using the search bar.',
      'Step 2: Use the filter options located on the left-hand side of the results page.',
      'Step 3: You can filter by price range, property type (house, apartment, etc.), number of bedrooms, and more.',
      'Step 4: Apply the filters, and the search results will update accordingly.'
    ]
  },
  {
    question: 'How to Edit Your Listing',
    answer: [
      'Step 1: Log in to your account and go to your dashboard.',
      'Step 2: Navigate to “My Listings” to view all properties you have listed.',
      'Step 3: Select the listing you want to edit.',
      'Step 4: Update the information (price, description, images) and click “Save Changes.”'
    ]
  },
  {
    question: 'How to Access the Help Center',
    answer: [
      'Step 1:  Click on the "Help" link located in the website footer.',
      'Step 2:  Browse through frequently asked questions or use the search bar to find specific topics.',
      'Step 3:  If you cant find the information you need, use the “Contact Us” form to reach our support team.'
    ]
  },
  {
    question: 'Where do you buy properties?',
    answer: ['Step 1: Navigate to the homepage and use the search bar at the top.']
  },
  {
    question: 'Do you always stick to your first offer?',
    answer: ['Step 3: Use filters (price, property type, number of bedrooms) to narrow down results.']
  },
  {
    question: 'What if my seller wants to work directly with the buyer?',
    answer: ['Step 4: Click on any listing to view more details, photos, and contact options.']
  },
  {
    question: 'How can I be sure that you’re giving me your best offer?',
    answer: [
      'Step 1: Navigate to the homepage and use the search bar at the top.',

      'Step 4: Click on any listing to view more details, photos, and contact options.'
    ]
  },
  {
    question: 'Why should I use a real estate salesperson?',
    answer: [
      'Step 3: Use filters (price, property type, number of bedrooms) to narrow down results.',
      'Step 4: Click on any listing to view more details, photos, and contact options.'
    ]
  }
];


export const privacyData = [
  {
    id: 2,
    title:'Agent Responsibilties',
    content: [
      'Compliance with Laws: Agents must comply with all applicable local, state, and federal laws and regulations when using our Website and conducting  transactions.Read More',
      'Professional Conduct: Agents are expected to maintain the highest standards of professional conduct and integrity. Misrepresentation of properties or unethical behavior will not be tolerated.',
      'Accuracy of Listings: Agents must ensure that all property listings are accurate, complete, and up-to-date. Any discrepancies or errors should be promptly corrected.'
    ]
  },
  {
    id: 3,
    title:'Property Listings',
    content: [
      'Content Ownership: All content, including property listings, photographs, and descriptions, is provided for informational purposes only. We do not guarantee the accuracy or completeness of this content.Read More',
      'No Endorsement: Listing a property on our Website does not constitute an endorsement or guarantee of the propertys quality or value.',
    ]
  },
  {
    id: 4,
    title:'Intellectual Property',
    content: [
      'Ownership: All content on our Website, including text, graphics, logos, and software, is the property of [Your Real Estate Website Name] or its licensors and is protected by intellectual property laws.Read More',
      'Limited License: You are granted a limited, non-exclusive, non-transferable license to access and use the content on our Website for personal, non-commercial purposes only.',
    ]
  },
  {
    id: 5,
    title:'Limitation of Liability',
    content: [
      'No Warranties: Our Website is provided on an "as is" and "as available" basis. We make no warranties or representations regarding the accuracy, reliability, or availability of our Website or its content.Read More',
      'Limitation of Liability: To the fullest extent permitted by law, Squarebric shall not be liable for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our Website.',
    ]
  },

]