import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  incrementMinValue,
  decrementMinValue,
  incrementMaxValue,
  decrementMaxValue,
  resetValues
} from '../../../../../redux/BedroomReducer';


const MobileBedroom = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const minValue = useSelector((state) => state.bedroom.minValue);
  const maxValue = useSelector((state) => state.bedroom.maxValue);
  useEffect(() => {
    dispatch(resetValues());
    return () => {
      dispatch(resetValues());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(resetValues());
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="border-2 border-gray-300  mr-0 w-full p-2">
      <div onClick={toggleDropdown} className="text-[#2E4374] font-sixth font-[600] text-sm  flex justify-between items-center">
        <div className="ml-1">Bedroom</div>

        <div className="flex items-center ">
          <span className="mr-2">Min:</span>
          <button
            onClick={() => dispatch(decrementMinValue())}
            className={`bg-gray-500 text-white px-2 py-1 rounded-l border ${
              minValue === 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={minValue === 0}
          >
            -
          </button>
          <span className="px-4">{minValue}</span>
          <button
            onClick={() => dispatch(incrementMinValue())}
            className={`bg-gray-500 text-white px-2 py-1 rounded-r border ${
              minValue === 19 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={minValue === 19}
          >
            +
          </button>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Max:</span>
          <button
            onClick={() => dispatch(decrementMaxValue())}
            className={`bg-gray-500 text-white px-2 py-1 rounded-l border ${
              maxValue <= 0 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={maxValue <= 0}
          >
            -
          </button>
          <span className="px-4">{maxValue}</span>
          <button
            onClick={() => dispatch(incrementMaxValue())}
            className={`bg-gray-500 text-white px-2 py-1 rounded-r border ${
              maxValue === 20 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={maxValue === 20}
          >
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default MobileBedroom;