import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    console.log('Current pathname:', pathname); // Debug log
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;