import React from 'react';
import { Link } from 'react-router-dom';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useMediaQuery } from '@mui/material';

const nearbydata = [
  {
    links: [
      {
        icon: 'https://squarebric-media-files.squarebric.com/airport.svg',
        name: 'Airport'
      },
      {
        icon: 'https://squarebric-media-files.squarebric.com/beach.svg',
        name: 'Beach'
      },
      {
        icon: 'https://squarebric-media-files.squarebric.com/nearby_loc.svg',
        name: 'Nearest District'
      },
      {
        icon: 'https://squarebric-media-files.squarebric.com/mall.svg',
        name: 'Famous Mall'
      }
    ]
  }
];

const Nearby = () => {
  return (
    <div className="flex">
      <div className="m-4 p-2">
        <h2 className="text-[#1E2858] font-fifth font-[600] text-lg">Near by Attraction</h2>
        {nearbydata.map((item, index) => (
          <div key={index} className="grid grid-cols-2 md:grid-cols-4 p-5 gap-4 md:ml-0 sm:ml-20">
            {item.links.map((link, linkIndex) => (
              <Link key={linkIndex} className="text-[#1E2858]">
                <div className={`text-[#1E2858] flex justify-center ${index === nearbydata.length - 1 && ''}`}>
                  <img src={link.icon} alt="" className="w-20 h-10 md:w-40 md:h-10 sm:w-20 sm:h-10" />
                </div>
                <div className="text-[#1E2858] font-fifth flex justify-center font-semibold text-sm md:text-lg sm:text-sm">
                  {link.name}
                </div>
              </Link>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const Reviewproject = () => {
  const isTabletScreen = useMediaQuery('(max-width:1050px)');
  const ratingValue = 4.1;
  return (
    <>
    
      <div className="flex justify-center">
        <div className="border-2 mt-10 m-4 p-4 w-full md:w-auto">
          <div className="flex justify-between p-2">
            <h1 className="text-[#1E2858] font-[400] font-fifth text-sm md:text-lg sm:text-sm">
              About Dubai al Karama city 992521
            </h1>
            <h1 className="text-[#2e4373] font-SecondCustom font-semibold flex items-center text-sm md:text-lg sm:text-sm">
              Explore Project <TrendingFlatIcon />
            </h1>
          </div>
          <div className="flex justify-start  mt-4 p-2">
            <div className="text-[#2e4373] font-thirdCustom text-lg">
              <div className="flex text-sm text-[#1E2858] font-[600] font-fifth">
                <p className="ml-2">{ratingValue}</p>
                <Rating
                  name="read-only"
                  value={ratingValue}
                  precision={0.1}
                  readOnly
                  emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
                />
              </div>
              <div>
                <p className="flex text-sm text-[#1E2858] font-[400] font-sixth ml-2">15 Reviews</p>
              </div>
            </div>
            <div className="">
              <p className="flex text-sm text-[#1E2858] font-[600] font-sixth ml-6">Rank 52</p>
              <p className="flex text-sm text-[#1E2858] font-[400] font-sixth">out of 917 localities</p>
            </div>
          </div>
          <div
            className={`flex md:flex-row flex-col  ${
              isTabletScreen ? 'gap-4' : 'flex-row ml-0 sm:ml-0 md:mt-2 md:ml-4'
            }`}
            style={{ justifyContent: isTabletScreen ? 'flex-start  ' : 'flex-start ' }}
          >
            <div>
              {/* <div className="flex gap-2">
                <p className="text-[#2E4374] font-[400] text-sm font-sixth">Environment</p>
                <div className="flex gap-1">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5</p>
                  <div className="flex border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </div>
              </div> */}
              <div className="flex gap-2 md:justify-start justify-between ">
                <p className="text-[#2E4374] font-[400] text-sm font-sixth">Environment</p>
                <span className="flex justify-start md:ml-14 ml-0">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5 </p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full ml-1 bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </span>

                {/* <div className="flex gap-1">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5</p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </div> */}
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="176" height="5" viewBox="0 0 176 5" fill="none">
                  <rect width="176" height="5" rx="2.5" fill="#D9D9D9" />
                  <rect width="73" height="5" rx="2.5" fill="#FAD13B" />
                </svg>
              </div>
            </div>
            <div className="md:ml-10">
              {/* <div className="flex gap-2">
                <p className="text-[#2E4374] font-[400] text-sm font-sixth">Commuting</p>
                <div className="flex gap-1">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4</p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </div>
              </div> */}
              <div className="flex gap-2 md:justify-start justify-between ">
                <p className="text-[#2E4374] font-[400] text-sm font-sixth">Commuting</p>
                <span className="flex justify-start md:ml-[70px] ml-0">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4 </p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full ml-1 bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </span>

                {/* <div className="flex gap-1">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5</p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </div> */}
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="176" height="5" viewBox="0 0 176 5" fill="none">
                  <rect width="176" height="5" rx="2.5" fill="#D9D9D9" />
                  <rect width="73" height="5" rx="2.5" fill="#FAD13B" />
                </svg>
              </div>
            </div>
            <div className="md:ml-10">
              <div className="flex gap-2 md:justify-start justify-between ">
                <p className="text-[#2E4374] font-[400] text-sm font-sixth">Places of interest</p>
                <span className="flex justify-start md:ml-9 ml-0">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5</p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </span>

                {/* <div className="flex gap-1">
                  <p className="text-[#2E4374] font-[400] text-sm font-sixth">4.5</p>
                  <div className="flex items-center justify-center border-2 border-[#2E4374] w-4 h-4 rounded-full bg-white">
                    <KeyboardArrowDownIcon style={{ color: '#2E4374', fontSize: '15px' }} />
                  </div>
                </div> */}
              </div>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="176" height="5" viewBox="0 0 176 5" fill="none">
                  <rect width="176" height="5" rx="2.5" fill="#D9D9D9" />
                  <rect width="73" height="5" rx="2.5" fill="#FAD13B" />
                </svg>
              </div>
            </div>
          </div>
          <p className="mt-4 text-[#2E4374] font-sixth font-[400] text-sm p-2">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only
          </p>
          <button className="border-2 p-2 border-[#2e4374] rounded-md mt-4 text-sm text-[#2E4374] hover:text-white hover:bg-[#2E4374] transition-transform transform hover:-translate-y-1 transition-all duration-300">
            Compare Localities
          </button>
        </div>
      </div>
      <div className="">
        <Nearby />
      </div>
    </>
  );
};

export default Reviewproject;
