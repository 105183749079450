// In filterSlice.js
import { createSlice } from '@reduxjs/toolkit';


const filterSlice = createSlice({
    name: 'SearchFilters',
    initialState: {
        selectedCountry: null,
        selectedState: null,
        selectedCity: null,
        purpose: null,
        propertyType: null,
        subPropertyType: [],
        selectedMin: null,
        selectedMax: null,
        currency: 'usd',
        min_total_area: null,
        max_total_area: null,
        selectedAreaOption: null,
        min_Bathrooms: null,
        max_Bathrooms: null,
        min_Bedrooms: null,
        max_Bedrooms: null,
    },
    reducers: {
        resetFilters: (state, action) => {
            switch (action.payload) {
                case 'country':
                    state.selectedCountry = null;
                    break;
                case 'state':
                    state.selectedState = null;
                    break;
                case 'city':
                    state.selectedCity = null;
                    break;
                case 'propertyType':
                    state.propertyType = null;
                    break;
                case 'subPropertyType':
                    state.subPropertyType = [];
                    break;
                case 'bathrooms':
                    state.min_Bathrooms = null;
                    state.max_Bathrooms = null;
                    break;
                case 'area':
                    state.min_total_area = null;
                    state.max_total_area = null;
                    break;
                // Add cases for other filters
                default:
                    break;
            }
        },
        // Other reducers
    },
});

export const { resetFilters } = filterSlice.actions;
export default filterSlice.reducer;
