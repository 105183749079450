import React from 'react';

const TabButton = ({ children, onSelect, className }) => {
  if (!children) {
    throw new Error('children prop is required for TabButton');
  }

  if (!onSelect) {
    throw new Error('onSelect prop is required for TabButton');
  }

  if (!className) {
    throw new Error('className prop is required for TabButton');
  }

  return (
    <button className={className} onClick={onSelect}>
      {children}
    </button>
  );
};


export default TabButton;
