import { createSlice } from '@reduxjs/toolkit';

export const selectedOptionsSlice = createSlice({
  name: 'selectedOptions',
  initialState: {
    selectedOptions: []
  },
  reducers: {
    setSelectedOptions: (state, action) => {
      state.selectedOptions = action.payload;
    }
  }
});

export const { setSelectedOptions } = selectedOptionsSlice.actions;

export const selectSelectedOptions = (state) => state.selectedOptions.selectedOptions;

export default selectedOptionsSlice.reducer;
