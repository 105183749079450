import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Rectangle } from 'react-leaflet';

const DeveloperServicePage = () => {
  const isTabletScreen = useMediaQuery('(max-width:1024px)');
  const isSmallScreen = useMediaQuery('(max-width:650px)');

  return (
    <div className="flex justify-center mt-10 ">
      <div className="flex flex-col md:flex-row justify-between w-full p-2 rounded-lg md:mt-10 mt-0">
        <div
          className={`flex flex-col ${isTabletScreen ? 'w-full' : 'md:w-2/5'}`}
          style={{ margin: isTabletScreen ? '20px' : 'auto' }}
        >
          <div className="text-center md:text-left mb-4 mt-0 sm:mt-10 md:-mt-6 lg:-mt-32 w-full md:-ml-5 ml-0">
            <h1
              className={`font-fifth font-bold uppercase text-sm sm:text-xl md:text-2xl lg:text-2xl xl:text-3xl leading-7 text-[#2E4374] -ml-6 md:ml-6   ${
                isTabletScreen ? 'text-[15px]' : ''
              }`}
            >
              <span class="underlineresponsive">SQUAREBRIC LEADING</span> REAL ESTATE DEVELOPER IN TURKEY
            </h1>
            <p
              className={`font-sixth tracking-wide text-sm md:text-base lg:text-lg text-[#2E4374] mt-4 md:ml-6 -ml-5  ${
                isTabletScreen ? 'text-[14px]' : ''
              }`}
            >
              Discover elegance and comfort in our newest property. This development perfectly balances sophistication
              and practicality, creating an ideal living space.
            </p>
            <div className="mt-4 md:ml-6 mr-12 md:mt-6">
              <button className="md:py-2 py-1 px-4 uppercase font-thirdCustom font-semibold border-2 border-[#2E4374] text-xs md:text-xs lg:text-base text-[#2E4374] hover:text-white hover:bg-[#2E4374] transition-transform transform hover:-translate-y-1 transition-all duration-300 md:ml-10 ">
                VIEW ALL PROPERTIES
              </button>
            </div>
          </div>
        </div>
        <div
          className={`flex flex-col md:flex-row items-start justify-between md:ml-0 ml-2 w-full md:mb-0 mb-4 ${
            isTabletScreen ? 'w-full' : 'md:w-3/5'
          } gap-4`}
        >
          <div className="text-[#2E4374] font-thirdCustom uppercase text-[9px] w-full">
            <img
              src="https://squarebric-media-files.squarebric.com/imageproperty2.png"
              alt="Property"
              className={`object-cover w-full ${isTabletScreen ? 'h-[250px]' : 'h-[420px]'}`}
            />
            <div className="text-[9px] font-sixth font-bold text-[#2E4374] mt-1">
              Rashid Yachits & Marine Builders | B- 15/20 Pitam Pura, Delhi 110034
            </div>
          </div>
          <div className="flex flex-row md:flex-col sm:flex-row gap-4 w-full">
            <div className="text-[#2E4374] font-thirdCustom uppercase text-[9px] w-full">
              <img
                src="https://squarebric-media-files.squarebric.com/imageproperty1.png"
                alt="Property"
                className={`object-cover w-full ${isTabletScreen ? 'h-[100px]' : 'h-[190px]'}`}
              />
              <div className="text-[9px] text-[#2E4374] font-bold font-sixth mt-1">Sahara & Marita Builders</div>
              <div className="text-[9px] text-[#2E4374] font-bold font-sixth">B- 15/20 Pitam Pura, Delhi 110034</div>
            </div>
            <div className="text-[#2E4374] font-thirdCustom uppercase text-[9px] w-full md:-mt-1.5 mt-0">
              <img
                src="https://squarebric-media-files.squarebric.com/imageProperty.png"
                alt="Property"
                className={`object-cover w-full ${isTabletScreen ? 'h-[100px]' : 'h-[190px]'}`}
              />
              <div className="text-[9px] text-[#2E4374] font-bold font-sixth mt-1">Sahara & Marita Builders</div>
              <div className="text-[9px] text-[#2E4374] font-bold font-sixth">B- 15/20 Pitam Pura, Delhi 110034</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeveloperServicePage;
