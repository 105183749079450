import React, { useState, useEffect, useRef } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import imageCompression from 'browser-image-compression';
import { API_KEY } from './Constant';
import './Image.css';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ImageUpload({ propertyId }) {
  const [files, setFiles] = useState({});
  const [message, setMessage] = useState('');
  const [folder, setFolder] = useState('');
  const [uvid, setUvid] = useState('');
  const [activeIndex, setActiveIndex] = useState(null);
  const [showMessage, setShowMessage] = useState(true);
  const [jsonData, setJsonData] = useState({});
  const [percentages, setPercentages] = useState({});
  const [globalDefaultImage, setGlobalDefaultImage] = useState('');
  const [defaultImageError, setDefaultImageError] = useState('');
  const [overallPercentage, setOverallPercentage] = useState(0);
  const [filesUploaded, setFilesUploaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [firstCategoryUploaded, setFirstCategoryUploaded] = useState(false);

  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const MAX_IMAGES_PER_CATEGORY = 4;

  const ImageData = [
    'Exterior View',
    'Kitchen',
    'Living Room',
    'Bedroom',
    'Bathroom',
    'Floor Plan',
    'Master Plan',
    'Location Plan',
    'Other',
    'Brochure'
  ];

  const ImageDetails = {
    default: ['builder_floor/0.png'],
    'Exterior View': ['builder_floor/0.png', 'builder_floor/1.png'],
    Bathroom: ['builder_floor/2.png', 'builder_floor/3.png'],
    Bedroom: ['builder_floor/4.png', 'builder_floor/5.png'],
    Kitchen: ['builder_floor/6.png', 'builder_floor/7.png']
  };

  useEffect(() => {
    let userUvid = localStorage.getItem('uvid');
    if (!userUvid) {
      userUvid = uuidv4();
      localStorage.setItem('uvid', userUvid);
    }
    setUvid(userUvid);
  }, []);

  const handleButtonClick = () => {
    if (!folder) {
      setMessage('Please select a category to upload.');
      return;
    }
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    if (!folder) {
      setMessage('Please select a category to upload.');
      return;
    }
    // setTimeout(() => {
    //   setShowMessage(false);
    // }, 3000);

    const selectedFiles = Array.from(e.target.files);

    if (folder === 'Brochure') {
      const pdfFiles = selectedFiles.filter((file) => file.type === 'application/pdf');
      const nonPdfFiles = selectedFiles.filter((file) => file.type !== 'application/pdf');

      if (nonPdfFiles.length > 0) {
        setMessage('Please select only PDF files for Brochure category.');
        return;
      }

      setFiles((prevFiles) => ({
        ...prevFiles,
        [folder]: pdfFiles
      }));
    } else {
      const imageFiles = selectedFiles.filter((file) => ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type));
      const resizedImageFiles = await Promise.all(imageFiles.map(resizeImage));

      setFiles((prevFiles) => {
        const currentFiles = prevFiles[folder] || [];
        const allFiles = currentFiles.concat(resizedImageFiles);

        if (allFiles.length > MAX_IMAGES_PER_CATEGORY) {
          setMessage('You can only upload up to 4 photos per category.');
          return prevFiles;
        } else {
          setMessage('');
          setFilesUploaded(true);

          return {
            ...prevFiles,
            [folder]: allFiles
          };
        }
      });
    }
    updateJsonData();
    updatePercentages();
  };

  const resizeImage = async (file) => {
    const options = {
      maxWidthOrHeight: 600,
      useWebWorker: true
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error('Error resizing the image:', error);
      return file;
    }
  };

  const handleRemoveFile = (fileIndex) => {
    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      updatedFiles[folder].splice(fileIndex, 1);
      if (updatedFiles[folder].length === 0) {
        delete updatedFiles[folder];
        setFilesUploaded(false);
      }
      return updatedFiles;
    });
    updateJsonData();
    updatePercentages();
  };
  // console.log('files', files);
  const handleSetGlobalDefault = (file) => {
    setGlobalDefaultImage(file);
    setDefaultImageError('');
    console.log('defaultimages', globalDefaultImage);
    updateJsonData(file);
  };

  const handleUpload = async () => {
    if (loading) return;
    setLoading(true);
    if (Object.keys(files).length === 0) {
      setMessage('Please select a category to upload.');
      setLoading(true);
      return;
    }
    if (!globalDefaultImage) {
      setDefaultImageError('Please set a default image before uploading.');
      setLoading(true);
      return;
    }
    setDefaultImageError('');
    console.log('checkerror', defaultImageError);

    const s3 = new AWS.S3({
      accessKeyId: 'AKIAWEUJPXRNXRPOD2FB',
      secretAccessKey: '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',
      region: 'ap-south-1'
    });

    try {
      const uploadPromises = [];

      for (const category in files) {
        files[category].forEach((file) => {
          const fileUvid = uuidv4();
          const params = {
            Bucket: 'square-media-files',
            Fields: {
              key: `${propertyId}/${category}/${propertyId}-${file.name}`,
              ContentType: file.type
            },
            Expires: 60
          };

          const uploadPromise = new Promise((resolve, reject) => {
            s3.createPresignedPost(params, (err, data) => {
              if (err) {
                reject(err);
              } else {
                resolve({ data, file, fileUvid });
              }
            });
          });

          uploadPromises.push(uploadPromise);
        });
      }

      const presignedPosts = await Promise.all(uploadPromises);

      const uploadFilePromises = presignedPosts.map(({ data, file, fileUvid }) => {
        const formData = new FormData();
        Object.keys(data.fields).forEach((key) => {
          formData.append(key, data.fields[key]);
        });
        formData.append('file', file);

        return axios
          .post(data.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            if (response.status === 204) {
              return { response, file, key: data.fields.key };
            }
          });
      });

      const responses = await Promise.all(uploadFilePromises);

      if (responses.every(({ response }) => response.status === 204)) {
        // setMessage('Files uploaded successfully');
        const updatedJsonData = {};
        for (const category in files) {
          updatedJsonData[category] = {
            images: files[category].map((file) => {
              const response = responses.find(({ file: uploadedFile }) => uploadedFile.name === file.name);
              return `${propertyId}/${category}/${response.propertyId}-${file.name}`;
            }),
            defaultImage: globalDefaultImage ? `${propertyId}/${folder}/${propertyId}-${globalDefaultImage.name}` : ''
          };
        }
        setJsonData(updatedJsonData);
        updatePropertyImages(updatedJsonData);
      } else {
        setMessage('Some files failed to upload.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('File upload failed.');
    }
    window.scrollTo({
      top: 5000,
      behavior: 'smooth'
    });
    console.log('jsonbydefaults images', jsonData);
  };

  const uploadData = (index, item) => {
    if (folder !== item) {
      setFolder(item);
      setActiveIndex(index);
      setMessage('');
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  };

  const getFileCount = (category) => {
    return files[category]?.length || '';
  };

  const getPercentage = (category) => {
    const fileCount = files[category]?.length || 0;
    return ((fileCount / MAX_IMAGES_PER_CATEGORY) * 100).toFixed(2);
  };

  const updatePropertyImages = async () => {
    const userData = localStorage.getItem('userData');
    const token = userData ? JSON.parse(userData).access_token : null;

    if (!token) {
      setMessage('No authentication token found.');
      return;
    }

    const url = `https://square-bric-backend-api.squarebric.com/property-listing/update-property-images/${propertyId}`;
    // console.log('API URL:', url);
    // console.log('Token:', token);

    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json' // Ensure correct content type if required
        }
      });

      console.log('Response Status:', response.status);
      console.log('Response Data:', response.data);

      if (response.status === 200) {
        toast.success('Image uploaded successfully');
        setTimeout(() => {
          navigate('/dashboard/property');
        }, 5000);
      } else {
        setMessage('Failed to update property images');
      }
    } catch (error) {
      console.error('Error updating property images:', error);
      setMessage('Error updating property images');
      console.log('Error updating property images:', error);
    }
  };

  const updateJsonData = (defaultImage = globalDefaultImage) => {
    const data = {
      ImageDetails: {
        exterior_view: [],
        kitchen: [],
        living_room: [],
        bedroom: [],
        bathroom: [],
        floor_plan: [],
        master_plan: [],
        location_plan: [],
        other: [],
        brochure: [],
        default: []
      }
    };

    ImageData.forEach((category) => {
      const categoryKey = category.toLowerCase().replace(/\s+/g, '_');
      if (files[category]) {
        data.ImageDetails[categoryKey] = files[category].map((file) => {
          const fileName = file.name;
          return `${propertyId}/${category}/${propertyId}-${fileName}`;
        });
      } else {
        data.ImageDetails[categoryKey] = [];
      }
    });

    if (defaultImage) {
      const defaultImageUrl = `${propertyId}/${folder}/${propertyId}-${defaultImage.name}`;
      data.ImageDetails.default = [defaultImageUrl];
    }

    setJsonData(data);
  };
  console.log('jsonData', jsonData);

  const updatePercentages = () => {
    const updatedPercentages = {};
    let totalFileCount = 0;
    let totalCategoryCount = 0;

    ImageData.forEach((category) => {
      const fileCount = files[category]?.length || 0;
      totalFileCount += fileCount;
      totalCategoryCount += MAX_IMAGES_PER_CATEGORY;

      const percentage = Math.min(((fileCount / MAX_IMAGES_PER_CATEGORY) * 100).toFixed(2), 100);
      updatedPercentages[category] = percentage;
    });

    const overallPercentage = Math.min(((totalFileCount / totalCategoryCount) * 100).toFixed(2), 100);

    setPercentages(updatedPercentages);
    setOverallPercentage(overallPercentage);
  };

  useEffect(() => {
    updatePercentages();
  }, []);
  // const borderWidth = `${overallPercentage}px`;
  //ImageData, files,
  return (
    <div className="">
      <h1 className="ml-[7%] mt-5 pl-6 text-start p-4 leading-[17px] thirdCustom bg-[#EAF7FF]  text-[20px] font-[600] text-[#1E2858]">
        Photos Upload
      </h1>

      <div className="flex justify-center ml-[10%] items-center flex-col mt-5 w-[70%] h-[500px] border-dotted border-2 border-blue-500 p-5">
        <div
          style={{
            width: '100%',
            position: 'relative',
            height: '20px',
            backgroundColor: '#e0e0e0',
            borderRadius: '10px'
          }}
          className="mb-24 "
        >
          <div
            style={{
              width: `${overallPercentage}%`,
              height: '100%',
              backgroundColor: '#4caf50',
              borderRadius: '10px 0 0 10px' // Rounded corners on the left side
            }}
          />

          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: '#000',
              fontSize: '10px',
              fontFamily: 'Arial, sans-serif'
            }}
          >
            {`${overallPercentage}%`}
          </div>
        </div>

        <div className=" w-[100%]  ">
          <div className="flex mt-3  gap-2 w-full">
            {ImageData.map((item, index) => (
              <button
                key={index}
                onClick={() => uploadData(index, item)}
                className={` p-3 border rounded  ${activeIndex === index ? 'bg-[#1E2858] text-white' : 'bg-gray-200'}`}
              >
                {item} {getFileCount(item)}
              </button>
            ))}
          </div>
          <input type="file" ref={fileInputRef} multiple onChange={handleFileChange} style={{ display: 'none' }} />

          <div className="flex justify-center flex-col items-center  h-44">
            <div className=" ">
              {showMessage && (!files[folder] || files[folder].length <= 0) && (
                <p
                  className={`moving-text bg-green-100 text-[16px] w-[430px] text-center capitalize  p-5 rounded-[50px] mt-8 font-[400] relative `}
                >
                  85% Of <span className="text-red-400">Buyers</span> enquire on Properties with Photos
                  <br />
                  Upload Photos & Get up to <span className="text-red-400">10x more Enquires</span>
                </p>
              )}
            </div>
            <div className="flex justify-start ">
              <div className="flex mt-3 justify-start w-auto ml-[8%]">
                {files[folder]?.map((file, index) => (
                  <div key={index} className="relative m-2">
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      className="w-32 h-32 object-cover border rounded"
                    />
                    <button
                      onClick={() => handleRemoveFile(index)}
                      className="absolute top-0 right-0 bg-[#1E2858] text-white p-1 rounded"
                    >
                      X
                    </button>
                    <button
                      onClick={() => handleSetGlobalDefault(file)}
                      className={`absolute text-[16px] font-[400] bottom-0 right-0 bg-[#1E2858] text-white p-1 rounded ${
                        globalDefaultImage === file ? 'bg-green-300' : ''
                      }`}
                    >
                      {globalDefaultImage === file ? 'Default' : 'Set Default'}
                    </button>
                  </div>
                ))}
              </div>

              <div className="flex justify-center mt-5 ">
                {files[folder] && files[folder].length < MAX_IMAGES_PER_CATEGORY ? (
                  <div
                    className="upload-icon flex justify-center items-center w-32 h-32 border-dotted border-2 border-blue-500"
                    onClick={handleButtonClick}
                  >
                    <AddIcon fontSize="large" />
                    <br />
                  </div>
                ) : (
                  (!files[folder] || files[folder].length < MAX_IMAGES_PER_CATEGORY) && (
                    <div className={`flex justify-center items-center relative }`}>
                      <button
                        onClick={handleButtonClick}
                        className=" p-3 ml-2 text-[18px] w-[240px] relative      font-[400] thirdCustom bg-[#1E2858] text-white rounded-[5px]"
                      >
                        Select Files to Upload <DriveFolderUploadIcon className="pb-1" style={{ fontSize: '36px' }} />
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {defaultImageError && (
            <p
              className="text-red-500 font-semibold text-[20px] absolute top-[50px] left-[40%] z-[10]
              transition-all duration-300 ease-in-out transform hover:text-red-500 hover:scale-105 hover:underline hover:shadow-lg"
            >
              {defaultImageError}
            </p>
          )}
          {showMessage && (
            <p
              className="text-red-500 font-semibold text-[20px] absolute top-[50px] left-[40%] z-[10]
              transition-all duration-300 ease-in-out transform hover:text-red-500 hover:scale-105 hover:underline hover:shadow-lg"
            >
              {message}
            </p>
          )}

          <div className="flex justify-end relative">
            <button
              disabled={loading}
              onClick={handleUpload}
              className="bg-[#1E2858] capitalize mb-4 block w-full max-w-[200px] mt-4 p-2 text-white font-[400] rounded-[7px] mr-8 shadow-lg transform transition duration-300 ease-in-out hover:scale-105"
            >
              <DriveFolderUploadIcon />
              Upload
            </button>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
}

export default ImageUpload;
