import React, { useState, useEffect } from 'react';
import { propertyData } from '../componenet/propertyDummyData';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import useMediaQuery from '@mui/material/useMediaQuery';
const bg = {
  background: 'rgba(217, 217, 217, 0.80)'
};

const headerStyle = {
  color: 'white',
  background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
};

export const Card = ({ buttonLabel, title, description, image, location, prize, area, tag }) => {
  return (
    <div className="rounded overflow-hidden flex justify-start items-start flex-col bg-white shadow-lg m-2 h-auto border-2 border-[#E5EFFF] relative w-full sm:w-full md:[500px]">
      <div className="relative w-full  h-60">
        <img className="w-full h-full object-cover" src={image} alt={title} />
        <span
          className={`absolute top-10 -left-1 px-2 py-1 text-xs font-semibold font-thirdCustom text-[#1E2858] rounded-md uppercase ${
            tag === 'Residential' ? 'bg-green-500' : 'bg-blue-500'
          }`}
          style={bg}
        >
          {tag}
        </span>
      </div>
      <div className="p-4 h-full">
        <div className="font-sixth text-[#1E2858] font-semibold text-base md:text-base lg:text-base">{title}</div>
        <p className="font-sixth text-sm md:text-sm lg:text-sm overflow-hidden overflow-ellipsis text-[#666]">
          {description}
        </p>
        <div className="flex justify-between items-center mt-2 ">
          <div className="flex gap-1 font-sixth text-sm font-[500] text-[#1E2858] ">
            {/* <span className="mt-0.5">
              <img src="https://squarebric-media-files.squarebric.com/Map_Pin.png" alt="location" />
            </span> */}
            <div className=" flex-shrink-0 ">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24" fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.63609 4.63627C2.12139 8.15092 2.12129 13.8493 5.63587 17.3641L9.87998 21.6072C11.0516 22.7776 12.9494 22.7776 14.1208 21.6075L18.3641 17.3641C21.8787 13.8493 21.8786 8.15092 18.3639 4.63627C14.8492 1.12163 9.1508 1.12163 5.63609 4.63627ZM16.9497 6.0505C19.6834 8.78411 19.6834 13.2162 16.9499 15.9499L12.7069 20.1929C12.3168 20.5826 11.6842 20.5826 11.2937 20.1925L7.05002 15.9498C4.31656 13.2162 4.31664 8.78411 7.05029 6.0505C9.78396 3.31688 14.216 3.31688 16.9497 6.0505ZM12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7ZM12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9Z"
                  fill="#2e4374"
                />
              </svg>
            </div>
            <span className="mt-0 text-[#2e4374]">Istanbul</span>
          </div>
          <div className="flex gap-1 font-thirdCustom text-sm font-[500] text-[#1E2858] ">
            <span className="font-sixth font-semibold text-[#1E2858]">
              <img src="https://squarebric-media-files.squarebric.com/MGBED2.svg" alt="mgmad" width={20} />
            </span>
            <span className="mt-0 text-[#2e4374]">2</span>
          </div>
          <div className=" flex gap-1 font-thirdCustom text-sm font-[500] text-[#1E2858] ">
            <span className="font-sixth font-semibold text-[#1E2858] mt-0.5">
              <img src="https://squarebric-media-files.squarebric.com/MGBED.svg" alt="mgmad1" width={25.5} />
            </span>
            <span className="mt-0.5 text-[#2e4374]">2</span>
          </div>
          <div className="flex gap-1 font-sixth text-sm font-[500] text-[#1E2858]">
            <span className="mt-0.5 text-[#1E2858]">
              <img src="https://squarebric-media-files.squarebric.com/area5.svg" alt="area" width={20} />
            </span>
            <span className="mt-0.5 text-[#2e4374]">45 sq.m</span>
          </div>
        </div>
        <div className="flex justify-between items-center mt-2 ml-2">
          <div className="text-[#1E2858] font-sixth font-semibold text-base md:text-base lg:text-base">
            <span className='text-lg text-[#1E2858]'>$ </span>{prize}</div>
          <button className=" text-[#1E2858] font-semibold text-sm md:text-sm lg:text-sm px-3 py-1  md:text-[15px] sm:text-[15px] border-2 border-[#2E4374] rounded  hover:bg-[#2E4374] hover:text-white  transform hover:-translate-y-1 transition-all duration-300 mt-2 -mb-1">
          {buttonLabel}
          </button>
          {/* <div
            className="font-sixth text-[#1E2858] font-semibold text-sm md:text-sm lg:text-sm px-3 py-1 rounded-sm transition-all duration-300 transform hover:scale-105"
            style={headerStyle}
          >
            {buttonLabel}
          </div> */}
        </div>
      </div>
    </div>
  );
};

const PropertyDetails = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isTabletScreen = useMediaQuery('(max-width:1050px)');
  const isSmallScreen = useMediaQuery('(max-width:750px)');

  const numCardsToShow = windowWidth >= 1024 ? 3 : windowWidth >= 768 ? 2 : 1;

  const totalCards = propertyData.length;

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? totalCards - numCardsToShow : prevIndex - 1));
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === totalCards - numCardsToShow ? 0 : prevIndex + 1));
  };

  return (
    <div className="container mx-auto mt-10 sm:mt-10 md:mt-20">
 <h2
  className="relative inline-block mb-4 pb-2 text-sm sm:text-sm md:text-2xl lg:text-3xl font-bold text-[#2E4374] font-fifth ml-4 sm:ml-4 uppercase leading-6"
  style={{ marginLeft: isTabletScreen ? '10px' : '', textAlign: isSmallScreen ? 'center' : 'justify' }}
>
 <span className='underlineresponsive'> Squarebric's Prime Pro</span>perties Recommendations
  {/* <span
    className="absolute w-1/2 h-1 lg:-bottom-0 lg:left-0 md:-bottom-0 md:left-0 sm:left-0 sm:bottom-0 left-[60px] bottom-7 bg-[#4BA3DB]"
    style={{ marginBottom: '2px' }}
  ></span> */}
</h2>

      <p
        className=" text-sm md:text-xl lg:text-2xl mb-10 ml-4 sm:ml-4 text-center sm:text-center md:text-left text-[#2E4374] font-sixth "
        style={{ marginLeft: isTabletScreen ? '10px' : '' }}
      >
        Explore the diverse and vibrant real estate opportunities in Turkey, where ancient history meets modern living
      </p>

      <div className="flex relative justify-center">
        <div className="flex gap-4 overflow-hidden w-full ">
          {propertyData.slice(currentIndex, currentIndex + numCardsToShow).map((card, index) => (
            <div
              key={index}
              className="w-full s transform transition-transform duration-300 ease-in-out"
              style={{ width: isSmallScreen ? '100%' : `${100 / numCardsToShow}%` }}
            >
              <Card
                buttonLabel={card.buttonLabel}
                description={card.description}
                image={card.image}
                title={card.title}
                location={card.location}
                prize={card.prize}
                area={card.area}
                tag={card.tag}
              />
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        {currentIndex > 0 && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 left-2 bg-white p-2 opacity-60"
            style={{ left: isTabletScreen ? '10px' : '' }}
            onClick={goToPrevSlide}
          >
            <ArrowBackIosIcon />
          </button>
        )}
        {currentIndex + numCardsToShow < totalCards && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 -right-1  bg-white p-2 opacity-60"
            onClick={goToNextSlide}
            style={{ right: isTabletScreen ? '0px' : '' }}
          >
            <ArrowForwardIosIcon />
          </button>
        )}
      </div>

      {/* Mobile view */}
      <div className="lg:hidden relative">
        <div className="flex gap-4 overflow-hidden">
          {propertyData.slice(currentIndex, currentIndex + numCardsToShow).map((card, index) => (
            <div key={index} className="w-full">
              <Card
                buttonLabel={card.buttonLabel}
                description={card.description}
                image={card.image}
                title={card.title}
                location={card.location}
                prize={card.prize}
                area={card.area}
                tag={card.tag}
              />
            </div>
          ))}
        </div>

        {/* Navigation buttons */}
        {currentIndex > 0 && (
          <button className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-white p-2 " onClick={goToPrevSlide}>
            <ArrowBackIosIcon />
          </button>
        )}
        {currentIndex + numCardsToShow < totalCards && (
          <button
            className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-white p-2"
            onClick={goToNextSlide}
            style={{ marginRight: '20px' }}
          >
            <ArrowForwardIosIcon />
          </button>
        )}
      </div>
    </div>
  );
};

export default PropertyDetails;
