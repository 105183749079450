import React, { useState } from 'react';
import Card from '@mui/material/Card';
import { ReviewData } from './ReviewData';
import StarRateIcon from '@mui/icons-material/StarRate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useMediaQuery from '@mui/material/useMediaQuery';

const Review = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeArrow, setActiveArrow] = useState(null); // State for tracking active arrow button

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % ReviewData.length);
    setActiveArrow('next'); // Set active arrow to next
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + ReviewData.length) % ReviewData.length);
    setActiveArrow('prev'); // Set active arrow to prev
  };

  const visibleProperties = ReviewData.slice(currentIndex, currentIndex + 1);

  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(<StarRateIcon key={i} className="text-amber-400" />);
    }
    return stars;
  };

  const isSmallScreen = useMediaQuery('(max-width:1024px)');
  const isTablet = useMediaQuery('(max-width: 1024px) and (min-width: 768px)');

  const arrowMarginTop = isSmallScreen ? '10px' : isTablet ? '10px' : '60px';

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  return (
    <div className="mt-10 w-full mb-6 md:mb-10 sm:mb-8">
      <div className="backgroundand">
        <div className="flex flex-col mx-auto max-h-screen">
          <div className="font-customFont text-3xl text-white sm:text-center md:text-left md:text-4xl mt-10 ml-24 xl:ml-[20%] lg:ml-[20%] md:ml-[20%] sm:mr-[40%] ">
            Client Reviews
          </div>
          <Card className="md:w-3/5 w-4/5 mx-auto mt-8 relative h-auto lg:h-[430px] mb-12">
            {visibleProperties.map((review, i) => (
              <div key={review.id} className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 md:m-12 m-5">
                  <h1 className="text-xl font-bold font-SecondCustom text-text2">{review.name}</h1>
                  <div className="font-semibold font-thirdCustom text-sm text-text2">{review.location}</div>
                  <div className="font-thirdCustom text-base font-semibold md:mt-10  text-text2 2xl:mt-5 xl:mt-3 lg:mt-2">
                    {review.service}
                  </div>
                  <p className="md:mt-5 xl:mt-3 lg:mt-2 lg:text-sm font-thirdCustom xl:text-base md:text-base text-text3 mr-8">
                    {isSmallScreen ? truncateText(review.description, 20) : review.description}
                  </p>
                  <div className=" mt-5">{renderStars()}</div>
                </div>
                <div className=" md:w-1/2">
                  <img className="object-cover w-full lg:h-[450px] md:h-[500px]" src={review.image} alt={`Review ${i + 1}`} />
                </div>
              </div>
            ))}
            <div className="absolute inset-y-0 left-0 flex items-center" style={{ marginTop: arrowMarginTop }}>
              <div
                className={`cursor-pointer text-sm p-2 shadow-md ${
                  activeArrow === 'prev' ? 'bg-[#2e4374] text-white opacity-60' : 'bg-white text-[#1E2858] opacity-55'
                }`}
                onClick={prevSlide}
              >
                <ArrowBackIosNewIcon />
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center" style={{ marginTop: arrowMarginTop }}>
              <div
                className={`cursor-pointer text-sm p-2 shadow-md ${
                  activeArrow === 'next' ? 'bg-[#2e4374] text-white opacity-60' : 'bg-white text-[#1E2858] opacity-55'
                }`}
                onClick={nextSlide}
              >
                <ArrowForwardIosIcon />
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Review;
