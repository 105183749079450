import { useState, useEffect, useCallback } from 'react';

const useProperties = (page, limit,searchQuery = '', filters = {}) => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const fetchProperties = useCallback(async () => {
    setLoading(true);
    try {
      const userData = localStorage.getItem('userData');
      let token = '';
      if (userData) {
        const parsedUserData = JSON.parse(userData);
        token = parsedUserData.access_token;
      }

      const skip = (page - 1) * limit;
      console.log('skip', skip, limit);

      const response = await fetch(
        `https://square-bric-backend-api.squarebric.com/myunit/my-unit-details?skip=${skip}&limit=${limit}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        }
      );

      // Ensure response is ok and parse JSON
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('data', data);

      if (Array.isArray(data?.data) && data.data.length > 0) {
        setProperties(data.data[0]?.data || []);
        setTotalItems(data.data[0]?.total || 0);
      } else {
        setProperties([]);
        setTotalItems(0);
      }
    } catch (err) {
      console.error('API Error:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [page, limit]);

  useEffect(() => {
    fetchProperties();
  }, [fetchProperties]);

  return { properties, loading, error, totalItems, refetch: fetchProperties };
};

export default useProperties;


// import { useState, useEffect, useCallback } from 'react';

// const useProperties = (page, limit, searchQuery = '', filters = {}) => {
//   const [properties, setProperties] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [totalItems, setTotalItems] = useState(0);

//   const fetchProperties = useCallback(async () => {
//     setLoading(true);
//     try {
//       const userData = localStorage.getItem('userData');
//       let token = '';
//       if (userData) {
//         const parsedUserData = JSON.parse(userData);
//         token = parsedUserData.access_token;
//       }

//       const skip = (page - 1) * limit;

//       // Construct query parameters including search and filters
//       const queryParams = new URLSearchParams({
//         skip,
//         limit,
//         searchQuery,
//         ...filters,
//       }).toString();

//       const response = await fetch(
//         `http://square-bric-backend-api.squarebric.com/myunit/my-unit-details?${queryParams}`,
//         {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         }
//       );

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const data = await response.json();

//       if (Array.isArray(data?.data) && data.data.length > 0) {
//         // Update the properties and totalItems based on filtered data
//         setProperties(data.data[0]?.data || []);
//         setTotalItems(data.data[0]?.total || 0);
//       } else {
//         setProperties([]);
//         setTotalItems(0);
//       }
//     } catch (err) {
//       console.error('API Error:', err);
//       setError(err.message);
//     } finally {
//       setLoading(false);
//     }
//   }, [page, limit, searchQuery, filters]);

//   useEffect(() => {
//     fetchProperties();
//   }, [fetchProperties]);

//   return { properties, loading, error, totalItems, refetch: fetchProperties };
// };

// export default useProperties;

