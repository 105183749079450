import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Logo from "../asset/Logo.png";
import Login from "../asset/Login.png";
import { sidebarData } from "./Card";

import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const handleLogout = () => {
    // Perform logout action here
    console.log('Logging out...');
    localStorage.clear();
    toast.success("Logged out successfully");
    setTimeout(() => {
      navigate("/login");
    }, 2000); // Redirect after showing the toast
  };

  return (
    <div className="bg-[#061E35]  rounded-b-[14px] w-[7%] min-h-screen  fixed top-0 left-0 ">
      <Link to="/">
        <div className="flex flex-col justify-center items-center">
          <img src={Logo} alt="logo" className="pt-2  brightness-100" />
        </div>
      </Link>

      {sidebarData.map((items, key) => (
        (key !== 1 && key !== 2) && (
          <Link to={`${items.link}`} key={key}>
            <div className="flex flex-col justify-center items-center my-5 pt-2 transition duration-300">
              <img
                src={items.img}
                alt={items.title}
                className={`transition duration-300 ${
                  currentPath.includes(items.link) ? 'brightness-100' : 'brightness-50 hover:brightness-100'
                }`}
              />
              <span
                className={`text-gray-200 text-[11px] whitespace-nowrap break-words font-[500] leading-[30px] capitalize thirdCustom transition duration-300 ${
                  currentPath.includes(items.link) ? 'brightness-100' : 'brightness-50 hover:brightness-100'
                }`}
              >
                {/* {items.title} */}
                {t(`${items.title}`)}
              </span>
            </div>
          </Link>
        )
      ))}

      <Link to="#">
        <div
          className="flex flex-col justify-center items-center mt-[20px] brightness-50 transition duration-300 hover:brightness-100"
          onClick={handleLogout}
          style={{ cursor: 'pointer' }}
        >
          <img src={Login} alt="login" />
          <span className="text-white text-[14px] my-2 font-[400] capitalize"> {t('Logout')}</span>
        </div>
      </Link>

      <ToastContainer />
    </div>
  );
};

export default Sidebar;
