import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  propertyType: '',
  subPropertyType: [],
};

const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    setPropertyType: (state, action) => {
      state.propertyType = action.payload;
      state.subPropertyType = []; 
    },
    toggleSubPropertyType: (state, action) => {
      state.subPropertyType = action.payload;
    },
    clearPropertyType: (state) => {
      state.propertyType = '';
    },
    clearSubPropertyType: (state) => {
      state.subPropertyType = [];
    },
    removeSubPropertyType: (state, action) => {
      const index = action.payload;
      state.subPropertyType = state.subPropertyType.filter((_, i) => i !== index);
    },
    removeSubPropertyType: (state, action) => {
      const index = action.payload;
      state.subPropertyType = state.subPropertyType.filter((_, i) => i !== index);
    },
  },
});

export const { setPropertyType, toggleSubPropertyType, clearPropertyType, clearSubPropertyType, removeSubPropertyType } = propertySlice.actions;

export default propertySlice.reducer;
