import React, { useEffect, useState } from 'react';
import Sidebar from '../Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Outlet } from 'react-router-dom';
import Createlisting from '../Listing/Createlisting';



function Layout() {
  const [tokenValid, setTokenValid] = useState(true);
  const navigate = useNavigate();

  const verifyToken = async (token) => {
    try {
      const response = await axios.get(
        'https://square-bric-backend-api.squarebric.com/user/users/me',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.status === 200; // Token is valid
    } catch (error) {
      if (error.response && error.response.status === 401) {
        return false; // Unauthorized, token is invalid
      }
      return false; // Other errors
    }
  };
  useEffect(() => {
    const checkToken = async () => {
      const userData = localStorage.getItem('userData');
      if (userData) {
        const { access_token: token } = JSON.parse(userData);
        if (token) {
          const valid = await verifyToken(token);
          if (!valid) {
            // Token is invalid or expired, handle logout
            localStorage.removeItem('userData');
            setTokenValid(false);
            navigate('/login');
          } else {
            setTokenValid(true);
          }
        } else {
          setTokenValid(false);
          navigate('/login');
        }
      } else {
        setTokenValid(false);
        navigate('/login');
      }
    };

    // Initial check
    checkToken();

    // Set up interval for periodic checks
    // const intervalId = setInterval(checkToken, 5 * 60 * 1000); // 5 minutes
    const intervalId = setInterval(checkToken, 30 * 60 * 1000);


    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

 
  return (
    <div className="sidbar">
 
 <div className="sidebar">
        <Sidebar />
      </div>
      <div className="content flex-1">
        {tokenValid ? (
          <div>
            <Outlet />
          </div>
        ) : (
          <p>Redirecting to login...</p>
        )}
      </div>
     
    </div>
  );
}

export default Layout;
