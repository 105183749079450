import React, { useState } from 'react';
import { Card, Divider, CardContent } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Mediadata, Recentdata } from './mediadata';
import '../main.css';

const Mediacenter = () => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate index of the first and last item to be displayed based on current page
  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Mediadata.slice(indexOfFirstItem, indexOfLastItem);

  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  const pageCount = Math.ceil(Mediadata.length / itemsPerPage);
  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const pageNumbers = Math.min(maxVisiblePages, pageCount);
    const pagesBeforeEllipsis = Math.min(currentPage - 1, Math.floor((maxVisiblePages - 1) / 2));
    const pagesAfterEllipsis = Math.min(pageCount - currentPage, Math.floor((maxVisiblePages - 1) / 2));
    const startPage = Math.max(1, currentPage - pagesBeforeEllipsis);
    const endPage = Math.min(pageCount, currentPage + pagesAfterEllipsis);

    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i ? 'text-white rounded-full bg-[#2e4374]' : 'bg-gray-200 text-[#2e4374] rounded-full'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(1)}
              className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-[#2e4374] text-white' : 'bg-gray-200'}`}
            >
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {buttons}
        {endPage < pageCount - 1 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {endPage < pageCount && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(pageCount)}
              className={`px-3 py-1 rounded ${currentPage === pageCount ? 'bg-[#2e4374] text-white' : 'bg-gray-200'}`}
            >
              {pageCount}
            </button>
          </li>
        )}
      </>
    );
  };

  return (
    <div className="w-full mb-20">
      {/* Container for Heading and Main Grid */}
      <div className="flex flex-col md:p-10 p-6 mx-auto max-w-7xl">
        {/* Main Content */}
        <div className="flex flex-col">
          <h1 className="text-[#1e2858] md:text-xl text-base font-semibold font-thirdCustom">Squarebric Blog</h1>
          <p className="text-[#1e2858] md:text-base text-xs mt-4 font-semibold font-thirdCustom">
            Managing over 25,000 units on behalf of the Dubai Government and other owners, Squarebric properties is
            unrivalled by its experience and expertise in managing residential and commercial properties.
          </p>
        </div>

        {/* Recent Posts Slider */}
        <div className="lg:hidden block mt-6">
          <Slider {...settings}>
            {Recentdata.map((item) => (
              <div key={item.id} className="">
                <Card className="bg-blue-200 p-1 mx-2">
                  <img src={item.img} alt={item.title} className="w-full object-contain" />
                  <div className="m-2">
                    <h2 className="font-thirdCustom text-[#1E2858] text-sm font-[600]">{item.title}</h2>
                    <p className="font-thirdCustom text-[#1E2858] text-xs font-[400] mt-3">{item.description}</p>
                  </div>
                </Card>
              </div>
            ))}
          </Slider>
        </div>

        {/* Main Card Grid */}
        <div className="flex mt-12 justify-center">
          <div className="grid gap-10 md:grid-cols-2 lg:w-full ">
            {currentItems.map((item) => (
              <Card
                key={item.id}
                style={{ border: '1px solid #bdbdbd', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}
              >
                <CardContent>
                  <img src={item.img} alt={item.title} className="w-full object-cover"/>
                  <div className="mt-4">
                    <h2 className="font-thirdCustom text-[#1E2858] text-sm md:text-base font-bold">{item.title}</h2>
                    <p className="font-thirdCustom text-[#1E2858] text-sm">{item.description}</p>
                  </div>
                  <Divider style={{ backgroundColor: '#1E2858', width: '100%', marginTop: '30px' }} />
                  <div className="text-[#1E2858] flex justify-center text-lg font-semibold mt-3 mb-4">
                    Read More
                    <EastIcon className="mt-1 ml-1" style={{ color: '#2E4374' }} />
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>

          {/* Recent Posts Sidebar */}
          <div className="bg-[#EEF7FF] rounded-lg ml-10 hidden lg:block w-4/5">
            <h1 className="ml-12 mt-5 text-[#1e2858] text-xl font-semibold font-thirdCustom">Recent Posts</h1>
            <div className="flex flex-col items-center">
              {Recentdata.map((item) => (
                <Card key={item.id} className="mt-5 bg-blue-200 w-4/5 flex flex-row">
                  <img src={item.img} alt={item.title} className="h-28 m-2" />
                  <div className="m-2">
                    <h2 className="font-thirdCustom text-[#1E2858] text-base font-semibold">{item.title}</h2>
                    <p className="font-thirdCustom text-[#1E2858] text-sm mt-3">{item.description}</p>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </div>

        {/* Pagination */}
        <div className="flex justify-center items-center mt-4">
          {/* <button
            onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
            disabled={currentPage === 1}
            className="px-3 py-1 mx-1 text-[#071C35] text-lg font-bold mr-2"
          >
            &lt;&lt;
          </button> */}
          <button
          onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 text-lg font-bold mr-2 ${currentPage === 1 ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &lt;&lt;
        </button>
          <ul className="flex">{renderPaginationButtons()}</ul>
          <button
          onClick={() => setCurrentPage(currentPage === pageCount ? pageCount : currentPage + 1)}
          disabled={currentPage === pageCount}
          className={`px-3 py-1 mx-1 text-lg font-bold ml-2 ${currentPage === pageCount ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &gt;&gt;
        </button>
        </div>
      </div>
    </div>
  );
};

export default Mediacenter;