import React from 'react';
import Propertymain from './Propertymain';
import Allservices from './Allservices';
import DemandingService from './DemandingService';
import Review from './Review';

const Service = () => {
  return (
    <div className="">
      <div>
        <Propertymain />
      </div>
      <div>
        <Allservices />
      </div>
      <div>
        <DemandingService />
      </div>
      <div>
        <Review />
      </div>
    </div>
  );
};

export default Service;
