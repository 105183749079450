// forgotPasswordSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const forgotPassword = createAsyncThunk('/forgotPassword', async (email, { rejectWithValue }) => {
  try {
    const response = await axios.post('http://square-bric-backend-api.squarebric.com/user/forgot-password', { email });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    isRequesting: false,
    requestSuccess: false,
    requestError: null
  },
  reducers: {
    clearForgotPasswordState: (state) => {
      state.isRequesting = false;
      state.requestSuccess = false;
      state.requestError = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isRequesting = true;
        state.requestSuccess = false;
        state.requestError = null;
      })
      .addCase(forgotPassword.fulfilled, (state) => {
        state.isRequesting = false;
        state.requestSuccess = true;
        state.requestError = null;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isRequesting = false;
        state.requestSuccess = false;
        state.requestError = action.payload.message; // Assuming your API returns an error message in the response
      });
  }
});

export const { clearForgotPasswordState } = forgotPasswordSlice.actions;

export const selectForgotPasswordState = (state) => state.forgotPassword;

export default forgotPasswordSlice.reducer;
