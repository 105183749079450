import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServiceData, setCurrentPage } from '../../../../redux/HomeserviceReducer/SinleHomeserviceReducer';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Modal, Box } from '@mui/material';
import './Property.css';
import SinglePropertyPage from './SinglePropertyPage';
import StarIcon from '@mui/icons-material/Star';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import growth from '../../../../assets/fluent.svg';

const ServiceDetail = () => {
  const { serviceType } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const serviceData = useSelector((state) => state.service.data);
  console.log("servicedata",serviceData);
  const loading = useSelector((state) => state.service.loading);
  const error = useSelector((state) => state.service.error);
  const totalCount = useSelector((state) => state.service.totalCount);
  const currentPage = useSelector((state) => state.service.currentPage);

  const [selectedService, setSelectedService] = useState(null);
  const [previousData, setPreviousData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cardsPerPage = 5;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loading) {
      setPreviousData(serviceData);
    }
    dispatch(fetchServiceData({ serviceType, currentPage, cardsPerPage }));
  }, [dispatch, serviceType, currentPage, cardsPerPage]);

  const paginate = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const pageCount = Math.ceil(totalCount / cardsPerPage);

  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const pageNumbers = Math.min(maxVisiblePages, pageCount);
    const pagesBeforeEllipsis = Math.min(currentPage - 1, Math.floor((maxVisiblePages - 1) / 2));
    const pagesAfterEllipsis = Math.min(pageCount - currentPage, Math.floor((maxVisiblePages - 1) / 2));
    const startPage = Math.max(1, currentPage - pagesBeforeEllipsis);
    const endPage = Math.min(pageCount, currentPage + pagesAfterEllipsis);

    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => paginate(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i ? 'text-white bg-[#2e4374] rounded-full' : 'bg-gray-200 rounded-full'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <li className="">
            <button
              onClick={() => paginate(1)}
              className={`px-3 py-1 rounded ${
                currentPage === 1 ? 'text-white bg-[#2e4374] rounded-full' : 'bg-gray-200 rounded-full'
              }`}
            >
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {buttons}
        {endPage < pageCount - 1 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {endPage < pageCount && (
          <li className="mx-1">
            <button
              onClick={() => paginate(pageCount)}
              className={`px-3 py-1 rounded ${
                currentPage === pageCount ? 'text-white bg-[#2e4374] rounded-full' : 'bg-gray-200 rounded-full'
              }`}
            >
              {pageCount}
            </button>
          </li>
        )}
      </>
    );
  };

  const closePopup = () => {
    setIsModalOpen(false);
    setSelectedService(null);
  };

  const handleCardClick = (service) => {
    setSelectedService(service);
    setIsModalOpen(true);
  };

  return (
    <div className=" ">
      <div className="md:mt-28 mt-36 text-[#2E4374] w-full overflow-x-hidden mb-24 overflow-auto">
        <div className="md:mt-0 mt-0 flex flex-col justify-center items-center px-4 md:px-0">
          <div className="mt-16 px-4 md:px-0 w-full lg:w-3/5">
            <div
              className="flex font-fourth font-semibold text-sm gap-2 flex-wrap md:text-sm justify-start ml-4 items-center"
              style={{ fontSize: '10px' }}
            >
              <div
                onClick={() => navigate('/')}
                className={`cursor-pointer ${window.location.pathname === '/' ? 'underline' : ''}`}
              >
                Home
              </div>
              <div className="flex items-center">
                <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />}</span>
                <div
                  onClick={() => navigate('/ServicePage')}
                  className={`cursor-pointer ${window.location.pathname === '/ServicePage' ? 'underline' : ''}`}
                >
                  Most Demanding Service
                </div>
              </div>
              <div className="flex items-center">
                <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />}</span>
                <div
                  onClick={() => navigate(`/ServicePage/${serviceType}`)}
                  className={`cursor-pointer ${
                    window.location.pathname.includes(`/ServicePage/${serviceType}`) ? 'underline' : ''
                  }`}
                >
                  {serviceType}
                </div>
              </div>
              <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />}</span>
              <div>Total Results: {totalCount}</div>
            </div>
          </div>

          <div className="p-4 grid grid-cols-1 gap-4 justify-center items-center w-full lg:w-3/5 transition ">
            {loading && previousData.length > 0 ? (
              previousData.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row justify-between items-center rounded-lg overflow-hidden shadow-lg w-full relative cursor-pointer"
                  onClick={() => handleCardClick(item)}
                >
                  <div className="flex-1 p-4">
                    <div className="flex justify-between items-center">
                      <h6 className="font-bold ml-2">{item.service_provider_name}</h6>
                    </div>
                    <div className="p-2 text-sm font-thirdCustom">
                      <p>
                        <strong>Concern Person:</strong> {item.concern_persons}
                      </p>
                      <p>
                        <strong>Phone Number:</strong> {item.phone_number}
                      </p>
                      <p className="w-full md:w-1/2">
                        <strong>Description:</strong> {item.service_description}
                      </p>
                      <div className="flex justify-items-end">
                        <StarIcon className="text-yellow-600" />
                        <h6 className="font-bold text-lg" style={{ fontSize: '10px' }}>
                          {item.average_rating.toFixed(1)} <span>({item.rating_count} Ratings)</span>
                        </h6>
                        <div className="flex items-center ml-2">
                          <div className="flex font-bold items-center">
                            <img src="https://squarebric-media-files.squarebric.com/fluent.svg" alt="growth icon" width={20} height={20} className="" />
                            <p className="ml-2 text-lg md:text-lg" style={{ fontSize: '10px' }}>
                              {item.enquired} people recently enquired
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-4 relative w-full md:w-auto">
                    <img
                      src="https://squarebric-media-files.squarebric.com/singleService.png"
                      alt="Service"
                      className="w-full md:w-48 rounded-lg border-2 mt-3 border-cyan-400"
                    />
                    {item.verified && (
                      <span className="absolute top-4 left-2 text-black rounded-md bg-green-200 px-2 py-1">
                        Verified
                        <CheckCircleIcon style={{ color: 'green', width: '15px' }} />
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : loading ? (
              <div>Loading...</div>
            ) : serviceData.length > 0 ? (
              serviceData.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col md:flex-row justify-between items-center rounded-lg overflow-hidden shadow-lg w-full relative cursor-pointer"
                  style={{
                    transition: 'transform 0.3s ease',
                    cursor: 'pointer',
                    transform: 'scale(1)',
                
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.02)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                  onClick={() => handleCardClick(item)}
                >
                  <div className="flex-1 p-4">
                    <div className="flex justify-between items-center">
                      <h6 className="font-bold ml-2">{item.service_provider_name}</h6>
                    </div>
                    <div className="p-2 text-sm font-thirdCustom">
                      <p>
                        <strong>Concern Person:</strong> {item.concern_persons}
                      </p>
                      <p>
                        <strong>Phone Number:</strong> {item.phone_number}
                      </p>
                      <p className="w-full md:w-1/2">
                        <strong>Description:</strong> {item.service_description}
                      </p>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <StarIcon className="text-yellow-600" />
                          <h6 className="font-bold ml-1 text-sm mt-1" style={{ fontSize: '10px' }}>
                            {item.average_rating.toFixed(1)} <span>({item.rating_count} Ratings)</span>
                          </h6>
                          <div className="flex items-center ml-2">
                            <img
                              src="https://squarebric-media-files.squarebric.com/fluent.svg"
                              alt="growth icon"
                              width={20}
                              height={20}
                              className=""
                            />
                            <p className="ml-2 text-base font-bold mt-1" style={{ fontSize: '10px' }}>
                              {item.enquired} people recently enquired
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-4 relative w-full md:w-auto">
                    <img
                      src="https://squarebric-media-files.squarebric.com/singleService.png"
                      alt="Service"
                      className="w-full md:w-48 rounded-lg border-2 mt-3 border-cyan-400"
                    />
                    {item.verified && (
                      <span className="absolute top-4 left-2 text-black rounded-md bg-green-200 px-2 py-1">
                        Verified
                        <CheckCircleIcon style={{ color: 'green', width: '15px' }} />
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <p className="h-screen"></p>
            )}
          </div>
        </div>
        <div className="flex justify-center items-center mt-4  ">
          <button
            onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
            disabled={currentPage === 1}
            className={`px-3 py-1 mx-1 text-lg font-bold mr-2 ${currentPage === 1 ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
            // className={`px-3 py-1 text-lg font-bold mr-2 ${
            //   currentPage === 1 ? 'text-white bg-[#2e4374]' : 'text-[#071C35]'
            // }`}
          >
            &lt;&lt;
          </button>
          <ul className="flex">{renderPaginationButtons()}</ul>
          <button
            onClick={() => paginate(currentPage === pageCount ? pageCount : currentPage + 1)}
            disabled={currentPage === pageCount}
            // className={`px-3 py-1 text-lg font-bold ml-2 ${
            //   currentPage === pageCount ? 'text-white bg-[#2e4374]' : 'text-[#071C35]'
            // }`}
            className={`px-3 py-1 mx-1 text-lg font-bold ml-2 ${currentPage === pageCount ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          
            &gt;&gt;
          </button>
        </div>
        <Modal open={isModalOpen} onClose={closePopup}>
          <Box className="modal-box">
            <SinglePropertyPage serviceUuid={selectedService?.service_uuid} onClose={closePopup} />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default ServiceDetail;
