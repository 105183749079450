// AdvanceFilter.js
import React from 'react';
import MultiselecterArea from './multiselcter/MultiselecterArea';
import Area from './multiselcter/Area';
import Bathroom from './multiselcter/Bathroom';
import Bedroom from './multiselcter/Bedroom';

const AdvanceFilter = () => {
  return (
    <div className=" flex bg-white justify-between mt-[-11px]  gap-1 py-2 px-4  w-[1000px] pl-4  ">
      <div className="">
        <MultiselecterArea />
      </div>
      <div className="">
        <Area />
      </div>
      <div className="">
        <Bedroom />
      </div>
      <div className="">
        <Bathroom />
      </div>
    </div>
  );
};

export default AdvanceFilter;