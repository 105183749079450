// src/redux/selectedPriceSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  min: '',
  max: ''
};

const selectedPriceSlice = createSlice({
  name: 'Pricevalue',
  initialState,
  reducers: {
    setMinPrice(state, action) {
      state.min = action.payload;
    },
    setMaxPrice(state, action) {
      state.max = action.payload;
    },
    resetPrices(state) {
      state.min = '';
      state.max = '';
    }
  }
});

export const { setMinPrice, setMaxPrice, resetPrices } = selectedPriceSlice.actions;
export default selectedPriceSlice.reducer;
