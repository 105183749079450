import React, { useState, useEffect, useRef } from 'react';


import 'tailwindcss/tailwind.css';
import EditNoteIcon from '@mui/icons-material/EditNote';

import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import L, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {  useNavigate, useLocation, useParams } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { amenity } from '../Propertyhomeservice/AmentiesData';

import ImageFlat from '../Propertyhomeservice/imageupload/ImageFlat';
import ImageResiLand from '../Propertyhomeservice/imageupload/ImageResiLand';
import ImageResidential from '../Propertyhomeservice/imageupload/ImageResidential';

import axios from 'axios';
import ImageVilla from '../Propertyhomeservice/imageupload/ImageVilla';

import ImageBuilder from '../Propertyhomeservice/imageupload/ImageBuilder';
import ImageCommShop from '../Propertyhomeservice/imageupload/ImageCommShop';
import ImageCommLand from '../Propertyhomeservice/imageupload/ImageCommLand';

import ImageItPark from '../Propertyhomeservice/imageupload/ImageItPark';

import ImageAgriCul from '../Propertyhomeservice/imageupload/ImageAgriCul';

import ImageFarmHouse from '../Propertyhomeservice/imageupload/ImageFarmHouse';

import ImageIndustrialLand from '../Propertyhomeservice/imageupload/ImageIndustrialLand';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png'
});
const MapViewUpdater = ({ position }) => {
  const map = useMap();
  map.setView(position, 13); // Adjust zoom level as needed
  return null;
};
const FormWithCompletionPercentage = () => {
  // basic to property details only states
  const [numBedrooms, setNumBedrooms] = useState('');
  const [bedrooms, setBedrooms] = useState([]);
  const [fillPercentage, setFillPercentage] = useState(0);
  const [amount, setAmount] = useState('');
  const [fromCurrency, setFromCurrency] = useState('');
  const [convertedValues, setConvertedValues] = useState({});
  const [boundaryWall, setBoundaryWall] = useState('');
  const [status, setStatus] = useState('');
  const [bed, setBed] = useState();
  const [furnishedStatus, setFurnishedStatus] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [balconyCount, setBalconyCount] = useState('');
  const [availabilityMonth, setAvailabilityMonth] = useState('');
  const [availabilityYear, setAvailabilityYear] = useState('');
  const [floorNumber, setFloorNumber] = useState('');
  const [validated, setValidated] = useState(false);
  const [roadWidth, setRoadWidth] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [gatedColony, setGatedColony] = useState('');
  const [pantryError, setPantryError] = useState('');
  const [anyConstructionError, setAnyConstructionError] = useState('');
  const [boundaryWallError, setBoundaryWallError] = useState('');
  const [gatedColonyError, setGatedColonyError] = useState('');
  const [enable, setEnable] = useState(false);
  const [openSides, setOpenSides] = useState([1, 2, 3, 4]);
  const [selectedOpenSide, setSelectedOpenSide] = useState('');
  const [selectedOptions, setSelectedOptions] = useState('');
  const [street, setStreet] = useState('');
  const [totalFloors, setTotalFloors] = useState('');
  const [error, setError] = useState('');
  const [totalFloorsError, setTotalFloorsError] = useState('');
  const [lengthError, setLengthError] = useState('');
  const [breadthError, setBreadthError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [isCornerPlot, setIsCornerPlot] = useState('');
  const [isCornerPlotError, setIsCornerPlotError] = useState('');
  const [selectedOptioning, setSelectedOptioning] = useState('');
  const [leasError, setLeasError] = useState('');
  const [validation, setValidation] = useState({});
  const [basVal, setBasVal] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedPropertyType, setSelectedPropertyType] = useState('');
  const [bsicvalidationMessages, setBasicValidationMessages] = useState({});
  const [validationMessagesFlat, setValidationMessagesFlat] = useState({});
  const [validationMessagesResiLandPlot, setValidationMessagesResiLandPlot] = useState({});
  const [oneData, setOneData] = useState({ amenities: {} });
  const [jsonAmenities, setJsonAmenities] = useState();
  const [propertyId, setPropertyId] = useState();
  const [villaValidation, setVillaValidation] = useState({});
  const [builderValidation, setBuilderValidation] = useState({});
  const [commShopValidation, setCommShopValidation] = useState({});
  const [commlandValidation, setCommLandValidation] = useState({});
  const [selectFloor,setSelectFloor] = useState('');
  const [bathroom,setBathRoom]=useState("")
  const [facing,setFacing]=useState("")
  const [commitValidation, setCommITValidation] = useState({});
  const [commindusValidation, setCommIndusValidation] = useState({});
  const [agriValidation, setAgriValidation] = useState({});
  const [farmhouseValidation, setFarmHouseValidation] = useState({});
  const [locationValidation, setLocationValidation] = useState({});
  const [validationMessageHouse, setValidationMessageHouse] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
//   const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
//   const [errores, setErrores] = useState(null);
   const {property_id } = useParams();
//    console.log("idadata",property_id)

const [data, setData] = useState({
    title: '',
    description: '',
    agent_name: '',
    owner_type: '',
    purpose: '',
    property_type : '',
    sub_property_type: ''
  });
  const [datas, setDatas] = useState({
        country:"",
        state:"",
        city:"",
        address:"",
        zipcode:"",
        street:""
  });

  
  

  
  

useEffect(() => {
    const fetchProperties = async () => {
      try {
        const userData = localStorage.getItem('userData');
        const token = userData ? JSON.parse(userData).access_token : '';
  
        if (!token) {
          throw new Error('Authorization token is missing');
        }
  
        const response = await axios.get(`https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
  
        if (!response || response.status !== 200) {
          throw new Error(`Network response was not ok: ${response.statusText || response.status}`);
        }
  
       
    
        setBasicData({
          ...basicData,
          title: response.data.property_details.title,
          Agent_Name: response.data.property_details.agent_name,
          description: response.data.property_details.description,
          selectedOwner: response.data.property_details.owner_type,
          selectedPurpose: response.data.property_details.purpose,
          selectedPropertyType: response.data.property_details.property_type,
          selectedSubType: response.data.property_details.sub_property_type
        });

        const superAreaMeasurement = response.data.property_details.area.measurement.find(m => m.super_area);

        if (superAreaMeasurement) {
          setProperty(prevState => ({
            ...prevState,
            area: {
              ...prevState.area,
              super_area: {
                value: superAreaMeasurement.super_area.value || '',
                unit: superAreaMeasurement.super_area.unit || ''
              }
            },
           
          }));
        }
        const { property_on_the_floors } = response.data.property_details;
      

        // Update state with the extracted data
        setProperty(prevProperty => ({
          ...prevProperty,
          floors_of_property: property_on_the_floors || '',
        
        }));
        const facing = response.data.property_details.facing;

      // Update state with the extracted data
      setProperty(prevProperty => ({
        ...prevProperty,
        facing: facing || ''
      }));

      const  bathrooms = response.data.property_details.bathrooms;
      setProperty(prevProperty => ({
        ...prevProperty,
        bathrooms: bathrooms || ''
      }));
      const  possession_status = response.data.property_details.possession_status
      setProperty(prevProperty => ({
        ...prevProperty,
        possession_status:possession_status || ''
      }));
      const furnished_status =response.data.property_details.furnished_status
      setFurnishedStatus(furnished_status);
        setTotalFloors(response.data.property_details.total_floor);
        // setBalconyCount(value);
        const currencyData = response.data?.property_details?.currency;
            // setFromCurrency('usd');
          setAmount(currencyData[0].usd);
        setSelectedLivingRoom(response.data?.property_details?.living_room);
        setSelectedHall(response.data?.property_details?.hall);
        setSelectedKitchen(response.data?.property_details?.kitchen);
        setSelectedDiningRoom(response.data?.property_details?.dining_room);
        setNumBedrooms(response.data?.property_details?.bedroom_number);
        setFacing(response.data?.property_details?.facing);
        setSelectFloor(response.data?.property_details?.floor);
        setBathRoom(response.data?.property_details?.bathrooms);
        setSelectFloor(response.data?.property_details?.property_on_the_floors);
        setTotalFloors(response.data?.property_details?.total_floor)
        
//total_floor
      //   const possesion = response.data?.property_details?.possession_status;
      //   console.log("possesionData",possesion);

      //  setStatus(possesion);

       
       const { availability_month, availability_year } = response.data?.property_details.availability;
             console.log("data", availability_month,availability_year)
            //  if( possesion ==="under_construction"){
              setAvailabilityMonth(availability_month);
              setAvailabilityYear(availability_year);
   
            //  }
     

          
      

          const {country,state,city,address,zipcode,street} = response.data.location;
             setSelectedCountry(country);
             setSelectedState(state);
             setSelectedCity(city);
             setSearchQuery(address);
             setZipCode(zipcode);
             setStreet(street);
          // const keys = Object.keys(currencyData[0]);
          // console.log("keys", keys.toString());
          //   setFromCurrency(keys);
          
            console.log("responseValueCorner",response.data.property_details);
            
         
            const { unit, length, width } = response.data?.property_details?.area?.measurement[0]?.side_measurement || {};
            console.log("side", unit, length, width);
            setLength(length);
            setBreadth(width)
            setInputUnit(unit)
            setOutputUnit("sqft")
            setIsCornerPlot(response.data.property_details.is_corner_plot)
            setAnyConstruction(response.data.property_details.construction)
            setBoundaryWall(response.data.property_details.boundary_wall)
            setGatedColony(response.data.property_details.is_gated)
            setSelectedOpenSide(response.data.property_details.number_open_sides)
            setRoadWidth(response.data.property_details.road_facing_width)
          

      } catch (err) {
        console.error("Error fetching property details:", err.message);
        // Optionally set an error state here to handle it in the UI
      } 
      // Optionally handle final state update, e.g., setting loading to false
    };
  
    if (property_id) {
      fetchProperties();
    }
  }, [property_id]);
  
  


  const { t, i18n } = useTranslation();
  const [show, setshow] = useState(false);
const navigate = useNavigate();
//   const { id } = useParams();
//   console.log('id', id);

  const [selectedItems, setSelectedItems] = useState([]);
  const floorNo = [
    'Lowerbasement',
    'upperbasement',
    'ground',
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30
  ];
  const balconies = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
  ];
  const units = ['', 'meters', 'feet', 'yards'];
  const furnishedOptions = [
    { value: 'furnished', label: 'Furnished' },
    { value: 'unfurnished', label: 'Unfurnished' },
    { value: 'semi_furnished', label: 'Semi-Furnished' }
  ];

  const transactionTypes = [
    { value: 'under_construction', label: 'Under Construction' },
    { value: 'ready_to_move', label: 'Ready to Move' }
  ];

  const availability = {
    month: ['jan', 'feb', 'march', 'april', 'may', 'june', 'july', 'aug', 'sep', 'oct', 'nov', 'dec'],
    year: Array.from({ length: 11 }, (_, i) => 2024 + i) // Creating an array from 2024 to 2034
  };
  const [basicData, setBasicData] = useState({
    title: '',
    Agent_Name: '',
    description: '',
    OwnerName: ['Agent', 'Owner'],
    purposeOptions: ['Sell', 'Rent'],
    propertyTypeOptions: {
      Residential: ['FlatApartment', 'ResidentialHouse', 'ResidentialLandPlot', 'Villa', 'BuilderFloor'],
      Commercial: ['CommercialShop', 'CommercialLand', 'CommercialItPark', 'IndustrialLand'],
      Other: ['Agriculture', 'Farmhouse']
    },
    selectedOwner: '',
    selectedPurpose: '',
    selectedPropertyType: '',
    selectedSubType: ''
  });

  const noofbedroom = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
  ];
  const [property, setProperty] = useState({
    area: {
      super_area: {
        value: '',
        unit: ''
      },
      carpet_area: {
        value: '',
        unit: ''
      }
    },
    convertedAreas: {
      super_area: [],
      carpet_area: []
    },

    floorsofproperty: '',
    facing: '',
    bedrooms: '',
    bathrooms: '',
    unitOptions: ['sqmt', 'sqyrd', 'acre', 'sqft', 'hectare'],
    totalFloorsOptions: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    ],
    floorsOfPropertyOptions: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    ],
    facingOptions: ['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'],

    bedroomsOptions: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    ],
    bathroomsOptions: [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30
    ]
  });

  const currentLanguage = i18n.language;

  // Get the facing options based on the current language
  const facingOptions = property.facingOptions[currentLanguage] || [];

  const [basicInformation, setBasicInformation] = useState({
    country: '',
    state: '',
    city: '',
    Address: '',
    zipCode: ''
  });

  const [form2, setForm2] = useState({
    city: '',
    area: '',
    Address: '',
    zipCode: '',
    location: ''
  });

  const [position, setPosition] = useState([28.6139, 77.209]); // Default to New Delhi
  const [searchQuery, setSearchQuery] = useState('');
  const locations = useLocation();
  const pathnames = locations.pathname.split('/').filter(Boolean);
  const lastSegment = pathnames[pathnames.length - 1]; // Remove empty elements
  // for checkbox data

  /// plot and length and width and total
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [inputUnit, setInputUnit] = useState('');
  const [outputUnit, setOutputUnit] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('');

  const showToastMessage = (message, type) => {
    setToastMessage(message);
    setToastType(type);
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 3000); // Hide after 3 seconds
  };

  const handleLengthChange = (e) => {
    const { value } = e.target;

    // Regular expression to check for non-negative numbers
    const nonNegativeNumberPattern = /^(0|[1-9]\d*)?$/;

    // Check if the value matches the regular expression
    if (!nonNegativeNumberPattern.test(value)) {
      return; // Ignore the invalid value
    }
    // Regular expression to check for up to 5 digits
    const maxFiveDigitsPattern = /^\d{0,5}$/;

    // Check if the value matches the regular expression
    if (!maxFiveDigitsPattern.test(value)) {
      return; // Ignore the invalid value
    }

    // Update the state if the value is valid
    setLength(value);
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { length, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { length, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { length, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { length, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setAgriValidation((prevErrors) => {
        const { length, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleBreadthChange = (e) => {
    const { value } = e.target;

    // Regular expression to check for non-negative numbers
    const nonNegativeNumberPattern = /^(0|[1-9]\d*)?$/;

    // Check if the value matches the regular expression
    if (!nonNegativeNumberPattern.test(value)) {
      return; // Ignore the invalid value
    }
    const maxFiveDigitsPattern = /^\d{0,5}$/;

    // Check if the value matches the regular expression
    if (!maxFiveDigitsPattern.test(value)) {
      return; // Ignore the invalid value
    }

    // Update the state if the value is valid
    setBreadth(value);
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { breadth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { breadth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { breadth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { breadth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setAgriValidation((prevErrors) => {
        const { breadth, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleInputUnitChange = (e) => {
    const value = e.target.value;
    setInputUnit(value);

    // Clear error if value is selected
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setAgriValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleOutputUnitChange = (e) => {
    const value = e.target.value;
    setOutputUnit(value);

    // Clear error if value is selected
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { outputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { outputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { outputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { outputUnit, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setAgriValidation((prevErrors) => {
        const { outputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const convertToSquareMeters = (length, breadth, unit) => {
    let lengthInMeters, breadthInMeters;

    switch (unit) {
      case 'meters':
        lengthInMeters = parseFloat(length);
        breadthInMeters = parseFloat(breadth);
        break;
      case 'feet':
        lengthInMeters = parseFloat(length) * 0.3048;
        breadthInMeters = parseFloat(breadth) * 0.3048;
        break;
      case 'yards':
        lengthInMeters = parseFloat(length) * 0.9144;
        breadthInMeters = parseFloat(breadth) * 0.9144;
        break;
      default:
        lengthInMeters = 0;
        breadthInMeters = 0;
    }

    return lengthInMeters * breadthInMeters;
  };

  const areaInSquareMeters = convertToSquareMeters(length, breadth, inputUnit);
  const areaInSquareFeet = areaInSquareMeters * 10.7639;
  const areaInSquareYards = areaInSquareMeters * 1.19599;
  const areaInAcres = areaInSquareMeters / 4046.86;
  const areaInHectares = areaInSquareMeters / 10000;

  const getArea = () => {
    switch (outputUnit) {
      case 'sqmt':
        return areaInSquareMeters ? `${areaInSquareMeters.toFixed(2)} sqmt` : '0 sqmt';
      case 'sqft':
        return areaInSquareFeet ? `${areaInSquareFeet.toFixed(2)} sqft` : '0 sqft';
      case 'sqyards':
        return areaInSquareYards ? `${areaInSquareYards.toFixed(2)} sqyards` : '0 sqyards';
      case 'acres':
        return areaInAcres ? `${areaInAcres.toFixed(5)} acres` : '0 acres';
      case 'hectares':
        return areaInHectares ? `${areaInHectares.toFixed(5)} hectares` : '0 hectares';
      default:
        return '0';
    }
  };
  // after adad living kitchen and hall and dining room
  const [kitchen, setKitchen] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);
  const [selectedKitchen, setSelectedKitchen] = useState('');
  const [selectedLivingRoom, setSelectedLivingRoom] = useState('');
  const [selectedHall, setSelectedHall] = useState('');
  const [selectedDiningRoom, setSelectedDiningRoom] = useState('');

  // Handle change events
  const handleKitchenChange = (e) => {
    const value = e.target.value;
    setSelectedKitchen(value);

    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        selectedKitchen: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { selectedKitchen, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { selectedKitchen, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedKitchen, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { selectedKitchen, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleLivingRoomChange = (e) => {
    const value = e.target.value;
    setSelectedLivingRoom(value);

    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        selectedLivingRoom: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { selectedLivingRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { selectedLivingRoom, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedLivingRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedLivingRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { selectedLivingRoom, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleHallChange = (e) => {
    const value = e.target.value;
    setSelectedHall(value);

    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        selectedHall: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { selectedHall, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { selectedHall, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedHall, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { selectedHall, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleDiningRoomChange = (e) => {
    const value = e.target.value;
    setSelectedDiningRoom(value);

    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        selectedDiningRoom: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { selectedDiningRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { selectedDiningRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedDiningRoom, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { selectedDiningRoom, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleTotalFloors = (e) => {
    const newValue = e.target.value;
    setTotalFloors(newValue);
    if (newValue) {
      setValidationMessagesFlat((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }

    if (newValue) {
      setVillaValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setBuilderValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setCommShopValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setCommLandValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setCommITValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setCommIndusValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }

    if (newValue) {
      setFarmHouseValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setValidationMessageHouse((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  // State to hold the completion percentage
  const [completion, setCompletion] = useState(0);
  const [completion1, setCompletion1] = useState(0);
  const [completion2, setCompletion2] = useState(0);
  const [showpercentage, setShowPercentage] = useState(0);
  const [showpercentageone, setShowPercentageOne] = useState(0);
  const [showpercentagetwo, setShowPercentageTwo] = useState(0);
  const [propertyPer, setPropertyPer] = useState(0);
  const [one2, setOne2] = useState(0);
  const [one3, setOne3] = useState(0);
  const [one4, setOne4] = useState(0);
  const [country, setCountry] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [plotAreaUnit, setPlotAreaUnit] = useState('');
  const [plotLength, setPlotLength] = useState('');
  const [plotBreadth, setPlotBreadth] = useState('');
  const [totalSize, setTotalSize] = useState('');
  const [selectedOptiones, setSelectedOptiones] = useState();
  const [selectedUnit, setSelectedUnit] = useState('Sq-m');
  const [plotWidth, setPlotWidth] = useState('');
  const [anyConstruction, setAnyConstruction] = useState('');

  // Handle input change
  const unitToSqM = {
    'Sq-ft': 0.092903,
    'Sq-yrd': 0.836127,
    'Sq-m': 1,
    Acre: 4046.86,

    Hectare: 10000,
    Sqkm: 1000000
  };
  const option = ['industrial', 'commercial', 'open space', 'agriculture', 'commercial', 'residential'];
  const handleWidthChange = (event) => {
    setPlotWidth(event.target.value);
  };

  // for amenties functionlity

  const transformAmenities = (selectedType) => {
    return amenity[selectedType] || {};
  };

  useEffect(() => {
    if (basicData.selectedSubType) {
      const data = transformAmenities(basicData.selectedSubType);
      setOneData({ amenities: data });
    }
  }, [basicData.selectedSubType]);

  const handleAmenityChange = (e, category, amenityName) => {
    const { checked } = e.target;
    setOneData((prev) => {
      const updatedAmenities = {
        ...prev.amenities,
        [category]: prev.amenities[category].map((item) => (item.name === amenityName ? { ...item, checked } : item))
      };
      return { ...prev, amenities: updatedAmenities };
    });
  };

  const handleAmenityChanges = (e, category, amenityName) => {
    const checked = e
      ? e.target.checked
      : !oneData.amenities[category]?.find((item) => item.name === amenityName)?.checked;
    setOneData((prev) => {
      const updatedAmenities = {
        ...prev.amenities,
        [category]: prev.amenities[category].map((item) => (item.name === amenityName ? { ...item, checked } : item))
      };
      return { ...prev, amenities: updatedAmenities };
    });
  };

  console.log('percntageValueData', oneData.overallPercentage);

  const generateJsonData = () => {
    const jsonData = { amenities: {} };

    Object.keys(oneData.amenities || {}).forEach((category) => {
      const categoryData = {};

      oneData.amenities[category].forEach((item) => {
        if (item.checked) {
          categoryData[item.name.toLowerCase()] = true;
        }
      });

      if (Object.keys(categoryData).length > 0) {
        jsonData.amenities[category] = categoryData;
      }
    });

    return jsonData;
  };

  useEffect(() => {
    let jsonItems = generateJsonData();
    setJsonAmenities(jsonItems);
    console.log('jsondatanew', jsonItems);
  }, [oneData]);

  const convertArea = (value, fromUnit, toUnit) => {
    const conversionRates = {
      sqmt: 1,
      sqyrd: 1.19599,
      acre: 0.000247105,
      sqft: 10.7639,
      hectare: 0.0001
    };

    if (!conversionRates[fromUnit] || !conversionRates[toUnit]) {
      throw new Error('Invalid unit provided for conversion.');
    }

    const valueInSqmt = value / conversionRates[fromUnit]; // Convert to square meters
    const convertedValue = valueInSqmt * conversionRates[toUnit]; // Convert to target unit

    return convertedValue;
  };

  const handleAreaChange = (e, areaType) => {
    const { name, value } = e.target;

    // Check if the value is valid (up to 6 digits)
    const isValueValid = /^\d{0,6}$/.test(value);

    // Check if the value must be greater than zero
    const isValueGreaterThanZero = parseFloat(value) > 0;

    // Update the property state
    setProperty((prevProperty) => {
      const updatedProperty = {
        ...prevProperty,
        area: {
          ...prevProperty.area,
          [areaType]: {
            ...prevProperty.area[areaType],
            [name === areaType ? 'value' : 'unit']: value
          }
        }
      };

      // Update converted areas if needed
      updateConvertedAreas(updatedProperty.area[areaType].value, updatedProperty.area[areaType].unit, areaType);
      return updatedProperty;
    });

    // Clear validation messages for this field
    setValidationMessagesFlat((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          // Assuming unit field has no specific value validation, just clear errors
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    setVillaValidation((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    // Repeat similar logic for other validation states
    setBuilderValidation((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    setCommShopValidation((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    setCommITValidation((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    setFarmHouseValidation((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });

    setValidationMessageHouse((prevErrors) => {
      const newErrors = { ...prevErrors };

      if (name === 'super_area' || name === 'unit_super_area') {
        if (name === 'super_area') {
          if (isValueValid) {
            if (isValueGreaterThanZero) {
              delete newErrors['property.area.super_area.value'];
            } else {
              newErrors['property.area.super_area.value'] = 'Value must be greater than zero.';
            }
          } else {
            newErrors['property.area.super_area.value'] = 'Value must be up to 6 digits.';
          }
        }

        if (name === 'unit_super_area') {
          delete newErrors['property.area.super_area.unit'];
        }
      }

      return newErrors;
    });
  };

  const updateConvertedAreas = (value, unit, areaType) => {
    if (!value || !unit) return;
    const newConvertedAreas = property.unitOptions.map((option) => ({
      [option]: Math.round(convertArea(parseFloat(value), unit, option) * 100) / 100
    }));
    setProperty((prevProperty) => ({
      ...prevProperty,
      convertedAreas: {
        ...prevProperty.convertedAreas,
        [areaType]: newConvertedAreas
      }
    }));
  };

  useEffect(() => {
    if (property.area.super_area.value && property.area.super_area.unit) {
      updateConvertedAreas(property.area.super_area.value, property.area.super_area.unit, 'super_area');
    }
  }, [property.area.super_area.value, property.area.super_area.unit]);

  // street

  const handleStreetChange = (event) => {
    const value = event.target.value;
    setStreet(value);
    if (value) {
      setLocationValidation((prevErrors) => {
        const { street, ...rest } = prevErrors;
        return rest; // Remove the state error from the validation state
      });
    }
  };

  useEffect(() => {
    const totalFields = Object.keys(form2).length;
    const filledFields = Object.values(form2).filter((value) => value !== '').length;
    const completionPercentage2 = Math.round((filledFields / totalFields) * 25);
    const completionPercentage3 = Math.round((filledFields / totalFields) * 100);
    setShowPercentageTwo(completionPercentage3);
    setCompletion2(completionPercentage2);
  }, [form2]);

  useEffect(() => {
    fetch('https://square-bric-backend-api.squarebric.com/country-state-sub-city')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setCountry(data);
        console.log('this is country', data);
      })
      .catch((error) => {
        console.error('Error fetching country data:', error);
      });
  }, []);

  useEffect(() => {
    if (selectedCity) {
      searchLocation(selectedCity);
    }
  }, [selectedCity]);

  const handleCountryChange = (event) => {
    const newValue = event.target.value;
    setSelectedCountry(newValue);
    setSelectedState(''); // Clear state and city when country changes
    setSelectedCity('');

    // Clear the specific error for country if a valid value is chosen
    if (newValue) {
      setLocationValidation((prevErrors) => {
        const { selectedCountry, ...rest } = prevErrors;
        return rest; // Remove the country error from the validation state
      });
    }
  };

  const handleSelectionChanged = (event, setState, setError) => {
    const { value } = event.target;
    setState(value);
    setError('');
  };
  const handleStateChange = (event) => {
    const newValue = event.target.value;
    setSelectedState(newValue);
    setSelectedCity(''); // Clear city when state changes

    if (newValue) {
      setLocationValidation((prevErrors) => {
        const { selectedState, ...rest } = prevErrors;
        return rest; // Remove the state error from the validation state
      });
    }
  };

  const handleCityChange = (event) => {
    const newValue = event.target.value;
    setSelectedCity(newValue);

    if (newValue) {
      setLocationValidation((prevErrors) => {
        const { selectedCity, ...rest } = prevErrors;
        return rest; // Remove the city error from the validation state
      });
    }
  };

  const handleSelectionChange = (event) => {
    const value = event.target.value;
    setIsCornerPlot(value);
    setIsCornerPlotError(''); // Clear error when selection is made
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { isCornerPlot, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  console.log('cornerplot', isCornerPlot);

  const handleOptionChanging = (event) => {
    const newValue = event.target.value;
    setSelectedOptioning(newValue);
    setLeasError(''); // Clear error when selection is made

    if (newValue) {
      setCommLandValidation((prevErrors) => {
        const { selectedOptioning, ...rest } = prevErrors;
        return rest;
      });
    }
    if (newValue) {
      setAgriValidation((prevErrors) => {
        const { selectedOptioning, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  //    show top to bottom
  const handleClick = (id) => {
    TopToBottom(id);
  };
  const TopToBottom = (current) => {
    if (current === 0) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    if (current === 1) {
      window.scrollTo({
        top: 970,
        behavior: 'smooth'
      });
    }
    if (current === 2) {
      window.scrollTo({
        top: 1550,
        behavior: 'smooth'
      });
    }
    if (current === 3) {
      window.scrollTo({
        top: 2000,
        behavior: 'smooth'
      });
    }
  };
  // for right side data
  const getProgressColor = (percentage) => {
    if (percentage >= 0 && percentage <= 30) {
      return 'bg-yellow-500';
    } else if (percentage > 30 && percentage <= 50) {
      return 'bg-orange-500';
    } else if (percentage > 50 && percentage <= 75) {
      return 'bg-pink-500';
    } else if (percentage > 75 && percentage <= 100) {
      return 'bg-green-500';
    } else {
      return ''; // Default color if percentage is out of range
    }
  };

  // all data of propert details and basic information
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBasicData({ ...basicData, [name]: value });
    validateField(name, value);
  };
  // 2
  const handlePurposeChange = (e) => {
    setBasicData({ ...basicData, selectedPurpose: e.target.value });
    validateField('selectedPurpose', e.target.value);
  };
  const handleOwner = (e) => {
    setBasicData({ ...basicData, selectedOwner: e.target.value });
    validateField('selectedOwner', e.target.value);
  };
  //3
  const handlePropertyTypeChange = (e) => {
    setBasicData({
      ...basicData,
      selectedPropertyType: e.target.value,
      selectedSubType: ''
    });
    validateField('selectedPropertyType', e.target.value);
  };
  // validation for every componenets

  // 4 1,2,3,4 for basic information
  const handleSubTypeChange = (e) => {
    setBasicData({ ...basicData, selectedSubType: e.target.value });
    setSelectedPropertyType({ ...basicData, selectedSubType: e.target.value });
    // console.log('datachange', selectedPropertyType);
    validateField('selectedSubType', e.target.value);

    setProperty((prevProperty) => ({
      ...prevProperty,
      area: {
        ...prevProperty.area,
        super_area: {
          ...prevProperty.area.super_area,
          value: ''
        }
      }
    }));

    setProperty((prevProperty) => ({
      ...prevProperty,
      floors_of_property: {
        ...prevProperty.floors_of_property,
        value: ''
      }
    }));
    setProperty((prevProperty) => ({
      ...prevProperty,
      bathrooms: {
        ...prevProperty.bathrooms,
        value: ''
      }
    }));
    setProperty((prevProperty) => ({
      ...prevProperty,
      facing: {
        ...prevProperty.facing,
        value: ''
      }
    }));

    setTotalFloors('');
    setSelectedLivingRoom('');
    setSelectedHall('');

    setSelectedKitchen('');

    setSelectedDiningRoom('');
    setNumBedrooms('');
    setBalconyCount('');
    setAvailabilityMonth('');
    setAvailabilityYear('');
    setFromCurrency('');
    setAmount('');
    setLength('');
    setBreadth('');
    setRoadWidth('');
    setSelectedOpenSide('');
    setIsCornerPlot('');
    setAnyConstruction('');

    setGatedColony('');
    setSelectedOption('');
  };

  const handleDirectionChange=(e)=>{
    const value=e.target.value;
 setFacing(value)
 if (value) {
  setValidationMessagesFlat((prevErrors) => ({
    ...prevErrors,
    facing: ''
  }));
}
  }

  const handleChnageFloor=(e)=>{
    const value=e.target.value
    setSelectFloor(value)
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        selectFloor: ''
      }));
    }
  }
const handleBathroomChange=(e)=>{
  const value=e.target.value;
  setBathRoom(value) 
  if (value) {
    setValidationMessagesFlat((prevErrors) => ({
      ...prevErrors,
      bathroom: ''
    }));
  }

}


  const handleDropdownChange = (e) => {
    // const { name, value } = e.target;
    // setProperty({ ...property, [name]: value });
    const { name, value } = e.target;
    setProperty((prevProperty) => ({
      ...prevProperty,
      [name]: value
    }));
    if (value) {
      setValidationMessagesFlat((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { bathrooms, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { facing, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { bathrooms, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { facing, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { totalFloors, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
    if (value) {
      setFarmHouseValidation((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
    if (value) {
      setValidationMessageHouse((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
    if (value) {
      setValidationMessageHouse((prev) => ({
        ...prev,
        [name]: ''
      }));
    }

    if (value) {
      setValidationMessageHouse((prev) => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  // curency calculator
  const exchangeRates = {
    INR: { inr: 1, USD: 0.013, pound: 0.011, euro: 0.012, riyal: 0.048 },
    USD: { usd: 1, inr: 75.0, pound: 0.74, euro: 0.85, riyal: 3.75 },
    POUND: { pound: 1, inr: 100.0, usd: 1.36, euro: 1.17, riyal: 5.1 },
    EURO: { euro: 1, inr: 90.0, usd: 1.18, pound: 0.85, riyal: 4.3 },
    RIYAL: { riyal: 1, inr: 20.0, usd: 0.27, pound: 0.2, euro: 0.23 }
  };

  useEffect(() => {
    if (amount && amount > 0 && fromCurrency) {
      convertCurrency();
    } else {
      setConvertedValues({});
    }
  }, [amount, fromCurrency]);

  const convertCurrency = () => {
    const rates = exchangeRates[fromCurrency];
    console.log('rates', rates);
    const conversions = {};
    for (const [currency, rate] of Object.entries(rates)) {
      conversions[currency] = (amount * rate).toFixed(2);
    }

    setConvertedValues(conversions);
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    setAmount(value);

    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        amount: ''
      }));
    }
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommShopValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setAgriValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { amount, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleCurrencyChange = (e) => {
    const value = e.target.value;
    setFromCurrency(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        fromCurrency: ''
      }));
    }
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setAgriValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { fromCurrency, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  // console.log('area', property.area);
  // for locations
  const handleInputChange2 = (event) => {
    const { name, value } = event.target;
    setBasicInformation({
      ...basicInformation,
      [name]: value
    });

    // Reset dependent fields
    if (name === 'country') {
      setBasicInformation({
        ...basicInformation,
        country: value,
        state: '',
        city: ''
      });
    } else if (name === 'state') {
      setBasicInformation({
        ...basicInformation,
        state: value,
        city: ''
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { inputUnit, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const provider = new OpenStreetMapProvider();
  const searchLocation = async (query) => {
    try {
      const results = await provider.search({ query });
      if (results && results.length > 0) {
        const { x, y, label } = results[0];
        setPosition([y, x]);
        setSearchQuery(label);
        fetchZipCode(y, x);
        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  const fetchZipCode = async (lat, lon) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`);
      const data = await response.json();
      if (data && data.address) {
        setZipCode(data.address.postcode || '');
        setSearchQuery(data.display_name || '');
        setError(null);
      } else {
        setError('Location not found');
      }
    } catch (err) {
      setError('Error fetching location');
    }
  };

  const handleMarkerDragEnd = async (event) => {
    const marker = event.target;
    const newPosition = marker.getLatLng();
    setPosition([newPosition.lat, newPosition.lng]);
    fetchZipCode(newPosition.lat, newPosition.lng);
  };

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearchQuery(newValue);
    if (newValue) {
      setLocationValidation((prevErrors) => {
        const { searchQuery, ...rest } = prevErrors;
        return rest; // Remove the state error from the validation state
      });
    }
  };
  const handleKeyDowns = (e) => {
    // const newValue =e.target.value;

    if (e.key === 'Enter') {
      e.preventDefault();
      searchLocation(searchQuery); // Prevent the default action (e.g., form submission)
      // handleSearchSubmit(); // Call the search function
    }
  };

  const handleSearchClick = () => {
    searchLocation(searchQuery);
  };
  const handleStatusChange = (event) => {
    const value = event.target.value;
    setStatus(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        status: ''
      }));
    }

    if (value) {
      setVillaValidation((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setBuilderValidation((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { status, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleFurnishedStatusChange = (event) => {
    const value = event.target.value;
    setFurnishedStatus(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        furnishedStatus: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { furnishedStatus, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const options = furnishedOptions[currentLanguage] || [];

  const handleBalconyChange = (event) => {
    const value = event.target.value;
    setBalconyCount(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        balconyCount: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { balconyCount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { balconyCount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { balconyCount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { balconyCount, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { balconyCount, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleAvailabilityMonthChange = (event) => {
    const value = event.target.value;
    setAvailabilityMonth(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        availabilityMonth: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { availabilityMonth, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleAvailabilityYearChange = (event) => {
    const value = event.target.value;
    setAvailabilityYear(value);
    if (value) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        availabilityYear: ''
      }));
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setBuilderValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommShopValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommITValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setValidationMessageHouse((prevErrors) => {
        const { availabilityYear, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleFloorNumberChange = (event) => {
    setFloorNumber(event.target.value);
  };
  const handleBedroomChanges = (event) => {
    setBed(event.target.value);
  };
  // agriculture
  const handleOptionChangees = (e) => {
    const newValue = e.target.value;
    setSelectedOptiones(newValue);
    setLeasError('');
    if (newValue) {
      setCommITValidation((prevErrors) => {
        const { selectedOptiones, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const handleOptionChanges = (e) => {
    const value = e.target.value;
    setSelectedOptions(value);
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { selectedOptions, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { selectedOptions, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { selectedOptions, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setAgriValidation((prevErrors) => {
        const { selectedOptions, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setAgriValidation((prevErrors) => {
        const { selectedOptions, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
  };
  // commercial land
  const handleSelects = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value) {
      setCommLandValidation((prevErrors) => {
        const { selectedOption, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommITValidation((prevErrors) => {
        const { selectedOption, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleGatedColonyChange = (event) => {
    setGatedColony(event.target.value === 'yes');
  };
  const handleBoundaryWallChange = (event) => {
    setBoundaryWall(event.target.value === 'yes');
  };
  const handleAnyConstructionChange = (event) => {
    const value = event.target.value;
    setAnyConstruction(value);
    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { anyConstruction, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { anyConstruction, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const nearbyBusinessOptions = ['Agriculture Related', 'Pets Store', 'Bouquet', 'Hair Salon', 'Nursery'];
  const handleSelect = (e) => {
    const newValue = e.target.value;
    setSelectedBusiness(newValue);

    if (newValue) {
      setCommITValidation((prevErrors) => {
        const { selectedBusiness, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const [formData, setFormData] = useState({
    isCornerShop: '',
    isMainRoadFacing: '',
    hasPersonalWashroom: '',
    pantryType: ''
  });
  const handleInputChanged = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    if (value) {
      setCommITValidation((prevErrors) => {
        const { pantryType, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const [selectedValue, setSelectedValue] = useState('');
  const handleChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    if (newValue) {
      setCommITValidation((prevErrors) => {
        const { selectedValue, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleOpenSideChange = (event) => {
    const value = event.target.value;
    setSelectedOpenSide(value);

    if (value) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setVillaValidation((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommLandValidation((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }

    if (value) {
      setCommIndusValidation((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setAgriValidation((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }
    if (value) {
      setFarmHouseValidation((prevErrors) => {
        const { selectedOpenSide, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  const checkedItems = selectedItems.filter((item) => item.checked);

  // Construct the amenities object
  const amenities = {
    facilities: checkedItems
  };

  // Log the name and checked status of each item
  checkedItems.forEach((item) => console.log(item.name, item.checked));
  const currencyStringss = Object.keys(convertedValues).map(([currency, value]) => {
    return `${value} ${currency}`;
  });

  // no of bedrooms
  const handleNumBedroomsChange = (event) => {
    const num = event.target.value;

    setNumBedrooms(num);

    if (num) {
      setValidationMessagesFlat((prevErrors) => ({
        ...prevErrors,
        bedrooms: ''
      }));
    }
    if (num) {
      setVillaValidation((prevErrors) => {
        const { numBedrooms, ...rest } = prevErrors;
        return rest;
      });
    }
    if (num) {
      setBuilderValidation((prevErrors) => {
        const { numBedrooms, ...rest } = prevErrors;
        return rest;
      });
    }
    if (num) {
      setFarmHouseValidation((prevErrors) => {
        const { numBedrooms, ...rest } = prevErrors;
        return rest;
      });
    }
    if (num) {
      setValidationMessageHouse((prevErrors) => {
        const { numBedrooms, ...rest } = prevErrors;
        return rest;
      });
    }
  };

  // showing percentage of basic information
  const totalFields = 7;

  const calculateCompletionPercentage = () => {
    let filledFields = 0;

    if (basicData.title) filledFields++;
    if (basicData.description) filledFields++;
    if (basicData.Agent_Name) filledFields++;
    if (basicData.selectedOwner) filledFields++;
    if (basicData.selectedPurpose) filledFields++;
    if (basicData.selectedPropertyType) filledFields++;
    if (basicData.selectedSubType) filledFields++;

    return (filledFields / totalFields) * 100;
  };

  useEffect(() => {
    let basicdatas = Math.round(calculateCompletionPercentage());

    setShowPercentage(basicdatas);
  }, [basicData]);

  const totalField2s = 6;

  const calculateCompletionPercentage2 = () => {
    let filledFields = 0;

    if (basicData.title) filledFields++;
    if (basicData.description) filledFields++;
    if (basicData.Agent_Name) filledFields++;
    if (basicData.selectedOwner) filledFields++;
    if (basicData.selectedPurpose) filledFields++;
    if (basicData.selectedPropertyType) filledFields++;
    if (basicData.selectedSubType) filledFields++;

    return (filledFields / totalField2s) * 25;
  };

  useEffect(() => {
    let basicdatas = Math.round(calculateCompletionPercentage2());

    setOne2(basicdatas);
  }, [basicData]);
  // locations details

  const calculateCompletionPercentages = () => {
    const totalFieldss = 6;
    let filledFields = 0;

    if (selectedCountry) filledFields++;
    if (selectedState) filledFields++;
    if (selectedCity) filledFields++;
    if (basicInformation.Address) filledFields++;
    if (basicInformation.zipCode) filledFields++;
    if (searchQuery) filledFields++;
    if (street) filledFields++;

    return (filledFields / totalFieldss) * 100;
  };
  useEffect(() => {
    let locationDatas = Math.round(calculateCompletionPercentages());

    setShowPercentageOne(locationDatas);
  }, [basicInformation, searchQuery, selectedCountry, selectedCity, selectedState, street]);
  const calculateCompletionPercentages3 = () => {
    const totalFieldss3 = 7;
    let filledFields = 0;

    if (selectedCountry) filledFields++;
    if (selectedState) filledFields++;
    if (selectedCity) filledFields++;
    if (searchQuery) filledFields++;
    if (zipCode) filledFields++;
    if (searchQuery) filledFields++;
    if (street) filledFields++;

    return (filledFields / totalFieldss3) * 25;
  };
  useEffect(() => {
    let locationDatas = Math.round(calculateCompletionPercentages3());

    setOne3(locationDatas);
  }, [basicInformation, searchQuery, selectedCountry, selectedState, selectedCity, street]);
  // property details percentages

  const PropertDetailsPercentage = () => {
    let totals = 34;
    let filledFields = 0;

    // Check each property field and increment filledFields if they are not null or undefined

    if (selectedLivingRoom) filledFields++;
    if (selectedKitchen) filledFields++;
    if (selectedDiningRoom) filledFields++;
    if (selectedHall) filledFields++;
    if (property.area.super_area.value) filledFields++;
    if (property.area.super_area.unit) filledFields++;
    if (property.total_floors) filledFields++;
    if (property.floors_of_property) filledFields++;
    if (property.facing) filledFields++;
    if (property.bathrooms) filledFields++;
    if (furnishedStatus) filledFields++;
    if (status) filledFields++;
    if (balconyCount) filledFields++;
    if (availabilityMonth) filledFields++;
    if (availabilityYear) filledFields++;
    if (floorNumber) filledFields++;
    if (fromCurrency) filledFields++;
    if (amount) filledFields++;
    if (numBedrooms) filledFields++;
    if (totalFloors) filledFields++;
    if (plotLength) filledFields++;
    if (plotBreadth) filledFields++;
    if (roadWidth) filledFields++;
    if (length) filledFields++;
    if (breadth) filledFields++;
    if (inputUnit) filledFields++;
    if (outputUnit) filledFields++;
    if (isCornerPlot) filledFields++;
    if (selectedOpenSide) filledFields++;

    if (selectedOptions) filledFields++;
    if (gatedColony) filledFields++;
    if (anyConstruction) filledFields++;
    return (filledFields / totals) * 100;
  };

  useEffect(() => {
    let propertyData = Math.round(PropertDetailsPercentage());

    setPropertyPer(propertyData);
  }, [
    selectedOptions,
    gatedColony,
    anyConstruction,
    property,
    availability,
    amount,
    fromCurrency,
    balconies,
    transactionTypes,
    floorNo,
    furnishedOptions,
    numBedrooms,
    length,
    breadth,
    inputUnit,
    outputUnit,
    totalFloors,
    selectedLivingRoom,
    selectedKitchen,
    selectedDiningRoom,
    selectedHall,
    isCornerPlot,
    roadWidth,
    selectedOpenSide
  ]);

  const PropertDetailsPercentage4 = () => {
    let totals = 31;
    let filledFields = 0;

    if (selectedLivingRoom) filledFields++;
    if (selectedKitchen) filledFields++;
    if (selectedDiningRoom) filledFields++;
    if (selectedHall) filledFields++;
    if (property.area.super_area.value) filledFields++;
    if (property.area.super_area.unit) filledFields++;
    if (property.total_floors) filledFields++;
    if (property.floors_of_property) filledFields++;
    if (property.facing) filledFields++;
    if (property.bathrooms) filledFields++;
    if (furnishedStatus) filledFields++;
    if (status) filledFields++;
    if (balconyCount) filledFields++;
    if (availabilityMonth) filledFields++;
    if (availabilityYear) filledFields++;
    if (floorNumber) filledFields++;
    if (totalFloors) filledFields++;
    if (amount) filledFields++;
    if (numBedrooms) filledFields++;
    if (plotWidth) filledFields++;
    if (amount) filledFields++;
    if (fromCurrency) filledFields++;
    if (length) filledFields++;
    if (breadth) filledFields++;
    if (inputUnit) filledFields++;
    if (outputUnit) filledFields++;
    if (roadWidth) filledFields++;
    if (isCornerPlot) filledFields++;
    if (selectedOpenSide) filledFields++;

    return (filledFields / totals) * 25;
  };
  useEffect(() => {
    let propertyData = Math.round(PropertDetailsPercentage4());

    setOne4(propertyData);
  }, [
    property,
    availability,
    amount,
    fromCurrency,
    balconies,
    transactionTypes,
    floorNo,
    furnishedOptions,
    numBedrooms,
    bedrooms,
    length,
    breadth,
    inputUnit,
    outputUnit,
    totalFloors,
    selectedLivingRoom,
    selectedKitchen,
    selectedDiningRoom,
    selectedHall,
    roadWidth,
    isCornerPlot,
    selectedOpenSide,
    selectedOptions,
    gatedColony,
    anyConstruction
  ]);
  let finaloutput = 0;
  finaloutput = one2 + one3 + one4;

  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const progress = circumference - (finaloutput / 100) * circumference;

  // amenties

  const items = [
    { id: 0, label: 'Basic Information', percentage: showpercentage },
    { id: 1, label: 'Property Details', percentage: propertyPer },
    { id: 2, label: 'Location', percentage: showpercentageone }
  ];

  // plot total area in units for jsonData
  const convertToSquareMeterss = (length, breadth, unit) => {
    let lengthInMeters, breadthInMeters;

    switch (unit) {
      case 'meters':
        lengthInMeters = parseFloat(length);
        breadthInMeters = parseFloat(breadth);
        break;
      case 'feet':
        lengthInMeters = parseFloat(length) * 0.3048;
        breadthInMeters = parseFloat(breadth) * 0.3048;
        break;
      case 'yards':
        lengthInMeters = parseFloat(length) * 0.9144;
        breadthInMeters = parseFloat(breadth) * 0.9144;
        break;
      default:
        lengthInMeters = 0;
        breadthInMeters = 0;
    }

    return lengthInMeters * breadthInMeters;
  };

  const areaInSquareMeter = convertToSquareMeterss(length, breadth, inputUnit);
  const Feet = areaInSquareMeter * 10.7639;
  const Yards = areaInSquareMeter * 1.19599;
  const Acres = areaInSquareMeter / 4046.86;
  const Hectares = areaInSquareMeter / 10000;

  const convertedArray = Object.entries(convertedValues).map(([currency_name, amount]) => ({
    [currency_name]: parseFloat(amount)
  }));

  // Example of updating the state
  let commITPark_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    total_floor: totalFloors,

    nearby_businesses: selectedBusiness,
    currently_leasedOut: selectedOptiones,
    pantry_caferia: formData.pantryType,
    bathrooms: bathroom,

    land_zone: selectedOption,
    possesion_status: status,
    furnished_status: furnishedStatus,

    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };
  let commLand_Data = {
    area: {
      measurement: [
        {
          side_measurement: {
            unit: inputUnit,
            length: length,
            width: breadth
          }
        }
      ],
      total_area: [
        {
          sqft: Feet
        },

        { sqmt: areaInSquareMeter },

        { yard: Yards },

        { acer: Acres },
        { hectare: Hectares }
      ]
    },
    land_Zone: selectedOption,
    construction_floor: totalFloors,
    road_facing_width: roadWidth,
    number_open_sides: selectedOpenSide,
    availability: {
      availability_month: availabilityMonth,
      availability_year: availabilityYear
    },
    currently_leasedOut: selectedOptiones,

    boundry_wall: selectedOptions,

    currency: convertedArray
  };
  let IndsLand_Data = {
    area: {
      measurement: [
        {
          side_measurement: {
            unit: inputUnit,
            length: length,
            width: breadth
          }
        }
      ],
      total_area: [
        {
          sqft: Feet
        },
        { sqmt: areaInSquareMeter },
        { yard: Yards },

        { acer: Acres },
        { hectare: Hectares }
      ]
    },

    construction_floors: totalFloors,
    road_facing_width: roadWidth,
    number_open_sides: selectedOpenSide,

    construction: selectedOptiones,
    boundry_wall: selectedOptions,

    availability: {
      availability_month: availabilityMonth,
      availability_year: availabilityYear
    },
    currency: convertedArray
  };
  let agri_Data = {
    area: {
      measurement: [
        {
          side_measurement: {
            unit: inputUnit,
            length: length,
            width: breadth
          }
        }
      ],
      total_area: [
        {
          sqft: Feet
        },
        { sqmt: areaInSquareMeter },
        { yard: Yards },

        { acer: Acres },
        { hectare: Hectares }
      ]
    },

    road_facing_width: roadWidth,
    number_open_sides: selectedOpenSide,

    currently_leasedOut: selectedOptiones,

    boundry_wall: selectedOptions,

    currency: convertedArray
  };
  let commshop_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    total_floor: totalFloors,
    property_on_the_floors:selectFloor,
    facing:facing,
    bathrooms: bathroom,

    possesion_status: status,
    furnished_status: furnishedStatus,
    balcony_count: balconyCount,

    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };
  let villa_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        },
        {
          side_measurement: {
            unit: inputUnit,
            length: length,
            width: breadth
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    road_facing_width: roadWidth,
    number_open_sides: selectedOpenSide,
    total_floor: totalFloors,

    facing:facing,
    bathrooms:bathroom,
    bedroom_number: numBedrooms,

    possesion_status: status,
    furnished_status: furnishedStatus,
    balcony_count: balconyCount,

    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };

  let flat_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    totalNo_of_floors:totalFloors,
    property_on_the_floors:selectFloor,
    facing:facing,
    bathrooms:bathroom,
    bedroom_number: numBedrooms,
    kitchen: selectedKitchen,
    living_room: selectedLivingRoom,
    hall: selectedHall,
    dining_room: selectedDiningRoom,

    possesion_status: status,
    furnished_status: furnishedStatus,

    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };

  let farmhouse_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    total_floor:totalFloors,
    property_on_the_floors:selectFloor,

    bathrooms:bathroom,
    balcony_count: balconyCount,
    bedroom_number: numBedrooms,

    possesion_status: status,
    furnished_status: furnishedStatus,
    number_open_sides: selectedOpenSide,
    road_facing_width: roadWidth,
    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };

  let resLand_Data = {
    area: {
      measurement: [
        {
          side_measurement: {
            unit: inputUnit,
            length: length,
            width: breadth
          }
        }
      ],
      total_area: [
        {
          sqft: Feet
        },
        { sqmt: areaInSquareMeter },
        { yard: Yards },

        { acer: Acres },
        { hectare: Hectares }
      ]
    },

    road_facing_width: roadWidth,
    number_open_sides: selectedOpenSide,
    is_corner_plot: isCornerPlot,
    construction: anyConstruction,
    boundary_wall: selectedOptions,
    is_gated: gatedColony,

    currency: convertedArray
  };
  let builder_Data = {
    area: {
      measurement: [
        {
          super_area: {
            value: property.area.super_area.value,
            unit: property.area.super_area.unit
          }
        }
      ],
      total_area: property.convertedAreas.super_area
    },

    total_floor:totalFloors,

    facing:facing,
    bathrooms:bathroom,
    bedroom_number:numBedrooms,

    possesion_status:status,
    furnished_status:furnishedStatus,
    balcony_count: balconyCount,

    ...(status === 'under_construction' && {
      availability: {
        availability_month: availabilityMonth,
        availability_year: availabilityYear
      }
    }),
    currency: convertedArray
  };

  function pankaj() {
    let selectedPropertyData = {};

    if (basicData.selectedSubType === 'ResidentialLandPlot') {
      selectedPropertyData = resLand_Data;
    } else if (basicData.selectedSubType === 'Villa') {
      selectedPropertyData = villa_Data;
    } else if (basicData.selectedSubType === 'BuilderFloor') {
      selectedPropertyData = builder_Data;
    } else if (basicData.selectedSubType === 'FlatApartment') {
      selectedPropertyData = flat_Data;
    } else if (basicData.selectedSubType === 'ResidentialHouse') {
      selectedPropertyData = flat_Data;
    } else if (basicData.selectedSubType === 'CommercialShop') {
      selectedPropertyData = commshop_Data;
    } else if (basicData.selectedSubType === 'CommercialLand') {
      selectedPropertyData = commLand_Data;
    } else if (basicData.selectedSubType === 'CommercialItPark') {
      selectedPropertyData = commITPark_Data;
    } else if (basicData.selectedSubType === 'IndustrialLand') {
      selectedPropertyData = IndsLand_Data;
    } else if (basicData.selectedSubType === 'Agriculture') {
      selectedPropertyData = agri_Data;
    } else if (basicData.selectedSubType === 'Farmhouse') {
      selectedPropertyData = farmhouse_Data;
    }

    const jsonData = {
      property_details: {
        title: basicData.title,
        agent_name: basicData.Agent_Name,
        description: basicData.description,
        owner_type: basicData.selectedOwner,
        purpose: basicData.selectedPurpose,
        property_type: basicData.selectedPropertyType,
        sub_property_type: basicData.selectedSubType,
        ...selectedPropertyData
      },
      location: {
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
        address: searchQuery,
        zipcode: zipCode,
        location: searchQuery,
        street: street,
        geo_location: {
          Lat: position[0],
          Long: position[1]
        }
      },
      ...jsonAmenities,
      score: finaloutput
    };

    // console.log(jsonData);
    return jsonData;
  }

  const handleRoadWidthChange = (event) => {
    const input = event.target.value;
    const maxFiveDigitsPattern = /^\d{0,5}$/;

    // Check if the value matches the regular expression
    if (!maxFiveDigitsPattern.test(input)) {
      return; // Ignore the invalid value
    }

    if (!isNaN(input) || input === '') {
      setRoadWidth(input);
    }
    if (input) {
      setValidationMessagesResiLandPlot((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (input) {
      setVillaValidation((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (input) {
      setCommLandValidation((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (input) {
      setCommIndusValidation((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (input) {
      setAgriValidation((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
    if (input) {
      setFarmHouseValidation((prevErrors) => {
        const { roadWidth, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  // villa

  useEffect(() => {
    const length = parseFloat(plotLength);
    const breadth = parseFloat(plotBreadth);
    if (!isNaN(length) && !isNaN(breadth)) {
      let size = length * breadth;
      // Convert to the selected unit if necessary
      if (plotAreaUnit === 'sq-yd') {
        size = size / 9; // 1 sq-yard = 9 sq-ft
      } else if (plotAreaUnit === 'sq-m') {
        size = size * 0.092903; // 1 sq-ft = 0.092903 sq-m
      }
      setTotalSize(size.toFixed(2));
    } else {
      setTotalSize('');
    }
  }, [plotLength, plotBreadth, plotAreaUnit]);

  console.log('cornerdataerorors', isCornerPlotError);

  // validations for every componenets
  //1 basic information

  const validateField = (name, value) => {
    let message = '';

    switch (name) {
      case 'title':
        if (!value) {
          message = 'Title is required';
        } else if (!/^[a-zA-Z0-9 ]*$/.test(value)) {
          message = 'Title can only contain numbers and text';
        } else if (/\d{4,}/.test(value)) {
          message = 'Title cannot contain more than three consecutive numeric characters';
        } else if (value.length > 30) {
          message = 'Title cannot exceed 30 characters';
        }
        break;
      case 'description':
        if (!value) {
          message = 'Description is required';
        } else if (value.length < 15) {
          message = 'Description should be at least 15 characters long';
        } else if (/\d{4,}/.test(value)) {
          message = 'Title cannot contain more than three consecutive numeric characters';
        }
        break;
      case 'Agent_Name':
        if (!value) {
          message = 'Agent name is required';
        } else if (!/^[a-zA-Z ]*$/.test(value)) {
          message = 'Agent name can only contain text';
        }
        // else if (value.length > 40) {
        //   message = 'Agent name cannot exceed 40 characters';
        // }
        break;
      case 'selectedOwner':
        if (!value) {
          message = 'Please select owner type';
        }
        break;
      case 'selectedPurpose':
        if (!value) {
          message = 'Please select purpose';
        }
        break;
      case 'selectedPropertyType':
        if (!value) {
          message = 'Please select property type';
        }
        break;
      case 'selectedSubType':
        if (!value) {
          message = 'Please select subproperty type';
        }
        break;
      default:
        break;
    }

    setBasicValidationMessages((prevState) => ({
      ...prevState,
      [name]: message
    }));
  };

  const validateForm = () => {
    const errors = {};

    if (!basicData.title) {
      errors.title = 'Title is required';
    } else if (!/^[a-zA-Z0-9 ]+$/.test(basicData.title)) {
      errors.title = 'Title can only contain letters, numbers, and spaces';
    } else if (/\d{4,}/.test(basicData.title)) {
      // Ensure no sequence of four or more consecutive numeric characters
      errors.title = 'Title cannot contain more than three consecutive numeric characters';
    } else if (basicData.title > 30) {
      errors.title = 'Title cannot exceed 30 characters';
    }

    if (!basicData.description) {
      errors.description = 'Description is required';
    } else if (basicData.description.length < 15) {
      errors.description = 'Description should be at least 15 characters long';
    } else if (/\d{4,}/.test(basicData.description)) {
      // Ensure no sequence of four or more consecutive numeric characters
      errors.description = 'Title cannot contain more than three consecutive numeric characters';
    }

    if (!basicData.Agent_Name) {
      errors.Agent_Name = 'Agent name is required';
    } else if (!/^[a-zA-Z ]*$/.test(basicData.Agent_Name)) {
      errors.Agent_Name = 'Agent name can only contain text';
    } else if (basicData.Agent_Name.length > 40) {
      errors.Agent_Name = 'Agent name cannot exceed 40 characters';
    } else if (/\d{4,}/.test(basicData.Agent_Name)) {
      errors.Agent_Name = 'Title cannot contain more than three consecutive numeric characters';
    }
    if (!basicData.selectedOwner) {
      errors.selectedOwner = 'Please select owner type';
    }
    if (!basicData.selectedPurpose) {
      errors.selectedPurpose = 'Please select purpose';
    }
    if (!basicData.selectedPropertyType) {
      errors.selectedPropertyType = 'Please select property type';
    }
    if (!basicData.selectedSubType) {
      errors.selectedSubType = 'Please select subproperty type';
    }

    setBasicValidationMessages(errors);

    return Object.keys(errors).length === 0;
  };
  // validation for flat
  const validateFormFlat = () => {
    const errors = {};

    // Check each required field
    if (!property.area.super_area.value) {
      errors['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      errors['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      errors['property.area.super_area.value'] = 'Value must be greater than zero.';
    }

    if (!property.area.super_area.unit) {
      errors['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      errors.totalFloors = 'Total Number of Floors is required';
    }

    if (!selectFloor) {
      errors.selectFloor = 'Property On The Floors is required';
    }

    if (!facing) {
      errors.facing = 'Facing Direction is required';
    }

    if (!bathroom) {
      errors.bathroom = 'Bathrooms is required';
    }

    if (!selectedKitchen) {
      errors.selectedKitchen = 'Kitchen selection is required';
    }

    if (!selectedLivingRoom) {
      errors.selectedLivingRoom = 'Living Room selection is required';
    }

    if (!selectedHall) {
      errors.selectedHall = 'Hall selection is required';
    }

    if (!selectedDiningRoom) {
      errors.selectedDiningRoom = 'Dining Room selection is required';
    }

    if (!numBedrooms) {
      errors.bedrooms = 'Number of Bedrooms is required';
    }

    if (!status) {
      errors.status = 'Possession Status is required';
    }

    if (!furnishedStatus) {
      errors.furnishedStatus = 'Furnished Status is required';
    }

    if (!balconyCount) {
      errors.balconyCount = 'Balcony Count is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        errors.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        errors.availabilityYear = 'Availability Year is required';
      }
    }

    if (!fromCurrency) {
      errors.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      errors.amount = 'Amount is required and must be greater than 0';
    } else if (amount.toString().length > 12) {
      errors.amount = 'Amount must be no more than 10 digits';
    }

    setValidationMessagesFlat(errors); // Update the state with error messages
    return Object.keys(errors).length === 0; // Return false if there are errors
  };

  const validateFormHouse = () => {
    const errors = {};

    // Check each required field
    if (!property.area.super_area.value) {
      errors['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      errors['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      errors['property.area.super_area.value'] = 'Value must be greater than zero.';
    }

    if (!property.area.super_area.unit) {
      errors['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      errors.totalFloors = 'Total Number of Floors is required';
    }

    if (!selectFloor) {
      errors.selectFloor = 'Property On The Floors is required';
    }

    if (!facing) {
      errors.facing = 'Facing Direction is required';
    }

    if (!bathroom) {
      errors.bathroom = 'Bathrooms is required';
    }

    if (!selectedKitchen) {
      errors.selectedKitchen = 'Kitchen selection is required';
    }

    if (!selectedLivingRoom) {
      errors.selectedLivingRoom = 'Living Room selection is required';
    }

    if (!selectedHall) {
      errors.selectedHall = 'Hall selection is required';
    }

    if (!selectedDiningRoom) {
      errors.selectedDiningRoom = 'Dining Room selection is required';
    }

    if (!numBedrooms) {
      errors.bedrooms = 'Number of Bedrooms is required';
    }

    if (!status) {
      errors.status = 'Possession Status is required';
    }

    if (!furnishedStatus) {
      errors.furnishedStatus = 'Furnished Status is required';
    }

    if (!balconyCount) {
      errors.balconyCount = 'Balcony Count is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        errors.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        errors.availabilityYear = 'Availability Year is required';
      }
    }
    if (!fromCurrency) {
      errors.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      errors.amount = 'Amount is required and must be greater than 0';
    }
    setValidationMessageHouse(errors); // Update the state with error messages
    return Object.keys(errors).length === 0; // Return false if there are errors
  };
  const validateFormResiLand = () => {
    const error = {};

    if (!inputUnit) {
      error.inputUnit = 'Please select unit';
    }
    if (!length) {
      error.length = 'Please enter length';
    }
    if (!breadth) {
      error.breadth = 'Please enter breadth';
    }
    if (!outputUnit) {
      error.outputUnit = 'Please select output unit';
    }
    if (!isCornerPlot) {
      error.isCornerPlot = 'Please select is corner plot';
    }
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    if (!anyConstruction) {
      error.anyConstruction = 'Please select the construction status';
    }
    if (!selectedOptions) {
      error.selectedOptions = 'Please select the boundary wall status';
    }
    if (!gatedColony) {
      error.gatedColony = 'Please select the gated colony status';
    }

    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }

    setValidationMessagesResiLandPlot(error); // Update the state with error messages
    return Object.keys(error).length === 0;
  };

  const validationforvilla = () => {
    const error = {};
    if (!inputUnit) {
      error.inputUnit = 'Please select unit';
    }
    if (!length) {
      error.length = 'Please enter length';
    }
    if (!breadth) {
      error.breadth = 'Please enter breadth';
    }
    if (!outputUnit) {
      error.outputUnit = 'Please select output unit';
    }
    if (!property.area.super_area.value) {
      error['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      error['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      error['property.area.super_area.value'] = 'Value must be number greater than zero.';
    }

    if (!property.area.super_area.unit) {
      error['property.area.super_area.unit'] = 'Unit is required.';
    }
    if (!selectedKitchen) {
      error.selectedKitchen = 'Kitchen selection is required';
    }

    if (!selectedLivingRoom) {
      error.selectedLivingRoom = 'Living Room selection is required';
    }

    if (!selectedHall) {
      error.selectedHall = 'Hall selection is required';
    }

    if (!selectedDiningRoom) {
      error.selectedDiningRoom = 'Dining Room selection is required';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }
    if (!furnishedStatus) {
      error.furnishedStatus = 'Furnished Status is required';
    }
    if (!bathroom) {
      error.bathroom = 'Bathrooms is required';
    }
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!numBedrooms) {
      error.numBedrooms = 'Number of Bedrooms is required';
    }
    if (!facing) {
      error.facing = 'Facing Direction is required';
    }
    if (!status) {
      error.status = 'Possession Status is required';
    }
    if (!balconyCount) {
      error.balconyCount = 'Balcony Count is required';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        error.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        error.availabilityYear = 'Availability Year is required';
      }
    }

    setVillaValidation(error); // Update the state with error messages
    return Object.keys(error).length === 0;
  };
  // validation for builders
  const builderValidate = () => {
    const error = {};
    if (!property.area.super_area.value) {
      error['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      error['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      error['property.area.super_area.value'] = 'Value must be greater than zero.';
    }

    if (!property.area.super_area.unit) {
      error['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }

    if (!facing) {
      error.facing = 'Facing Direction is required';
    }

    if (!bathroom) {
      error.bathroom = 'Bathrooms is required';
    }

    if (!selectedKitchen) {
      error.selectedKitchen = 'Kitchen selection is required';
    }

    if (!selectedLivingRoom) {
      error.selectedLivingRoom = 'Living Room selection is required';
    }

    if (!selectedHall) {
      error.selectedHall = 'Hall selection is required';
    }

    if (!selectedDiningRoom) {
      error.selectedDiningRoom = 'Dining Room selection is required';
    }

    if (!numBedrooms) {
      error.numBedrooms = 'Number of Bedrooms is required';
    }

    if (!status) {
      error.status = 'Possession Status is required';
    }

    if (!furnishedStatus) {
      error.furnishedStatus = 'Furnished Status is required';
    }

    if (!balconyCount) {
      error.balconyCount = 'Balcony Count is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        error.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        error.availabilityYear = 'Availability Year is required';
      }
    }

    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }

    setBuilderValidation(error); // Update the state with error messages
    return Object.keys(error).length === 0;
  };
  const commshiopValidate = () => {
    const error = {};
    if (!property.area.super_area.value) {
      error['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      error['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      error['property.area.super_area.value'] = 'Value must be number greater than zero.';
    }

    if (!property.area.super_area.unit) {
      error['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }
    if (!selectFloor) {
      error.selectFloor = 'Property On The Floors is required';
    }

    if (!facing) {
      error.facing = 'Facing Direction is required';
    }
    if (!bathroom) {
      error.bathroom = 'Bathrooms is required';
    }
    if (!status) {
      error.status = 'Possession Status is required';
    }
    if (!furnishedStatus) {
      error.furnishedStatus = 'Furnished Status is required';
    }
    if (!balconyCount) {
      error.balconyCount = 'Balcony Count is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        error.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        error.availabilityYear = 'Availability Year is required';
      }
    }

    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    setCommShopValidation(error); // Update the state with error messages
    return Object.keys(error).length === 0;
  };
  const commlandvalidate = () => {
    const error = {};

    if (!inputUnit) {
      error.inputUnit = 'Please select unit';
    }
    if (!length) {
      error.length = 'Please enter length';
    }
    if (!breadth) {
      error.breadth = 'Please enter breadth';
    }
    if (!outputUnit) {
      error.outputUnit = 'Please select output unit';
    }
    if (!selectedOptions) {
      error.selectedOptions = 'Please select the boundary wall status';
    }
    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    if (!selectedOption) {
      error.selectedOption = 'Please select land zone';
    }
    if (!selectedOptioning) {
      error.selectedOptioning = 'Please select lease out status';
    }

    if (!availabilityMonth) {
      error.availabilityMonth = 'Availability Month is required';
    }

    if (!availabilityYear) {
      error.availabilityYear = 'Availability Year is required';
    }
    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    setCommLandValidation(error);

    return Object.keys(error).length === 0;
  };
  const commItPark = () => {
    const error = {};
    if (!property.area.super_area.value) {
      error['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      error['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      error['property.area.super_area.value'] = 'Value must be number greater than zero.';
    }

    if (!property.area.super_area.unit) {
      error['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }
    if (!status) {
      error.status = 'Possession Status is required';
    }
    if (!furnishedStatus) {
      error.furnishedStatus = 'Furnished Status is required';
    }
    if (!selectedValue) {
      error.selectedValue = 'Bathrooms is required';
    }
    if (!selectedOption) {
      error.selectedOption = 'Please select land zone';
    }
    if (!selectedOptiones) {
      error.selectedOptiones = 'Please select lease out status';
    }

    if (!formData.pantryType) {
      error.pantryType = 'Pantry Type is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        error.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        error.availabilityYear = 'Availability Year is required';
      }
    }
    if (!selectedBusiness) {
      error.selectedBusiness = 'Select nearby business  is required';
    }

    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    setCommITValidation(error);
    return Object.keys(error).length === 0;
  };

  const commIndustrialValidate = () => {
    const error = {};

    if (!inputUnit) {
      error.inputUnit = 'Please select unit';
    }
    if (!length) {
      error.length = 'Please enter length';
    }
    if (!breadth) {
      error.breadth = 'Please enter breadth';
    }
    if (!outputUnit) {
      error.outputUnit = 'Please select output unit';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }
    if (!selectedOptions) {
      error.selectedOptions = 'Please select the boundary wall status';
    }
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    if (!anyConstruction) {
      error.anyConstruction = 'Please select construction status';
    }

    if (!availabilityMonth) {
      error.availabilityMonth = 'Availability Month is required';
    }

    if (!availabilityYear) {
      error.availabilityYear = 'Availability Year is required';
    }
    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    setCommIndusValidation(error);
    return Object.keys(error).length === 0;
  };
  const AgriValiadte = () => {
    const error = {};
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    if (!selectedOptions) {
      error.selectedOptions = 'Please select the boundary wall status';
    }
    if (!inputUnit) {
      error.inputUnit = 'Please select unit';
    }
    if (!length) {
      error.length = 'Please enter length';
    }
    if (!breadth) {
      error.breadth = 'Please enter breadth';
    }
    if (!outputUnit) {
      error.outputUnit = 'Please select output unit';
    }
    if (!selectedOptioning) {
      error.selectedOptioning = 'Please select lease out status';
    }
    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    setAgriValidation(error);
    return Object.keys(error).length === 0;
  };
  const farmHouseValidate = () => {
    const error = {};

    // Check each required field
    if (!property.area.super_area.value) {
      error['property.area.super_area.value'] = 'Super Area is required.';
    } else if (!/^[0-9]+(\.[0-9]+)?$/.test(property.area.super_area.value)) {
      error['property.area.super_area.value'] = 'Value must be a number.';
    } else if (parseFloat(property.area.super_area.value) <= 0) {
      error['property.area.super_area.value'] = 'Value must be greater than zero.';
    }

    if (!property.area.super_area.unit) {
      error['property.area.super_area.unit'] = 'Unit is required.';
    }

    if (!totalFloors) {
      error.totalFloors = 'Total Number of Floors is required';
    }

    // if (!property.floors_of_property) {
    //   error.floors_of_property = 'Property On The Floors is required';
    // }

    // if (!property.facing) {
    //   error.facing = 'Facing Direction is required';
    // }

    if (!bathroom) {
      error.bathroom = 'Bathrooms is required';
    }

    if (!selectedKitchen) {
      error.selectedKitchen = 'Kitchen selection is required';
    }

    if (!selectedLivingRoom) {
      error.selectedLivingRoom = 'Living Room selection is required';
    }

    if (!selectedHall) {
      error.selectedHall = 'Hall selection is required';
    }

    if (!selectedDiningRoom) {
      error.selectedDiningRoom = 'Dining Room selection is required';
    }

    if (!numBedrooms) {
      error.numBedrooms = 'Number of Bedrooms is required';
    }

    if (!status) {
      error.status = 'Possession Status is required';
    }

    if (!furnishedStatus) {
      error.furnishedStatus = 'Furnished Status is required';
    }

    if (!balconyCount) {
      error.balconyCount = 'Balcony Count is required';
    }

    if (status !== 'ready_to_move') {
      if (!availabilityMonth) {
        error.availabilityMonth = 'Availability Month is required';
      }

      if (!availabilityYear) {
        error.availabilityYear = 'Availability Year is required';
      }
    }

    if (!fromCurrency) {
      error.fromCurrency = 'Currency selection is required';
    }

    if (!amount || amount <= 0) {
      error.amount = 'Amount is required and must be greater than 0';
    }
    if (!selectedOpenSide) {
      error.selectedOpenSide = 'Please select open side';
    }
    if (!roadWidth) {
      error.roadWidth = 'Please enetr road width';
    }
    setFarmHouseValidation(error);
    return Object.keys(error).length === 0;
  };

  const locationValidate = () => {
    const error = {};

    if (!selectedCountry) {
      error.selectedCountry = 'Please select country';
    }

    if (!selectedState) {
      error.selectedState = 'Please select state';
    }

    if (!selectedCity) {
      error.selectedCity = 'Please select city';
    }
    if (!searchQuery) {
      error.searchQuery = 'Please choose city';
    }
    if (!street) {
      error.street = 'Please enter street name or landmark';
    }

    setLocationValidation(error);
    return Object.keys(error).length === 0;
  };
  const scrollToCenter = () => {
    // Get the height and width of the document
    const documentHeight = document.documentElement.scrollHeight;
    const documentWidth = document.documentElement.scrollWidth;

    // Get the height and width of the viewport
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    // Calculate the center positions
    const centerVertical = (documentHeight - viewportHeight) / 3;
    const centerHorizontal = (documentWidth - viewportWidth) / 3;

    // Scroll to the center position smoothly
    window.scrollTo({
      top: centerVertical,
      left: centerHorizontal,
      behavior: 'smooth'
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions

    setIsSubmitting(true);
    const userData = localStorage.getItem('userData');
    const token = userData ? JSON.parse(userData).access_token : null; // Set submitting state to true
    console.log('token', token);

    let jsonItem = pankaj();
    console.log('checkcountryjson', jsonItem);
    const basicValid = validateForm();
    console.log('basicvalid', basicValid);
    const validatFlat = validateFormFlat();
    console.log('validateflat', validatFlat);
    const validateHouse = validateFormHouse();
    const validateResi = validateFormResiLand();
    const validateVilla = validationforvilla();
    const validateBuilder = builderValidate();
    const validateComm = commshiopValidate();
    const validateCommLand = commlandvalidate();
    console.log('validateCommLand', validateCommLand);
    const validateCommIt = commItPark();
    const validateCommInd = commIndustrialValidate();
    const validateAgri = AgriValiadte();
    const validateFarm = farmHouseValidate();
    console.log('validateFarm', validateFarm);
    const validateLoc = locationValidate();
    console.log('farmhous', validateLoc);

    if (
      basicValid &&
      validateLoc &&
      (validatFlat ||
        validateResi ||
        validateVilla ||
        validateBuilder ||
        validateComm ||
        validateHouse ||
        validateCommLand ||
        validateCommIt ||
        validateCommInd ||
        validateAgri ||
        validateFarm)
    ) {
      try {
        const response = await fetch(
          `https://square-bric-backend-api.squarebric.com/property-listing/update-property-listing/${property_id}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}` // Add token to headers
              // Add other headers like authorization token if needed
            },
            body: JSON.stringify(jsonItem)
          }
        );

        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }

        const responseData = await response.json();
        console.log('Success:', responseData);
        console.log('jsondatafinalsmyunits', responseData.property_id);
        setPropertyId(responseData.property_id);
        toast.success('Registered successfully');
        setTimeout(() => {
          setshow(true);
        }, 1000);
        showToastMessage('Successfully registered your property', 'success');
      } catch (error) {
        console.error('Error:', error);
        console.error('Error message:', error.message);
        showToastMessage('There was an error registering your property', 'error');
      } finally {
        setIsSubmitting(false); // Reset submitting state
      }
    } else {
      scrollToCenter();
      setIsSubmitting(false); // Reset submitting state if validation fails
    }
  };
  const unitLabels = {
    '': 'Birim Seçin',
    'meters': 'Metre',
    'feet': 'Ayak',
    'yards': 'Yarda'
  };
  return (
    <div className="">
      {show === false ? (
        <>
          <div className=" ml-[7%] mt-5">
         

            <h1 className="p-2 m-4 mr-4 mt-4 bg-[#EAF7FF]  text-[20px] font-[600]  text-[#1E2858] thirdCustom">
             Edit Property
              {/* {t('Property Listing')} */}
            </h1>

            <div className="grid lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 bg-white gap-4 p-4 mt-[-10px]">
              <div className="lg:col-span-2 xl:col-span-2 2xl:col-span-3 3xl:col-span-4 border border-gray-300">
                <div className="">
                  <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Basic Information')}
                  </h1>
                  <div className="grid grid-cols-2 gap-4 p-10">
                    <div className="col-span-2">
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Title')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <input
                        type="text"
                        name="title"
                        value={basicData.title || ''}
                        onChange={handleInputChange}
                        placeholder={t('titlePlaceholder')}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                      />
                      {bsicvalidationMessages.title && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.title')}
                        
                        </span>
                      )}
                    </div>
                    <div className="col-span-2">
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Description')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <textarea
                        type="text"
                        name="description"
                        value={basicData.description || ''}
                        onChange={handleInputChange}
                        placeholder={t('descriptionPlaceholder')}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px] h-[100px] border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                      />
                      {bsicvalidationMessages.description && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.description')}
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Name')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <input
                        type="text"
                        name="Agent_Name"
                        value={basicData.Agent_Name || ''}
                        onChange={handleInputChange}
                        placeholder={t('agentNamePlaceholder')}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                      />
                      {bsicvalidationMessages.Agent_Name && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.Agent_Name')}
                        
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Owner Type')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <select
                       value={basicData.selectedOwner || ''}
                        onChange={handleOwner}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px]   outline-none"
                      >
                        <option value="" >
                        {t('Select Owner Type')}
                        </option>
                        {basicData.OwnerName.map((purpose, index) => (
                          <option key={index} value={purpose} className="capitalize">
                          {t(`OwnerName.${purpose}`)}
                          </option>
                        ))}
                      </select>
                      {bsicvalidationMessages.selectedOwner && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.ownerType')}
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Purpose')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <select
                    value={basicData.selectedPurpose || ''}
                        onChange={handlePurposeChange}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                      >
                        <option value="">
                                 
                        {t('Select purpose')}
                        </option>
                        {basicData.purposeOptions.map((purpose, index) => (
                          <option key={index} value={purpose} className="capitalize">
                                {t(`purposeOptions.${purpose}`)}
                          </option>
                        ))}
                      </select>
                      {bsicvalidationMessages.selectedPurpose && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.purpose')}
                        </span>
                      )}
                    </div>
                    <div>
                      <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Property Type')}
                        <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                      </label>
                      <select
                      value={basicData.selectedPropertyType || ''}
                        onChange={handlePropertyTypeChange}
                        className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                      >
                        <option value="">
                        {t('Select property type')}
                        </option>
                        {Object.keys(basicData.propertyTypeOptions).map((type, index) => (
                          <option key={index} value={type} className="capitalize">
                          {t(`propertyTypeOptions.${type}`)}
                          </option>
                        ))}
                      </select>
                      {bsicvalidationMessages.selectedPropertyType && (
                        <span className="text-[12px] text-red-500 font-[400]">
                        {t('bsicvalidationMessages.selectedPropertyType')}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                {/*propert details*/}

                {basicData.selectedPropertyType && (
                  <div className="m-10 mt-[-20px]">
                    <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                      {t('Sub Type')}
                      <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                    </label>
                    <select
                       value={basicData.selectedSubType || ''}
                      onChange={handleSubTypeChange}
                      className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                    >
                      <option value="" >
                      {t('Select sub type')}
                      </option>
                      {basicData.propertyTypeOptions[basicData.selectedPropertyType].map((subType, index) => (
                        <option key={index} value={subType} className="capitalize">
                        {t(`propertyTypeOptions.${basicData.selectedPropertyType}SubOptions.${subType}`)}
                        </option>
                      ))}
                    </select>
                    {bsicvalidationMessages.selectedSubType && (
                      <span className="text-[12px] text-red-500 font-[400]">
                       {bsicvalidationMessages.selectedSubType}
                      </span>
                    )}
                  </div>
                )}


                {basicData.selectedSubType === 'CommercialShop' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {commShopValidation['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commShopValidation.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                              {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {commShopValidation['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commShopValidation.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="" >
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {commShopValidation.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.totalFloors')}
                            
                            </p>
                          )}
                        </div>
                      
                          <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Select Floors of Property')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="selectFloor"
                            value={selectFloor}
                            onChange={handleChnageFloor}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Floors of Property')}</option>
                            {[
                               1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {commShopValidation.selectFloor && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                              {t('commShopValidation.selectFloor')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Facing Direction')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="facing"
                            value={facing}
                            onChange={handleDirectionChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Facing Direction')}</option>
                            {['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'].map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.facing && (
                            <p className="text-[12px] text-red-500 font-[400]">
                           
                            {t('commShopValidation.facing')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('commShopValidation.bathrooms')}  
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                              {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.status && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.possession_status')}
                            </span>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" >
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                              {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {commShopValidation.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commShopValidation.availabilityMonth')}
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" >
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {commShopValidation.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commShopValidation.availabilityYear')}
                                </p>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                              {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.furnishedStatus && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.furnished_status')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.balconyCount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.balcony_count')}
                            
                            </span>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                               <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {commShopValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.currency')}
                            
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                              {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {commShopValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commShopValidation.amount')}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'CommercialLand' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="col-span-2 grid grid-cols-3 gap-5">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Select Unit')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            <select
                              value={inputUnit}
                              onChange={handleInputUnitChange}
                              className=" mt-[5px] p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            >
                              {units.map((unit) => (
                                <option key={unit} value={unit}>
                                {t(`units.${unit}`)}
                                </option>
                              ))}
                            </select>
                          </label>
                          {commlandValidation.inputUnit && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.inputUnit')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotLengthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                             {t('Plot Length')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            type="number"
                            value={length}
                            onChange={handleLengthChange}
                            placeholder={t('lengthPlaceholder')}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />

                          {commlandValidation.length && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.length')}
                            
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                           {t('Plot Breath')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>

                          <input
                            type="number"
                            value={breadth}
                            placeholder={t('breadthPlaceholder')}
                            onChange={handleBreadthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />

                          {commlandValidation.breadth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.breadth')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-cols-2 gap-5 mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                        {t('Output Unit')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          <select
                            value={outputUnit}
                            onChange={handleOutputUnitChange}
                            className=" mb-3 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                           <option value="">{t('Select Unit')}</option>
      <option value="acres">{t('Acres')}</option>
      <option value="hectares">{t('Hectares')}</option>
      <option value="sqft">{t('Square Feet')}</option>
      <option value="sqyards">{t('Square Yards')}</option>
      <option value="sqmt">{t('Square Meters')}</option>
                          </select>
                          {commlandValidation.outputUnit && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.outputUnit')} 
                            </p>
                          )}
                        </label>

                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                        {t('Total Area')}
                          <input
                            type="text"
                            value={getArea()}
                            className=" mt-0 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          ></input>
                        </label>
                      </div>

                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] mt-2 ">
                        {t('Allowed Floors for Construction')}
                          <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                        </label>
                        <select
                          name="total_floors"
                          value={totalFloors}
                          onChange={handleTotalFloors}
                          className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        >
                          <option value="" >
                          {t('Select Total Floors')}
                          </option>
                          {[
                            0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                            25, 26, 27, 28, 29, 30
                          ].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        {commlandValidation.totalFloors && (
                          <p className="text-[12px] text-red-500 font-[400]">
                          {t('commlandValidation.totalFloors')}
                          </p>
                        )}
                      </div>

                      <div className="mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">{t('Land Zone')}</label>
                        <select
                          value={selectedOption}
                          onChange={handleSelects}
                          className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                        >
                          {option.map((option) => (
                            <option key={option} value={option} className="capitalize">
                              {option}
                            </option>
                          ))}
                        </select>

                        {commlandValidation.selectedOption && (
                          <p className="text-[12px] text-red-500 font-[400]">
                          {t('commlandValidation.selectedOption')}
                          
                          </p>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="">
                          <div className="flex justify-start mt-4">
                            <h1 className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Boundary Wall')}
                            </h1>
                            <label className="flex  items-center">
                              <input
                                type="radio"
                                name="boundaryWall"
                                value="yes"
                                checked={selectedOptions === 'yes'}
                                onChange={handleOptionChanges}
                                className="ml-4 mr-2 mt-2" 
                              />
                              <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('Yes')}
                              </span>
                            </label>
                            <label className="inline-flex items-center ">
                              <input
                                type="radio"
                                name="boundaryWall"
                                value="no"
                                checked={selectedOptions === 'no'}
                                onChange={handleOptionChanges}
                                className="ml-4 mr-2 mt-2" 
                              />
                              <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('No')}
                              </span>
                            </label>
                          </div>

                          {commlandValidation.selectedOptions && (
                            <p className="text-[12px] text-red-500 font-[400]">  {t('commlandValidation.selectedOptions')}</p>
                          )}
                        </div>
                        <div className="">
                          <div className="flex justify-start items-start mt-4">
                            <h1 className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Currently Leased Out')}
                            </h1>
                            <label className="inline-flex items-center">
                              <input
                                type="radio"
                                name="leasedOut"
                                value="yes"
                                checked={selectedOptioning === 'yes'}
                                onChange={handleOptionChanging}
                                className="ml-4 mr-2 mt-2" 
                              />
                              <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('Yes')}
                              </span>
                            </label>
                            <label className="inline-flex items-center mt-0">
                              <input
                                type="radio"
                                name="leasedOut"
                                value="no"
                                checked={selectedOptioning === 'no'}
                                onChange={handleOptionChanging}
                                className="ml-4 mr-2 mt-2" 
                              />
                              <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('No')}
                              </span>
                            </label>
                          </div>

                          {commlandValidation.selectedOptioning && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.selectedOptioning')}
                            
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <div className="">
                          <label
                            htmlFor="dynamic-options"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                           {t('No of open sides')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dynamic-options"
                            value={selectedOpenSide}
                            onChange={handleOpenSideChange}
                            className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300  bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                         <option value="">{t('Select an option')}</option>
                            {[1, 2, 3, 4].map((side) => (
                              <option key={side} value={side}>
                                {side}
                              </option>
                            ))}
                          </select>

                          {commlandValidation.selectedOpenSide && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.selectedOpenSide')}
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label
                            htmlFor="roadWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] "
                          >
                          {t('Width of road facing the plot')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            id="roadWidthInput"
                            type="type"
                            value={roadWidth}
                            onChange={handleRoadWidthChange}
                            placeholder={t('roadWidthPlaceholder')}
                            className="mt-1 block w-full py-2 px-3 border  h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />

                          {commlandValidation.roadWidth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.roadWidth')}
                            
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('AvailabilityMonth')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={availabilityMonth}
                            onChange={handleAvailabilityMonthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Month')}
                            </option>
                            {availability.month.map((month) => (
                              <option key={month} value={month} className="capitalize">
                                 {t(`month.${month}`)}
                              </option>
                            ))}
                          </select>

                          {commlandValidation.availabilityMonth && (
                            <p className="text-[12px] text-red-500 font-[400]">  {t('commlandValidation.availabilityMonth')}</p>
                          )}
                        </div>
                        <div className="mt-0">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('AvailabilityYear')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={availabilityYear}
                            onChange={handleAvailabilityYearChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="" >
                            {t('Select Year')}
                            </option>
                            {availability.year.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>

                          {commlandValidation.availabilityYear && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.availabilityYear')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                              <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>

                          {commlandValidation.fromCurrency && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.currency')}
                            
                            </p>
                          )}
                        </div>

                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />

                          {commlandValidation.amount && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commlandValidation.amount')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2">
                        {Object.keys(convertedValues).length > 0 && (
                          <div className="result flex ">
                            <p className="p-3">
                              {amount} {fromCurrency} is approximately:
                            </p>
                            {Object.entries(convertedValues).map(([currency, value]) => (
                              <p key={currency} className="p-3">
                                {value} {currency}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {basicData.selectedSubType === 'CommercialItPark' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-5">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {commitValidation['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commitValidation.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                  {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {commitValidation['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commitValidation.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {commitValidation.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]">     {t('commitValidation.totalFloors')}</p>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                 {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {commitValidation.status && (
                            <p className="text-[12px] text-red-500 font-[400]">  {t('commitValidation.possession_status')}</p>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                  {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {commitValidation.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commitValidation.availabilityMonth ')}
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {commitValidation.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('commitValidation.availabilityYear')}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                    {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>
                          {commitValidation.furnishedStatus && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commitValidation.furnished_status')}
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Nearby Businesses')}
                          </label>
                          <select
                            value={selectedBusiness}
                            onChange={handleSelect}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                              <option value="">{t('Select nearby business')}.</option>
                            {nearbyBusinessOptions &&
                              nearbyBusinessOptions.map((option) => (
                                <option key={option} value={option} className="capitalize">
                                 {t(`nearbyBusinessOptions.${option}`)}
                                </option>
                              ))}
                          </select>
                          {commitValidation.selectedBusiness && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commitValidation.selectedBusiness')}
                            </span>
                          )}
                        </div>

                            <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('validationMessagesFlat.bathrooms')}  
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Land Zone')}
                          </label>
                          <select
                            value={selectedOption}
                            onChange={handleSelects}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] capitalize border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            {option.map((option) => (
                              <option key={option} value={option} className="capitalize">
                                 {t(`propertyOptions.${option}`)}
                              </option>
                            ))}
                          </select>
                          {commitValidation.selectedOption && (
                            <span className="text-[12px] text-red-500 font-[400]"> {t('commitValidation.selectedOption')}</span>
                          )}
                        </div>
                        <div className="col-span-2 grid grid-cols-2">
                          <div className="">
                            <div className=" flex justify-start">
                              <label className="block  text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                                {' '}
                                {t('Pantry/Cafeteria:')}
                              </label>
                              <label className="inline-flex items-center mr-4 mb-0">
                                <input
                                  type="radio"
                                  name="pantryType"
                                  value="Dry"
                                  checked={formData.pantryType === 'Dry'}
                                  onChange={handleInputChanged}
                                  className="mr-2 ml-2 leading-tight"
                                />
                                <span className="text-sm">{t('Dry')}</span>
                              </label>
                              <label className="inline-flex items-center mr-4 mb-0">
                                <input
                                  type="radio"
                                  name="pantryType"
                                  value="Wet"
                                  checked={formData.pantryType === 'Wet'}
                                  onChange={handleInputChanged}
                                  className="mr-2 leading-tight"
                                />
                                <span className="text-sm">{t('Wet')}</span>
                              </label>
                              <label className="inline-flex items-center mb-0">
                                <input
                                  type="radio"
                                  name="pantryType"
                                  value="Not Available"
                                  checked={formData.pantryType === 'Not Available'}
                                  onChange={handleInputChanged}
                                  className="mr-2 leading-tight"
                                />
                                <span className="text-sm">{t('Not Available')}</span>
                              </label>
                            </div>
                            {commitValidation.pantryType && (
                              <span className="text-[12px] text-red-500 font-[400]">  {t('commitValidation.pantryType')}</span>
                            )}
                          </div>
                          <div className="">
                            <div className="flex justify-start ">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('Currently Leased Out')}
                              </label>
                              <label className="inline-flex items-center">
                                <input
                                  type="radio"
                                  name="leasedOut"
                                  value="yes"
                                  checked={selectedOptiones === 'yes'}
                                  onChange={handleOptionChangees}
                                  className=" mt-1 ml-2 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                                />
                                <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                                {t('Yes')}
                                </span>
                              </label>
                              <label className="inline-flex items-center mt-0">
                                <input
                                  type="radio"
                                  name="leasedOut"
                                  value="no"
                                  checked={selectedOptiones === 'no'}
                                  onChange={handleOptionChangees}
                                  className=" mt-1 p-2 ml-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                                />
                                <span className="ml-2 block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                                {t('No')}
                                </span>
                              </label>
                            </div>
                            {commitValidation.selectedOptiones && (
                              <span className="text-[12px] text-red-500 font-[400]">
                             {t('commitValidation.selectedOptiones')}
                              </span>
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                             <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {commitValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commitValidation.currency')}
                            
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                                   {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {commitValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commitValidation.amount')}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {basicData.selectedSubType === 'IndustrialLand' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="col-span-2 grid grid-cols-3 gap-5">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Select Unit')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            <select
                              value={inputUnit}
                              onChange={handleInputUnitChange}
                              className=" mt-[5px] p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            >
                              {units.map((unit) => (
                                <option key={unit} value={unit}>
                                {t(`units.${unit}`)}
                                </option>
                              ))}
                            </select>
                          </label>
                          {commindusValidation.inputUnit && (
                            <p className="text-[12px] text-red-500 font-[400]"> {t('commindusValidation.inputUnit')}</p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotLengthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Plot Length')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            type="number"
                            value={length}
                            onChange={handleLengthChange}
                            placeholder={t('lengthPlaceholder')}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {commindusValidation.length && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.length')}
                            
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Plot Breath')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>

                          <input
                            type="number"
                            value={breadth}
                            placeholder={t('breadthPlaceholder')}
                            onChange={handleBreadthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {commindusValidation.breadth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.breadth')}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-span-2 grid grid-cols-2 mt-5 gap-5">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                        {t('Output Unit')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          <select
                            value={outputUnit}
                            onChange={handleOutputUnitChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  h-10 border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                           <option value="">{t('Select Unit')}</option>
      <option value="acres">{t('Acres')}</option>
      <option value="hectares">{t('Hectares')}</option>
      <option value="sqft">{t('Square Feet')}</option>
      <option value="sqyards">{t('Square Yards')}</option>
      <option value="sqmt">{t('Square Meters')}</option>
                          </select>
                          {commindusValidation.outputUnit && (
                            <p className="text-[12px] text-red-300 font-[400]">
                            {t('commindusValidation.outputUnit')}
                            </p>
                          )}
                        </label>

                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                        {t('Total Area')}
                          <input
                            type="text"
                            value={getArea()}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          ></input>
                        </label>
                      </div>

                      <div className="col-span-2 mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] mt-2  ">
                        {t('Allowed Floors for Construction')}
                          <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                        </label>
                        <select
                          name="total_floors"
                          value={totalFloors}
                          onChange={handleTotalFloors}
                          className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                        >
                          <option value="">
                          {t('Select Total Floors')}
                          </option>
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12].map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        {commindusValidation.totalFloors && (
                          <p className="text-[12px] text-red-500 font-[400]">
                          {t('commindusValidation.totalFloors')}
                          </p>
                        )}
                      </div>

                      <div className="col-span-2 grid grid-cols-2">
                        <div className="">
                          <div className="flex justify-start mt-4">
                            <h1 className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Boundary Wall')}
                            </h1>
                            <label className="flex  items-center">
                              <input
                                type="radio"
                                name="boundaryWall"
                                value="yes"
                                checked={selectedOptions === 'yes'}
                                onChange={handleOptionChanges}
                                className=" mt-1 ml-2 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                                   <span className="ml-2  "> {t('Yes')}</span>
                            </label>
                            <label className="inline-flex items-center ">
                              <input
                                type="radio"
                                name="boundaryWall"
                                value="no"
                                checked={selectedOptions === 'no'}
                                onChange={handleOptionChanges}
                                className=" mt-1 ml-2 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                               <span className="ml-2   "> {t('No')}</span>
                            </label>
                          </div>
                          {commindusValidation.selectedOptions && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.selectedOptions')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <div className="flex justify-start ">
                            <span className="block text-[14px] font-[600] leading-[30px] mt-4 text-[#1E2858]">
                            {t('Construction Done')}
                            </span>
                            <label className="ml-4 mt-4 ">
                              <input
                                type="radio"
                                name="anyConstruction"
                                value="yes"
                                checked={anyConstruction === 'yes'}
                                onChange={handleAnyConstructionChange}
                                className="mr-2 mt-2"
                              />
                              {t('Yes')}
                            </label>
                            <label className="ml-3 mt-4">
                              <input
                                type="radio"
                                name="anyConstruction"
                                value="no"
                                checked={anyConstruction === 'no'}
                                onChange={handleAnyConstructionChange}
                                className="mr-2 mt-2"
                              />
                          {t('No')}
                            </label>
                          </div>
                          {commindusValidation.anyConstruction && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.anyConstruction')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div className="">
                          <label
                            htmlFor="dynamic-options"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                                 {t('No of open sides')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dynamic-options"
                            value={selectedOpenSide}
                            onChange={handleOpenSideChange}
                            className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                             <option value="">   {t('Select an option')}</option>
                            {openSides.map((side) => (
                              <option key={side} value={side}>
                                {side}
                              </option>
                            ))}
                          </select>
                          {commindusValidation.selectedOpenSide && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.selectedOpenSide')}
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label
                            htmlFor="roadWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] "
                          >
                      {t('Width of road facing the plot')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            id="roadWidthInput"
                            type="type"
                            value={roadWidth}
                            onChange={handleRoadWidthChange}
                            placeholder={t('roadWidthPlaceholder')}
                            className="mt-1 block w-full py-2 px-3 border  h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          {commindusValidation.roadWidth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.roadWidth')}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('AvailabilityMonth')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={availabilityMonth}
                            onChange={handleAvailabilityMonthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Month')}
                            </option>
                            {availability.month.map((month) => (
                              <option key={month} value={month} className="capitalize">
                                 {t(`month.${month}`)}
                              </option>
                            ))}
                          </select>
                          {commindusValidation.availabilityMonth && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.availabilityMonth')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('AvailabilityYear')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={availabilityYear}
                            onChange={handleAvailabilityYearChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Year')}
                            </option>
                            {availability.year.map((year) => (
                              <option key={year} value={year}>
                                {year}
                              </option>
                            ))}
                          </select>
                          {commindusValidation.availabilityYear && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.availabilityYear')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                                <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {commindusValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.currency')}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col mt-0">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                             {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {commindusValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('commindusValidation.amount')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2">
                        {Object.keys(convertedValues).length > 0 && (
                          <div className="result flex ">
                            <p className="p-3">
                              {amount} {fromCurrency} is approximately:
                            </p>
                            {Object.entries(convertedValues).map(([currency, value]) => (
                              <p key={currency} className="p-3">
                                {value} {currency}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'FlatApartment' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {validationMessagesFlat['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessagesFlat.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                  
                                  {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {validationMessagesFlat['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessagesFlat.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Select Floors of Property')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="selectFloor"
                            value={selectFloor}
                            onChange={handleChnageFloor}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Floors of Property')}</option>
                            {[
                               1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {validationMessagesFlat.selectFloor && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                              {t('validationMessagesFlat.selectFloor')}
                            </p>
                          )}
                        </div>




                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]"> {t('validationMessagesFlat.totalFloors')}</p>
                          )}
                        </div>
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Facing Direction')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="facing"
                            value={facing}
                            onChange={handleDirectionChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Facing Direction')}</option>
                            {['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'].map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.facing && (
                            <p className="text-[12px] text-red-500 font-[400]">
                           
                            {t('validationMessagesFlat.facing')}
                            </p>
                          )}
                        </div>

                        {/* Living Room Field */}
                        <div>
                          <label
                            htmlFor="living-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('Living Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="living-room"
                            value={selectedLivingRoom}
                            onChange={handleLivingRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                           <option value="">{t('Select Living Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.selectedLivingRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.selectedLivingRoom')}
                            </p>
                          )}
                        </div>

                        {/* Hall Field */}
                        <div>
                          <label htmlFor="hall" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Hall')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="hall"
                            value={selectedHall}
                            onChange={handleHallChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                            <option value="">{t('Select Hall')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.selectedHall && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            
                            {t('validationMessagesFlat.selectedHall')}
                            </p>
                          )}
                        </div>

                        {/* Dining Room Field */}
                        <div>
                          <label
                            htmlFor="dining-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('Dining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dining-room"
                            value={selectedDiningRoom}
                            onChange={handleDiningRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                              <option value=""> {t('Select Dining Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.selectedDiningRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                             
                            {t('validationMessagesFlat.dining_room')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="kitchen-select"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('Kitchen')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="kitchen-select"
                            value={selectedKitchen}
                            onChange={handleKitchenChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                             <option value="">{t('Select Kitchen')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.selectedKitchen && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.kitchen')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Number of Bedrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <select
                              value={numBedrooms}
                              onChange={handleNumBedroomsChange}
                              className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                            >
                              <option value=""> {t('Select Bedrooms')}</option>
                              {noofbedroom.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                            {validationMessagesFlat.bedrooms && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesFlat.bedrooms')}    
                              </p>
                            )}
                          </label>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('validationMessagesFlat.bathrooms')}  
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                            {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.balconyCount && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.balcony_count')}  
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                            {t('Possession Status')}

                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                             {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.status && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.possession_status')}  
                            </p>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                    {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {validationMessagesFlat.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessagesFlat.availabilityMonth')}  
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                                {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {validationMessagesFlat.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessagesFlat.availabilityYear')}  
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                            {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                              {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>

                          {validationMessagesFlat.furnishedStatus && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.furnished_status')}  
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                             <option value="">    
                             {t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.fromCurrency && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.currency')} 
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Amount')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {validationMessagesFlat.amount && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesFlat.amount')} 
                            </p>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'ResidentialHouse' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {validationMessageHouse['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessageHouse.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                  {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {validationMessageHouse['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessageHouse.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>


                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Select Floors of Property')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="selectFloor"
                            value={selectFloor}
                            onChange={handleChnageFloor}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Floors of Property')}</option>
                            {[
                               1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {validationMessagesFlat.selectFloor && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                              {t('validationMessagesFlat.selectFloor')}
                            </p>
                          )}
                        </div>


                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {validationMessageHouse.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]"> {t('validationMessageHouse.selectFloor')}</p>
                          )}
                        </div>
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Facing Direction')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="facing"
                            value={facing}
                            onChange={handleDirectionChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Facing Direction')}</option>
                            {['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'].map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.facing && (
                            <p className="text-[12px] text-red-500 font-[400]">
                           
                            {t('validationMessageHouse.facing')}
                            </p>
                          )}
                        </div>

                        {/* Living Room Field */}
                        <div>
                          <label
                            htmlFor="living-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                               {t('Living Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="living-room"
                            value={selectedLivingRoom}
                            onChange={handleLivingRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                                   <option value="">{t('Select Living Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.selectedLivingRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessageHouse.selectedLivingRoom')}
                            </p>
                          )}
                        </div>

                        {/* Hall Field */}
                        <div>
                          <label htmlFor="hall" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Hall')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="hall"
                            value={selectedHall}
                            onChange={handleHallChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                                 <option value="">{t('Select Hall')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.selectedHall && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessageHouse.selectedHall')}
                            </p>
                          )}
                        </div>

                        {/* Dining Room Field */}
                        <div>
                          <label
                            htmlFor="dining-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                                    {t('Dining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dining-room"
                            value={selectedDiningRoom}
                            onChange={handleDiningRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                                   <option value=""> {t('Select Dining Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.selectedDiningRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessageHouse.dining_room')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="kitchen-select"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                         {t('Kitchen')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="kitchen-select"
                            value={selectedKitchen}
                            onChange={handleKitchenChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                             <option value="">{t('Select Kitchen')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.selectedKitchen && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessageHouse.kitchen')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Number of Bedrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <select
                              value={numBedrooms}
                              onChange={handleNumBedroomsChange}
                              className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                            >
                                   <option value="">{t('Select Bedrooms')}</option>
                              {noofbedroom.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                            {validationMessageHouse.bedrooms && (
                              <p className="text-[12px] text-red-300 font-[400]">
                              {/* {validationMessageHouse.numBedrooms} */}
                              {t('validationMessageHouse.bedrooms')}  
                              
                              </p>
                            )}
                          </label>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('validationMessagesFlat.bathrooms')}  
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.balconyCount && (
                            <p className="text-[12px] text-red-500 font-[400]">{t('validationMessageHouse.balcony_count')} </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.status && (
                            <p className="text-[12px] text-red-500 font-[400]">  {t('validationMessageHouse.possession_status')}</p>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                  {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {validationMessageHouse.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessageHouse.availabilityMonth')}  
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {validationMessageHouse.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('validationMessageHouse.availabilityYear')}  
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                  {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.furnishedStatus && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessageHouse.furnished_status')} 
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                              <option value=""> {t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {validationMessageHouse.fromCurrency && (
                            <p className="text-[12px] text-red-500 font-[400]">   {t('validationMessageHouse.currency')} </p>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                             {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder="Enter amount"
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {validationMessageHouse.amount && (
                            <p className="text-[12px] text-red-500 font-[400]">   {t('validationMessageHouse.amount')} </p>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'ResidentialLandPlot' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="col-span-2 grid grid-cols-3 gap-3">
                          <div>
                            <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Select Unit')}
                              <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            </label>
                            <select
                              value={inputUnit}
                              onChange={handleInputUnitChange}
                              className="mt-[5px] p-2 w-full thirdCustom border-[1px] h-10 border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            >
                              {units.map((unit) => (
                                <option key={unit} value={unit}>
                                {t(`units.${unit}`)}
                                </option>
                              ))}
                            </select>

                            {validationMessagesResiLandPlot.inputUnit && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.inputUnit')}
                              </p>
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor="plotLengthInput"
                              className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                            >
                                 {t('Plot Length')}
                              <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            </label>
                            <input
                              type="number"
                              value={length}
                              onChange={handleLengthChange}
                              placeholder={t('lengthPlaceholder')}
                              className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />
                            {validationMessagesResiLandPlot.length && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.length')}
                              </p>
                            )}
                          </div>
                          <div>
                            <label
                              htmlFor="plotWidthInput"
                              className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                            >
                             {t('Plot Breath')}
                              <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            </label>

                            <input
                              type="number"
                              value={breadth}
                              placeholder={t('breadthPlaceholder')}
                              onChange={handleBreadthChange}
                              className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />

                            {validationMessagesResiLandPlot.breadth && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.breadth')}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="col-span-2 grid grid-cols-2 gap-3">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Output Unit')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                            <select
                              value={outputUnit}
                              onChange={handleOutputUnitChange}
                              className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            >
                              <option value="">{t('Select Unit')}</option>
      <option value="acres">{t('Acres')}</option>
      <option value="hectares">{t('Hectares')}</option>
      <option value="sqft">{t('Square Feet')}</option>
      <option value="sqyards">{t('Square Yards')}</option>
      <option value="sqmt">{t('Square Meters')}</option>
                            </select>
                            {validationMessagesResiLandPlot.outputUnit && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.outputUnit')}
                              </p>
                            )}
                          </label>

                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Total Area')}
                            <input
                              type="text"
                              value={getArea()}
                              readOnly
                              className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            ></input>
                          </label>
                        </div>
                        <div className="col-span-2">
                          <div className="mt-4  flex justify-start">
                            <span className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Is this a corner plot')}
                            </span>
                            <label>
                              <input
                                type="radio"
                                name="cornerPlot"
                                value="yes"
                                checked={isCornerPlot === 'yes'}
                                onChange={handleSelectionChange}
                                className="ml-4 mt-[10px] mr-2"
                              />
                           {t('Yes')}
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="cornerPlot"
                                value="no"
                                checked={isCornerPlot === 'no'}
                                onChange={handleSelectionChange}
                                className="ml-4 mt-[10px] mr-2"
                              />
                         {t('No')}
                            </label>
                          </div>
                          {validationMessagesResiLandPlot.isCornerPlot && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesResiLandPlot.isCornerPlot')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label
                            htmlFor="dynamic-options"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                          {t('No of open sides')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dynamic-options"
                            value={selectedOpenSide}
                            onChange={handleOpenSideChange}
                            className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                             <option value=""> {t('Select an option')}</option>
                            {[1, 2, 3, 4].map((side) => (
                              <option key={side} value={side}>
                                {side}
                              </option>
                            ))}
                          </select>
                          {validationMessagesResiLandPlot.selectedOpenSide && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesResiLandPlot.selectedOpenSide')}
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label
                            htmlFor="roadWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] "
                          >
                           {t('Width of road facing the plot')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            id="roadWidthInput"
                            type="text"
                            value={roadWidth}
                            onChange={handleRoadWidthChange}
                            placeholder={t('roadWidthPlaceholder')}
                            className="mt-1 block w-full py-2 px-3 border  h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          {validationMessagesResiLandPlot.roadWidth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesResiLandPlot.roadWidth')}
                            </p>
                          )}
                        </div>
                        <div className="col-span-2 grid grid-cols-3 gap-3">
                          <div className="">
                            <div className="flex justify-start">
                              <span className="block text-[14px] font-[600] leading-[30px] mt-4 text-[#1E2858]">
                              {t('Construction Done')}
                              </span>
                              <label className="ml-4">
                                <input
                                  type="radio"
                                  name="anyConstruction"
                                  value="yes"
                                  checked={anyConstruction === 'yes'}
                                  onChange={handleAnyConstructionChange}
                                  className="mr-2 mt-6"
                                />
                                     {t('Yes')}
                              </label>
                              <label className="ml-3">
                                <input
                                  type="radio"
                                  name="anyConstruction"
                                  value="no"
                                  checked={anyConstruction === 'no'}
                                  onChange={handleAnyConstructionChange}
                                  className="mr-2 mt-6"
                                />
                             {t('No')}
                              </label>
                            </div>
                            {validationMessagesResiLandPlot.anyConstruction && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.anyConstruction')}
                              </p>
                            )}
                          </div>
                          <div className="">
                            <div className=" mt-4 flex justify-start">
                              <span className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('Boundary Wall')}
                              </span>
                              <div className="flex">
                                <label className="flex">
                                  <input
                                    type="radio"
                                    name="boundaryWall"
                                    value="yes"
                                    checked={selectedOptions === 'yes'}
                                    onChange={handleOptionChanges}
                                   className="ml-4 mr-2 mt-2" 
                                  /> 
                                    {t('Yes')}
                                </label>
                                <label className=" flex">
                                  <input
                                    type="radio"
                                    name="boundaryWall"
                                    value="no"
                                    checked={selectedOptions === 'no'}
                                    onChange={handleOptionChanges}
                                    className="ml-4 mr-2 mt-2"
                                  />
                                          {t('No')}
                                </label>
                              </div>
                            </div>
                            {validationMessagesResiLandPlot.selectedOptions && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.selectedOptions')}
                              </p>
                            )}
                          </div>
                          <div className="">
                            <div className="  flex justify-start">
                              <span className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] mt-4">
                              {t('Gated Colony')}
                              </span>
                              <label>
                                <input
                                  type="radio"
                                  name="gatedColony"
                                  value="yes"
                                  checked={gatedColony === 'yes'}
                                  onChange={(event) =>
                                    handleSelectionChanged(event, setGatedColony, setGatedColonyError)
                                  }
                                  className="ml-4 mr-2 mt-6"
                                />
                                {t('Yes')}
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name="gatedColony"
                                  value="no"
                                  checked={gatedColony === 'no'}
                                  onChange={(event) =>
                                    handleSelectionChanged(event, setGatedColony, setGatedColonyError)
                                  }
                                  className="ml-4 mr-2 mt-6"
                                />
                              {t('No')}
                              </label>
                            </div>
                            {validationMessagesResiLandPlot.gatedColony && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('validationMessagesResiLandPlot.gatedColony')}
                              </p>
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                                <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {validationMessagesResiLandPlot.fromCurrency && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('validationMessagesResiLandPlot.currency')}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                        {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {validationMessagesResiLandPlot.amount && (
                            <p className="text-[12px] text-red-500 font-[400]">{t('validationMessagesResiLandPlot.amount')}</p>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {basicData.selectedSubType === 'Villa' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="col-span-2 grid grid-cols-3 gap-3">
                        <div>
                          <label
                            htmlFor="inputUnit"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                             {t('Select Unit')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={inputUnit}
                            onChange={handleInputUnitChange}
                            className=" mt-[5px] p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            {units.map((unit) => (
                              <option key={unit} value={unit}>
                              {t(`units.${unit}`)}
                              </option>
                            ))}
                          </select>
                          {villaValidation.inputUnit && (
                            <p className="text-[12px] text-red-500 font-[400]">  {t('villaValidation.inputUnit')}</p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotLengthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                               {t('Plot Length')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            type="number"
                            value={length}
                            onChange={handleLengthChange}
                            placeholder={t('lengthPlaceholder')}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {villaValidation.length && (
                            <p className="text-[12px] text-red-500 font-[400]">{villaValidation.length}</p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Plot Breath')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>

                          <input
                            type="number"
                            value={breadth}
                             placeholder={t('breadthPlaceholder')}
                            onChange={handleBreadthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {villaValidation.breadth && (
                            <p className="text-[12px] text-red-500 font-[400]">{villaValidation.breadth}</p>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-cols-2 gap-3 mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                        {t('Output Unit')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          <select
                            value={outputUnit}
                            onChange={handleOutputUnitChange}
                            className=" mt-0 p-2 w-full thirdCustom border-[1px] h-10   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          > <option value="">{t('Select Unit')}</option>
                          <option value="acres">{t('Acres')}</option>
                          <option value="hectares">{t('Hectares')}</option>
                          <option value="sqft">{t('Square Feet')}</option>
                          <option value="sqyards">{t('Square Yards')}</option>
                          <option value="sqmt">{t('Square Meters')}</option>
                          </select>
                          {villaValidation.outputUnit && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.outputUnit')}
                            
                            </p>
                          )}
                        </label>

                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                        {t('Total Area')}
                          <input
                            type="text"
                            value={getArea()}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          ></input>
                        </label>
                      </div>

                      <div className="grid grid-cols-2  gap-4 mt-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {villaValidation['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('villaValidation.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                   {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {villaValidation['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('villaValidation.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label
                            htmlFor="kitchen-select"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                                {t('Kitchen')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="kitchen-select"
                            value={selectedKitchen}
                            onChange={handleKitchenChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                 <option value="">{t('Select Kitchen')}</option>
                            {kitchen.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {villaValidation.selectedKitchen && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.kitchen')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="living-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                           {t('Living Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="living-room"
                            value={selectedLivingRoom}
                            onChange={handleLivingRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                       <option value="">{t('Select Living Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {villaValidation.selectedLivingRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.selectedLivingRoom')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label htmlFor="hall" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Hall')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="hall"
                            value={selectedHall}
                            onChange={handleHallChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                              <option value="">{t('Select Hall')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {villaValidation.selectedHall && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.selectedHall')}
                            </p>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="dining-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                          {t('Dining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dining-room"
                            value={selectedDiningRoom}
                            onChange={handleDiningRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                             <option value=""> {t('Select Dining Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {villaValidation.selectedDiningRoom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.dining_room')}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          {villaValidation.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.totalFloors')}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Facing Direction')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="facing"
                            value={facing}
                            onChange={handleDirectionChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Facing Direction')}</option>
                            {['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'].map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.facing && (
                            <p className="text-[12px] text-red-500 font-[400]">
                           
                            {t('validationMessagesFlat.facing')}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('validationMessagesFlat.bathrooms')}  
                            </p>
                          )}
                        </div>


                        <div className="">
                          <label
                            htmlFor="dynamic-options"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('No of open sides')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dynamic-options"
                            value={selectedOpenSide}
                            onChange={handleOpenSideChange}
                            className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                          <option value="">     {t('Select an option')}</option>
                            {openSides.map((side) => (
                              <option key={side} value={side}>
                                {side}
                              </option>
                            ))}
                          </select>
                          {villaValidation.selectedOpenSide && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.selectedOpenSide')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Number of Bedrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <select
                              value={numBedrooms}
                              onChange={handleNumBedroomsChange}
                              className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                            >
                              <option value="">Select Bedrooms</option>
                              {noofbedroom.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                            {villaValidation.numBedrooms && (
                              <p className="text-[12px] text-red-300 font-[400]">
                              {t('villaValidation.numBedrooms')}
                              </p>
                            )}
                          </label>
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                              {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {villaValidation.status && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.possession_status')}
                            </p>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                  {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {villaValidation.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('villaValidation.availabilityMonth')}
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {villaValidation.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('villaValidation.availabilityYear')}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                              {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>

                          {villaValidation.furnishedStatus && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.furnished_status')}
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>

                          {villaValidation.balconyCount && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.balcony_count')}
                            </p>
                          )}
                        </div>

                        <div className="">
                          <label
                            htmlFor="roadWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] "
                          >
                                  {t('Width of road facing the plot')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            id="roadWidthInput"
                            type="text"
                            value={roadWidth}
                            onChange={handleRoadWidthChange}
                            placeholder={t('roadWidthPlaceholder')}
                            className="mt-1 block w-full py-2 px-3 border  h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />

                          {villaValidation.roadWidth && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.roadWidth')}
                            </p>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] mt-2 focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                             <option value=""> {t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {villaValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">  {t('villaValidation.currency')}</span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                         {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px] mb-0 border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          />
                          {villaValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('villaValidation.amount')}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {basicData.selectedSubType === 'BuilderFloor' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {builderValidation['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('builderValidation.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                  {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {builderValidation['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('builderValidation.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {builderValidation.totalFloors && (
                            <p className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.totalFloors')}
                            </p>
                          )}
                        </div>

                     
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Facing Direction')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="facing"
                            value={facing}
                            onChange={handleDirectionChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Facing Direction')}</option>
                            {['East', 'West', 'North', 'South', 'North-East', 'North-West', 'South-East', 'South-West'].map((option) => (
                              <option key={option} value={option}>
                                {t(option)}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.facing && (
                            <p className="text-[12px] text-red-500 font-[400]">
                           
                            {t('validationMessagesFlat.facing')}
                            </p>
                          )}
                        </div>


                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {validationMessagesFlat.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('validationMessagesFlat.bathrooms')}  
                            </p>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="kitchen-select"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('Kitchen')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="kitchen-select"
                            value={selectedKitchen}
                            onChange={handleKitchenChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                         <option value="">  {t('Select Kitchen')}</option>
                            {kitchen.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {builderValidation.selectedKitchen && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.kitchen')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="living-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                              {t('Lining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="living-room"
                            value={selectedLivingRoom}
                            onChange={handleLivingRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                              <option value="">{t('Select Living Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {builderValidation.selectedLivingRoom && (
                            <span className="text-[12px] text-red-500 fonr-[400]">
                              
                            {t('builderValidation.selectedLivingRoom')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label htmlFor="hall" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Hall')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="hall"
                            value={selectedHall}
                            onChange={handleHallChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                            <option value="">{t('Select Hall')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {builderValidation.selectedHall && (
                            <span className="text-[12px] text-red-500 fonr-[400]">
                            {t('builderValidation.selectedHall')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="dining-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                              {t('Dining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dining-room"
                            value={selectedDiningRoom}
                            onChange={handleDiningRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                           <option value=""> {t('Select Dining Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {builderValidation.selectedDiningRoom && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.dining_room')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Number of Bedrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <select
                              value={numBedrooms}
                              onChange={handleNumBedroomsChange}
                              className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                            >
                             <option value="">   {t('Select Bedrooms')}</option>
                              {noofbedroom.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                            {builderValidation.numBedrooms && (
                              <p className="text-[12px] text-red-500 font-[400]">
                              {t('builderValidation.numBedrooms')}
                              </p>
                            )}
                          </label>
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                     {t(`transactionTypes.${type.value}`)}
                              </option>
                            ))}
                          </select>
                          {builderValidation.status && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.possession_status')}
                            </span>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                  {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {builderValidation.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('builderValidation.availabilityMonth')}
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {builderValidation.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('builderValidation.availabilityYear')}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                   {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>
                          {builderValidation.furnishedStatus && (
                            <span className="text-[12px] text-red-500 font-[400]">
                                   
                            {t('builderValidation.furnished_status')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10 border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                          {builderValidation.balconyCount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.balcony_count')}
                            </span>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                                <option value="">{t('Select Currency')}</option>

                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {builderValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">
                           
                            {t('builderValidation.currency')}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                                {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {builderValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('builderValidation.amount')}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'Agriculture' && (
                  <div className=" ">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="p-10">
                      <div className="">
                        <label
                          htmlFor="dynamic-options"
                          className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                        >
                           {t('No of open sides')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                        </label>
                        <select
                          id="dynamic-options"
                          value={selectedOpenSide}
                          onChange={handleOpenSideChange}
                          className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        >
                                   <option value="">  {t('Select an option')}</option>
                          {openSides.map((side) => (
                            <option key={side} value={side}>
                              {side}
                            </option>
                          ))}
                        </select>
                        {agriValidation.selectedOpenSide && (
                          <span className="text-[12px] text-red-500 font-[400]">
                          {t('agriValidation.selectedOpenSide')}
                          </span>
                        )}
                      </div>

                      <div className="flex flex-col items-start mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                        {t('Width of road facing the plot')}
                        </label>
                        <input
                          type="text"
                          value={roadWidth}
                          onChange={handleRoadWidthChange}
                          placeholder={t('roadWidthPlaceholder')}
                          className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        {agriValidation.roadWidth && (
                          <span className="text-[12px] text-red-500 font-[400]">
                          {t('agriValidation.roadWidth')}
                          </span>
                        )}
                      </div>

                      <div className="">
                        <div className="flex justify-start mt-4">
                          <h1 className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">{t('Boundary Wall')}</h1>
                          <label className="flex  items-center">
                            <input
                              type="radio"
                              name="boundaryWall"
                              value="yes"
                              checked={selectedOptions === 'yes'}
                              onChange={handleOptionChanges}
                              className=" mt-1 ml-2 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />
                            <span className="ml-2 block text-[14px] font-[600] leading-[30px]">{t('Yes')}</span>
                          </label>
                          <label className="inline-flex items-center ">
                            <input
                              type="radio"
                              name="boundaryWall"
                              value="no"
                              checked={selectedOptions === 'no'}
                              onChange={handleOptionChanges}
                              className=" mt-1 ml-2 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />
                            <span className="ml-2 block text-[14px] font-[600] leading-[30px] ">{t('No')}</span>
                          </label>
                        </div>
                        {agriValidation.selectedOptions && (
                          <span className="text-[12px] text-red-500 font-[400]">
                          {t('agriValidation.selectedOptions')}
                          </span>
                        )}
                      </div>

                      <div className="col-span-2 grid grid-cols-3 gap-3">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Select Unit')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={inputUnit}
                            onChange={handleInputUnitChange}
                            className=" mt-[5px] p-2 w-full thirdCustom border-[1px] h-10  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          > {units.map((unit) => (
                            <option key={unit} value={unit}>
                            {t(`units.${unit}`)}
                            </option>
                          ))}
                          </select>
                          {agriValidation.inputUnit && (
                            <span className="text-[12px] text-red-500 font-[400]">   {t('agriValidation.inputUnit')}</span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotLengthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                                {t('Plot Length')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            type="number"
                            value={length}
                            onChange={handleLengthChange}
                            placeholder={t('lengthPlaceholder')}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {agriValidation.length && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('agriValidation.length')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="plotWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Plot Breath')}
                          </label>

                          <input
                            type="number"
                            value={breadth}
                            placeholder={t('breadthPlaceholder')}
                            onChange={handleBreadthChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          />
                          {agriValidation.breadth && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('agriValidation.breadth')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 grid grid-cols-2 gap-3 mt-4">
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                        {t('Output Unit')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          <select
                            value={outputUnit}
                            onChange={handleOutputUnitChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                           <option value="">{t('Select Unit')}</option>
          <option value="acres">{t('Acres')}</option>
          <option value="hectares">{t('Hectares')}</option>
          <option value="sqft">{t('Square Feet')}</option>
         <option value="sqyards">{t('Square Yards')}</option>
           <option value="sqmt">{t('Square Meters')}</option>
                          </select>
                          {agriValidation.outputUnit && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('agriValidation.outputUnit')}
                            </span>
                          )}
                        </label>

                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                        {t('Total Area')}
                          <input
                            type="text"
                            value={getArea()}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px] h-10   border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          ></input>
                        </label>
                      </div>
                      <div className="">
                        <div className="flex justify-start mt-4">
                          <h1 className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currently Leased Out')}
                          </h1>

                          <label className="flex">
                            <input
                              type="radio"
                              name="leasedOut"
                              value="yes"
                              checked={selectedOptioning === 'yes'}
                              onChange={handleOptionChanging}
                              className="mt-1 ml-2 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />
                            <span className="ml-2 block text-[14px] font-[600] leading-[30px] ">{t('Yes')}</span>
                          </label>
                          <label className="flex mt-0">
                            <input
                              type="radio"
                              name="leasedOut"
                              value="no"
                              checked={selectedOptioning === 'no'}
                              onChange={handleOptionChanging}
                              className="mt-1 p-2 ml-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                            />
                            <span className="ml-2 block text-[14px] font-[600] leading-[30px] ">{t('No')}</span>
                          </label>
                        </div>
                        {agriValidation.selectedOptioning && (
                          <span className="text-[12px] text-red-500 font-[400]">    {t('agriValidation.selectLeaseOutStatus')}</span>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-5 mt-4">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 outline-none  p-2 w-full    thirdCustom"
                          >
                       <option value="">   {t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {agriValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">{agriValidation.fromCurrency}</span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                            {t('Amount')}<sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {agriValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">{agriValidation.amount}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-span-2 pb-8">
                        {Object.keys(convertedValues).length > 0 && (
                          <div className="result flex ">
                            <p className="p-3">
                              {amount} {fromCurrency} is approximately:
                            </p>
                            {Object.entries(convertedValues).map(([currency, value]) => (
                              <p key={currency} className="p-3">
                                {value} {currency}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {basicData.selectedSubType === 'Farmhouse' && (
                  <div className="">
                    <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Property Details')}
                    </h1>
                    <div className="m-10 h-auto">
                      <div className="grid grid-cols-2  gap-4">
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Super Area')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <div className="flex flex-row  w-[100%] ">
                            <div className="flex flex-col  w-[80%]">
                              <input
                                type="text"
                                name="super_area"
                                value={property.area.super_area.value}
                                placeholder={t('superAreaPlaceholder')}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2  thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              />
                              {farmhouseValidation['property.area.super_area.value'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                 
                                {t('farmhouseValidation.property.area.super_area.value')}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col w-[20%]">
                              <select
                                name="unit_super_area"
                                value={property.area.super_area.unit}
                                onChange={(e) => handleAreaChange(e, 'super_area')}
                                className="mt-1 p-2 thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="" className="text-[14px]">
                                {t('Select Unit')}
                                </option>
                                {property.unitOptions.map((option) => (
                                  <option key={option} value={option} className="capitalize text-[14px] font-[400]">
                                    {t(`unitOptions.${option}`)}
                                  </option>
                                ))}
                              </select>
                              {farmhouseValidation['property.area.super_area.unit'] && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('farmhouseValidation.property.area.super_area.unit')}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                          {t('Total Number of Floors')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="total_floors"
                            value={totalFloors}
                            onChange={handleTotalFloors}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Total Floors')}
                            </option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
                              26, 27, 28, 29, 30
                            ].map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          {farmhouseValidation.totalFloors && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.totalFloors')}
                            </span>
                          )}
                        </div>

                        <div>
                          <label
                            htmlFor="kitchen-select"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                                 {t('Kitchen')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="kitchen-select"
                            value={selectedKitchen}
                            onChange={handleKitchenChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                            <option value="">{t('Select Kitchen')}</option>
                            {kitchen.map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.selectedKitchen && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.totalFloors')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="living-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                           {t('Living Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="living-room"
                            value={selectedLivingRoom}
                            onChange={handleLivingRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                           <option value="">{t('Select Living Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.selectedLivingRoom && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.selectedLivingRoom')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label htmlFor="hall" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Hall')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="hall"
                            value={selectedHall}
                            onChange={handleHallChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                            <option value="">{t('Select Hall')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.selectedHall && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.selectedHall')}
                            </span>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="dining-room"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('Dining Room')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dining-room"
                            value={selectedDiningRoom}
                            onChange={handleDiningRoomChange}
                            className="mt-1 p-2 w-full border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                          >
                            <option value=""> {t('Select Dining Room')}</option>
                            {[
                              0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
                              25, 26, 26, 27, 28, 29, 30
                            ].map((num) => (
                              <option key={num} value={num}>
                                {num}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.selectedDiningRoom && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.dining_room')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Balconies')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={balconyCount}
                            onChange={handleBalconyChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select Balcony Count')}
                            </option>
                            {balconies.map((count) => (
                              <option key={count} value={count}>
                                {count}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.balconyCount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.balcony_count')}
                            </span>
                          )}
                        </div>

                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Number of Bedrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <select
                              value={numBedrooms}
                              onChange={handleNumBedroomsChange}
                              className="mt-1 p-2 w-full thirdCustom border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none"
                            >
                             <option value=""> {t('Select Bedrooms')}</option>
                              {noofbedroom.map((i) => (
                                <option key={i} value={i}>
                                  {i}
                                </option>
                              ))}
                            </select>
                            {farmhouseValidation.numBedrooms && (
                              <p className="text-[12px] text-red-300 font-[400]">
                              {t('farmhouseValidation.numBedrooms')}
                              </p>
                            )}
                          </label>
                        </div>
                        
     <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  ">
                            {t('Bathrooms')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            name="bathrooms"
                            value={bathroom}
                            onChange={handleBathroomChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">{t('Select Bathrooms')}</option>
                            {[
                              1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,26,27,28,29,30
                            ].map((item) => (
                              <option key={item} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.bathroom && (
                            <p className="text-[12px] text-red-500 font-[400]">
                       
                            {t('farmhouseValidation.bathrooms')}  
                            </p>
                          )}
                        </div>
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Possession Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={status}
                            onChange={handleStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Status')}
                            </option>
                            {transactionTypes.map((type) => (
                              <option key={type.value} value={type.value}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.status && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.possession_status')}
                            </span>
                          )}
                        </div>
                        {status === 'under_construction' && (
                          <div className="grid grid-cols-2 gap-2">
                            <div className="">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                              {t('AvailabilityMonth')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityMonth}
                                onChange={handleAvailabilityMonthChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] capitalize focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Month')}
                                </option>
                                {availability.month.map((month) => (
                                  <option key={month} value={month} className="capitalize">
                                  {t(`month.${month}`)}
                                  </option>
                                ))}
                              </select>
                              {farmhouseValidation.availabilityMonth && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('farmhouseValidation.availabilityMonth')}
                                </p>
                              )}
                            </div>
                            <div className="mt-0">
                              <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                              {t('AvailabilityYear')}
                                <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                              </label>
                              <select
                                value={availabilityYear}
                                onChange={handleAvailabilityYearChange}
                                className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                              >
                                <option value="">
                                {t('Select Year')}
                                </option>
                                {availability.year.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </select>
                              {farmhouseValidation.availabilityYear && (
                                <p className="text-[12px] text-red-500 font-[400]">
                                {t('farmhouseValidation.availabilityYear')}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                        <div className="">
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] ">
                          {t('Furnished Status')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <select
                            value={furnishedStatus}
                            onChange={handleFurnishedStatusChange}
                            className=" mt-1 p-2 w-full thirdCustom border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none"
                          >
                            <option value="">
                            {t('Select a Furnished Status')}
                            </option>
                            {furnishedOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                              {t(`furnishedOptions.${option.value}`)}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.furnishedStatus && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.furnished_status')}
                            </span>
                          )}
                        </div>

                        <div className="">
                          <label
                            htmlFor="dynamic-options"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]"
                          >
                            {t('No of open sides')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          </label>
                          <select
                            id="dynamic-options"
                            value={selectedOpenSide}
                            onChange={handleOpenSideChange}
                            className="mt-1 block w-full py-2 px-3 border h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                             <option value="">{t('Select an option')}</option>
                            {openSides.map((side) => (
                              <option key={side} value={side}>
                                {side}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.selectedOpenSide && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.selectedOpenSide')}
                            </span>
                          )}
                        </div>
                        <div className="">
                          <label
                            htmlFor="roadWidthInput"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858] "
                          >
                               {t('Width of road facing the plot')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>
                          </label>
                          <input
                            id="roadWidthInput"
                            type="type"
                            value={roadWidth}
                            onChange={handleRoadWidthChange}
                            placeholder={t('roadWidthPlaceholder')}
                            className="mt-1 block w-full py-2 px-3 border  h-10 border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                          {farmhouseValidation.roadWidth && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.roadWidth')}
                           
                            </span>
                          )}
                        </div>

                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Currency')}
                            <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <select
                            id="fromCurrency"
                            value={fromCurrency}
                            onChange={handleCurrencyChange}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] h-10 mt-1 outline-none  p-2 w-full    thirdCustom"
                          >
                           <option value="">{t('Select Currency')}</option>
                            {Object.keys(exchangeRates).map((currency) => (
                              <option key={currency} value={currency}>
                                {currency}
                              </option>
                            ))}
                          </select>
                          {farmhouseValidation.fromCurrency && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.currency')}
                            </span>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <label
                            htmlFor="amount"
                            className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]  "
                          >
                           {t('Amount')} <sup className="text-red-500 text-[14px] relative top-0 text-0">*</sup>{' '}
                          </label>
                          <input
                            type="number"
                            id="amount"
                            placeholder={t('amountPlaceholder')}
                            value={amount}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                handleAmountChange(e);
                              }
                            }}
                            className="border-[1px]  border-gray-300 rounded-[5px] focus:border-blue-500 focus:border-[1px] outline-none  p-2 w-full    thirdCustom"
                          />
                          {farmhouseValidation.amount && (
                            <span className="text-[12px] text-red-500 font-[400]">
                            {t('farmhouseValidation.amount')}
                            </span>
                          )}
                        </div>
                        <div className="col-span-2">
                          {Object.keys(convertedValues).length > 0 && (
                            <div className="result flex ">
                              <p className="p-3">
                                {amount} {fromCurrency} is approximately:
                              </p>
                              {Object.entries(convertedValues).map(([currency, value]) => (
                                <p key={currency} className="p-3">
                                  {value} {currency}
                                </p>
                              ))}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="">
                  <h1 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9] ">
                    {t('Location')}
                  </h1>
                  <div className="p-10">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Country')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          <select
                            value={selectedCountry || ''}
                            onChange={handleCountryChange}
                            className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1 p-2 w-full"
                          >
                            <option value="">Select</option>
                            {country?.map((data, index) =>
                              Object.keys(data).map((country) => (
                                <option key={index} value={country}>
                                  {country}
                                </option>
                              ))
                            )}
                          </select>
                          {locationValidation.selectedCountry && (
                            <span className="text-[12px] text-red-500 font-[400]">
                              {locationValidation.selectedCountry}
                            </span>
                          )}
                        </label>
                      </div>

                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('City')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          <select
                            value={selectedState || ''}
                            onChange={handleStateChange}
                            disabled={!selectedCountry}
                            className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1 p-2 w-full"
                          >
                            <option value="">Select</option>
                            {selectedCountry &&
                              Object.keys(country[0][selectedCountry]).map((state, index) => (
                                <option key={index} value={state}>
                                  {state}
                                </option>
                              ))}
                          </select>
                          {locationValidation.selectedState && (
                            <span className="text-[12px] text-red-500 font-[400]">
                              {locationValidation.selectedState}
                            </span>
                          )}
                        </label>
                      </div>

                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Location')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          <select
                            value={selectedCity || ''}
                            onChange={handleCityChange}
                            disabled={!selectedState}
                            className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1 p-2 w-full"
                          >
                            <option value="">Select</option>
                            {selectedState &&
                              country[0][selectedCountry][selectedState].map((city, index) => (
                                <option key={index} value={city}>
                                  {city}
                                </option>
                              ))}
                          </select>
                          {locationValidation.selectedCity && (
                            <span className="text-[12px] text-red-500 font-[400]">
                              {locationValidation.selectedCity}
                            </span>
                          )}
                        </label>
                      </div>

                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Address')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          <input
                            type="text"
                            name="Address"
                            value={searchQuery || ''}
                            onChange={handleSearchChange}
                            placeholder="Enter address"
                            className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1 p-1 w-full"
                          />
                          {/* {locationValidation.searchQuery && (
          <span className="text-[12px] text-red-500 font-[400]">{locationValidation.searchQuery}</span>
        )} */}
                        </label>
                      </div>

                      <div>
                        <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Zip Code')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                          <input
                            type="text"
                            name="zipCode"
                            value={zipCode || ''}
                            onChange={(e) => setZipCode(e.target.value)}
                            placeholder="Enter zip code"
                            className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1 p-1 w-full"
                          />
                          {locationValidation.zipCode && (
                            <span className="text-[12px] text-red-500 font-[400]">{locationValidation.zipCode}</span>
                          )}
                        </label>
                      </div>

                      <div>
                        <label htmlFor="street" className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                          {t('Street')}
                          <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                        </label>
                        <input
                          type="text"
                          id="street"
                          value={street || ''}
                          onChange={handleStreetChange}
                          className="border-[1px] border-gray-300 rounded-[5px] h-10 capitalize focus:border-blue-500 outline-none mt-1 p-1 w-full"
                        />
                        {locationValidation.street && (
                          <span className="text-[12px] text-red-500 font-[400]">{locationValidation.street}</span>
                        )}
                      </div>

                      <div className="col-span-2">
                        <div>
                          <label className="block text-[14px] font-[600] leading-[30px] text-[#1E2858]">
                            {t('Locality')}
                            <sup className="text-red-500 text-[14px] relative top-0">*</sup>
                            <input
                              type="text"
                              value={searchQuery}
                              onChange={handleSearchChange}
                              onKeyDown={handleKeyDowns}
                              className="border-[1px] border-gray-300 rounded-[5px] focus:border-blue-500 outline-none mt-1.5 p-1 w-full"
                              placeholder="Search location"
                            />
                            {/* {locationValidation.searchQuery && (
            <span className="text-[12px] text-red-500 font-[400]">{locationValidation.searchQuery}</span>
          )} */}
                          </label>
                          <button
                            onClick={handleSearchClick}
                            className='bg-[#1E2858] mt-3 capitalize mb-4 className="block w-full max-w-[200px] p-2
           text-white font-[400] mr-10 rounded-[7px] text-[16px] shadow-lg transform transition duration-300 ease-in-out hover:scale-105"'
                          >
                            {t('Search')}
                          </button>
                          <MapContainer
                            center={position}
                            zoom={13}
                            style={{ height: '500px', width: '100%', zIndex: '0' }}
                          >
                            <TileLayer
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker
                              position={position}
                              draggable={true}
                              eventHandlers={{
                                dragend: handleMarkerDragEnd
                              }}
                            >
                              <Popup>{selectedCity || 'Selected Location'}</Popup>
                            </Marker>
                            <MapViewUpdater position={position} />
                          </MapContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {basicData.selectedSubType && amenity[basicData.selectedSubType] && (
                  <div className="mb-4">
                    <h2 className="text-[20px] text-[#1E2858] font-[500] mb-4 p-4 thirdCustom bg-[#D9D9D9]">
                      Amenities
                    </h2>
                    {Object.keys(amenity[basicData.selectedSubType]).map((category) => {
                      // Change specific category names
                      const displayCategory =
                        category === 'near_localities'
                          ? 'NearLocality'
                          : category === 'property_features'
                          ? 'PropertyFeature'
                          : category;
                      return (
                        <div key={category} className="m-10">
                          <h3 className="text-[17px] text-[#1E2858] font-[500] capitalize mb-3">{displayCategory}</h3>
                          <div className="grid grid-cols-3">
                            {amenity[basicData.selectedSubType][category].map((amenity) => {
                              const isChecked =
                                oneData.amenities[category] &&
                                oneData.amenities[category].find((item) => item.name === amenity.name)?.checked;
                              return (
                                <div key={amenity.name} className="flex justify-start mb-2">
                                  <input
                                    type="checkbox"
                                    checked={isChecked || false}
                                    onChange={(e) => handleAmenityChanges(e, category, amenity.name)}
                                    className="appearance-none w-4 h-4 mt-1 border border-gray-300 rounded-full checked:bg-[#1E2858] peer mr-2"
                                  />
                                  <span className="mr-2 mb-1">
                                    <img
                                      src={amenity.emoji}
                                      alt="icon"
                                      className={`emoji ${
                                        isChecked ? 'bg-[#EAF7FF]' : 'bg-transparent'
                                      } p-1 rounded-[5px] mt-0 w-6 h-6`}
                                    />
                                  </span>
                                  <label
                                    onClick={() => handleAmenityChanges(null, category, amenity.name)}
                                    className="mb-2 font-[400] text-[14px] capitalize text-[#1E2858] leading-[20px] thirdCustom flex items-center cursor-pointer"
                                  >
                                    {t(amenity.name)}
                                  </label>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="flex justify-end ">
                  <button
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                    className='bg-[#1E2858] capitalize mb-4 className="block w-full max-w-[200px] p-2
           text-white font-[400] mr-10 text-[16px] rounded-[7px] shadow-lg transform transition duration-300 ease-in-out hover:scale-105"'
                  >
                    {isSubmitting ? 'Registering...' : 'Register property'}
                  </button>
                </div>
                <ToastContainer />
              </div>

              <div
                className="lg:col-span-1 xl:col-span-1 2xl:col-span-13 3xl:col-span-1 xs:hidden sm:hidden md:hidden lg:block xl:block 2xl:block
                   fixed right-4 lg:w-[21%] 2xl:w-[17%] bg-gray-200 shadow-[0px_10px_1px_rgba(221,_221,_221,_1),_0_10px_20px_rgba(204,_204,_204,_1)] h-auto"
              >
                <div class="bg-white flex flex-row justify-center gap-6 p-6   ">
                  <ul className="list-disc relative ">
                    {items.map((item) => (
                      <li key={item.id} className={`  relative mt-0 ${item.id !== 0 && 'mt-2'}`}>
                        <div
                          className={`text-[#1E2858] font-[600] text-[16px] py-2  thirdCustom cursor-pointer${
                            item.id === 4 ? '' : 'r'
                          }`}
                          onClick={() => handleClick(item.id)}
                        >
                            {t(`${item.label}`)}
                          {item.percentage !== undefined && (
                            <div className="flex flex-row gap-5   ">
                              <div className="w-[130px] mt-3     h-2  bg-gray-500 rounded-[10px]">
                                <span
                                  className={` h-2 rounded-full block transition-width duration-500 ${getProgressColor(
                                    item.percentage
                                  )}`}
                                  style={{ width: `${item.percentage}%` }}
                                ></span>
                                <span className="text-[12px] text-gray-400 font-[400]">{item.percentage}%/100%</span>
                              </div>

                              <div className="relative">
                                <button className="cursor-pointer text-[8px] text-[#7BCFF8]">
                                  <EditNoteIcon></EditNoteIcon>
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="flex flex-row  gap-3 bg-white mt-3 ">
                  <svg className="w-24 h-24 relative top-o xs:z-10 sm:z-10 md:z-10" viewBox="0 0 120 120">
                    <circle
                      className="text-gray-300"
                      strokeWidth="10"
                      stroke="currentColor"
                      fill="transparent"
                      r="50"
                      cx="60"
                      cy="60"
                    />
                    <circle
                      className="text-green-500"
                      strokeWidth="10"
                      strokeDasharray={circumference}
                      strokeDashoffset={progress}
                      strokeLinecap="round"
                      stroke="currentColor"
                      fill="transparent"
                      r="50"
                      cx="60"
                      cy="60"
                    />
                    <foreignObject x="10" y="10" width="100" height="100">
                      <div xmlns="http://www.w3.org/1999/xhtml" className="flex items-center justify-center h-full">
                        <p className="text-center text-black">{finaloutput}%</p>
                      </div>
                    </foreignObject>
                  </svg>
                  <p className="mt-5 leading-[20px] ">
                    <span className="text-[#1E2858] font-[600] text-[16px]  thirdCustom  cursor-pointer">
                    {t('Property score')}
                    </span>
                    <br />
                    <span className="text-[11px] font-[400] pr-6 leading-[10px] text-slate-900 thirdCustom ">
                      {' '}
                      {t('Better your property score,greater your visibility')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <p>
          {basicData.selectedSubType === 'FlatApartment' && property_id && <ImageFlat propertyId={property_id} />}
          {basicData.selectedSubType === 'ResidentialHouse' && property_id && (
            <ImageResidential propertyId={property_id} />
          )}
          {basicData.selectedSubType === 'ResidentialLandPlot' && property_id && (
            <ImageResiLand propertyId={property_id} />
          )}
          {basicData.selectedSubType === 'Villa' && property_id && <ImageVilla propertyId={property_id} />}
          {basicData.selectedSubType === 'BuilderFloor' && property_id && <ImageBuilder propertyId={property_id} />}
          {basicData.selectedSubType === 'CommercialShop' && property_id && <ImageCommShop propertyId={property_id} />}
          {basicData.selectedSubType === 'CommercialLand' && property_id && <ImageCommLand propertyId={property_id} />}
          {basicData.selectedSubType === 'CommercialItPark' && property_id && <ImageItPark propertyId={property_id} />}
          {basicData.selectedSubType === 'IndustrialLand' && property_id && (
            <ImageIndustrialLand propertyId={propertyId} />
          )}
          {basicData.selectedSubType === 'Agriculture' && property_id && <ImageAgriCul propertyId={property_id} />}
          {basicData.selectedSubType === 'Farmhouse' && property_id && <ImageFarmHouse propertyId={property_id} />}
        </p>
      )}
    </div>
  );
};

export default FormWithCompletionPercentage;
