import React, { useState, useEffect } from 'react';
import _debounce from 'lodash/debounce';
import { useMediaQuery, useTheme } from '@mui/material';
import 'tailwindcss/tailwind.css';
import FilterPage from '../componenet/layout/FilterPage';
import Heropage from './HeroPage';
import ChooseUs from './ChooseUs';
import PropertyDetails from './PropertyDetals';
import DeveloperServicePage from './DeveloperServicePage';
import BrowserProperty from './BrowserProperty';
import BestProperty from './BestProperty';
import BestResidential from './BestResidential';
import VerifyPropertyPage from './VerifyPropertyPage';
import BecomAgent from './BecomAgent';
import MorePropertyDetails from '../page/PropertyDetails/MorePropertyDetails';
import MobileMainPage from '../componenet/layout/UI/SelectFilter/MobileMainPageFilter';
import BlogPage from './BlogPage';
import "./main.css"
import { useTranslation } from 'react-i18next';

const MainPage = () => {
  const [scrolling, setScrolling] = useState(false);
  const theme = useTheme();
  const isCustomQuery = useMediaQuery('(min-width:1050px)');
  const { t } = useTranslation();
  useEffect(() => {
    const handleScroll = _debounce(() => {
      setScrolling(window.scrollY > 0);
    }, 200);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="">
      <div className=" bg-[url('https://d34yb5orp91dp6.cloudfront.net/header%20Image.png')] mt-[70px] bg-cover bg-center h-[500px] w-[100%]  flex flex-col text-white">
        <div className="flex flex-col justify-center items-center">
          <h1 className="text-white font-[500] text-[24px] sm:text-[30px] md:text-[30px] xl:text-[38px] lg:text-[40px] 2xl:text-[45px] md:text-justify mt-24 md:ml-0 ml-4">
            {t('Find Real Estate and Get Your Dream Space')}
          </h1>
          <h3 className="xs:text-[14px] sm:text-[14px]  md:text-[16px] xl:text-[22px] lg:text-[24px] 2xl:text-[27px] font-[400] m-4 text-justify text-white  mt-5 bg-clip-text bg-gradient-to-r from-purple-400 via-pink-500 to-red-500  drop-shadow-md">
            {t(
              'We are a real estate agency that will help you find the best residence you dream of, let’s discuss your dream house?'
            )}
          </h3>
          <div className="w-full mt-16 flex justify-center">{isCustomQuery ? <FilterPage /> : <MobileMainPage />}</div>
        </div>
      </div>

      
      <div className="mx-auto max-w-7xl">
      
          <div className="">
            <Heropage />
          </div>
          <div className="">
            <ChooseUs />
          </div>
          <div className="">
            <PropertyDetails />
          </div>
          <div className="">
            <DeveloperServicePage />
          </div>
          <div className="">
            <BrowserProperty />
          </div>
          <div className="">
            <BestProperty />
          </div>
          <div className="">
            <BestResidential />
          </div>
          <div className="">
            <VerifyPropertyPage />
          </div>
          <div className="">
            <BecomAgent />
          </div>
          <div className="">
            <MorePropertyDetails />
          </div>
          <div>
            <BlogPage />
          </div>
        </div>
      </div>
   
  );
};

export default MainPage;
