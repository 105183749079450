import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Amenities = () => {
  const { property_id } = useParams();
  const [amenities, setAmenities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAmenities = async () => {
      try {
        const response = await axios.get(
          `https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`
        );
        const amenitiesData = response.data.amenities || {};

        const amenitiesList = [
          { icon: 'https://squarebric-media-files.squarebric.com/garbage.svg', name: 'Garbage Collection', available: amenitiesData.facilities?.garbage },
          { icon: 'https://squarebric-media-files.squarebric.com/school.svg', name: 'Near School', available: amenitiesData.near_localities?.school },
          { icon: 'https://squarebric-media-files.squarebric.com/watering.svg', name: 'Garden', available: amenitiesData.property_feature?.garden },
          { icon: 'https://squarebric-media-files.squarebric.com/swimmingpool.svg', name: 'Swimming Pool', available: amenitiesData.option?.swimming_pool },
          { icon: 'https://squarebric-media-files.squarebric.com/garden.svg', name: 'Basement Parking', available: amenitiesData.parking?.basement_parking }
        ].filter((item) => item.available);

        setAmenities(amenitiesList);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAmenities();
  }, [property_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="mt-10 ml-6">
      <h2 className="text-[#1E2858] font-[600] font-sixth flex text-[16px] lg:text-[24px]">Amenities</h2>
      {amenities.length === 0 ? (
        <p className="text-[#2e4374] text-[16px] font-[600] mt-2 text-center">Amenities not available.</p>
      ) : (
        <div className="grid md:grid-cols-4 grid-cols-2 sm:grid-cols-2 md:p-0 sm:p-4 p-0 leading-8 justify-center mt-4">
          {amenities.map((amenity, index) => (
            <div key={index} className="flex items-center mb-4">
              <img
                className="bg-[#FAD13B] rounded-md hover:bg-[#DACEC3] p-1"
                src={amenity.icon}
                alt={amenity.name}
                width={30}
                height={30}
              />
              <div className="text-[14px] md:text-[16px] lg:text-[20px] font-400 text-[#2e4374] ml-4 font-sixth flex-grow">
                {amenity.name}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Amenities;
