// serviceSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchServiceDetails = createAsyncThunk(
  'service/fetchServiceDetails',
  async (serviceUuid) => {
    console.log('Fetching service details for:', serviceUuid); // Debugging line
    const response = await axios.get(`https://square-bric-backend-api.squarebric.com/home-service/home-service-details?service_uuid=${serviceUuid}`);
    console.log('Response data:', response.data); // Debugging line
    return response.data;
  }
);

const serviceSlice = createSlice({
  name: 'service1',
  initialState: {
    service: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceDetails.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchServiceDetails.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.service = action.payload;
      })
      .addCase(fetchServiceDetails.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default serviceSlice.reducer;
