import React, { useState, useEffect } from 'react';

const data = [
  {
    date: "10/10/2023",
    name: "Preeti Singh",
    email: "Preetsingh@Gmail.Com",
    phone: "8880000000",
    location: "Lucknow, India",
    message: "A Viewing For This ...",
    property: "Damac Lagoons",
    status: "Approve"
  },
  {
    date: "10/10/2023",
    name: "Preeti Singh",
    email: "Preetsingh@Gmail.Com",
    phone: "8880000000",
    location: "Lucknow, India",
    message: "A Viewing For This ...",
    property: "Damac Lagoons",
    status: "Pending"
  },
  {
    date: "10/10/2023",
    name: "Preeti Singh",
    email: "Preetsingh@Gmail.Com",
    phone: "8880000000",
    location: "Lucknow, India",
    message: "A Viewing For This ...",
    property: "Damac Lagoons",
    status: "Pending"
  },
  {
    date: "10/10/2023",
    name: "Preeti Singh",
    email: "Preetsingh@Gmail.Com",
    phone: "8880000000",
    location: "Lucknow, India",
    message: "A Viewing For This ...",
    property: "Damac Lagoons",
    status: "Pending"
  }
  // Add more entries as needed
];

function PropertyInquery() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data);
  }, []);

  return (
    <div className="App">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200">
          <thead>
            <tr className="w-full bg-[#2E4374] text-white">
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Date</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Name</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Email Id</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Phone No.</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Location</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Message</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Property Name</th>
              <th className="py-2 px-2 md:px-4 font-[600] text-[14px] leading-[19px]">Status</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item, index) => (
              <tr key={index} className="border-t border-gray-200">
                <td className="py-2 px-2 md:px-4 text-[#2E4374] font-[600] text-[16px] leading-[19px]">{item.date}</td>
                <td className="py-2 px-2 md:px-4 font-[600] text-[16px] text-[#2E4374] leading-[19px]">{item.name}</td>
                <td className="py-2 px-2 md:px-4 font-[600] text-[16px] text-[#2E4374] leading-[19px]">{item.email}</td>
                <td className="py-2 px-2 md:px-4 text-[#25CD25] font-[600] text-[16px] leading-[19px]">{item.phone}</td>
                <td className="py-2 px-2 md:px-4 font-[600] text-[16px] text-[#2E4374] leading-[19px]">{item.location}</td>
                <td className="py-2 px-2 md:px-4 font-[300] text-[14px] text-[#000000] leading-[25px] tracking-widest">{item.message}</td>
                <td className="py-2 px-2 md:px-4 font-[400] text-[12px] text-[#1E2858] leading-[15px] tracking-widest text-center">{item.property}</td>
                <td className="py-2 px-2 md:px-4">
                  <span
                    className={`inline-block px-3 py-1 border rounded-[12px] p-4 ${
                      item.status === 'Approve'
                        ? 'bg-green-100 text-[#25CD25] text-[14px] font-[500] leading-[17px] p-4'
                        : 'bg-red-100 text-[#F9584E] text-[14px] font-[500]'
                    }`}
                  >
                    {item.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PropertyInquery;
