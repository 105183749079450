import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Photos = () => {
  const { property_id } = useParams();
  const [imageData, setImageData] = useState(null);
  const [categories, setCategories] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visibleRange, setVisibleRange] = useState([0, 6]);
  const [underlineStyle, setUnderlineStyle] = useState({});
  const isTabletScreen = useMediaQuery('(max-width:1040px)');
  const isMobileScreen = useMediaQuery('(max-width:768px)');
  const sliderRef = useRef(null);
  const thumbnailSliderRef = useRef(null);
  const buttonRefs = useRef([]);
  const [currentCategoryIndex, setCurrentCategoryIndex] = useState(0);

  const maxVisibleCategories = 5;

  // Calculate if there are more categories on either side
  const canSlideNext = currentCategoryIndex + maxVisibleCategories < Object.keys(categories).length;
  const canSlidePrev = currentCategoryIndex > 0;

  useEffect(() => {
    const fetchImageData = async () => {
      try {
        const response = await axios.get(
          `https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`
        );
        const imageDetails = response.data.ImageDetails;
        setImageData(imageDetails);
        const filteredCategories = {};
        Object.keys(imageDetails).forEach((key) => {
          if (key !== 'default' && imageDetails[key]?.length > 0) {
            filteredCategories[key] = key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
          }
        });
        
        setCategories(filteredCategories);

        // Set initially selected category to one of the active categories
        const validCategories = Object.keys(filteredCategories);
        if (validCategories.length > 0) {
          setSelectedCategory(validCategories[0]); // Select the first active category
        }
      } catch (error) {
        console.error('Error fetching image data:', error);
      }
    };

    fetchImageData();
  }, [property_id]);

  useEffect(() => {
    if (isMobileScreen) {
      setVisibleRange([0, 3]);
    } else if (isTabletScreen) {
      setVisibleRange([0, 4]);
    } else {
      setVisibleRange([0, 5]);
    }
  }, [isMobileScreen, isTabletScreen]);

  useEffect(() => {
    if (selectedCategory && buttonRefs.current.length > 0) {
      const buttonIndex = Object.keys(categories).indexOf(selectedCategory);
      const button = buttonRefs.current[buttonIndex];
      if (button) {
        const { offsetLeft, offsetWidth } = button;
        setUnderlineStyle({
          left: `${offsetLeft}px`,
          width: `${offsetWidth}px`
        });
      }
    }
  }, [selectedCategory, visibleRange]);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: currentImageIndex * (sliderRef.current.offsetWidth / (isTabletScreen ? 1 : 1)),
        behavior: 'smooth'
      });
    }
  }, [currentImageIndex, isTabletScreen]);

  useEffect(() => {
    setCurrentImageIndex(0);
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedCategory]);

// Check if there are no images in any category
const hasNoImages = Object.keys(imageData || {}).every((key) => !imageData[key] || imageData[key].length === 0);


  const handleNextImage = () => {
    if (imageData[selectedCategory]) {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imageData[selectedCategory].length);
    }
  };

  const handlePreviousImage = () => {
    if (imageData[selectedCategory]) {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex - 1 + imageData[selectedCategory].length) % imageData[selectedCategory].length
      );
    }
  };

  const getValidIndices = () => {
    return Object.keys(categories).filter((label) => imageData[label] && imageData[label].length > 0);
  };
  

  const handleNextCategory = () => {
    const validCategories = getValidIndices();
    const currentIndex = validCategories.indexOf(selectedCategory);
  
    if (currentIndex < validCategories.length - 1) {
      setSelectedCategory(validCategories[currentIndex + 1]);
      if (visibleRange[1] < validCategories.length) {
        setVisibleRange([visibleRange[0] + 1, visibleRange[1] + 1]);
      }
    }
    if (canSlideNext) {
      setCurrentCategoryIndex(currentCategoryIndex + 1);
    }
  };
  
  const handlePreviousCategory = () => {
    const validCategories = getValidIndices();
    const currentIndex = validCategories.indexOf(selectedCategory);
  
    if (currentIndex > 0) {
      setSelectedCategory(validCategories[currentIndex - 1]);
      if (visibleRange[0] > 0) {
        setVisibleRange([visibleRange[0] - 1, visibleRange[1] - 1]);
      }
    }
    if (canSlidePrev) {
      setCurrentCategoryIndex(currentCategoryIndex - 1);
    }
  };
  

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
  };

  if (!imageData || !categories) {
    return <div></div>;
  }

  const imagesToDisplay = imageData[selectedCategory] || [];

  const handleNextThumbnail = () => {
    if (thumbnailSliderRef.current) {
      thumbnailSliderRef.current.scrollBy({
        left: 100,
        behavior: 'smooth'
      });
    }
  };

  const handlePreviousThumbnail = () => {
    if (thumbnailSliderRef.current) {
      thumbnailSliderRef.current.scrollBy({
        left: -100,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="relative flex flex-col justify-between rounded-lg w-full mt-10 p-5">
      {/* {Object.keys(categories).length > 0 && ( */}
        <h2 className="text-[#1E2858] font-[600] font-sixth flex text-[16px] lg:text-[24px] ml-0 ">Photos</h2>
        {hasNoImages && <p className="font-[600] mt-2 text-center">NO IMAGES AVAILABLE</p>}
      {/*  )} */}

      {/* -----button for category selection----- */}
      <div className="flex justify-between mb-4 gap-2 mt-6 ml-0">
        {Object.keys(categories).length > 4 && (
          <button
            onClick={handlePreviousCategory}
            className="text-[#2E4374] hover:bg-[#2e4374] hover:text-[#fff] flex justify-center items-center bg-gray-100 p-2 h-8 w-8 rounded-md"
          >
            <KeyboardArrowLeftIcon />
          </button>
        )}

        <div className="relative flex justify-start xl:gap-[90px] lg:gap-[40px] md:gap-[40px] gap-[35px]">
          {Object.keys(categories)
            .slice(visibleRange[0], visibleRange[1])
            .map((label, index) => {
              const hasImages = imageData[label] && imageData[label].length > 0;

              return (
                <span
                  key={label}
                  ref={(el) => (buttonRefs.current[index] = el)}
                  onClick={() => hasImages && setSelectedCategory(label)}
                  className={`text-[#2E4374] font-sixth font-400 capitalize text-[14px] md:text-[16px] lg:text-[20px] ${
                    selectedCategory === label && hasImages ? 'underline' : ''
                  } ${!hasImages ? 'opacity-50 cursor-not-allowed' : ''}`}
                >
                  {categories[label]}
                </span>
                
              );
            })}

          {/* <div
                 style={{ ...underlineStyle, position: 'absolute', bottom: '-2px', height: '2px', background: '#2E4374' }}
         ></div>   */}
        </div>

        {Object.keys(categories).length > 4 && (
          <button
            onClick={handleNextCategory}
            className="text-[#2E4374] hover:bg-[#2e4374] hover:text-[#fff] bg-gray-100 flex justify-center items-center p-2 h-8 w-8 rounded-md mr-0 md:mr-6" // Disable if there's no next category
          >
            <KeyboardArrowRightIcon />
          </button>
        )}
      </div>

      <div className={`flex w-full ${isMobileScreen ? 'flex-col' : 'flex-row'} md:p-0 `}>
        {/* ----main image displaying---- */}

        <div className="relative w-full lg:w-[80%]">
          {imagesToDisplay.length > 1 && (
            <button
              onClick={handlePreviousImage}
              className="absolute top-1/2 left-0 transform -translate-y-1/2 flex justify-center items-center text-white bg-[#4BA3DB] font-light p-2 h-8 w-8 rounded-full lg:hidden"
            >
              <KeyboardArrowLeftIcon />
            </button>
          )}
          <div ref={sliderRef} className="w-full flex overflow-hidden">
            {imagesToDisplay.length > 0 &&
              imagesToDisplay.map((imageSrc, index) => (
                <div key={index} className={`flex-shrink-0 w-full ${index === currentImageIndex ? 'block' : 'hidden'}`}>
                  <img
                    src={`https://squarebric-media-files.squarebric.com/${imageSrc}`}
                    alt={`Property image ${index}`}
                    className="w-full h-[520px] object-cover"
                  />
                </div>
              ))}
          </div>
          {imagesToDisplay.length > 1 && (
            <button
              onClick={handleNextImage}
              className="absolute top-1/2 right-0 transform -translate-y-1/2 flex justify-center items-center text-white bg-[#0ea5e9] p-2 h-8 w-8 rounded-full lg:hidden"
            >
              <KeyboardArrowRightIcon />
            </button>
          )}
        </div>

        {/* -------------------thumbnail images displaying------------------- */}

        <div
          className={`flex flex-col items-center gap-0 mt-4 md:mt-0 md:ml-0 relative w-[20%]  ${
            imagesToDisplay.length <= 2 ? 'justify-start' : 'justify-start'
          } hidden lg:flex`}
        >
          {imagesToDisplay.length > 0 && (
            <button className="bg-[#2E4374] mb-2 w-48 p-2 h-12 mt-2">
              <h1 className=" text-white flex justify-center items-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                  <path
                    d="M12.5 7C6.98 7 2.5 9.24 2.5 12C2.5 14.24 5.44 16.13 9.5 16.77V20L13.5 16L9.5 12V14.73C6.35 14.17 4.5 12.83 4.5 12C4.5 10.94 7.54 9 12.5 9C17.46 9 20.5 10.94 20.5 12C20.5 12.73 19.04 13.89 16.5 14.53V16.58C20.03 15.81 22.5 14.05 22.5 12C22.5 9.24 18.02 7 12.5 7Z"
                    fill="white"
                  />
                </svg>
                <span className="text-[16px] font-sixth">360 View Available</span>
              </h1>
            </button>
          )}
          <div className="mt-4">
            {imagesToDisplay.length > 3 && (
              <button
                onClick={handlePreviousThumbnail}
                className="absolute top-14 left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white bg-[#4BA3DB] font-light p-2 h-8 w-8 rounded-full"
              >
                <KeyboardArrowUpIcon />
              </button>
            )}
            <div ref={thumbnailSliderRef} className="flex flex-col justify-center items-center gap-4 overflow-x-auto">
              {imagesToDisplay.map((imageSrc, index) => (
                <button
                  key={index}
                  onClick={() => handleThumbnailClick(index)}
                  className="w-48 h-24 p-1 border-2 border-gray-300 rounded"
                >
                  <img
                    src={`https://squarebric-media-files.squarebric.com/${imageSrc}`}
                    alt={`Thumbnail ${index}`}
                    className="w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
            {imagesToDisplay.length > 3 && (
              <button
                onClick={handleNextThumbnail}
                className="absolute bottom-0 left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white bg-[#0ea5e9] p-2 h-8 w-8 rounded-full"
              >
                <KeyboardArrowDownIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Photos;
