import React, { useState, useEffect, useRef } from 'react';
import DynamicAllfilter from './DynamicAllfilter';
import DynamicBathroom from './DynamicBathroom';
import DynamicArea from './DynamicArea';
import DynamicBedroom from './DynamicBedroom';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Drawer, IconButton, Menu, MenuItem, Switch, useMediaQuery } from '@mui/material';
import DynemicPrice from './DynamicPrice';

import { SlideToggle } from '@todys/react-slide-toggle';
import SubPropertyTypeDropdown from './SubPropertyType';
import { clearPropertyType, clearSubPropertyType, removeSubPropertyType } from '../../../redux/propertyReducer';
import { clearMinValueBed, clearMaxValueBed } from '../../../redux/BedroomReducer';

import { clearMin, clearMax } from '../../../redux/selectedPriceReducer';
import { removeSelectedMin, removeSelectedMax } from '../../../redux/multiselectRedcucer';

import PropertyType from './PropertyType';
import CloseIcon from '@mui/icons-material/Close';
import MobileDynamicPrice from './MobileDynamicPrice';
import MobileDynamicArea from './MobileDynamicArea';
import DynamicPrice from './DynamicPrice';
import MobileDynamicBathroom from './MobileDynamicBathroom';
import MobileDynamicBedroom from './MobiileDynamicBedroom';

const buildQuery = (params) => {
  const queryParams = [
    `country=${encodeURIComponent(params.selectedCountry)}`,
    `state=${encodeURIComponent(params.selectedState)}`,
    `sub_city=${encodeURIComponent(params.selectedCity)}`,
    `purpose=${encodeURIComponent(params.purpose)}`,
    `property_type=${encodeURIComponent(params.propertyType)}`,
    `currency=${encodeURIComponent(params.currency)}`,
    ...params.subPropertyType.map((type) => `sub_property_type=${encodeURIComponent(type)}`),
    params.selectedMin != null && `min_price=${encodeURIComponent(params.selectedMin)}`,
    params.selectedMax != null && `max_price=${encodeURIComponent(params.selectedMax)}`,
    params.min_total_area && `min_total_area=${encodeURIComponent(params.min_total_area)}`,
    params.max_total_area && `max_total_area=${encodeURIComponent(params.max_total_area)}`,
    params.selectedAreaOption && `area_unit=${encodeURIComponent(params.selectedAreaOption)}`,
    params.min_Bathrooms && `min_bathrooms=${encodeURIComponent(params.min_Bathrooms)}`,
    params.max_Bathrooms && `max_bathrooms=${encodeURIComponent(params.max_Bathrooms)}`,
    params.min_Bedrooms && `min_bedrooms=${encodeURIComponent(params.min_Bedrooms)}`,
    params.max_Bedrooms && `max_bedrooms=${encodeURIComponent(params.max_Bedrooms)}`,
    `skip=${params.skip || 0}`,
    `limit=${params.limit || 500}`
  ].filter(Boolean);

  return `https://square-bric-backend-api.squarebric.com/property-search/property-search?${queryParams.join('&')}`;
};

const DynamicFilterMainPage = () => {
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  const selectedState = useSelector((state) => state.location.selectedState);
  const selectedCity = useSelector((state) => state.location.selectedCity);
  const purpose = useSelector((state) => state.filter.selectedButton);
  const propertyType = useSelector((state) => state.properties.propertyType);
  const subPropertyType = useSelector((state) => state.properties.subPropertyType);

  const min_total_area = useSelector((state) => state.selectedPrice.min);
  const max_total_area = useSelector((state) => state.selectedPrice.max);
  const min_Bathrooms = useSelector((state) => state.bathroom.minValue);
  const max_Bathrooms = useSelector((state) => state.bathroom.maxValue);
  const min_Bedrooms = useSelector((state) => state.bedroom.minValue);
  const max_Bedrooms = useSelector((state) => state.bedroom.maxValue);
  const selectedAreaOption = useSelector((state) => state.area.selectedAreaOption);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isCustomScreen = useMediaQuery('(min-width:1275px)');
  const [isBudgetOpen, setIsBudgetOpen] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const [toggleState1, setToggleState1] = useState(false);
  const [toggleState2, setToggleState2] = useState(false);
  const [toggleState3, setToggleState3] = useState(false);
  const [toggleState4, setToggleState4] = useState(false);
  const [toggleState5, setToggleState5] = useState(false);
  const [toggleState6, setToggleState6] = useState(false);


  const fetchData = async () => {
    const currency = selectedCurrency?.label === 'Dollar' ? 'usd' : selectedCurrency?.label;
    const params = {
      selectedCountry,
      selectedState,
      selectedCity,
      purpose,
      propertyType,
      subPropertyType,


      currency,
      min_total_area,
      max_total_area,
      selectedAreaOption,
      min_Bathrooms,
      max_Bathrooms,
      min_Bedrooms,
      max_Bedrooms,
      skip: 0,
      limit: 500
    };

    const url = buildQuery(params);
    console.log('Constructed URL:', url);

    setLoading(true);
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      console.log('API Response:', result);

      if (result.documents && result.documents.length > 0) {
        setData(result.documents);
        navigate(`/SearchProperty?${url.split('?')[1]}`, { state: { searchResults: result.documents } });
      } else {
        console.error('No data found in response');
        setError('No data found');
      }
    } catch (err) {
      console.error('Fetch error:', err);
      setError('An error occurred: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedCountry,
    selectedState,
    selectedCity,
    purpose,
    propertyType,
    subPropertyType,



    min_total_area,
    max_total_area,
    selectedAreaOption,
    min_Bathrooms,
    max_Bathrooms,
    min_Bedrooms,
    max_Bedrooms
  ]);

  console.log('subPropety', subPropertyType);
  const refBudget = useRef(null);
  const refArea = useRef(null);
  const refPropertyType = useRef(null);
  const refSubPropertyType = useRef(null);
  const refBathroom = useRef(null);
  const refBedroom = useRef(null);

  const handleClickOutside = (event) => {
    if (refBudget.current && !refBudget.current.contains(event.target)) {
      setToggleState(false);
      setMin(false)
      setMax(false)
    }
    if (refArea.current && !refArea.current.contains(event.target)) {
      setToggleState1(false);
        setIsMinDropdownOpen(false);
        setIsMaxDropdownOpen(false)
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const clickEvent1 = () => {
    setToggleState1((prev) => !prev);
  };

  const clickEvent2 = () => {
    setToggleState2((prev) => !prev);
  };

  const clickEvent3 = () => {
    setToggleState3((prev) => !prev);
  };

  const clickEvent4 = () => {
    setToggleState4((prev) => !prev);
  };

  const clickEvent5 = () => {
    setToggleState5((prev) => !prev);
  };

  const clickEvent6 = () => {
    setToggleState6(!toggleState6);
  };
  const clickEvent = () => {
    setToggleState((prev) => !prev);
  };
  const handleClick = () => {
    setIsBudgetOpen(!isBudgetOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (refPropertyType.current && !refPropertyType.current.contains(event.target)) {
        setToggleState3(false);
      }

      if (refSubPropertyType.current && !refSubPropertyType.current.contains(event.target)) {
        setToggleState4(false);
      }

      if (refBathroom.current && !refBathroom.current.contains(event.target)) {
        setToggleState6(false);
      }

      if (refBedroom.current && !refBedroom.current.contains(event.target)) {
        setToggleState5(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const open = Boolean(anchorEl);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const open2 = Boolean(anchorEl2);
  const handleClose2 = () => {
    setAnchorEl2(null);
  };
  const hasSelectedValues =
    propertyType ||
    (Array.isArray(subPropertyType) && subPropertyType.length > 0) ||
    min_total_area ||
    max_total_area;

  const handleRemovePropertyType = () => {
    dispatch(clearPropertyType());
    // dispatch(clearSubPropertyType());
  };

  const handleRemoveSubPropertyType = (index) => {
    dispatch(removeSubPropertyType(index));
  };


  // pricerange
  const [isOpen, setIsOpen] = useState(false);
  const [min, setMin] = useState(false);
  const [max, setMax] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('Dollar');
  const priceOptions = Array.from({ length: 20 }, (_, i) => (i + 1) * 5000);

  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');

  const dropdownRef = useRef(null);
  const minDropdownRef = useRef(null);
  const maxDropdownRef = useRef(null);
  const minRef=useRef(null)

  const filteredMaxOptions = priceOptions.filter((price) => (minPrice ? price > parseInt(minPrice, 10) : true));

  const handleMinInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Allow only digits
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }
    if (value.length > 10) {
      value = value.slice(0, 10);
    }
    setMinPrice(value);
    if (parseInt(value, 10) >= parseInt(maxPrice, 10)) {
      setMinPrice('');
    }

  };

  const handleMaxInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }
    // Limit the value to 10 digits
    if (value.length > 10) {
      value = value.slice(0, 10); // Keep only the first 10 digits
    }

    setMaxPrice(value);
    if (parseInt(minPrice, 10) >= parseInt(value, 10)) {
      setMinPrice('');
    }

  };

  const currencies = [
    { name: 'Dollar', symbol: '$' },
    { name: 'Rupees', symbol: '₹' },
    { name: 'Qatari Riyal', symbol: '﷼' },
    { name: 'Irani Riyal', symbol: '﷼' }
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setMax(false);
    setMin(false);
  };

  const selectCurrency = (currency) => {
    setSelectedCurrency(currency.name);
    setIsOpen(false);
    setMaxPrice('');
    setMinPrice('');
  };

  const handleDropdownToggle = () => {
    setMin(!min);
    setIsOpen(false);
    setMax(false);
  };

  const handleDropdownToggleMax = () => {
    setMax(!max);
    setMin(false);
    setIsOpen(false);
  };

  const truncateValue = (value) => {
    if (typeof value === 'number') {
      const stringValue = value.toString();
      return stringValue.length > 3 ? stringValue.slice(0, 3) + '...' : stringValue;
    } else if (typeof value === 'string') {
      return value.length > 3 ? value.slice(0, 3) + '...' : value;
    } else {
      return value;
    }
  };

  const handleMinValueSelect = (price) => {
    setMinPrice(price);
    if (parseInt(price, 10) >= parseInt(maxPrice, 10)) {
      setMaxPrice(''); // Clear maxPrice if it's less than or equal to minPrice
    }
    setMin(false);
  };

  const handleMaxValueSelect = (price) => {
    setMaxPrice(price);
    if (parseInt(price, 10) >= parseInt(minPrice, 10)) {
      setMaxPrice(price);
      setMax(false);
    }
  };

  const handleMinInputBlur = () => {
    setMin(false); // Close dropdown when input is blurred
  };

  const handleMaxInputBlur = () => {
    setMax(false); // Close dropdown when input is blurred
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        minDropdownRef.current && !minDropdownRef.current.contains(event.target) &&
        maxDropdownRef.current && !maxDropdownRef.current.contains(event.target)
      ) {
        if (parseInt(minPrice, 10) > parseInt(maxPrice, 10)) {
          setMaxPrice('');
        }
        setIsOpen(false);
        setMin(false);
        setMax(false);
      }

      
      
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [minPrice, maxPrice,min,max]);



 



  const getPriceDisplay = () => {
    if (minPrice && !maxPrice) {
      return `Starting from ${selectedCurrency === 'Dollar' ? '$' : '₹'}${minPrice}`;
    } else if (!minPrice && maxPrice) {
      return `Up to ${selectedCurrency === 'Dollar' ? '$' : '₹'}${maxPrice}`;
    } else if (minPrice && maxPrice) {
      return `${selectedCurrency === 'Dollar' ? '$' : '₹'}${minPrice} - ${selectedCurrency === 'Dollar' ? '$' : '₹'}${maxPrice}`;
    } else {
      return '';
    }
  };

  // min and max area 

  const [isUnitDropdownOpen, setIsUnitDropdownOpen] = useState(false);
  const [isMinDropdownOpen, setIsMinDropdownOpen] = useState(false);
  const [isMaxDropdownOpen, setIsMaxDropdownOpen] = useState(false);
  const [selectedAreaUnit, setSelectedAreaUnit] = useState('sqmt');

  // Area range options from 100 to 1000 in increments of 100
  const areaRangeOptions = Array.from({ length: 10 }, (_, i) => (i + 1) * 100);

  const [minArea, setMinArea] = useState('');
  const [maxArea, setMaxArea] = useState('');

  const unitDropdownRef = useRef(null);
  const minAreaDropdownRef = useRef(null);
  const maxAreaDropdownRef = useRef(null);

  const areaUnits = ['sqmt', 'sqft', 'sqyrd', 'acre'];

  const filteredMaxAreaOptions = areaRangeOptions.filter((area) => (minArea ? area > parseInt(minArea, 10) : true));

  const handleMinAreaInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Allow only digits
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }
    if (value.length > 6) {
      value = value.slice(0,6);
    }
    setMinArea(value);
    if (parseInt(value, 10) >= parseInt(maxArea, 10)) {
      setMinArea('');
    }
  };

  const handleMaxAreaInputChange = (e) => {
    let value = e.target.value.replace(/[^\d]/g, ''); // Allow only digits
    if (value.length > 0 && value.startsWith('0')) {
      value = value.slice(1); // Remove leading zero
    }
    if (value.length > 6) {
      value = value.slice(0, 6);
    }
    setMaxArea(value);
    if (parseInt(minArea, 10) >= parseInt(value, 10)) {
      setMinArea('');
    }
  };

  const toggleUnitDropdown = () => {
    setIsUnitDropdownOpen(!isUnitDropdownOpen);
    setIsMinDropdownOpen(false);
    setIsMaxDropdownOpen(false);
  };

  const selectAreaUnit = (unit) => {
    setSelectedAreaUnit(unit);
    setIsUnitDropdownOpen(false);
    setMaxArea('');
    setMinArea('');
  };

  const toggleMinDropdown = () => {
    setIsMinDropdownOpen(!isMinDropdownOpen);
    setIsUnitDropdownOpen(false);
    setIsMaxDropdownOpen(false);
  };

  const toggleMaxDropdown = () => {
    setIsMaxDropdownOpen(!isMaxDropdownOpen);
    setIsMinDropdownOpen(false);
    setIsUnitDropdownOpen(false);
  };

  const truncateValues = (value) => {
    if (typeof value === 'number') {
      const stringValue = value.toString();
      return stringValue.length > 3 ? stringValue.slice(0, 3) + '...' : stringValue;
    } else if (typeof value === 'string') {
      return value.length > 3 ? value.slice(0, 3) + '...' : value;
    } else {
      return value;
    }
  };

  const handleMinAreaSelect = (area) => {
    setMinArea(area);
    if (parseInt(area, 10) >= parseInt(maxArea, 10)) {
      setMaxArea(''); // Clear maxArea if it's less than or equal to minArea
    }
    setIsMinDropdownOpen(false);
  };

  const handleMaxAreaSelect = (area) => {
    setMaxArea(area);
    if (parseInt(area, 10) <= parseInt(minArea, 10)) {
      setMaxArea(''); // Clear maxArea if it's less than or equal to minArea
    }
    setIsMaxDropdownOpen(false);
  };

  const handleMinAreaBlur = () => {
    setIsMinDropdownOpen(false); // Close dropdown when input is blurred
  };

  const handleMaxAreaBlur = () => {
    setIsMaxDropdownOpen(false); // Close dropdown when input is blurred
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        unitDropdownRef.current && !unitDropdownRef.current.contains(event.target) &&
        minAreaDropdownRef.current && !minAreaDropdownRef.current.contains(event.target) &&
        maxAreaDropdownRef.current && !maxAreaDropdownRef.current.contains(event.target)
      ) {
        // Check if minArea is greater than maxArea and clear maxArea if needed
        if (parseInt(minArea, 10) > parseInt(maxArea, 10)) {
          setMaxArea('');
        }
        setIsUnitDropdownOpen(false);
        setIsMinDropdownOpen(false);
        setIsMaxDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [minArea, maxArea]);

  const getAreaDisplay = () => {
    if (minArea && !maxArea) {
      return `Starting from ${minArea} ${selectedAreaUnit}`;
    } else if (!minArea && maxArea) {
      return `Up to ${maxArea} ${selectedAreaUnit}`;
    } else if (minArea && maxArea) {
      return `${minArea} - ${maxArea} ${selectedAreaUnit}`;
    } else {
      return '';
    }
  };

  // min and max bathrooms

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [minBedrooms, setMinBedrooms] = useState('');
  const [maxBedrooms, setMaxBedrooms] = useState('');
  const dropdownRefs = useRef(null);

  useEffect(() => {
    // Reset values when component mounts
    setMinBedrooms('');
    setMaxBedrooms('');

    // Clean up values when component unmounts
    return () => {
      setMinBedrooms('');
      setMaxBedrooms('');
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRefs.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdowns = () => {
    setIsDropdownOpen(true);
  };

  const incrementMinBedrooms = () => {
    setMinBedrooms((prevMin) => {
      const newMin = prevMin === '' ? 1 : prevMin + 1;

      // Ensure minBedrooms does not exceed 19 (to keep it less than maxBedrooms)
      if (newMin >= 20) {
        return 19;
      }

      // Reset maxBedrooms if newMin becomes equal to or greater than maxBedrooms
      if (maxBedrooms !== '' && newMin >= maxBedrooms) {
        setMaxBedrooms(''); // Reset max if min >= max
      }

      return newMin;
    });
  };

  const decrementMinBedrooms = () => {
    setMinBedrooms((prevMin) => {
      if (prevMin <= 1) {
        return ''; // Set to empty string if minBedrooms goes below 1
      }
      const newMin = prevMin - 1;
      if (newMin === '' && maxBedrooms <= prevMin) {
        setMaxBedrooms(''); // Reset max if min is empty and max <= old min
      }
      return newMin;
    });
  };

  const incrementMaxBedrooms = () => {
    setMaxBedrooms((prevMax) => {
      // Calculate new max value
      const newMax = prevMax === '' ? (minBedrooms !== '' ? minBedrooms + 1 : 1) : prevMax + 1;

      // Ensure maxBedrooms does not exceed 20
      return newMax > 20 ? 20 : newMax;
    });
  };

  const decrementMaxBedrooms = () => {

    setMaxBedrooms((prevMax) => {
      if (prevMax <= 1) {
        return ''; // Set to empty string if maxBathrooms goes below 1
      }
    
      const newMax = prevMax - 1;
    
      // Check if minBathrooms is greater than or equal to newMax
      if (minBedrooms !== '' && minBedrooms  >= newMax) {
        setMinBedrooms(''); // Reset minBathrooms if it's greater than or equal to maxBathrooms
      }
    
      return newMax;
    });





 
  };

  const displayText =
    minBedrooms === '' && maxBedrooms === ''
      ? `Min: ${minBedrooms} Max: ${maxBedrooms}`
      : ''


  const getBedroomDisplay = () => {
    if (minBedrooms && !maxBedrooms) {
      return `Starting from ${minBedrooms} `;
    } else if (!minBedrooms && maxBedrooms) {
      return `Up to ${maxBedrooms} `;
    } else if (minBedrooms && maxBedrooms) {
      return ` ${minBedrooms}-${maxBedrooms} bath`;
    } else {
      return 'Select Bedrooms';
    }
  };




  // min and max bedroom
  const [isDropdownOpenBathroom, setIsDropdownOpenBathroom] = useState(false);
const [minBathrooms, setMinBathrooms] = useState('');
const [maxBathrooms, setMaxBathrooms] = useState('');
const dropdownRefBathroom = useRef(null);

useEffect(() => {
  // Reset values when component mounts
  setMinBathrooms('');
  setMaxBathrooms('');

  // Clean up values when component unmounts
  return () => {
    setMinBathrooms('');
    setMaxBathrooms('');
  };
}, []);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRefBathroom.current && !dropdownRefBathroom.current.contains(event.target)) {
      setIsDropdownOpenBathroom(false);
    }
  };
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);

const toggleDropdownBathroom = () => {
  setIsDropdownOpenBathroom((prev) => !prev);
};

const incrementMinBathrooms = () => {
  setMinBathrooms((prevMin) => {
    const newMin = prevMin === '' ? 1 : prevMin + 1;

    if (newMin >= 20) return 19;

    if (maxBathrooms !== '' && newMin >= maxBathrooms) {
      setMaxBathrooms('');
    }

    return newMin;
  });
};

const decrementMinBathrooms = () => {
  setMinBathrooms((prevMin) => {
    if (prevMin <= 1) return '';
    const newMin = prevMin - 1;
    if (newMin === '' && maxBathrooms <= prevMin) {
      setMaxBathrooms('');
    }
    return newMin;
  });
};

const incrementMaxBathrooms = () => {
  setMaxBathrooms((prevMax) => {
    const newMax = prevMax === '' ? (minBathrooms !== '' ? minBathrooms + 1 : 1) : prevMax + 1;
    return newMax > 20 ? 20 : newMax;
  });
};

const decrementMaxBathrooms = () => {
  setMaxBathrooms((prevMax) => {
    if (prevMax <= 1) {
      return ''; // Set to empty string if maxBathrooms goes below 1
    }
  
    const newMax = prevMax - 1;
  
    // Check if minBathrooms is greater than or equal to newMax
    if (minBathrooms !== '' && minBathrooms >= newMax) {
      setMinBathrooms(''); // Reset minBathrooms if it's greater than or equal to maxBathrooms
    }
  
    return newMax;
  });
};

const getBathroomDisplay = () => {
  if (minBathrooms && !maxBathrooms) {
    return `Starting from ${minBathrooms}`;
  } else if (!minBathrooms && maxBathrooms) {
    return `Up to ${maxBathrooms}`;
  } else if (minBathrooms && maxBathrooms) {
    return ` ${minBathrooms} - ${maxBathrooms} beds`;
  } else {
    return 'Select Bathrooms';
  }
};

  const handleClearAll = () => {
    dispatch(removeSelectedMax());
    dispatch(removeSelectedMin());
    dispatch(removeSelectedMin());
    dispatch(clearMax());
    dispatch(clearMin());
    dispatch(clearMinValueBed());
    dispatch(clearMaxValueBed());
    dispatch(clearPropertyType());
    dispatch(clearSubPropertyType());
    setMinArea('')
      setMaxArea('')
      setMinBathrooms('')
      setMaxBathrooms('')
      setMinBedrooms('')
      setMaxBedrooms('')
      setMinPrice('')
      setMaxPrice('')
  };
  // const handleDataFromChild = (data) => {
  //   console.log('Data from child:', data);
  //   setChildData(data); // Update parent state with data from child
  // };

  return (
    <div className=" flex flex-col relative ">
      <div className='flex items-center justify-center absolute top-[-55px] left-2 '>
        <span>  {!isCustomScreen && (
          <IconButton onClick={handleDrawerToggle}>
            <MenuIcon />
          </IconButton>
        )}</span>
      

        <span className="text-[#2E4374] font-sixth font-[600]  md:hidden whitespace-nowrap">APPLIED FILTER</span>
      </div>

        <div className="hidden md:block ">
        {hasSelectedValues && (
          <div className="p-4 border-2 rounded-lg w-[230px] ">
            <div className="flex justify-between mb-4">
              <div className="text-[#2E4374] font-[500] text-[16px] leading-[20px]">Applied Filters</div>
              <div className="text-[#0ea5e9] font-[500] text-[16px] leading-[20px]" onClick={handleClearAll}>
                Clear All
              </div>
            </div>
            <div className=" mt-4 w-auto">
              {propertyType && (
                <div className="inline-flex items-center border-[#818EA] border-2 rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff]  text-[14px] p-1">
                  <span className="text-center ml-1">{propertyType}</span>
                  <button
                    className="ml-2 rounded-full flex items-center justify-center p-1"
                    onClick={handleRemovePropertyType}
                  >
                    <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                  </button>
                </div>
              )}

            </div>

         <div className='grid grid-cols-1 mt-0'>
  
                  {subPropertyType.length > 0 &&
                subPropertyType.map((type, index) => (
                  <div
                    key={index}
                    className=" "
                  >
                    <div className='border-[#818EA] border-2 inline-flex items-center    rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff] text-[14px] mt-2 p-1'>
                    <span className="text-center px-2">{type}</span>
                    <button
                      className="ml-1 rounded-full flex items-center justify-center p-1"
                      onClick={() => handleRemoveSubPropertyType(index)}
                    >
                      <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                    </button>
                  </div>
                  </div>
                ))}
           </div>




            <div className="grid grid-cols-1 mt-0">
              <div className="">
                {(minPrice || maxPrice) && (
                  <div
                    key={''}
                    className="inline-flex items-center border-[#818EA] border-2 rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff] text-[14px] mt-2 p-1"
                  >
                    <div className='w-full flex'>
                      <span className="text-center px-2">{getPriceDisplay()}</span>
                      <button
                        className='ml-2 rounded-full flex items-center justify-center p-1'
                        onClick={() => { setMinPrice(''); setMaxPrice(''); }}
                      >
                        <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>



            <div className="grid grid-cols-1 mt-0">
              <div>
                {(minArea || maxArea) && (
                  <div
                    key={''}
                    className="inline-flex items-center border-[#818EA] border-2 rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff] text-[14px] mt-2 p-1"
                  >
                    <div className='w-full flex'>
                      <span className="text-center px-2">{getAreaDisplay()}</span>
                      <button
                        className='ml-2 rounded-full flex items-center justify-center p-1'
                        onClick={() => { setMinArea(''); setMaxArea(''); }}
                      >
                        <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>







            <div className=" gap-2 mt-0">
              <div className="grid grid-cols-1">
                <div>
                  {(minBedrooms || maxBedrooms) && (
                    <div
                      key={''}
                      className="inline-flex items-center border-[#818EA] border-2 rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff] text-[14px] mt-2 p-1"
                    >
                      <div className="w-full flex">
                        <span className="text-center px-2">{getBedroomDisplay()}</span>
                        <button
                          className="ml-2 rounded-full flex items-center justify-center p-1"
                          onClick={() => { setMinBedrooms(''); setMaxBedrooms(''); }} // Clear bedroom values
                        >
                          <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>



              </div>


              {/* Bedrooms */}

              <div className="grid grid-cols-1">
                <div>
                  {(minBathrooms || maxBathrooms) && (
                    <div
                      key={''}
                      className="inline-flex items-center border-[#818EA] border-2 rounded-full font-[400] hover:bg-[#dfedf6] bg-[#f0f9ff] text-[14px] mt-2 p-1"
                    >
                      <div className="w-full flex">
                        <span className="text-center px-2">{getBathroomDisplay()}</span>
                        <button
                          className="ml-2 rounded-full flex items-center justify-center p-1"
                          onClick={() => { setMinBathrooms(''); setMaxBathrooms(''); }} // Clear bedroom values
                        >
                          <CloseIcon style={{ fontSize: '12px', color: '#2E4374' }} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>



              </div>




            </div>














          </div>





        )}

        <div className="hidden  xl:flex w-[230px]  p-4 border-2 rounded-lg mt-0 flex-col hidden md:block">
          <div className="flex items-center justify-between mt-2 mb-2  ml-2">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize '>Hide already viewed</h1>
            <Switch style={{ fontSize: '15px' }} />
          </div>

          <div ref={refPropertyType} className="mb-2 mt-3">
            <div
              onClick={clickEvent3}
              className="flex items-center justify-between  ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Property Type</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState3 ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>
            <SlideToggle state={toggleState3} duration={0.5}>

              <PropertyType />
            </SlideToggle>
          </div>

          {/* Sub Property Type */}
          <div ref={refSubPropertyType} className="mb-2 mt-3">
            <div
              onClick={clickEvent4}
              className="flex items-center justify-between  ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Type of Property</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState4 ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>
            <SlideToggle state={toggleState4} duration={0.5}>
              <SubPropertyTypeDropdown />
            </SlideToggle>
          </div>

          {/* Budget */}
          <div ref={refBudget} className="mb-2 mt-3">
            <div
              type="button"
              onClick={clickEvent}
              className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] text-xl ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Budget</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>
            
          
            {toggleState ? (
  <div>
    <div className="flex justify-between border-gray-300 border-2 rounded-md h-10 w-[200px] ">

      {/* Min Price Input */}
      <div className="relative w-[50%] flex justify-between items-center" ref={minDropdownRef}>
        <button onClick={handleDropdownToggle} className="flex items-center text-sm px-3 font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
          <span className="w-[30%] text-left relative  text-[14px] font-[600] font-sixth">Min</span>
          <span className="w-[60%] truncate text-[14px] font-[600] font-sixth">{truncateValue(minPrice)}</span>
          <span className="w-[10%] text-right">
            <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: min ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </span>
        </button>

        {min && (
          <div className="absolute top-full left-0 mt-0 p-2 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48" id='' >
            <input
              type="text"
              className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
              placeholder="Min Price"
              value={minPrice}
              onChange={handleMinInputChange}
              onBlur={handleMinInputBlur} // Close on blur
            />
            <ul>
              {priceOptions.map((price) => (
                <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMinValueSelect(price)}>
                  {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Max Price Input */}
      <div className="relative w-[50%] flex justify-between items-center" ref={maxDropdownRef}>
        <button onClick={handleDropdownToggleMax} className="flex items-center px-4 font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
          <span className="w-[30%] text-left text-[14px] font-[600] relative left-[-2px] font-sixth">Max</span>
          <span className="w-[60%] truncate text-[14px] ml-2 font-[600] font-sixth">{truncateValue(maxPrice)}</span>
          <span className="w-[10%] text-right">
            <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: max ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </span>
        </button>

        {max && (
          <div className="absolute top-full right-0 mt-0 p-2  z-10 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48" >
            <input
              type="text"
              className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
              placeholder="Max Price"
              value={maxPrice}
              onChange={handleMaxInputChange}
              onBlur={handleMaxInputBlur} // Close on blur
            />
            <ul>
              {filteredMaxOptions.map((price) => (
                <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMaxValueSelect(price)}>
                  {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
) : null}


          </div>





          {/* Area */}
          <div ref={refArea} className="mb-2 mt-3">
            <div
              type="button"
              onClick={clickEvent1}
              className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] text-xl ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Area</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState1 ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>



            {
              toggleState1 ? (
                <div>
                  <div className="flex justify-between items-center border-gray-300 border-2 rounded-md h-10 w-[200px] px-2">
                  <div className="relative w-[30%]" ref={unitDropdownRef}>
          <div onClick={toggleUnitDropdown} className="flex items-center text-sm px-0 font-semibold w-full cursor-pointer">
            <span className='mr-2 text-[14px] font-[600] mt-1 font-sixth capitalize'>{selectedAreaUnit}</span>
            {/* <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: isUnitDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            /> */}
          </div>
          {isUnitDropdownOpen && (
            <div className="absolute top-full left-0 mt-0 p-2 z-10 w-28 bg-white shadow-lg mt-1 rounded-md text-sm">
              <ul>
                {areaUnits.map((unit) => (
                  <li
                    key={unit}
                    className="px-1 py-2 hover:bg-gray-100 cursor-pointer capitalize"
                    onClick={() => selectAreaUnit(unit)}
                  >
                    {unit}
                  </li>
                ))}
              </ul>
            </div>
          )}
                </div>
                    {/* Min Area Input */}
                    <div className="relative w-[35%] right-4 flex justify-between items-center" ref={minAreaDropdownRef}>
                      <div
                        onClick={toggleMinDropdown}
                        className="flex items-center text-sm px-0 font-semibold w-full whitespace-nowrap overflow-hidden-x mt-1"
                      >
                        <span className=' text-[14px] font-[600] ml-2 font-sixth'>Min</span>
                        <span className="text-[14px] font-[600] ml-1 font-sixth ">{truncateValue(minArea)}</span>
                        {/* <KeyboardArrowDownIcon
                          style={{
                            transition: 'transform 0.3s ease-in-out',
                            transform: isMinDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            position: 'relative',
                            right: '0px',
                            marginRight: "5px"
                          }}
                        /> */}
                      </div>
                      {isMinDropdownOpen && (
                        <div className="absolute top-full left-0 mt-1 z-10 p-2 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
                          <input
                            type="text"
                            className="border rounded-lg px-2 text-[14px] py-2 w-full"
                            placeholder="Min Area"
                            value={minArea}
                            onChange={handleMinAreaInputChange}
                            onBlur={handleMinAreaBlur} // Close on blur
                          />
                          <ul>
                            {areaRangeOptions.map((area) => (
                              <li
                                key={area}
                                className="px-1 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleMinAreaSelect(area)}
                              >
                                {area.toLocaleString()}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* Max Area Input */}
                    <div className="relative w-[35%] right-2 flex justify-between items-center" ref={maxAreaDropdownRef}>
                      <button
                        onClick={toggleMaxDropdown}
                        className="flex items-center text-sm px-0 font-semibold w-full whitespace-nowrap overflow-hidden mt-1"
                      >
                        <span className=' text-[14px] font-[600]  font-sixth'>Max </span>
                        <span className=" text-[14px] font-[600] pl-1  font-sixth">{truncateValue(maxArea)}</span>
                        {/* <KeyboardArrowDownIcon
                          style={{
                            transition: 'transform 0.3s ease-in-out',
                            transform: isMaxDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            marginRight: "5px"
                          }}
                        /> */}
                      </button>
                      {isMaxDropdownOpen && (
                        <div className="absolute top-full right-0 mt-1 p-2 z-10 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
                          <input
                            type="text"
                            className="border rounded-lg px-2 text-[14px] py-2 w-full"
                            placeholder="Max Area"
                            value={maxArea}
                            onChange={handleMaxAreaInputChange}
                            onBlur={handleMaxAreaBlur} // Close on blur
                          />
                          <ul>
                            {filteredMaxAreaOptions.map((area) => (
                              <li
                                key={area}
                                className="px-1 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleMaxAreaSelect(area)}
                              >
                                {area.toLocaleString()}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              ) : null
            }


          </div>

          {/* Bathroom */}
      
  

  
  
        <div ref={refBathroom} className="mb-2 mt-3">
            <div
              type="button"
              onClick={clickEvent6}
              className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] text-xl ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Bathroom</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState6 ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>

            <SlideToggle state={toggleState6} duration={0.5}>
            {
              toggleState6
                ? (<div className=" rounded h-10 z-0 h-[90px]">
                  <div className="relative inline-block w-[100%]" ref={dropdownRefs}>
                    <div
                      onClick={toggleDropdowns}
                      className="text-[#2E4374] text-sm px-1 border-none mt-1 border-[1px] border-gray-300  flex justify-between items-center"
                      style={{ backgroundColor: '#fff', padding: '2px', borderRadius: '5px', width: '100%' }}
                    >
                     
                    </div>

                    {toggleState6 && (
                      <div className="absolute top-0 mt-0 right-3  border-[1px] border-gray-500 rounded  h-auto w-44 ">
                        <div className="p-2">
                          {/* Min Bedrooms Controls */}
                          <div className="flex items-center justify-between mb-2 w-full">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Min:</span>
                            <button
                              onClick={decrementMinBedrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l ml-0.5 border ${minBedrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={minBedrooms === ''} // Disable if min value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{minBedrooms === '' ? '' : minBedrooms}</span>
                            <button
                              onClick={incrementMinBedrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>




                          {/* Max Bedrooms Controls */}
                          <div className="flex items-center  justify-between">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Max:</span>
                            <button
                              onClick={decrementMaxBedrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l border ${maxBedrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={maxBedrooms === ''} // Disable if max value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{maxBedrooms === '' ? '' : maxBedrooms}</span>
                            <button
                              onClick={incrementMaxBedrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>)
                : null
            }
  </SlideToggle>
          </div>




          {/* Bedroom */}
  
          <div ref={refBedroom} className="mb-2 mt-3">
            <div
              type="button"
              onClick={clickEvent5}
              className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] text-xl ml-2"
            >
              <span className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'> Bedroom</span>
              <KeyboardArrowDownIcon
                style={{
                  transition: 'transform 0.3s ease-in-out',
                  transform: toggleState5 ? 'rotate(180deg)' : 'rotate(0deg)',
                  marginRight: '7px'
                }}
              />
            </div>

            <SlideToggle state={toggleState5} duration={0.5}>
            {
              toggleState5
                ? (<div className=" rounded h-10 z-0 h-[90px]">
                  <div className="relative inline-block w-[100%]" ref={dropdownRefBathroom}>
                    <div
                      onClick={toggleDropdownBathroom}
                      className="text-[#2E4374] text-sm px-1 border-none mt-1 border-[1px] border-gray-300  flex justify-between items-center"
                      style={{ backgroundColor: '#fff', padding: '2px', borderRadius: '5px', width: '100%' }}
                    >
                     
                    </div>

                    {toggleState5 && (
                      <div className="absolute top-0 mt-0 right-3  border-[1px] border-gray-500 rounded  h-auto w-44 ">
                        <div className="p-2">
                          {/* Min Bedrooms Controls */}
                          <div className="flex items-center justify-between mb-2 w-full">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Min:</span>
                            <button
                              onClick={decrementMinBathrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l ml-0.5 border ${minBathrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={minBathrooms === ''} // Disable if min value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{minBathrooms === '' ? '' : minBathrooms}</span>
                            <button
                              onClick={incrementMinBathrooms }
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>




                          {/* Max Bedrooms Controls */}
                          <div className="flex items-center  justify-between">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Max:</span>
                            <button
                              onClick={decrementMaxBathrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l border ${maxBathrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={maxBathrooms  === ''} // Disable if max value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{maxBathrooms  === '' ? '' : maxBathrooms }</span>
                            <button
                              onClick={incrementMaxBathrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>)
                : null
            }
  </SlideToggle>
          </div>



          <div className="mb-2  ml-2 mt-3">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Construction Status</h1>
          </div>

          <div className="mb-2  ml-2 mt-3">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Furnishing Status</h1>
          </div>

          <div className="flex items-center justify-between mb-2  ml-2 mt-3">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Property with Photo</h1>
            <Switch defaultChecked style={{ fontSize: '15px' }} />
          </div>

          <div className="flex items-center justify-between mb-2 t ml-2 mt-3">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Verify property</h1>
            <Switch style={{ fontSize: '15px' }} />
          </div>

          <div className="flex items-center justify-between mb-2  ml-2 mt-3">
            <h1 className='text-[#2E4374] font-sixth font-[500] text-[18px] leading-[24px] capitalize'>Property with video</h1>
            <Switch />
          </div>

          <div className="mb-2 text-[#2E4374] font-sixth font-[500] text-xl ml-2 mt-3" >
            <label htmlFor="postedBy" className="flex items-center">
              Posted By
              <select id="postedBy" name="postedBy" className="ml-2 p-2 bg-white rounded-md shadow-sm sm:text-sm">
                <option value="User1">User 1</option>
                <option value="User2">User 2</option>
                <option value="User3">User 3</option>
                <option value="User4">User 4</option>
              </select>
            </label>
          </div>
        </div>
      </div>







      <Drawer
        anchor="bottom"
        open={drawerOpen}
        sx={{
          '& .MuiDrawer-paper': {
            
            height: '90%',
           
         
          }
        }}
        onClose={handleDrawerToggle}
        PaperProps={{
          style: {
            borderRadius: '16px 16px 0 0'
          }
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          style={{
            position: 'absolute',
            top: '0',
            right: '8px',
            color: '#2E4374'
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="p-4 w-full ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-xl xl:text-xl 2xl:text-xl p-2">
              <h1>Hide already viewed</h1>
              <div className="ml-4">
                <Switch style={{ fontSize: '15px' }} />
              </div>
            </div>


            <div ref={refPropertyType} className="flex flex-col p-2">
              <div
                onClick={clickEvent3}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                <span>Property Type</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState3 ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '7px'
                  }}
                />
              </div>
              <SlideToggle state={toggleState3} duration={0.5}>
                <PropertyType />
              </SlideToggle>
            </div>{' '}
            <div ref={refSubPropertyType} className="flex flex-col p-2">
              <div
                onClick={clickEvent4}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                <span>Type of Property</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState4 ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '7px'
                  }}
                />
              </div>
              <SlideToggle state={toggleState4} duration={0.5}>
                <SubPropertyTypeDropdown />
              </SlideToggle>
            </div>






            <div ref={refArea} className="flex flex-col p-2">
              <div
                onClick={clickEvent1}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                <span>Area</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState1 ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '7px'
                  }}
                />
              </div>
             
            {
              toggleState1 ? (
                <div>
                  <div className="flex justify-between items-center border-gray-300 border-2 rounded-md h-10 w-[100%] px-2">
                  <div className="relative w-[30%]" ref={unitDropdownRef}>
          <div onClick={toggleUnitDropdown} className="flex items-center text-sm px-0 font-semibold w-full cursor-pointer">
            <span className='mr-2 text-[14px] font-[600] mt-1 font-sixth capitalize'>{selectedAreaUnit}</span>
            {/* <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: isUnitDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            /> */}
          </div>
          {isUnitDropdownOpen && (
            <div className="absolute top-full left-0 mt-0 p-2 z-10 w-28 bg-white shadow-lg mt-1 rounded-md text-sm">
              <ul>
                {areaUnits.map((unit) => (
                  <li
                    key={unit}
                    className="px-1 py-2 hover:bg-gray-100 cursor-pointer capitalize"
                    onClick={() => selectAreaUnit(unit)}
                  >
                    {unit}
                  </li>
                ))}
              </ul>
            </div>
          )}
                </div>
                    {/* Min Area Input */}
                    <div className="relative w-[35%] right-4 flex justify-between items-center" ref={minAreaDropdownRef}>
                      <div
                        onClick={toggleMinDropdown}
                        className="flex items-center text-sm px-0 font-semibold w-full whitespace-nowrap overflow-hidden-x mt-1"
                      >
                        <span className=' text-[14px] font-[600] ml-2 font-sixth'>Min</span>
                        <span className="text-[14px] font-[600] ml-1 font-sixth ">{truncateValue(minArea)}</span>
                        {/* <KeyboardArrowDownIcon
                          style={{
                            transition: 'transform 0.3s ease-in-out',
                            transform: isMinDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            position: 'relative',
                            right: '0px',
                            marginRight: "5px"
                          }}
                        /> */}
                      </div>
                      {isMinDropdownOpen && (
                        <div className="absolute top-full left-0 mt-1 z-10 p-2 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
                          <input
                            type="text"
                            className="border rounded-lg px-2 text-[14px] py-2 w-full"
                            placeholder="Min Area"
                            value={minArea}
                            onChange={handleMinAreaInputChange}
                            onBlur={handleMinAreaBlur} // Close on blur
                          />
                          <ul>
                            {areaRangeOptions.map((area) => (
                              <li
                                key={area}
                                className="px-1 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleMinAreaSelect(area)}
                              >
                                {area.toLocaleString()}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* Max Area Input */}
                    <div className="relative w-[35%] right-2 flex justify-between items-center" ref={maxAreaDropdownRef}>
                      <button
                        onClick={toggleMaxDropdown}
                        className="flex items-center text-sm px-0 font-semibold w-full whitespace-nowrap overflow-hidden mt-1"
                      >
                        <span className=' text-[14px] font-[600]  font-sixth'>Max </span>
                        <span className=" text-[14px] font-[600] pl-1  font-sixth">{truncateValue(maxArea)}</span>
                        {/* <KeyboardArrowDownIcon
                          style={{
                            transition: 'transform 0.3s ease-in-out',
                            transform: isMaxDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                            marginRight: "5px"
                          }}
                        /> */}
                      </button>
                      {isMaxDropdownOpen && (
                        <div className="absolute top-full right-0 mt-1 p-2 z-10 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48">
                          <input
                            type="text"
                            className="border rounded-lg px-2 text-[14px] py-2 w-full"
                            placeholder="Max Area"
                            value={maxArea}
                            onChange={handleMaxAreaInputChange}
                            onBlur={handleMaxAreaBlur} // Close on blur
                          />
                          <ul>
                            {filteredMaxAreaOptions.map((area) => (
                              <li
                                key={area}
                                className="px-1 py-2 hover:bg-gray-100 cursor-pointer"
                                onClick={() => handleMaxAreaSelect(area)}
                              >
                                {area.toLocaleString()}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                </div>
              ) : null
            }

            </div>









            <div ref={refBudget} className="mb-2">
              <div
                type="button"
                onClick={clickEvent}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl ml-2"
              >
                <span>Budget</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '15px'
                  }}
                />
              </div>
              {toggleState ? (
  <div>
    <div className="flex justify-between border-gray-300 border-2 rounded-md h-10 w-[100%] ">

      {/* Min Price Input */}
      <div className="relative w-[50%] flex justify-between items-center" ref={minDropdownRef}>
        <button onClick={handleDropdownToggle} className="flex items-center text-sm px-3 font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
          <span className="w-[30%] text-left relative  text-[14px] font-[600] font-sixth">Min</span>
          <span className="w-[60%] truncate text-[14px] font-[600] font-sixth">{truncateValue(minPrice)}</span>
          <span className="w-[10%] text-right">
            <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: min ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </span>
        </button>

        {min && (
          <div className="absolute top-full left-0 mt-0 p-2 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48" id='' >
            <input
              type="text"
              className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
              placeholder="Min Price"
              value={minPrice}
              onChange={handleMinInputChange}
              onBlur={handleMinInputBlur} // Close on blur
            />
            <ul>
              {priceOptions.map((price) => (
                <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMinValueSelect(price)}>
                  {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Max Price Input */}
      <div className="relative w-[50%] flex justify-between items-center" ref={maxDropdownRef}>
        <button onClick={handleDropdownToggleMax} className="flex items-center px-4 font-[600] w-full whitespace-nowrap overflow-hidden mt-1">
          <span className="w-[30%] text-left text-[14px] font-[600] relative left-[-2px] font-sixth">Max</span>
          <span className="w-[60%] truncate text-[14px] ml-2 font-[600] font-sixth">{truncateValue(maxPrice)}</span>
          <span className="w-[10%] text-right">
            <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: max ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
            />
          </span>
        </button>

        {max && (
          <div className="absolute top-full right-0 mt-0 p-2  z-10 w-28 bg-white shadow-lg rounded-md text-sm overflow-y-auto overflow-x-hidden max-h-48" >
            <input
              type="text"
              className="border rounded-lg px-2 text-[14px] py-2 w-full truncate"
              placeholder="Max Price"
              value={maxPrice}
              onChange={handleMaxInputChange}
              onBlur={handleMaxInputBlur} // Close on blur
            />
            <ul>
              {filteredMaxOptions.map((price) => (
                <li key={price} className="px-1 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => handleMaxValueSelect(price)}>
                  {selectedCurrency === 'Dollar' ? `$${price.toLocaleString()}` : `₹${price.toLocaleString()}`}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>
) : null}

            </div>



















            <div ref={refBathroom} className="flex flex-col p-2">
              <div
                onClick={clickEvent6}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                <span>Bathroom</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState6 ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '7px'
                  }}
                />
              </div>
              
            <SlideToggle state={toggleState6} duration={0.5}>
            {
              toggleState6
                ? (<div className=" rounded h-10 z-0 h-[90px] ">
                  <div className="relative inline-block w-[100%] " ref={dropdownRefs}>
                    <div
                      onClick={toggleDropdowns}
                      className="text-[#2E4374] text-sm px-1 border-none mt-1 border-[1px] border-gray-300  flex justify-between items-center"
                      style={{ backgroundColor: '#fff', padding: '2px', borderRadius: '5px', width: '100%' }}
                    >
                     
                    </div>

                    {toggleState6 && (
                      <div className="relative  top-0 mt-0 right-0  border-[1px] border-gray-500 rounded  h-auto w-[100%] ">
                        <div className="p-2">
                          {/* Min Bedrooms Controls */}
                          <div className="flex items-center justify-between mb-2 w-full">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Min:</span>
                            <button
                              onClick={decrementMinBedrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l ml-0.5 border ${minBedrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={minBedrooms === ''} // Disable if min value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{minBedrooms === '' ? '' : minBedrooms}</span>
                            <button
                              onClick={incrementMinBedrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>




                          {/* Max Bedrooms Controls */}
                          <div className="flex items-center  justify-between">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Max:</span>
                            <button
                              onClick={decrementMaxBedrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l border ${maxBedrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={maxBedrooms === ''} // Disable if max value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{maxBedrooms === '' ? '' : maxBedrooms}</span>
                            <button
                              onClick={incrementMaxBedrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>)
                : null
            }
  </SlideToggle>
            </div>


            
            <div ref={refBedroom} className="flex flex-col p-2">
              <div
                onClick={clickEvent5}
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                <span>Bedroom</span>
                <KeyboardArrowDownIcon
                  style={{
                    transition: 'transform 0.3s ease-in-out',
                    transform: toggleState5 ? 'rotate(180deg)' : 'rotate(0deg)',
                    marginRight: '7px'
                  }}
                />
              </div>
           
              <SlideToggle state={toggleState5} duration={0.5}>
            {
              toggleState5
                ? (<div className=" rounded h-10 z-0 h-[90px]">
                  <div className="relative inline-block w-[100%]" ref={dropdownRefBathroom}>
                    <div
                      onClick={toggleDropdownBathroom}
                      className="text-[#2E4374] text-sm px-1 border-none mt-1 border-[1px] border-gray-300  flex justify-between items-center"
                      style={{ backgroundColor: '#fff', padding: '2px', borderRadius: '5px', width: '100%' }}
                    >
                     
                    </div>

                    {toggleState5 && (
                      <div className="relative  top-0 mt-0 right-0  border-[1px] border-gray-500 rounded  h-auto w-[100%] ">
                        <div className="p-2">
                          {/* Min Bedrooms Controls */}
                          <div className="flex items-center justify-between mb-2 w-full">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Min:</span>
                            <button
                              onClick={decrementMinBathrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l ml-0.5 border ${minBathrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={minBathrooms === ''} // Disable if min value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{minBathrooms === '' ? '' : minBathrooms}</span>
                            <button
                              onClick={incrementMinBathrooms }
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>




                          {/* Max Bedrooms Controls */}
                          <div className="flex items-center  justify-between">
                            <span className="mr-2 text-[12px] font-[600] font-sixth">Max:</span>
                            <button
                              onClick={decrementMaxBathrooms}
                              className={`bg-gray-500 text-white w-8 rounded-l border ${maxBathrooms === '' ? 'opacity-50 cursor-not-allowed' : ''
                                }`}
                              disabled={maxBathrooms  === ''} // Disable if max value is empty string
                            >
                              -
                            </button>
                            <span className="mr-2 w-6 text-center">{maxBathrooms  === '' ? '' : maxBathrooms }</span>
                            <button
                              onClick={incrementMaxBathrooms}
                              className="bg-gray-500 text-white w-8 rounded-r border"
                            >
                              +
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>)
                : null
            }
  </SlideToggle>
            </div>




            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl p-2">
              Construction Status
            </div>
            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl p-2">
              Furnishing Status
            </div>
            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-xs xl:text-sm 2xl:text-xl p-2">
              <h1 className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer">
                Property with Photo
              </h1>
              <Switch defaultChecked style={{ fontSize: '15px' }} />
            </div>
            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-xs xl:text-sm 2xl:text-xl p-2">
              <h1 className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer">
                Verify Property
              </h1>
              <Switch style={{ fontSize: '15px' }} />
            </div>
            <div className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-xs xl:text-xs 2xl:text-xl p-2">
              <h1 className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer">
                Property with Video
              </h1>
              <Switch />
            </div>
            <div className="text-[#2E4374] font-sixth font-[600] sm:text-sm md:text-xl lg:text-xs xl:text-xs 2xl:text-xl p-2">
              <label
                htmlFor="postedBy"
                className="flex items-center justify-between text-[#2E4374] font-sixth font-[500] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
              >
                Posted By
                <select
                  id="postedBy"
                  name="postedBy"
                  className="p-2 bg-white rounded-md text-[#2E4374] font-sixth font-[600] sm:text-sm md:text-xl lg:text-sm xl:text-sm 2xl:text-xl cursor-pointer"
                >
                  <option>User 1</option>
                  <option>User 2</option>
                  <option>User 3</option>
                  <option>User 4</option>
                </select>
              </label>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default DynamicFilterMainPage;