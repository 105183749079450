import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const Map = () => {
  const { property_id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(
          `https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`
        );
        setProperty(response.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProperty();
  }, [property_id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  if (!property) return <p>No property data available.</p>;

  const { location } = property;

  return (
    <div className="mt-10 p-3 ml-1">
      <h2 className="text-[#1E2858] font-[600] font-sixth flex text-[16px] lg:text-[24px] mb-4">Property Location</h2>

      <div className="relative " style={{ zIndex: 0 }}>
        <MapContainer
          center={{ lat: location.geo_location.Lat, lng: location.geo_location.Long }}
          zoom={13}
          style={{ height: '400px', width: '100%' }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={{ lat: location.geo_location.Lat, lng: location.geo_location.Long }}>
            <Popup>{`Located at ${location.address}`}</Popup>
          </Marker>
        </MapContainer>
      </div>
    </div>
  );
};

export default Map;
