import React from 'react';


const Career = () => {
  return (
    <div className="  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-10   ">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-[#1E2858] font-[700] text-[30px] leading-[26px] capitalize 2xl:text-[40px] lg:text-[40px] sm:text-[20px] md:text-[30px] ">Build career squarbric</h1>
        <p className="text-[#2E4374]   font-[500]  lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px]  leading-[26px] text-justify mt-5">
        Accelerate your career with squarebric. Benefit from a nurturing environment, 
        meaningful projects, and recognition for your hard work. Join us and propel
         your professional journey forward.
        </p>
      </div>
      <div className=" w-full">
        <img className="w-[100%] h-[500px]" src="https://squarebric-media-files.squarebric.com/leadership.png" alt="leadership" />
      </div>
    </div>
  );
};

export default Career;
