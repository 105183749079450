import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import axios from 'axios';
import FavoriteIcon from '@mui/icons-material/Favorite';
import purpose from '../../assets/purpose.svg';
import NumberofFloors from '../../assets/Num_of_floor.svg';

const PropertyDetail = () => {
  const { property_id } = useParams();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [propertyData, setPropertyData] = useState(null);
  const [liked, setLiked] = useState(false);

  const handleClick = () => {
    setLiked(!liked);
  };
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await axios.get(
          `https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${property_id}`
        );
        setPropertyData(response.data);
      } catch (error) {
        console.error('Error fetching property data:', error);
      }
    };

    fetchPropertyData();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [property_id]);

  if (!propertyData) {
    return <div>Loading...</div>;
  }

  const propertydata = [
    { img: 'https://squarebric-media-files.squarebric.com/super_area.png', name: 'Super area', data: '340 M²' },
    { img: 'https://squarebric-media-files.squarebric.com/Style.svg', name: 'Type', data: 'Apartment' },
    { img: 'https://squarebric-media-files.squarebric.com/launch.svg', name: 'Pre Launch Offer', data: '$10,000' },
    { img: 'https://squarebric-media-files.squarebric.com/Vector.svg', name: 'Carpet Area', data: '330 M²' },
    {
      img: 'https://squarebric-media-files.squarebric.com/floor.svg',
      name: 'Flooring',
      data: 'Vitrified, Ceramic Tiles'
    },
    { img: 'https://squarebric-media-files.squarebric.com/bed.svg', name: 'Bathroom(s)', data: '3' },
    { img: 'https://squarebric-media-files.squarebric.com/furnish.svg', name: 'Furnishing', data: 'Unfurnished' },
    { img: 'https://squarebric-media-files.squarebric.com/property.svg', name: 'Age of Property', data: '4 years' },
    { img: 'https://squarebric-media-files.squarebric.com/housefacing.svg', name: 'Facing', data: 'East' },
    { img: 'https://squarebric-media-files.squarebric.com/floors.svg', name: 'Number Of Floors', data: '10' },
    { img: 'https://squarebric-media-files.squarebric.com/propertyfloor.svg', name: 'Property On Floor', data: '2' },
    { img: 'https://squarebric-media-files.squarebric.com/agentname.svg', name: 'Agent Name', data: 'Sival' }
  ];

  const { property_details, currency, location } = propertyData;

  const superArea = property_details.area.measurement.find((measurement) => measurement.super_area);
  const carpetArea = property_details.area.measurement.find((measurement) => measurement.carpet_area);
  function formatStatus(status) {
    if (status == null) {
      return ''; // or handle the null/undefined case as needed
    }

    return status
      .toLowerCase()
      .replace(/_/g, '-') // Replace underscores with hyphens
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
  }

  return (
    <div className="md:p-3">
      <div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center mb-2 mt-2">
          <div className="flex justify-start flex-row gap-2 md:gap-2 lg:gap-2 xl:gap-8 ml-4">
            <div className="flex  text-[20px] ">
              <img src="https://squarebric-media-files.squarebric.com/fluent.svg" alt="growth" height={20} width={20} />
              <span className="text-[#4BA3DB] font-sixth font-[400] text-[14px] md:text-[14px] lg:text-[20px] ml-2 mt-1">
                Enquired by {propertyData.enquiries || '123'}
              </span>
            </div>
            <div className="flex items-center  text-[#4BA3DB] font-thirdCustom">
              <img
                src="https://squarebric-media-files.squarebric.com/share_icon.svg"
                alt="growth"
                height={20}
                width={20}
              />
              <span className="ml-1 font-sixth font-[400] text-[14px] md:text-[14px] lg:text-[20px]">Shared</span>
            </div>
            <div className="flex items-center text-[#4BA3DB] font-sixth font-[400] ">
              <div onClick={handleClick} style={{ cursor: 'pointer' }} className="mb-1">
                {liked ? (
                  <FavoriteIcon style={{ color: 'red', marginRight: '5px' }} />
                ) : (
                  <FavoriteBorderIcon style={{ color: 'red', marginRight: '5px' }} />
                )}
              </div>
              <span className="font-sixth text-[14px] md:text-[14px] lg:text-[20px]">Like</span>
            </div>
          </div>
          {windowWidth > 1024 && (
            <div className="flex items-center text-[#2E4374] font-sixth justify-center md:justify-end mt-2 md:mt-0 mr-4 text-[12px] md:text-xl sm:text-[12px]">
              <span>
                <FileDownloadIcon style={{ color: '',fontSize:'20px' }} />
              </span>
              Download brochure
            </div>
          )}
        </div>
        <div className="flex justify-start flex-row mb-4">
          <div className="text-[#1E2858] font-[600] font-sixth flex text-[16px] lg:text-[24px] items-center md:ml-6 ml-4">
            Property Detail
          </div>
          <span className=" ml-4 bg-green-200 text-[#3C8E51] px-1 h-[25px] rounded-sm text-[10px] flex items-center font-fifth sm:ml-2 md:ml-6 text-sm w-[105px] md:mt-[5px] mt-[8px]">
            {propertydata.verified ? 'Verified' : 'Not Verified'}
            <CheckCircleIcon style={{ color: 'green', width: '15px', marginLeft: '6px' }} />
          </span>
        </div>
      </div>

      <table className="bg-white w-full ">
        <tbody className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-3 4xl:grid-cols-3">
          <tr className="flex justify-start  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex items-center gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-xl text-[#2e4374]">
              <img src="https://squarebric-media-files.squarebric.com/launch.svg" alt="price" height={20} width={20} />
              <span className="text-[#916217] font-[600] font-sixth"> Pre Launch Offer</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-xl text-[#916217] capitalize">
              {propertydata.preLaunchOffer}
            </td>
          </tr>

          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img src="https://squarebric-media-files.squarebric.com/price.svg" alt="price" height={20} width={20} />
              <span className="font-sixth"> Price</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{propertydata.price}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl  text-[#2e4374]  w-1/2">
              <img src={purpose} alt="purpose" height={20} width={20} />
              {/* <img src="https://squarebric-media-files.squarebric.com/Purpose.png" alt="purpose" height={20} width={20} /> */}
              <span className="font-sixth"> Purpose</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize"> {property_details.purpose}</span>
            </td>
          </tr>
          <tr className=" border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300 flex">
            <td className="flex justify-start items-center gap-4  text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-xl  text-[#2e4374]  ml-6 ">
              <img
                src="https://squarebric-media-files.squarebric.com/add.svg"
                alt="address"
                height={20}
                width={20}
                className=" "
              />
              <span className="font-sixth capitalize"> Address</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize"> {propertydata.address}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl  text-[#2e4374]  w-1/2">
              <img src="https://squarebric-media-files.squarebric.com/Vector.svg" alt="tag" width={20} />
              <span className="font-sixth capitalize"> Super Area</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="capitalize">
                {' '}
                {superArea ? `${superArea.super_area.value} ${superArea.super_area.unit}` : 'N/A'}
              </span>
            </td>
          </tr>

          <tr className="flex border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/CarpetArea.svg"
                alt="furnishing"
                height={20}
                width={20}
              />
              <span className="font-sixth">Carpet Area</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">
                {carpetArea ? `${carpetArea.carpet_area.value} ${carpetArea.carpet_area.unit}` : 'N/A'}
              </span>
            </td>
          </tr>

          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/housefacing.svg"
                alt="facing"
                height={20}
                width={20}
              />
              <span className="text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl font-sixth">
                Facing
              </span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize"> {property_details.facing}</span>
            </td>
          </tr>

          <tr className="flex justify-start  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374] font-sixth">
              <img
                src="https://squarebric-media-files.squarebric.com/property.svg"
                alt="age of property"
                height={20}
                width={20}
              />
              Age of Property
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] font-sixth capitalize">
              {property_details.age_of_property}
            </td>
          </tr>

          <tr className="flex justify-start  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex items-center gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374] font-sixth">
              <img
                src="https://squarebric-media-files.squarebric.com/propertyfloor.svg"
                alt="property on floor"
                height={20}
                width={20}
              />
              Property on Floor
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] font-sixth capitalize">
              {propertydata.property_on_the_floors}
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/FLOORING.svg"
                alt="flooring"
                height={20}
                width={20}
              />
              <span className="font-sixth"> Flooring</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize"> {propertydata.flooring}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl  text-[#4BA3DB]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/availability.svg"
                alt="availability"
                height={20}
                width={20}
              />
              <span className="font-sixth capitalize"> Availability</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{propertydata.availability}</span>
            </td>
          </tr>

          <tr className="flex border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/furnish.svg"
                alt="furnishing"
                height={20}
                width={20}
              />
              <span className="font-sixth"> Furnishing</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{formatStatus(property_details.furnished_status)}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img src="https://squarebric-media-files.squarebric.com/bed.svg" alt="bedrooms" height={20} width={20} />
              <span className="font-sixth"> Bedrooms</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{property_details.bedroom_number}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/MGBED.svg"
                alt="bathrooms"
                height={20}
                width={20}
              />
              <span className="font-sixth capitalize"> Bathrooms</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{property_details.bathrooms}</span>
            </td>
          </tr>
          <tr className="flex border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/balcony1.svg"
                alt="balcony"
                height={20}
                width={20}
                className=" text-[#2e4374]"
              />
              <span className="font-sixth"> Balconies</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{property_details.balcony}</span>
            </td>
          </tr>

          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/agentname.svg"
                alt="bedrooms"
                height={20}
                width={20}
              />
              <span className="font-sixth"> Agent Name</span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize ">{property_details.agent_name}</span>
            </td>
          </tr>
          <tr className="flex  border-b-0 sm:border-b-0 md:border-b-[1px] border-gray-300">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/owername.svg"
                alt="facing"
                height={20}
                width={20}
              />
              <span className="text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl font-sixth">
                Owner Name
              </span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize">{property_details.agent_name}</span>
            </td>
          </tr>

          <tr className="flex justify-center items-center  border-b-0 sm:border-b-0 md:border-b-[1px]">
            <td className="flex justify-start gap-4 px-2 py-2 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374] ml-4  w-1/2">
              <img
                src="https://squarebric-media-files.squarebric.com/possessionstatus.svg"
                alt="facing"
                height={20}
                width={20}
              />
              <span className="text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl font-sixth">
                Possession Status
              </span>
            </td>
            <td className="px-2 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize mr-4">
                {property_details?.possession_status?.replace(/_/g, ' ') || 'N/A'}
              </span>
            </td>
          </tr>
          <tr className="flex  ">
            <td className="flex justify-start gap-4 px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2e4374]  w-1/2">
              {/* <img src="https://squarebric-media-files.squarebric.com/housefacing.svg" alt="facing" height={20} width={20} /> */}
              <img src={NumberofFloors} alt="facing" height={20} width={20} />
              <span className="text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl font-sixth">
                Number of Floors
              </span>
            </td>
            <td className="px-6 py-4 text-sm sm:text-sm md:text-[16px] lg:text-lg xl:text-xl 2xl:text-xl 3xl:text-[16px] 4xl:text-xl text-[#2E4374] w-1/2 text-right">
              <span className="font-sixth capitalize"> {property_details.property_on_the_floors}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PropertyDetail;
