import React from 'react'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
 const Masterplan = () => {
  return (
    <div>
           <div className="flex flex-col justify-start items-start mt-10 p-2 w-full">
        <h2 className="text-[#1E2858] mb-4 font-[600] font-sixth flex text-[16px] lg:text-[24px] items-center ml-3">
          Master Plan
          <TrendingFlatIcon className='mt-1'/>
        </h2>
        <div className="flex justify-center items-center p-2 w-full">
          <img
            className="border rounded-sm w-full object-cover"
            src="https://squarebric-media-files.squarebric.com/mastermap.svg"
            alt="Master Plan"
            style={{ borderColor: '#96A1B9' }}
          />
        </div>
      </div>
    </div>
  )
}

export default Masterplan
