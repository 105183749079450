// selectedBuildingReducer.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedOptions: [],
};

const selectedBathrromSlice = createSlice({
  name: 'selectedBathroom',
  initialState,
  reducers: {
    setSelectedOptions: (state, action) => {
      state.selectedOptions = action.payload;
    },
  },
});

export const { setSelectedOptions } = selectedBathrromSlice.actions;

export default selectedBathrromSlice.reducer;
