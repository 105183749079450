// src/redux/slices/buttonsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedButton: null,
  selectedSubOptions: [],
};

const buttonsSlice = createSlice({
  name: 'buttons',
  initialState,
  reducers: {
    selectButton: (state, action) => {
      state.selectedButton = action.payload;
    },
    setSelectedSubOptions: (state, action) => {
      state.selectedSubOptions = action.payload;
    },
  },
});

export const { selectButton, setSelectedSubOptions } = buttonsSlice.actions;
export default buttonsSlice.reducer;
