// serviceSlice.js (or your relevant slice file)
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: 1,
  totalCount: 3,
  // other state properties
};

const serviceSlice = createSlice({
  name: 'SearchProperty',
  initialState,
  reducers: {
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    // other reducers
  },


});

export const { setCurrentPage } = serviceSlice.actions;
export default serviceSlice.reducer;
