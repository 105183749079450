import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Reviewreview = () => {
  const [showAllCards, setShowAllCards] = useState(false);
  const [ratings, setRatings] = useState([]);

  useEffect(() => {
    fetchRatingsData();
  }, []);

  const fetchRatingsData = async () => {
    try {
      const response = await fetch(
        'https://square-bric-backend-api.squarebric.com/home-service/home-service-details?service_uuid=c6214b18-cb03-47b5-afc1-f24ce104d222'
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRatings(data.service_details.rating);
    } catch (error) {
      console.error('Error fetching rating data:', error);
    }
  };

  const toggleShowAllCards = () => {
    setShowAllCards(!showAllCards);
  };

  return (
    <div className="w-full mt-6 ">
      <h1 className="md:text-start text-start ml-14 text-[#2E4374] font-thirdCustom font-semibold md:text-2xl text-lg mt-4">
        Review of Our Customers
      </h1>
      <div className="flex flex-col justify-center items-center">
        {ratings.slice(0, showAllCards ? ratings.length : 2).map((rating, index) => (
          <div key={index} className="border md:w-4/5 mt-4  rounded-sm shadow-sm shadow-gray-400 bg-[#FFFFFF]">
            <div className="">
              <ul className="flex flex-col m-2">
                <div className="flex justify-between">
                  <div className="font-semibold text-[#2E4374] font-thirdCustom text-xl uppercase">
                    {rating.user_name}
                  </div>
                  <div className="flex">
                    {Array.from({ length: rating.rating }).map((_, index) => (
                      <span key={index} className="text-yellow-500 flex justify-end items-end ">
                        &#9733;
                      </span>
                    ))}
                  </div>
                </div>
                <li className="text-[#2E4374]  font-light font-thirdCustom w-3/5 flex justify-between capitalize mt-1">
                  {rating.review}
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>
      <div onClick={toggleShowAllCards} className="mt-10 flex justify-center items-center text-[#2E4374]">
        <span> </span>
        {showAllCards ? 'Show Less' : 'View More'}
        <KeyboardArrowDownIcon />
      </div>
    </div>
  );
};

export default Reviewreview;
