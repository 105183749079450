import React, { useState, useRef } from 'react';
import { cookiesData } from './Cookiesdata';
import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import 'react-lazy-load-image-component/src/effects/blur.css';

const About = () => {
  const [expandedCookieId, setExpandedCookieId] = useState(null); // Track the currently expanded cookie ID
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');
  const contentRefs = useRef([]);

  const toggleContent = (id) => {
    setExpandedCookieId((prevId) => (prevId === id ? null : id)); // Toggle or collapse if already expanded
  };

  const getContentHeight = (id) => {
    const content = contentRefs.current[id];
    return content ? content.scrollHeight : 0; // Get the scrollable height of the content
  };

  return (
    <>
      <div className="bg-[url('https://squarebric-media-files.squarebric.com/cookies.png')] mx-auto max-w-7xl bg-cover bg-center h-[550px] w-full flex flex-col mt-20">
        <ul className="flex p-5">
          <li>
            <Link to="/" className="text-white font-semibold capitalize">
              Home <KeyboardArrowRightIcon />
              <span className="text-white text-[14px] font-[500]"> {pathnameWithoutSlash}</span>
            </Link>
          </li>
        </ul>
        <h1 className="text-white mt-[280px]  text-[40px] md:text-[45px] mb-[200px] ml-[30px] xl:ml-[70px] font-[700] capitalize font-fifth ">
          Cookies
        </h1>
      </div>

      <div className="mt-5 mx-auto max-w-7xl p-10">
        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px]">
            Your Privacy Is Important to Us
          </h1>
       
          <p className="text-[#2E4374] md:font-[500] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5">
            We value your privacy and are committed to responsibly processing your data to enhance your experience. We
            use your information to deliver personalized content and advertisements, as well as to assess the
            effectiveness of these efforts, helping us improve our website. We may share this data with our trusted
            partners, either with your explicit consent or based on legitimate interests. You have full control over how
            your data is used.
            {isExpanded
              ? ' Whether you choose to grant consent or object to certain uses, you can easily manage these preferences below. We provide detailed options to customize your experience for each specific purpose, and your choices will be communicated to all partners involved, ensuring transparency and compliance with the industry-standard transparency and consent framework. Rest assured, we prioritize your right to privacy and are committed to safeguarding your personal information at every step.'
              : ''}
            <button
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-blue-400 ml-1 font-[700]"
              aria-expanded={isExpanded}
              aria-controls="expandable-content"
            >
              {isExpanded ? 'Read less' : 'Read more'}
            </button>
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px] mt-4">
            Strictly Necessary Cookies
          </h1>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5">
            These cookies are essential for the proper operation of the Rightmove website, ensuring that key functions
            work seamlessly. For example, they allow you to set your privacy preferences, securely log into your
            account, and fill out necessary forms. Since these cookies are critical for site functionality, they cannot
            be turned off. However, you may configure your browser to block or alert you about these cookies. Please
            keep in mind that doing so may affect your experience and prevent certain features of the Rightmove website
            from functioning  correctly.
          </p>
        </div>

        <div className="flex flex-col">
          {cookiesData.map((cookie) => (
            <div key={cookie.id}>
              <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px] mt-4">
                {cookie.id}. {cookie.name}
              </h1>
              <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5">
                {cookie.description.split('Read more')[0]}
                {expandedCookieId !== cookie.id && (
                  <button
                    onClick={() => toggleContent(cookie.id)}
                    className="text-blue-400 ml-1 font-[700] inline-flex"
                  >
                    Read more
                  </button>
                )}
              </p>

              {/* Content that will expand/collapse */}
              <div
                ref={(el) => (contentRefs.current[cookie.id] = el)}
                style={{
                  maxHeight: expandedCookieId === cookie.id ? `${getContentHeight(cookie.id)}px` : '0px',
                  transition: 'max-height 0.8s ease',
                  overflow: 'hidden',
                }}
              >
                {cookie.description
                  .split('Read more')[1]
                  .split(/(?=\d\.)/)
                  .map((paragraph, i) => (
                    <p
                      key={i}
                      className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2"
                    >
                      {paragraph.trim()}
                    </p>
                  ))}
                <button onClick={() => toggleContent(cookie.id)} className="text-blue-400 ml-1 font-[700] inline-flex">
                  Read less
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default About;
