import React from 'react';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

const Aboutproject = () => {
  return (
    <>
      <div className="w-full">
        <div
          className="bg-[#2e4374] text-white mt-10 m-4 p-4"
          style={{ borderBottomLeftRadius: '20%', borderTopRightRadius: '20%' }}
        >
          <div className="flex flex-col lg:flex-row justify-between p-4 font-SecondCustom font-semibold text-lg">
            <h1 className="font-[600] font-sixth flex text-[16px] lg:text-[24px]">About Project</h1>
            <h1 className="mt-4 md:mt-0 font-[600] font-sixth text-[16px] lg:text-[24px] hidden lg:block">
              Explore Project
              <TrendingFlatIcon />
            </h1>
          </div>
          <div className="flex flex-col lg:flex-row p-4 items-start">
            <img
              src="https://squarebric-media-files.squarebric.com/buildinghome.svg"
              alt="bed"
              className="mr-2 bg-[#E0E8FA] mb-4 md:mb-0"
              style={{ borderBottomLeftRadius: '50%' }}
              width={80}
            />
            <div class="w-full md:items-center">
              <div class="flex-1 justify-between text-center ">
                <h1 class="font-sixth text-base sm:text-sm xs:text-xs xs:ml-20 lg:text-left lg:mt-0 md:-mt-20 sm:-mt-24 xs:-mt-24 lg:ml-0 md:-ml-6 sm:ml-0">
                  UNMISSABLE OFFER QUEEN BOMONTI RESIDENCE
                </h1>
                <h1 className="mt-4 md:mt-0 font-fifth text-xl md:text-xl sm:text-sm font-[600] lg:hidden block md:-ml-10 sm:mr-40 xs:mr-0 xs:ml-20">
                  Explore Project
                  <TrendingFlatIcon />
                </h1>
              </div>

              <div className="flex flex-1 flex-col md:flex-row md:ml-6">
                <div class="mt-4 flex justify-center md:justify-start ">
                  <button class="border-2 p-2 mr-6 mb-4 rounded-md font-sixth px-4 lg:px-10 md:px-2 mt-4 lg:mb-0 ">
                    Follow Project
                  </button>
                  <button class="border-2 p-2 rounded-md font-sixth  mb-4 lg:px-10  mt-4 lg:mb-0 ">
                    Compare Projects
                  </button>
                </div>
                <span class="hidden md:block md:ml-4 border-l-2 border-white p-1 h-20"></span>

                <div class="flex-1 mt-4 md:mt-2 sm:mt-0 xs:mt-0 lg:ml-6 flex justify-center md:justify-start">
                  <button class="border-2 p-2 mb-4 md:mb-4 lg:mb-0 mt-6 rounded-md font-sixth px-8 lg:mr-6 md:mr-6 sm:mr-6 mr-6 lg:px-10">
                    View Map
                  </button>
                  <button class="border-2 p-2 rounded-md font-sixth lg:mb-0 mt-6 mb-4 ml-0 px-5 lg:px-10">
                    Call for price
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutproject;