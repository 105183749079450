import React ,{useEffect}from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSubPropertyType } from '../../../redux/propertyReducer';
import './Property.css'
const SubPropertyType = () => {
    const dispatch = useDispatch();
    const propertyType = useSelector((state) => state.properties.propertyType);
    const subPropertyType = useSelector((state) => Array.isArray(state.properties.subPropertyType) ? state.properties.subPropertyType : []);

    console.log('Current Property Type:', propertyType);
    console.log('for mobile devices Sub-Property Types:', subPropertyType);

  
    const subOptions = {
        Commercial: [
            { label: 'Commercial Shop', backendLabel: 'CommercialShop' },
            { label: 'Commercial Land', backendLabel: 'CommercialLand' },
            { label: 'IT Park', backendLabel: 'CommercialItPark' },
            { label: 'Industrial Land', backendLabel: 'IndustrialLand' }
        ],
        Residential: [
            { label: 'Flat/Apartment', backendLabel: 'FlatApartment' },
            { label: 'Residential House', backendLabel: 'ResidentialHouse' },
            { label: 'Residential Land Plot', backendLabel: 'ResidentialLandPlot' },
            { label: 'Villa', backendLabel: 'Villa' },
            { label: 'Builder Floor', backendLabel: 'BuilderFloor' }
        ],
        Other: [
            { label: 'Agriculture', backendLabel: 'Agriculture' },
            { label: 'Farmhouse', backendLabel: 'Farmhouse' }
        ]
    };

    const currentSubOptions = subOptions[propertyType] || [];
    const handleSubPropertyTypeChange = (backendLabel) => {
        if (subPropertyType.includes(backendLabel)) {
            dispatch(toggleSubPropertyType(subPropertyType.filter(item => item !== backendLabel)));
        } else {
            dispatch(toggleSubPropertyType([...subPropertyType, backendLabel]));
        }
    };

    return (
        <div className="w-full">
            <div className="flex flex-col">
             
                <ul className="list-none p-0 text-[#2E4374] font-sixth font-[400] text-[16px]">
                    {currentSubOptions.map((option) => (
                        <li
                            key={option.backendLabel}
                            className={`flex items-center p-2 cursor-pointer ${subPropertyType.includes(option.backendLabel) ? 'bg-gray-200' : ''}`}
                            onClick={() => handleSubPropertyTypeChange(option.backendLabel)}
                        >
                            <div className="flex-1">{option.label}</div>
                            <div>
                                <input
                                    type="checkbox"
                                    checked={subPropertyType.includes(option.backendLabel)}
                                    onChange={() => handleSubPropertyTypeChange(option.backendLabel)}
                                    className=" gradient-checkbox"
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default SubPropertyType;
