import React, { useState, useEffect } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const Card = ({ buttonLabel, title, description, image }) => {
  return (
    <div className="rounded overflow-hidden flex flex-col justify-start bg-white shadow-lg m-2 p-4 h-[300px] md:h-[350px] lg:h-[400px] border-2 border-[#E5EFFF]">
      <img className="mx-auto w-60 h-20 mt-2 object-fit" src={image} alt={buttonLabel} />
      <div className="text-center flex flex-col mt-6 h-full">
        <div className="font-sixth text-[#1E2858] font-semibold text-base md:text-base tracking-tighter lg:text-lg">{title}</div>
        <p className="font-sixth text-sm md:text-sm lg:text-base xl:text-base text-[#666] w-full md:mt-2  mx-auto">{description}</p>
        <div className="mt-auto ">
          <button className="font-sixth text-[#2e4374] hover:text-[#1BA3F9] font-semibold text-base md:text-base lg:text-sm xl:text-lg transition-transform transform hover:-translate-y-1 ">
            {buttonLabel}{' '}
            <span>
              <ArrowForwardIcon  className='lg:w-5 lg:h-5 w-5 h-5'/>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

const ChooseUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isTabletScreen = useMediaQuery('(max-width:1050px)'); // Adjust for larger tablets if needed
  const isSmallScreen = useMediaQuery('max-width:650px');

  const cards = [
    {
      description: "Experience luxury living through our curated selection of exclusive properties",
      image: 'https://squarebric-media-files.squarebric.com/Exclusive_Property.svg',
      buttonLabel: 'Read More',
      title: 'Exclusive Properties'
    },
    {
      description: "Ensuring real estate safety with strong security measures",
      image: 'https://squarebric-media-files.squarebric.com/Safe_and_secure.svg',
      buttonLabel: 'Read More',
      title: 'Safe and Secure'
    },
    {
      description: "Enhance property exploration with immersive virtual tours",
      image: 'https://squarebric-media-files.squarebric.com/vertual.svg',
      buttonLabel: 'Read More ',
      title: 'Virtual Tours'
    },
    {
      description: "Tech-driven service Boost real estate business, ensuring  client satisfaction",
      image: "https://squarebric-media-files.squarebric.com/Buisness_growth.svg",
      buttonLabel: 'Read More ',
      title: 'Business Growth'
    },
    {
      description: '"Enabling affordability in real estate through innovation and inclusivity".',
      image: "https://squarebric-media-files.squarebric.com/Afordable_cost.svg",
      buttonLabel: 'Read More ',
      title: 'Affordable Cost'
    }
  ];

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update logic to show 3 cards for screens between 1150px and 1024px
  const numCardsToShow = windowWidth >= 1150 ? 3 : windowWidth >= 1024 ? 3 : windowWidth >= 768 ? 2 : 1;


  const goToPrevSlide = () => {
    const newIndex = currentIndex === 0 ? cards.length - numCardsToShow : currentIndex - numCardsToShow;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = currentIndex === cards.length - numCardsToShow ? 0 : currentIndex + numCardsToShow;
    setCurrentIndex(newIndex);
  };

  const getCardsToShow = () => {
    const numCardsToShow = windowWidth >= 1150 ? 3 : windowWidth >= 1024 ? 3 : windowWidth >= 768 ? 2 : 1;
    return cards.slice(currentIndex, currentIndex + numCardsToShow);
  };

  const showPrevButton = currentIndex !== 0;
  const showNextButton = currentIndex + numCardsToShow < cards.length;

  return (
    <div className="flex justify-center items-center ">
      <div className="container mt-10 sm:mt-10 md:mt-20 w-full ml-4">
        <div
          className="text-[#1E2858] text-xl sm:text-xl md:text-2xl lg:text-3xl text-center md:text-left sm:text-center font-fifth font-[700] mb-4 "
          style={{ marginLeft: isTabletScreen ? '10px ' : '' }}
        >
          Why Choose US ?
        </div>
        <div className="hidden lg:flex justify-center overflow-hidden">
          {cards.map((card, index) => (
            <div key={index} className=" w-full">
              <Card
                buttonLabel={card.buttonLabel}
                description={card.description}
                image={card.image}
                title={card.title}
              />
            </div>
          ))}
        </div>

        {/* For tablets and smaller screens */}
        <div className="lg:hidden relative flex overflow-hidden">
          <div
            className="flex transition-transform duration-300 ease-in-out"
            style={{
              transform: `translateX(-${currentIndex * (100 / getCardsToShow().length)}%)`,
              width: ` ${getCardsToShow().length * 100}%`
            }}
          >
            {cards.map((card, index) => (
              <div
                key={index}
                className="flex-shrink-0 w-full md:w-1/2"
                style={{ width: ` ${100 / getCardsToShow().length}% ` }}
              >
                <Card
                  buttonLabel={card.buttonLabel}
                  description={card.description}
                  image={card.image}
                  title={card.title}
                />
              </div>
            ))}
          </div>
          {cards.length > 1 && (
            <>
              {showPrevButton && (
                <button
                  className="absolute top-1/2 transform -translate-y-1/2 left-1 bg-white p-2 opacity-60"
                  onClick={goToPrevSlide}
                >
                  <ArrowBackIosNewIcon />
                </button>
              )}
              {showNextButton && (
                <button
                  className="absolute top-1/2 transform -translate-y-1/2 right-1 bg-white p-2 opacity-60"
                  onClick={goToNextSlide}
                >
                  <ArrowForwardIosIcon />
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChooseUs;
