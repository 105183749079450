import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { termsData } from './Cookiesdata';


const About = () => {
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');

  return (
    <div>
      <div className=" bg-[url('https://squarebric-media-files.squarebric.com/termsandconditions.png')] mx-auto max-w-7xl bg-cover bg-center h-[550px] w-full flex flex-col mt-20 ">
        <ul className="flex p-5">
          <li className="">
            <Link to="/" className="text-white font-semibold capitalize ">
              Home <KeyboardArrowRightIcon />
              <span className="text-white text-[14px] font-[500]"> {pathnameWithoutSlash}</span>
            </Link>
          </li>
        </ul>
        <h1 className="text-white text-[30px] md:text-[45px] mb-[200px] ml-[30px] xl:ml-[70px] font-[700] capitalize mt-[280px] font-fifth">
          Terms And Conditions
        </h1>
      </div>

      <div className="mt-5 mx-auto max-w-7xl p-10 ">
        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px] ">Term OF USES</h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5 ">
            These Terms of Use ("Terms") constitute a legally binding agreement between you and Squarebric Realty
            Services Limited ("Squarebric," "We," "Us," or "the Company") regarding your use of the website
            www.squarebric.com (the "Site") and any services offered by Squarebric, including but not limited to content
            delivery via the Site or any mobile or internet-connected device (collectively referred to as "the
            Service").
          </p>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3 ">
            By accessing or using the Site and its tools, you agree to be bound by these Terms & Conditions. By merely
            using the Site, you are entering into a contract with Squarebric Realty Services Limited, the owner of the
            platform. These Terms, along with our policies, form your binding obligations with Squarebric. It is
            important that you review and understand these terms before using the platform to ensure you are fully
            informed of your Read more
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px]  mt-4">
            ACCEPTANCE OF THE TERMS OF USE
          </h1>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5 ">
            This website is owned and operated by Squarebric pvt Limited (which includes all subsidiaries of Squarebric)
            ("Squarebric," "Company," "we," or "us"). These Terms of Use, along with the lease or purchase agreements
            ("Property Agreement") that you may have entered into with Squarebric or its affiliates regarding Any govern
            your access to and use of www.squarebric.com the Intellectual Property and Usage Rights.{' '}
          </p>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3 ">
            The content, features, and functionality of Squarebric.com—including but not limited to information,
            software,text, displays, images, video, audio, and their design, selection, and arrangement—are owned by
            Squarebric.com,its licensors, or other material providers. These elements are protected by copyright,
            trademark, patent, trade secret, Read more
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px]  mt-4">
            ACCESSING THE WEBSITE AND ACCOUNT SECURITY
          </h1>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5 ">
            Squarebric reserves the right to modify or withdraw the Website, including any services or materials
            provided, at our sole discretion and without notice. We will not be liable if the Website, or any part of
            it, is unavailable at any time or for any duration. Additionally, we may restrict access to certain sections
            of the Website or the entire Website to any user, including registered users.
            <br />
            You are responsible for:
            <br />
            Arranging Access: Ensuring you have all necessary arrangements to access the Website. Informing Users:
            Making sure that anyone accessing the Website through your internet connection is aware of and complies with
            these Terms of Use. Read more
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px]  mt-4">
            Intellectual Property and Usage Rights
          </h1>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5 ">
            The content, features, and functionality of Squarebric.com—including but not limited to information,
            software, text, displays, images, video, audio, and their design, selection, and arrangement—are owned by
            Squarebric.com, its licensors, or other material providers. These elements are protected by copyright,
            trademark, patent, trade secret, and other intellectual property laws of the United Arab Emirates and
            internationally.
          </p>
          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3 ">
            You are granted a limited, non-commercial, and personal license to use the Website under these Terms of
            Use.You may not reproduce, distribute, modify, create derivative works of, publicly display, perform,
            republish,download, store, or transmit any material from our Website, except in the following
            circumstances
          </p>
        </div>

        <div className="flex flex-col">
          {termsData.map((cookie, index) => (
            <div key={index} className="">
              <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[25px] md:leading-[20px] mt-4 ">
                {cookie.name}
              </h1>
              <ul className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-5 mb-4 list-disc pl-5">
                {cookie.features.map((feature, index) => (
                  <li
                    key={index}
                    className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3 ">
            If you wish to exercise any of these rights, please reach out to us
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
