import React, { useState } from 'react';
import { newspressdata } from './mediadata';
import { Divider } from '@mui/material';
import Newspress from './Newspress';

const Squarecards = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = newspressdata.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(newspressdata.length / itemsPerPage);

  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const pageNumbers = Math.min(maxVisiblePages, pageCount);
    const pagesBeforeEllipsis = Math.min(currentPage - 1, Math.floor((maxVisiblePages - 1) / 2));
    const pagesAfterEllipsis = Math.min(pageCount - currentPage, Math.floor((maxVisiblePages - 1) / 2));
    const startPage = Math.max(1, currentPage - pagesBeforeEllipsis);
    const endPage = Math.min(pageCount, currentPage + pagesAfterEllipsis);

    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => setCurrentPage(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i ? 'text-white rounded-full bg-[#2e4374]' : 'bg-gray-200 rounded-full'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(1)}
              className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {buttons}
        {endPage < pageCount - 1 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {endPage < pageCount && (
          <li className="mx-1">
            <button
              onClick={() => setCurrentPage(pageCount)}
              className={`px-3 py-1 rounded ${currentPage === pageCount ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              {pageCount}
            </button>
          </li>
        )}
      </>
    );
  };

  return (
    <div className="md:w-full">
      {currentItems.map((item) => (
        <div
          key={item.id}
          className="border-2 border-1-neutral-400 flex flex-col md:flex-row md:m-20 m-6 md:p-8 p-4 rounded-lg mt-10"
        >
          <img src={item.img} alt="Recent" />
          <div className="md:ml-8">
            <h2 className="font-thirdCustom md:text-2xl text-base mt-2 md:mt-0 font-semibold text-[#1E2858]">
              {item.title}
            </h2>
            <p className="md:text-lg text-xs mt-4 text-[#1e2858]">{item.description}</p>
            <p className="font-thirdCustom mt-4 md:text-base text-xs font-semibold text-[#1e2858] bg-blue-100 rounded-lg p-2 md:w-40 w-28">
              Date: {item.date}
            </p>
            <div className="md:hidden">
              <Divider style={{ backgroundColor: '#1E2858', width: '100%', marginTop: '15px' }} />
              <div className="text-[#1E2858] flex justify-center text-base font-semibold mt-3">Read More</div>
            </div>
          </div>
        </div>
      ))}

      {/* Pagination */}
      <div className="flex justify-center items-center mt-4">
        <button
          onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 text-lg font-bold mr-2 ${
            currentPage === 1 ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'
          }`}
        >
          &lt;&lt;
        </button>
        <ul className="flex">{renderPaginationButtons()}</ul>
        <button
          onClick={() => setCurrentPage(currentPage === pageCount ? pageCount : currentPage + 1)}
          disabled={currentPage === pageCount}
          className={`px-3 py-1 mx-1 text-lg font-bold ml-2 ${
            currentPage === pageCount
              ? 'bg-[#2e4374] text-white rounded-full'
              : 'text-[#071C35] bg-gray-200 rounded-full'
          }`}
        >
          &gt;&gt;
        </button>
      </div>
    </div>
  );
};

const NewsPressRelease = () => {
  return (

    <div className="justify-center items-center flex w-full  ">
      <div className=" mt-6 mx-auto max-w-7xl">
        <div className="md:block hidden mx-24 ">
          <Newspress />
        </div>

        <div className="md:mt-[20px] md:mx-24 mx-6">
          <h1 className="font-[600] md:text-[36px] text-[20px] text-[#2E4374] font-thirdCustom">
            Press Release-Media Center
          </h1>
          <p className="text-[#2e4374] mt-1">Distracted By The Readable Content Of A Page When Looking At Its Layout.</p>
        </div>

        <div className="block md:hidden mx-6">
          <Newspress />
        </div>

        {/* <div className="bg-[#EEF7FF] mt-[20px] rounded-3xl grid md:grid-cols-2 grid-col-1 p-4 mx-16 ">
          <div className="p-2">
            <img src="https://squarebric-media-files.squarebric.com/News.png" alt="" />
          </div>

          <div className="mt-2">
            <h1 className="font-[600] text-[26px] text-[#1E2858]  capitalize">
              Squarebric celebrate the spirit of the union on UAE Flag Day
            </h1>
            <p className="text-[#1E2858] font-[400px] text-[16px]">
              It is a long established fact that a reader will be distracted by the readable content of a page when
              looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of
              letters, as opposed to using 'Content here, content here', making it look like readable English. Many
              desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
              search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved
              over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
            </p>

            <button className="text-[#1E2858] font-thirdCustom text-[20px] font-semibold mt-2">Read more</button>
          </div>
        </div> */}
        <div className='md:m-0 m-4'>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-10 bg-[#EEF7FF] mt-[20px] rounded-3xl p-6 md:mx-20">
          <div className="flex justify-center items-center ">
            <img src="https://squarebric-media-files.squarebric.com/News.png" alt="homeIcon" className="w-[100%] md:h-[400px]" />
          </div>
          <div className=" flex  flex-col">
            <div className="flex flex-col">
              <h1 className="text-[#1E2858] font-[600] md:text-[25px] text-[16px] md:leading-[30px] capitalize ">
                Squarebric celebrate the spirit of the union on UAE Flag Day
              </h1>
              <p className="text-[#2E4374] md:font-[500] font-[400] lg:text-[18px] 2xl:text-[18px]  text-[14px] md:text-[15px] md:leading-[25px] leading-[18px] text-justify md:mt-5 mt-2 ">
                It is a long established fact that a reader will be distracted by the readable content of a page when
                looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                of letters, as opposed to using 'Content here, content here', making it look like readable English. Many
                desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a
                search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have
                evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).
              </p>
              <p className="text-[#1E2858] font-[500] md:text-[22px] text-[16px] leading-[20px] mt-2">Read more</p>
            </div>
          </div>
        </div>
        </div>
        <div className="mt-4">
          <Squarecards />
        </div>
      </div>
    </div>
  );
};

export default NewsPressRelease;
