import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchLocationData = createAsyncThunk('location/fetchLocationData', async () => {
  const response = await fetch('https://square-bric-backend-api.squarebric.com/country-state-sub-city');
  const data = await response.json();
  return data[0] || {}; // Handle case where data[0] might be undefined
});

const locationSlice = createSlice({
  name: 'location',
  initialState: {
    countries: [],
    selectedCountry: localStorage.getItem('selectedCountry') || null,
    states: [],
    selectedState: localStorage.getItem('selectedState') || null,
    cities: [],
    selectedCity: localStorage.getItem('selectedCity') || null,
    loading: false,
    error: null,
    dataFetched: false,
    data: {}, // Initialize data as an empty object
  },
  reducers: {
    selectCountry: (state, action) => {
      state.selectedCountry = action.payload;
      state.states = state.data[action.payload] ? Object.keys(state.data[action.payload]) : [];
      state.selectedState = null;
      state.cities = [];
      state.selectedCity = null;
      // Save to local storage
      localStorage.setItem('selectedCountry', action.payload);
      localStorage.removeItem('selectedState');
      localStorage.removeItem('selectedCity');
    },
    selectState: (state, action) => {
      state.selectedState = action.payload;
      state.cities = state.data[state.selectedCountry] && state.data[state.selectedCountry][action.payload]
        ? state.data[state.selectedCountry][action.payload]
        : [];
      state.selectedCity = null;
      // Save to local storage
      localStorage.setItem('selectedState', action.payload);
      localStorage.removeItem('selectedCity');
    },
    selectCity: (state, action) => {
      state.selectedCity = action.payload;
      // Save to local storage
      localStorage.setItem('selectedCity', action.payload);
    },
    resetLocation: (state) => {
      state.selectedCountry = null;
      state.states = [];
      state.selectedState = null;
      state.cities = [];
      state.selectedCity = null;
      // Remove from local storage
      localStorage.removeItem('selectedCountry');
      localStorage.removeItem('selectedState');
      localStorage.removeItem('selectedCity');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLocationData.fulfilled, (state, action) => {
        state.loading = false;
        state.dataFetched = true;
        state.data = action.payload;
        state.countries = Object.keys(action.payload);

        // Reset state and cities based on selectedCountry and selectedState
        if (state.selectedCountry) {
          state.states = state.data[state.selectedCountry] ? Object.keys(state.data[state.selectedCountry]) : [];
        }
        if (state.selectedState) {
          state.cities = state.data[state.selectedCountry] && state.data[state.selectedCountry][state.selectedState]
            ? state.data[state.selectedCountry][state.selectedState]
            : [];
        }
      })
      .addCase(fetchLocationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { selectCountry, selectState, selectCity, resetLocation } = locationSlice.actions;

export default locationSlice.reducer;
