import React, { useState, useEffect } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useMediaQuery } from '@mui/material';

const Card = ({ item1, item2, selected }) => {
  const selectedStyle = selected
    ? {
        borderRadius: '9px',
        border: '4px solid rgba(75, 163, 219, 0.50)',
        background: 'linear-gradient(187deg, #DDDDDC -45.18%, #8EBCFF 243.01%)'
      }
    : {
        border: '1px solid #4BA3DB',
        background: 'linear-gradient(187deg, rgba(255, 255, 255, 0.50) 21.22%, rgba(115, 115, 115, 0.50) 241.2%)'
      };

  return (
    <div
      className={`card h-24 w-40 flex justify-center items-center rounded-lg transition duration-300 ${
        selected ? '' : 'p-4'
      }`}
      style={selectedStyle}
    >
      <ul className="flex flex-col justify-center items-center">
        <li className="text-[#1E2858] font-semibold uppercase">{item1}</li>
        <li className="text-[#1E2858] font-bold">{item2}</li>
      </ul>
    </div>
  );
};

const Slider = ({ cards, onCardClick }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const isMediumScreen = useMediaQuery('(min-width:601px) and (max-width:960px)');

  const visibleCards = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;

  const goToPrevSlide = () => {
    const newIndex = currentIndex - 1 < 0 ? cards.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    onCardClick(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = currentIndex + 1 >= cards.length ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    onCardClick(newIndex);
  };

  const getVisibleCards = () => {
    const endIndex = currentIndex + visibleCards;
    return cards
      .slice(currentIndex, endIndex)
      .concat(cards.slice(0, endIndex > cards.length ? endIndex - cards.length : 0));
  };

  return (
    <div className="slider-container mx-6">
      <div className="flex items-center">
        <button className="h-8 w-8 bg-[#7EB3DF] rounded-full mr-4" onClick={goToPrevSlide}>
          <ArrowBackIosNewIcon style={{ fontSize: '1rem', color: '#2E4374' }} />
        </button>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 ">
          {getVisibleCards().map((card, index) => (
            <div
              key={index}
              className={`flex ${index === 0 ? 'rounded-lg' : ''}`}
              onClick={() => {
                const cardIndex = (currentIndex + index) % cards.length;
                setCurrentIndex(cardIndex);
                onCardClick(cardIndex);
              }}
              style={{ width: isSmallScreen ? '100%' : 'auto' }}
            >
              <Card {...card} selected={index === 0} />
            </div>
          ))}
        </div>
        <button className="h-8 w-8 bg-[#9BC4E9] rounded-full ml-4" onClick={goToNextSlide}>
          <ArrowForwardIosIcon style={{ fontSize: '1rem', color: '#2E4374' }} />
        </button>
      </div>
    </div>
  );
};

const ServiceCard = () => {
  const [servicePrices, setServicePrices] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  useEffect(() => {
    fetchServiceDetails();
  }, []);

  const fetchServiceDetails = async () => {
    try {
      const response = await fetch(
        'https://square-bric-backend-api.squarebric.com/home-service/home-service-details?service_uuid=c6214b18-cb03-47b5-afc1-f24ce104d222'
      );
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const servicePriceData = Object.entries(data.service_details.service_price).map(([key, value]) => ({
        item1: key,
        item2: `$${value.price}`,
        description: value.description
      }));
      setServicePrices(servicePriceData);
    } catch (error) {
      console.error('Error fetching service details:', error);
    }
  };

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
  };

  const selectedServicePrice = servicePrices ? servicePrices[selectedCardIndex].item1 : '';

  return (
    <>
      <div className="flex flex-col justify-center items-center md:ml-6">
        <div>{servicePrices && <Slider cards={servicePrices} onCardClick={handleCardClick} />}</div>
      </div>
      <div className="ml-0 flex justify-center items-center md:ml-20">
        <div className="mt-4">
          <h1 className="flex text-[#2E4374] font-thirdCustom font-semibold capitalize text-xl mt-3 tracking-wide">
            Service includes in {selectedServicePrice}
          </h1>
          <ul className="text-[#2E4374] font-thirdCustom font-semibold text-base list-disc leading-7 md:ml-0 ml-14 w-4/5">
            {servicePrices && (
              <li key={selectedCardIndex}>
                <span className="text-[#2E4374] font-thirdCustom font-semibold">
                  {servicePrices[selectedCardIndex].description}
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ServiceCard;
