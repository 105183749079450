import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import './Property.css';


const bg = {
  background: ' var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
};

export const Designservice = () => {
  const isSmallScreen = useMediaQuery('(max-width:650px)');

  return (
    <div className="mt-5 w-full mb-10 flex justify-center ">
      <div className="backgrounddimand md:w-3/5 w-4/5 flex justify-end ml-7% max-h-screen mx-auto ">
        <Card
          className="md:mr-24 lg:mr-36 mr-6  lg:w-[350px] md:w-[300px] sm:w-[270px] w-3/5 mt-24 lg:h-[400px] md:h-[370px] sm:h-[340px]"
          style={{ borderTopLeftRadius: '50%', borderTopRightRadius: '50%' }}
        >
          <CardContent className="flex flex-col justify-center items-center">
            <div className="text-[#2E4374] font-SecondCustom  lg:text-2xl lg:mt-[40px] md:text-lg md:mt-[40px] sm:text-base sm:mt-[40px] text-sm mt-[20px] font-bold">
              Now Online
            </div>
            <p className="text-[#2E4374] font-fourth text-center lg:text-2xl lg:mt-4 md:text-lg md:mt-4 sm:text-base sm:mt-3 text-sm mt-1  leading-6 leading-tight font-extrabold">
              Design Of spaces
              <br /> and personalized
              <br /> rooms
            </p>
            <div
              className="text-center text-[#2E4374] font-thirdCustom text-base leading-tight p-1 lg:mt-[10px] md:mt-[10px] sm:mt-[4px] mt-[10px] sm:overflow-hidden sm:text-overflow-ellipsis  md:overflow-visible md:whitespace-normal"
              style={{ fontSize: isSmallScreen ? '10px' : '15px' }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua.
            </div>
            <Link to="/product">
              <div className="w-full flex justify-center lg:mt-5 md:mt-5 sm:mt-2 mt-3">
                <button className="bg-indigo-950 text-white font-customFont text-sm rounded lg:px-4 lg:py-1.5 lg:text-lg md:py-1.5 md:px-4 md:text-base sm:text-base sm:px-4 sm:py-1 px-4 py-1">
                  Read More
                </button>
              </div>
            </Link>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

const DemandingService = () => {
  const [services, setServices] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeArrow, setActiveArrow] = useState(null); // New state for tracking active arrow button
  const isSmallScreen = useMediaQuery('(max-width:650px)');
  const isMediumScreen = useMediaQuery('(max-width:1050px)');
  const isLargeScreen = useMediaQuery('(max-width:1450px)');
  const isExtraLargeScreen = useMediaQuery('(max-width:1821px)');

  let numberOfCardsToShow = 4;
  if (isSmallScreen) {
    numberOfCardsToShow = 1;
  } else if (isMediumScreen) {
    numberOfCardsToShow = 1;
  } else if (isLargeScreen) {
    numberOfCardsToShow = 2;
  } else if (isExtraLargeScreen) {
    numberOfCardsToShow = 3;
  } else {
    numberOfCardsToShow = 4;
  }

  useEffect(() => {
    axios
      .get('https://square-bric-backend-api.squarebric.com/home-service/most-demanding-service')
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => {
        console.error('Error fetching the services data:', error);
      });
  }, []);

  const totalCards = services.length;
  const hasNextSlide = currentIndex < totalCards - numberOfCardsToShow;
  const hasPrevSlide = currentIndex > 0;

  const nextSlide = () => {
    if (hasNextSlide) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
      setActiveArrow('next'); // Set active arrow to next
    }
  };

  const prevSlide = () => {
    if (hasPrevSlide) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
      setActiveArrow('prev'); // Set active arrow to prev
    }
  };

  const imageMappings = [
    { serviceType: 'Movers & Packers', imageUrl: 'https://squarebric-media-files.squarebric.com/moverspackers.png' },
    { serviceType: 'Swimming Pool Cleaning', imageUrl: 'https://squarebric-media-files.squarebric.com/swimming1.jpeg' },
    { serviceType: 'Kitchen Cleaning', imageUrl: 'https://squarebric-media-files.squarebric.com/kitchencleaning1.jpg' },
    { serviceType: 'Furniture Cleaning', imageUrl: 'https://squarebric-media-files.squarebric.com/furniturecleaning1.jpg' },
    { serviceType: 'Painting  Service', imageUrl: 'https://squarebric-media-files.squarebric.com/PaintingService.jpg' },
    { serviceType: 'Architecture', imageUrl: 'https://squarebric-media-files.squarebric.com/Architecture2.jpg' },
    { serviceType: 'Security System', imageUrl: 'https://squarebric-media-files.squarebric.com/Security.jpg' },
    { serviceType: 'Deep Cleaning', imageUrl: 'https://squarebric-media-files.squarebric.com/Demandservice.jpg' }
  ];

  return (
    <div className="w-full">
      <div className="md:mt-24 mt-16 text-[#2E4374] overflow-x-hidden">
        <div className="flex flex-col items-center justify-center relative">
          <div className="md:w-3/5 w-4/5 text-center md:text-left">
            <h1 className=" lg:text-4xl md:text-2xl md:ml-6 text-xl font-fourth text-[#2E4374]">
              Most Demanding Service
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center max-h-screen mx-auto md:w-3/5 w-4/5 relative">
            <div className="flex overflow-x-auto flex-1 mx-4">
              <div className="flex justify-center w-full">
                {services.slice(currentIndex, currentIndex + numberOfCardsToShow).map((item, index) => (
                  <div
                    key={index}
                    className="rounded-lg overflow-hidden shadow-lg m-4 bg-[#EAF4FA] font-bold"
                    style={{
                      transition: 'transform 0.3s ease',
                      cursor: 'pointer',
                      transform: 'scale(1)',
                      width: isSmallScreen ? '100%' : 'auto'
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = 'scale(1.05)';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = 'scale(1)';
                    }}
                  >
                    <img
                      src={
                        imageMappings.find((mapping) => mapping.serviceType === item.service_type)?.imageUrl ||
                        'fallbackImageUrl'
                      }
                      alt={item.service_type}
                      className="p-4 sm:h-20 w-full"
                      style={{ height: '300px' }}
                    />
                    <div className="flex justify-between flex-col">
                      <div className="font-semibold text-[#2E4374] ml-4 font-fifth flex-wrap text-xl">
                        {item.service_type}
                      </div>
                      <div className="text-xl md:text-xl sm:text-sm font-semibold font-thirdCustom  items-center -mt-2 ml-3 md:-mt-1 sm:mt-4 ">
                        <StarIcon className="text-yellow-600" style={{ fontSize: '20px' }} />
                        <span style={{ fontSize: isSmallScreen ? '15px' : '15px' }} className="text-left ml-2">
                          {item.avg_rat.toFixed(1)} (
                          {item.total_Rating > 999 ? (item.total_Rating / 1000).toFixed(1) + 'k' : item.total_Rating})
                        </span>
                      </div>
                    </div>
                    <div className="flex ml-1 md:ml-1 sm:ml-0 justify-between mt-0 mb-4">
                      <div className="flex font-thirdCustom font-semibold text-md md:text-md lg:text-md ml-2">
                        <span className="text-yellow-500 flex">
                          <img src="https://squarebric-media-files.squarebric.com/fluent.svg" alt="growth icon" width={20} height={20} />
                        </span>
                        <p
                          className="ml-2 text-sm text-[#2E4374] font-thirdCustom font-semibold mt-1"
                          style={{ fontSize: '5px' }}
                        >
                          <div className="text-sm font-semibold font-thirdCustom flex items-center">
                            {item.total_enquired > 999999
                              ? (item.total_enquired / 1000000).toFixed(1) + 'M'
                              : item.total_enquired > 999
                              ? (item.total_enquired / 1000).toFixed(1) + 'k'
                              : item.total_enquired}{' '}
                            Total Enquired
                          </div>
                        </p>
                      </div>
                      <Link to={`/service-detail/${item.service_type}`}>
                        <button
                          className="mr-4 font-thirdCustom md:text-base p-0 w-24 text-[#2e4374]  rounded-md border-2 border-[#2E4374]  hover:bg-[#2E4374] hover:text-white transition-transform transform hover:-translate-y-1 transition-all duration-300"
                          style={{
                            fontSize: isSmallScreen ? '15px' : '15px',
                            marginTop: isSmallScreen ? '10px' : '-5px'
                          }}
                        >
                          View All
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between w-full mt-4">
              <div
                className={`absolute left-0 transform -translate-y-1/2 p-2 ${
                  activeArrow === 'prev' ? 'bg-[#2E4374] text-white opacity-60' : 'bg-white text-[#2E4374] opacity-60'
                } ${!hasPrevSlide ? 'opacity-50 pointer-events-none' : ''}`}
                style={{ top: '50%', cursor: 'pointer' }}
                onClick={prevSlide}
              >
                <ArrowBackIosNewIcon />
              </div>
              <div
                className={`absolute right-0 transform -translate-y-1/2 p-2 ${
                  activeArrow === 'next' ? 'bg-[#2E4374] text-white opacity-60' : 'bg-white text-[#2E4374] opacity-60'
                } ${!hasNextSlide ? 'opacity-50 pointer-events-none' : ''}`}
                style={{ top: '50%', cursor: 'pointer' }}
                onClick={nextSlide}
              >
                <ArrowForwardIosIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Designservice />
      </div>
    </div>
  );
};

export default DemandingService;
