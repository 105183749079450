import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dollar from '../../../assets/dollar.svg';
import pound from '../../../assets/Pound.svg';
import euro from '../../../assets/euro.svg';
import {
  setSelectedMin,
  setSelectedMax,
  setSelectedCurrency,
 
  resetState
} from '../../../redux/multiselectRedcucer';
import { debounce } from 'lodash';

const currencyOptions = [
  { label: 'Dollar', icon: dollar },
  { label: 'Pound', icon: pound },
  { label: 'Euro', icon: euro }
];

const generateMillionValues = () => {
  const values = [];
  for (let i = 1; i <= 10; i++) {
    values.push(i * 1000000);
  }
  return values;
};



const MobileDynamicPrice = () => {
  const dispatch = useDispatch();
  const { selectedMin, selectedMax, selectedCurrency,  } =
    useSelector((state) => state.multiselect);
    const [isMinDropdownOpen, setIsMinDropdownOpen] = useState(false);
    const [isMaxDropdownOpen, setIsMaxDropdownOpen] = useState(false);
    const [isCurrencyDropdownOpen, setIsCurrencyDropdownOpen] = useState(false);
  const currencyRef = useRef(null);
  const minRef = useRef(null);
  const maxRef = useRef(null);
  const [customMin, setCustomMin] = useState('');
  const [customMax, setCustomMax] = useState('');
  const defaultMinOptions = generateMillionValues();
  const defaultMaxOptions = generateMillionValues();

  const generateDynamicMaxOptions = (minValue) => {
    const values = [];
    for (let i = minValue + 1000000; i <= minValue + 10000000; i += 1000000) {
      values.push(i);
    }
    return values;
  };
  const minValue = selectedMin ? parseInt(selectedMin, 10) : customMin ? parseInt(customMin, 10) : 0;
  const minOptions = [...defaultMinOptions, customMin && parseInt(customMin, 10)].filter(Boolean);
  const maxOptions = [...defaultMaxOptions, customMax && parseInt(customMax, 10)].filter(Boolean).filter(value => value > minValue);
  const dynamicMaxOptions = maxOptions.length === 0 ? generateDynamicMaxOptions(minValue) : maxOptions;
 

  
  const handleDropdownToggle = (type) => {
    if (type === 'currency') {
      setIsCurrencyDropdownOpen((prev) => !prev);
      setIsMinDropdownOpen(false);
      setIsMaxDropdownOpen(false);
    } else if (type === 'min') {
      setIsMinDropdownOpen((prev) => !prev);
      setIsCurrencyDropdownOpen(false);
      setIsMaxDropdownOpen(false);
    } else if (type === 'max') {
      setIsMaxDropdownOpen((prev) => !prev);
      setIsCurrencyDropdownOpen(false);
      setIsMinDropdownOpen(false);
    }
  };
  

  const handleCurrencySelection = (currency) => {
    let payload = currency;
    if (currency.label === 'Dollar') {
      payload = { ...currency, value: 'USD' };
    }
    dispatch(setSelectedCurrency(payload));
    setCustomMax('');
    setCustomMin('')
    dispatch(setSelectedMin(null));
    dispatch(setSelectedMax(null));
    setIsCurrencyDropdownOpen();
  };

  useEffect(() => {
    const parsedCustomMin = parseInt(customMin, 10);
    const parsedCustomMax = parseInt(customMax, 10);

    if (parsedCustomMin >= parsedCustomMax && !isNaN(parsedCustomMax)) {
      dispatch(setSelectedMax(null));
    }

    if (parsedCustomMax <= parsedCustomMin && !isNaN(parsedCustomMin)) {
      dispatch(setSelectedMin(null));
    }
  }, [customMin, customMax, dispatch]);

  useEffect(() => {
    if (selectedMin !== null) {
      setCustomMin(selectedMin);
    } else {
      setCustomMin('');
    }
    if (selectedMax !== null) {
      setCustomMax(selectedMax);
    } else {
      setCustomMax('');
    }
  }, [selectedMin, selectedMax]);

  
  
  

 
  useEffect(() => {
    const parsedCustomMin = parseInt(customMin, 10);
    const parsedCustomMax = parseInt(customMax, 10);

    if (parsedCustomMin >= parsedCustomMax && !isNaN(parsedCustomMax)) {
      dispatch(setSelectedMax(null));
    }

    if (parsedCustomMax <= parsedCustomMin && !isNaN(parsedCustomMin)) {
      dispatch(setSelectedMin(null));
    }
  }, [customMin, customMax, dispatch]);

  const handleMinChange = debounce((value) => {
    const numericValue = /^[0-9]*$/;
    if (numericValue.test(value) && value.length <= 10 && !/^0\d+/.test(value)) {
      setCustomMin(value);
  
      const parsedMin = parseInt(value, 10);
      const parsedMax = parseInt(customMax, 10);
  
      if (!isNaN(parsedMin)) {
        dispatch(setSelectedMin(value));
  
       
        if (parsedMin > parsedMax && !isNaN(parsedMax)) {
          dispatch(setSelectedMax(null));
          setCustomMax('');
        }
      } else {
       
        dispatch(setSelectedMin(null));
      }
    }
  }, 100);
  
  const handleMaxChange = debounce((value) => {
    const numericValue = /^[0-9]*$/;
    if (numericValue.test(value) && value.length <= 10 && !/^0\d+/.test(value)) {
      setCustomMax(value);
  
      const parsedMax = parseInt(value, 10);
      const parsedMin = parseInt(customMin, 10);
  
      if (!isNaN(parsedMax)) {
        dispatch(setSelectedMax(value));
  
       
        if (parsedMax < parsedMin && !isNaN(parsedMin)) {
          dispatch(setSelectedMin(null));
          setCustomMin('');
        }
      } else {
       
        dispatch(setSelectedMax(null));
      }
    }
  }, 100);
  

  const handleMinSelection = (value) => {
    const parsedValue = parseInt(value, 10);
    const parsedCustomMax = parseInt(customMax, 10);
    const parsedSelectedMax = parseInt(selectedMax, 10);

    dispatch(setSelectedMin(value));
    setCustomMin('');

    if ((parsedCustomMax && parsedValue >= parsedCustomMax) || (parsedSelectedMax && parsedValue >= parsedSelectedMax)) {
      dispatch(setSelectedMax(null));
      setCustomMax('');
    }
    setIsMinDropdownOpen(false);
  };

  const handleMaxSelection = (value) => {
    const parsedValue = parseInt(value, 10);
    const parsedCustomMin = parseInt(customMin, 10);
    const parsedSelectedMin = parseInt(selectedMin, 10);

    dispatch(setSelectedMax(value));
    setCustomMax('');

    if ((parsedCustomMin && parsedValue <= parsedCustomMin) || (parsedSelectedMin && parsedValue <= parsedSelectedMin)) {
      dispatch(setSelectedMin(null));
      setCustomMin('');
    }
    setIsMaxDropdownOpen(false)
  };

  const formatValue = (value) => {
    return value >= 1000000 ? `${value / 1000000} M` : value.toString().length > 3 ? `${value.toString().slice(0, 3)}...` : value;
  };

  const truncateValue = (value) => {
    return value.length > 3 ? value.slice(0, 3) + '...' : value;
  };
  return (
    <div className='h-[190px]'>
        <div className="flex flex-col border-2 border-gray-300 h-10 justify-between w-auto rounded-md">
      <div className="flex justify-between mt-1 ml-1">
        <div className="relative">
          <button
            onClick={() => handleDropdownToggle('currency')}
            className="flex items-center text-sm font-sixth text-[#2E4374] font-[500] whitespace-nowrap overflow-hidden w-24 mt-1"
          >
            <img src={selectedCurrency?.icon} alt={selectedCurrency?.label} className="w-4 h-4" />
            <span className="ml-2">{selectedCurrency?.label || 'Currency'}</span>
            <KeyboardArrowDownIcon
              style={{
                transition: 'transform 0.3s ease-in-out',
                transform: isCurrencyDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)'
              }}
            />
          </button>
          {isCurrencyDropdownOpen && (
            <div ref={currencyRef} className="absolute top-full mt-2 border border-gray-300 bg-white shadow-lg rounded-md w-24 font-sixth">
              {currencyOptions.map((currency) => (
                <button
                  key={currency.label}
                  onClick={() => handleCurrencySelection(currency)}
                  className="flex justify-start items-center p-2 hover:bg-gray-200 w-full text-sm"
                >
                  <img src={currency.icon} alt={currency.label} className="w-4 h-4" />
                  <span className="ml-2">{currency.label}</span>
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="relative">
          <button
            onClick={() => handleDropdownToggle('min')}
            className="flex items-center text-sm font-sixth text-[#2E4374] font-[500] whitespace-nowrap overflow-hidden w-24 mt-1"
          >
            <span>Min</span> <span className='ml-1'>{truncateValue(formatValue(selectedMin || customMin || ''))}</span>
            <KeyboardArrowDownIcon
              style={{
                transform: isMinDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
              }}
            />
          </button>
          {isMinDropdownOpen && (
            <div ref={minRef} className="absolute top-full mt-2 border border-gray-300 bg-white shadow-lg rounded-md text-sm font-sixth w-32 overflow-y-auto overflow-x-hidden max-h-36">
              <div className="px-2 py-1">
                <input
                  type="text"
                  value={customMin}
                  onChange={(e) => handleMinChange(e.target.value)}
                  placeholder="Min Price"
                  className="w-full p-1 border border-gray-300 text-sm rounded"
                />
              </div>


              {minOptions.map((value) => (
                <button
                  key={value}
                  onClick={() => handleMinSelection(value)}
                  className="hover:bg-gray-200 w-full text-left  text-sm font-sixth flex  p-1 ml-2"
                >
                  {formatValue(value)}
                </button>
              ))}

            </div>
          )}
        </div>

        <div className="relative">
          <button
            onClick={() => handleDropdownToggle('max')}
            className="flex items-center text-sm font-sixth text-[#2E4374] font-[500] whitespace-nowrap overflow-hidden w-24 mt-1"
          >
            <span>Max</span>  <span className='ml-1'>{truncateValue(formatValue(selectedMax || customMax || ''))}</span>
            <KeyboardArrowDownIcon

              style={{
                transform: isMaxDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s ease',
              }}
            />
          </button>
          {isMaxDropdownOpen && (
            <div ref={maxRef} className="absolute top-full mt-2 border border-gray-300 bg-white shadow-lg rounded-md w-32 overflow-y-auto overflow-x-hidden max-h-36 right-2">
              <div className='px-2 py-1'>
                <input
                  type="text"
                  value={customMax}
                  onChange={(e) => handleMaxChange(e.target.value)}
                  placeholder="Max Price"
                  className="w-full p-1 border border-gray-300 rounded text-sm"
                />
              </div>
              {dynamicMaxOptions.map((option) => (
                <button
                  key={option}
                  onClick={() => handleMaxSelection(option)}
                  className="hover:bg-gray-200 w-full text-left  text-sm font-sixth flex  p-1 ml-2"
                >
                  {formatValue(option)}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
export default  MobileDynamicPrice;