// import restaurenticon from '../../asset/restaurent.svg';
import restaurenticon from '../../asset/restaurant.svg';
import electricity from '../../asset/electricity.svg';
import MiniVrm from '../../asset/minivrv.svg';

import earthquick from '../../asset/earthquick.svg';
import prayer from '../../asset/prayer.svg';
import servants from '../../asset/servants.svg';
import store from '../../asset/store.svg';
import garbage from '../../asset/garbage.svg';
import hots  from '../../asset/hots.svg';
import airduct from '../../asset/airduct.svg';
import laundary from '../../asset/laundary.svg';

import securitys from '../../asset/securitys.svg';
import watersprinkers from '../../asset/watersprinkers.svg';
import wallpapers from '../../asset/wallpapers.svg';
import watertank from '../../asset/watertank.svg';
import autoservice from '../../asset/autoservice.svg';
import doublegazed from '../../asset/doublegazed.svg';
// import firehoouse from '../../asset/firehoouse.svg';
import compas from '../../asset/compas.svg';
import internets from '../../asset/internets.svg';
import maintaence from '../../asset/maintence.svg';
import sauna from '../../asset/sauna.svg';
import minivrv from '../../asset/minivrv.svg';
import satelight from '../../asset/satlight.svg';


import acquapark from '../../asset/acquapark.svg';
import pharmacy from '../../asset/pharmacy.svg';

import mosque from '../../asset/mosqu.svg';
import caferia from '../../asset/caferia.svg';
import school from '../../asset/school.svg';
import medicalstore from '../../asset/medicalstore.svg';
import carwashing from '../../asset/carwashing.svg';
import mall from '../../asset/mall.svg';
import policestation from '../../asset/policestation.svg';
import Sports from "../../asset/sportcenter.svg"
import Bakerys from "../../asset/Bakerys.svg"
// import Bakerysfrom '../../asset/bakery.svg';
import mixedgym from '../../asset/mixgym.svg';
import hospital from '../../asset/hospital.svg';
import playground from '../../asset/playground.svg';
import market from '../../asset/market.svg';
import stationary from '../../asset/stationary.svg';
// import woman  from '../../asset/woman .svg';
import woman from '../../asset/woman.svg';
import airconditioner from '../../asset/airconditioner.svg';
import woodenflooring from '../../asset/woodenflooring.svg';
import modernceiling from '../../asset/modern.svg';
import coverparking from '../../asset/coverparking.svg';
import plus from '../../asset/pluss.svg';
import openparking from '../../asset/openparking.svg';
import park from '../../asset/parks.svg';
import gym from '../../asset/gym.svg';
import rainwater from '../../asset/rainwaters.svg';
import swing from '../../asset/swingpool.svg';
import club from '../../asset/clubs.svg';
import firehoouse from '../../asset/firehoouse.svg';

export const amenity = {
  FlatApartment: {
    options: [
      { name: 'Prayer Room', checked: false, emoji: prayer },
      { name: 'Servant Room', checked: false, emoji: servants },
      { name: 'Store Room', checked: false, emoji: store }
    ],
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct },
      { name: 'Laundry', checked: false, emoji: laundary },
      { name: 'Car Washing', checked: false, emoji: carwashing },

      { name: 'Security Staff', checked: false, emoji: securitys },
      { name: 'Water Sprinkle System', checked: false, emoji: watersprinkers },
      { name: 'Wallpapers', checked: false, emoji: wallpapers },
      { name: 'Water Tank', checked: false, emoji: watertank },
      { name: 'Automotive Service', checked: false, emoji: autoservice },
      { name: 'Double Gazed Window', checked: false, emoji: doublegazed },
      { name: 'Fire House Cabenet', checked: false, emoji: firehoouse },
      { name: 'Compass Card', checked: false, emoji: compas },

      { name: 'Internet', checked: false, emoji: internets  },
      { name: 'Maintenance', checked: false, emoji: maintaence },
      { name: 'Sauna', checked: false, emoji: sauna },
      { name: 'Mini VRV system', checked: false, emoji: minivrv },
      { name: 'Satellite Or Cable TV', checked: false, emoji: satelight },
      { name: 'Security', checked: false, emoji: securitys },
      { name: 'Earthquake Resistance System', checked: false, emoji: earthquick },
      { name: 'Electricity Generator', checked: false, emoji: electricity },
      { name: 'Fire Fighting', checked: false, emoji: electricity }
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia },
      { name: 'School', checked: false, emoji: school },

      { name: 'Medical Store', checked: false, emoji: medicalstore },
      { name: 'Car Washing', checked: false, emoji: carwashing },
      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Sports Centre', checked: false, emoji:Sports },
      { name: 'Bakery', checked: false, emoji: Bakerys},
      { name: 'Mixed Gym', checked: false, emoji: mixedgym },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Play Ground', checked: false, emoji: playground },
      { name: 'Market', checked: false, emoji: market },
     
      { name: 'Restaurant', checked: false, emoji: restaurenticon },
      { name: 'Women salon', checked: false, emoji: woman  }
    ],
    property_features: [
      { name: 'Air Conditioner', checked: false, emoji: airconditioner },
      { name: 'Wooden Flooring', checked: false, emoji: woodenflooring },
      { name: '24hr Facility', checked: false, emoji: electricity },
      { name: 'Modern Ceiling', checked: false, emoji: modernceiling },
      { name: 'More', checked: false, emoji: plus }
    ],

    parking: [
      { name: 'Cover parking', checked: false, emoji: coverparking },
      { name: 'Open parking', checked: false, emoji: openparking }
    ]
  },
  ResidentialHouse: {
    options: [
      { name: 'Prayer Room', checked: false, emoji: prayer },
      { name: 'Servant Room', checked: false, emoji: servants },
      { name: 'Store Room', checked: false, emoji: store }
    ],
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot  Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct },
      { name: 'Laundry', checked: false, emoji: laundary },
      { name: 'Car Washing', checked: false, emoji: carwashing },

      { name: 'Security Staff', checked: false, emoji: securitys },
      { name: 'Water Sprinkle System', checked: false, emoji: watersprinkers },
      { name: 'Wallpapers', checked: false, emoji: wallpapers },
      { name: 'Water Wank', checked: false, emoji: watertank },
      { name: 'Automotive Wervice', checked: false, emoji: autoservice },
      { name: 'Double Gazed Window', checked: false, emoji: doublegazed },
      { name: 'Fire House Cabenet', checked: false, emoji: firehoouse },
      { name: 'Compass Card', checked: false, emoji: compas },

      { name: 'Internet', checked: false, emoji: internets  },
      { name: 'Maintenance', checked: false, emoji: maintaence },
      { name: 'Sauna', checked: false, emoji: sauna },
      { name: 'Mini VRV system', checked: false, emoji: minivrv },
      { name: 'Satellite Or Cable TV', checked: false, emoji: satelight },
      { name: 'Security', checked: false, emoji: securitys },
      { name: 'Earthquake Resistance System', checked: false, emoji: earthquick },
      { name: 'Electricity Generator', checked: false, emoji: electricity },
      { name: 'Fire Fighting', checked: false, emoji: electricity }
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia },
      { name: 'School', checked: false, emoji: school },

      { name: 'Medical Store', checked: false, emoji: medicalstore },
      { name: 'Car Washing', checked: false, emoji: carwashing },
      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Sports Centre', checked: false, emoji:Sports },
      { name: 'Bakery', checked: false, emoji: Bakerys},
      { name: 'Mixed Gym', checked: false, emoji: mixedgym },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Play Ground', checked: false, emoji: playground },
      { name: 'Market', checked: false, emoji: market },
     
      { name: 'Restaurant', checked: false, emoji: restaurenticon },
      { name: 'Women salon', checked: false, emoji: woman  }
    ],
    property_features: [
      { name: 'Air Conditioner', checked: false, emoji: airconditioner },
      { name: 'Wooden Flooring', checked: false, emoji: woodenflooring },
      { name: '24hr Facility', checked: false, emoji: electricity },
      { name: 'Modern Ceiling', checked: false, emoji: modernceiling },
      { name: 'More', checked: false, emoji: plus }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },

  ResidentialLandPlot: {
    near_localities: [
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'School', checked: false, emoji: school },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Car Washing', checked: false, emoji: stationary },
      { name: 'Bakery', checked: false, emoji: Bakerys},
      { name: 'Market', checked: false, emoji: market },
      { name: 'Restaurant', checked: false, emoji: restaurenticon },
      { name: 'Women Salon', checked: false, emoji: woman  }
    ]
  },
  Villa: {
    options: [
      { name: 'Prayer Room', checked: false, emoji: prayer },
      { name: 'Servant Room', checked: false, emoji: servants },
      { name: 'Store Room', checked: false, emoji: store }
    ],
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot  Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct },
      { name: 'Laundry', checked: false, emoji: laundary },
      { name: 'Car Washing', checked: false, emoji: carwashing },
      { name: 'View ALL', checked: false, emoji: plus }
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia },
      { name: 'School', checked: false, emoji: school },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Medical Store', checked: false, emoji: medicalstore },
      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Sports Centre', checked: false, emoji: "" },
      { name: 'Bakery ', checked: false, emoji: Bakerys},
      { name: 'Mixed Gym', checked: false, emoji: mixedgym },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Play Ground', checked: false, emoji: playground },
      { name: 'Market', checked: false, emoji: market },
    
      { name: 'Restaurant ', checked: false, emoji: restaurenticon },
      { name: 'Women Saloon', checked: false, emoji: woman  }
    ],
    property_features: [
      { name: 'Air Conditioner', checked: false, emoji: airconditioner },
      { name: 'Wooden Flooring', checked: false, emoji: woodenflooring },
      { name: '24hr Facility', checked: false, emoji: electricity },
      { name: 'Modern Ceiling', checked: false, emoji: modernceiling },
      { name: 'More', checked: false, emoji: plus }
    ],
    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },

  BuilderFloor: {
    options: [
      { name: 'Prayer Room', checked: false, emoji: prayer },
      { name: 'Servant Room', checked: false, emoji: servants },
      { name: 'Store Room', checked: false, emoji: store }
    ],
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct },
      { name: 'Laundry', checked: false, emoji: laundary },
      { name: 'Car Washing', checked: false, emoji: carwashing },
    
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia },
      { name: 'School', checked: false, emoji: school },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Medical Store', checked: false, emoji: medicalstore },
      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Sports Centre', checked: false, emoji:Sports },
      { name: 'Bakery ', checked: false, emoji: Bakerys},
      { name: 'Mixed Gym', checked: false, emoji: mixedgym },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Play Ground', checked: false, emoji: playground },
      { name: 'MARKET', checked: false, emoji: market },
   
      { name: 'Restaurant ', checked: false, emoji: restaurenticon },
      { name: 'Women Saloon', checked: false, emoji: woman  }
    ],
    property_features : [
      { name: 'Air Conditioner', checked: false, emoji: airconditioner },
      { name: 'Wooden Flooring', checked: false, emoji: woodenflooring },
      { name: '24hr Facility', checked: false, emoji: electricity },
      { name: 'Modern Ceiling', checked: false, emoji: modernceiling },
      { name: 'More', checked: false, emoji: plus }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },
  // Add amenities for Commercial and Other categories
  CommercialShop: {
    near_localities: [
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia },
      { name: 'School', checked: false, emoji: school },

      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'MARKET', checked: false, emoji: market }
    ],
    property_features : [
      { name: 'Air Conditioner', checked: false, emoji: airduct },

      { name: '24hr Facility', checked: false, emoji: electricity }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },

  CommercialLand: {
    property_features : [
      { name: 'Rain Water Harvesting', checked: false, emoji: rainwater },
      { name: 'Club House', checked: false, emoji: club },
      { name: 'Gym', checked: false, emoji: gym },
      { name: 'Park', checked: false, emoji: park },
      { name: 'Swimming Pool', checked: false, emoji: swing }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },

  CommercialItPark: {
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot  Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct }
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Pharmacy', checked: false, emoji: pharmacy },
      { name: 'Stationary', checked: false, emoji: stationary },
      { name: 'Mosque', checked: false, emoji: mosque },
      { name: 'Caferia', checked: false, emoji: caferia }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  },

  Farmhouse: {
    options: [
      { name: 'Prayer Room', checked: false, emoji: prayer },
      { name: 'Servant Room', checked: false, emoji: servants },
      { name: 'Store Room', checked: false, emoji: store }
    ],
    facilities: [
      { name: 'Garbage Chute', checked: false, emoji: garbage },
      { name: 'Hot  Water', checked: false, emoji: hots  },
      { name: 'Air Duct', checked: false, emoji: airduct }
    ],
    near_localities: [
      { name: 'Aqua Park', checked: false, emoji: acquapark },
      { name: 'Police Station', checked: false, emoji: policestation },
      { name: 'Hospital', checked: false, emoji: hospital },
      { name: 'Medical Store', checked: false, emoji: medicalstore },
      { name: 'Mall', checked: false, emoji: mall },
      { name: 'Play Ground', checked: false, emoji: playground } // Co
    ],
    property_features : [
      { name: 'Air Conditioner', checked: false, emoji: airconditioner },
      { name: 'Wooden Flooring', checked: false, emoji: woman  },
      { name: '24hr Facility', checked: false, emoji: electricity },
      { name: 'Modern Ceiling', checked: false, emoji: modernceiling }, // Corrected the emoji for 'Modern ceiling'
      { name: 'More', checked: false, emoji: plus }
    ],

    parking: [
      { name: 'Cover Parking', checked: false, emoji: coverparking },
      { name: 'Open Parking', checked: false, emoji: openparking }
    ]
  }
};