

import React, { useState } from 'react';

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

function ServiceListing() {
    const [approvalData, setApprovalData] = useState({
        approve: 60,
        disapprove: 30,
        pending: 10
      });
    
      // Function to update approval data dynamically
      const updateApprovalData = (newApprovalData) => {
        setApprovalData(newApprovalData);
      };
    
      const [data1, setData1] = useState({
        labels: ['Approve', 'Disapprove', 'Pending'],
        datasets: [
          {
            data: [approvalData.approve, approvalData.disapprove, approvalData.pending],
            backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
          }
        ]
      });
    
      const [data2, setData2] = useState({
        labels: ['Approve', 'Disapprove', 'Pending'],
        datasets: [
          {
            data: [50, 35, 15],
            backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
          }
        ]
      });
    
      const [data3, setData3] = useState({
        labels: ['Approve', 'Disapprove', 'Pending'],
        datasets: [
          {
            data: [40, 40, 20],
            backgroundColor: ['#36A7FF', '#FF6B6B', '#FFD700']
          }
        ]
      });
  return (
  
    <div className="bg-[white] p-5 rounded-lg shadow-lg grid  gap-4 place-content-center  h-[400px]">
    <div className=" rounded-lg shadow-lg] grid  grid-cols-2 gap-5">
      <div className="">
        <h1 className="ml-5 mt-2 font-[600] text-[20px] leading-[20px] text-[#161A41]">
        Property Listing Approval
        </h1>
        <ul className="flex flex-col p-5">
          <li className="">
            <span
              className="text-[16px] font-[700] leading-[12px] t text-[#161A4169]
"
            >
              Approve: {approvalData.approve}%
            </span>
            <div className="w-full h-4 mt-2 bg-gray-200 rounded">
              <div className="h-full bg-blue-500 rounded" style={{ width: `${approvalData.approve}%` }}></div>
            </div>
          </li>
          <li className="mt-4">
            <span className="text-[16px] font-[700] leading-[12px] t text-[#161A4169]">
              Disapprove: {approvalData.disapprove}%
            </span>
            <div className="w-full h-4 mt-2 bg-gray-200 rounded">
              <div className="h-full bg-red-500 rounded" style={{ width: `${approvalData.disapprove}%` }}></div>
            </div>
          </li>
          <li className="mt-4">
            <span className="text-[16px] font-[700] leading-[12px] t text-[#161A4169]">
              Pending: {approvalData.pending}%
            </span>
            <div className="w-full h-4 mt-2 bg-gray-200 rounded">
              <div className="h-full bg-yellow-500 rounded" style={{ width: `${approvalData.pending}%` }}></div>
            </div>
          </li>
        </ul>
      </div>
      <div className="flex justify-center items-center">
        <div className="relative w-[100%] h-64">
          <Pie
            data={data1}
            options={{
              cutout: '80%',
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false
                }
              }
            }}
          />
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <Pie
              data={data2}
              options={{
                cutout: '60%',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
          <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
            <Pie
              data={data3}
              options={{
                cutout: '40%',
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false
                  }
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ServiceListing;