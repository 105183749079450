import React from 'react';
import { useTranslation } from 'react-i18next';
// import global from '../../assets/global.svg'
import global from '../assets/global.svg'

function HandleLanguage() {
  const { i18n } = useTranslation(); // Access i18n instance

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
    console.log('Language changed to:', i18n.language);
  };

  return (
    <div className='flex justify-between items-center gap-1'>
    <div className=''>
     <img src = {global} className='!w-[19px] !h-[19px] mt-1' alt='country-img' />
     </div>
     <div className=''>
      <select
        onChange={handleLanguageChange}
        defaultValue={i18n.language}
        className="border-none outline-none  bg-transparent font-thirdCustom cursor-pointer font-[400]  mt-3 px-1 text-[16px]"
        style={{ color: 'white' }}
      >
        <option className="text-black font-thirdCustom font-[400] mt-2 text-[16px]" value="en">English</option>
        <option className="text-black font-thirdCustom font-[400] text-[16px]" value="tr">Türkçe</option>
       
      </select>
      </div>
    </div>
  );
}

export default HandleLanguage;
