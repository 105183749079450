import React, { useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select';
import { options2 } from '../Blog/mediadata';

const headerStyle = {
  color: 'gray',
  width: '100%',
  maxWidth: 250,
  height: 56,
  border: '1px solid #D9D9D9'
  // background: 'var(--button-3, linear-gradient(252deg, #071C35 -100.79%, #5B8BC5 169.82%))'
};

export default function DatePickerOpenTo() {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleBedroomChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: 56
    })
  };
  return (
    <div className=" grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 ">
      {/* <div className=""> */}
      <div className="" >
        <LocalizationProvider className='' style={{width:'500px'}} dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DatePicker']}>
            <DatePicker label={'Year'} views={['year']} />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      <div className="md:ml-4">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker', 'DatePicker']}>
            <DatePicker label={'Month'} views={['month']} />
          </DemoContainer>
        </LocalizationProvider>
      </div>
      {/* </div> */}

      {/* <div className="flex  md:ml-0 md:flex-row  flex-col"> */}
      <div className="mt-4 lg:ml-4 md:mt-2 lg:w-[240px] md:w-[99%] w-[100%]">
        <Select
          className=""
          value={selectedOption}
          onChange={handleBedroomChange}
          options={options2}
          styles={customStyles}
        />
      </div>
      {/* <div className="relative mt-4 md:mt-2 mr-4">
        <SearchIcon className="absolute ml-1 top-1/2 transform mt-1 -translate-y-1/2 left-3" style={{ fontSize: '1.5rem' }} />
        <input
          className="flex-grow border-none text-sm p-4 ml-4 text-center"
          style={{
            border: '2px solid #D9D9D9', // Set border to none
            width: '100%',
            borderRadius: '4px'
          }}
          placeholder=" Search by keyword"
        />
      </div> */}

      <button className="rounded-md border-[1.5px] border-slate-300 md:mt-1.5 mt-[14px] text-lg md:p-2 text-inter text-white bg-[#2e4374] md:ml-4 text-center lg:w-[215px] md:w-[96%] w-[100%] h-[59px] ">
        SEARCH
      </button>
      {/* </div> */}
    </div>
  );
}
