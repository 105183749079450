export const Mediadata = [
  {
    id: 1,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 2,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 3,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 4,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 5,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 6,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 7,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 8,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 9,
    img:  "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 10,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 11,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 12,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 13,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 14,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 15,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 16,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 17,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 18,
    img: "https://squarebric-media-files.squarebric.com/blogg.png",
    title: 'Tips For Renting Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  }
];

export const Recentdata = [
  {
    id: 1,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 2,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 3,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 4,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynmaic Property '
  },
  {
    id: 5,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 6,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  },
  {
    id: 7,
    img: "https://squarebric-media-files.squarebric.com/recentadd.png",
    title: 'Tips For Renting a Property In Dubai',
    description: 'Renting A Property In Dubai Dynamic Property '
  }
];

export const newspressdata = [
  {
    id: 1,
    img: "https://squarebric-media-files.squarebric.com/New4.png", 
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 2,
    img: "https://squarebric-media-files.squarebric.com/New2.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 3,
    img: "https://squarebric-media-files.squarebric.com/News1.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 4,
    img: "https://squarebric-media-files.squarebric.com/New3.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 5,
    img: "https://squarebric-media-files.squarebric.com/New4.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 6,
    img: "https://squarebric-media-files.squarebric.com/News1.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 7,
    img: "https://squarebric-media-files.squarebric.com/New3.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  },
  {
    id: 8,
    img: "https://squarebric-media-files.squarebric.com/New2.png",
    title: 'Squarebric celebrate the spirit of the union on UAE flag day',
    description:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    date: '03/05/3034'
  }
];

export const options2 = [
  { value: 'abu hail townhouse - P642', label: 'abu hail townhouse - P642' },
  { value: 'abu hail houses - P42', label: 'abu hail townhouses - P42' },
  { value: 'abu hail town - P64', label: 'abu hail townhouses - P64' },
  { value: 'abu  townhouses - P62', label: 'abu hail townhouses - P62' },
  { value: ' hail townhouses - P2', label: 'abu hail townhouses - P2' }
];



