import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const usePropertyDetails = (propertyId) => {
  const [properties, setProperties] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const userData = localStorage.getItem('userData');
        let token = '';
        if (userData) {
          const parsedUserData = JSON.parse(userData);
          token = parsedUserData.access_token;
        }

        const config = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        };

        const response = await axios.get(`https://square-bric-backend-api.squarebric.com/property-search/property-details?property_id=${propertyId}`, config);
        setProperties(response.data);
      } catch (err) {
        setError(err.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    };

    if (propertyId) {
      fetchProperties();
    }
  }, [propertyId]);

  return { properties, loading, error };
};

export default usePropertyDetails;
