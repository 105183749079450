import React, { useState } from "react";

const PropertyListingCard = () => {
  const [selectedOption, setSelectedOption] = useState("Monthly");
  const [data, setData] = useState({
    monthly: {
      total: 1800,
      change: "+3.4%",
      color: "text-green-500",
      weeks: [
        {
          name: "First Week",
          listings: 500,
          change: "+3.4%",
          changeColor: "text-green-500",
        },
        {
          name: "Second Week",
          listings: 400,
          change: "+11.4%",
          changeColor: "text-red-500",
        },
        {
          name: "Third Week",
          listings: 600,
          change: "+20.4%",
          changeColor: "text-green-500",
        },
        {
          name: "Fourth Week",
          listings: 300,
          change: "-7.0%",
          changeColor: "text-red-500",
        },
      ],
    },
    yearly: {
      total: 18000,
      change: "+8.1%",
      color: "text-green-500",
      weeks: [
        {
          name: "First Quarter",
          listings: 5000,
          change: "+3.1%",
          changeColor: "text-green-500",
        },
        {
          name: "Second Quarter",
          listings: 4000,
          change: "+2.8%",
          changeColor: "text-red-500",
        },
        {
          name: "Third Quarter",
          listings: 6000,
          change: "+15.4%",
          changeColor: "text-green-500",
        },
        {
          name: "Fourth Quarter",
          listings: 3000,
          change: "-5.2%",
          changeColor: "text-red-500",
        },
      ],
    },
    weekly: {
      total: 450,
      change: "+1.5%",
      color: "text-green-500",
      weeks: [
        {
          name: "Monday",
          listings: 100,
          change: "+0.5%",
          changeColor: "text-green-500",
        },
        {
          name: "Tuesday",
          listings: 80,
          change: "-0.5%",
          changeColor: "text-red-500",
        },
        {
          name: "Wednesday",
          listings: 110,
          change: "+2.1%",
          changeColor: "text-green-500",
        },
        {
          name: "Thursday",
          listings: 160,
          change: "+4.0%",
          changeColor: "text-green-500",
        },
      ],
    },
  });

  // Handle dropdown change
  const handleDropdownChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const selectedData = data[selectedOption.toLowerCase()]; // Dynamically select data

  return (
    <div className="bg-white p-6 shadow-lg rounded-lg h-[400px]">
      {/* Title and Dropdown */}
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-semibold text-blue-900">
          Total Property Listing
        </h2>
        <div>
          <select
            value={selectedOption}
            onChange={handleDropdownChange}
            className="bg-gray-100 text-gray-600 text-sm py-1 px-3 rounded-lg"
          >
            <option value="Monthly">Monthly</option>
            <option value="Yearly">Yearly</option>
            <option value="Weekly">Weekly</option>
          </select>
        </div>
      </div>

      {/* Total Listings */}
      <div className="my-4">
        <span className="text-3xl font-semibold text-blue-600">
          {selectedData.total}
        </span>
        <span
          className={`${selectedData.color} bg-green-100 ml-3 px-2 py-1 rounded-full text-xs`}
        >
          {selectedData.change}
        </span>
      </div>

      {/* Color Indicator */}
      <div className="flex space-x-2 mt-3">
        <div className="h-1 w-1/5 bg-purple-300"></div>
        <div className="h-1 w-1/5 bg-teal-300"></div>
        <div className="h-1 w-1/5 bg-yellow-300"></div>
        <div className="h-1 w-1/5 bg-blue-300"></div>
      </div>

      {/* Weekly Stats (Dynamic) */}
      <div className="grid grid-cols-2 gap-4 mt-6">
        {selectedData.weeks.map((week, index) => (
          <div key={index} className="border rounded-lg p-4">
            <div className="text-sm font-medium text-gray-500">{week.name}</div>
            <div className="text-2xl font-semibold">{week.listings}</div>
            <div
              className={`${week.changeColor} bg-green-100 px-2 py-1 rounded-full text-xs mt-1`}
            >
              {week.change}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PropertyListingCard;
