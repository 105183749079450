
import React,{useState,useEffect} from 'react';
import { useLocation,useParams } from 'react-router-dom';
import Header from './layout/Header';
import { Outlet } from 'react-router-dom';
import Footer from './layout/Footer';
// import Dashhome from '../DashboardAdmin/DashHome/Dashhome';


const Root = () => {
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const { property_id } = useParams();
 
  const location = useLocation();
  console.log("locations",location.pathname)
  const hiddenRoutes = [
    '/dashboard/inquiry',
    '/dashboard/inquiry/propertyinquery',
    '/dashboard/inquiry/serviceinquiry'
  ];
  const isHiddenRoute = hiddenRoutes.includes(location.pathname) || location.pathname.startsWith('/dashboard/inquiry/');


  const isDashboardRoute = () => {
    if(location.pathname === '/dashboard/property'){
      return location.pathname === '/dashboard/property'
     
    }
    if( location.pathname === '/dashboard/homeservices'){
      return location.pathname === '/dashboard/homeservices'
    }
    if( location.pathname ==='/dashboard/propertylisting'){
      return location.pathname === '/dashboard/propertylisting'
    }
   
    if( location.pathname ==='/dashboard/board'){
      return location.pathname === '/dashboard/board'
    }
 
    if( location.pathname ==='/dashboard/setting'){
      return location.pathname === '/dashboard/setting'
    }
    if( location.pathname ==='/dashboard/membership'){
      return location.pathname === '/dashboard/membership'
    }
    if( location.pathname ==='/dashboard/myunits'){
      return location.pathname === '/dashboard/myunits'
    }
    if(location.pathname ===`/dashboard/edit-property/${property_id}`){
      return location.pathname ===`/dashboard/edit-property/${property_id}`
      
    }
    if(location.pathname ==='/dashboard/inquiry'){
      return location.pathname ==='/dashboard/inquiry' 
     }
    
     
    
   
 
  };


  

  useEffect(() => {
   
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [location.pathname]);

  return (
    <>
      {!isDashboardRoute() && <Header />}
  {/* {showHeaderFooter && <Header />} */}
      <main>
        <Outlet />
      </main>
   {/* {showHeaderFooter && <Footer />} */}
      {!isDashboardRoute() && <Footer />}
    </>
  );
};

export default Root;
