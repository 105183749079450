import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectState, selectCity, fetchLocationData } from '../../../../redux/LocationReducer';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { toast, ToastContainer } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
const truncateText = (text, maxLength = 10) => {
  return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
};

const StateAndCitySelect = () => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showStates, setShowStates] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const selectedCountry = useSelector((state) => state.location.selectedCountry);
  console.log('selected country', selectedCountry);
  const {
    states = [],
    cities = [],
    selectedState,
    selectedCity,
    loading,
    dataFetched
  } = useSelector((state) => state.location);

  useEffect(() => {
    if (!dataFetched) {
      dispatch(fetchLocationData());
    }

    const storedState = localStorage.getItem('selectedState');
    const storedCity = localStorage.getItem('selectedCity');

    if (storedState) {
      dispatch(selectState(storedState));
    }

    if (storedCity) {
      dispatch(selectCity(storedCity));
    }
  }, [dispatch, dataFetched]);

  useEffect(() => {
    if (selectedCountry && !selectedState && !selectedCity) {
      setShowStates(true);
    }
  }, [selectedCountry, selectedState, selectedCity]);

  useEffect(() => {
    setShowStates(!selectedState);
  }, [selectedState]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };


  const contryvalid = ()=>{
    if (!selectedCountry) {
      setTimeout(()=>{
        setAlertMessage('Please select a country before proceeding');
        
      },1000)
     
      setAlertOpen(true);
      return;
    }

  }

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    console.log('Alert closed');
    setAlertOpen(false);
  };


  const handleSelect = (value) => {
   

    if (showStates) {
      dispatch(selectState(value));
      localStorage.setItem('selectedState', value);
    } else {
      dispatch(selectCity(value));
      localStorage.setItem('selectedCity', value);
      setIsOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleCloseSelection = () => {
    if (!showStates) {
      dispatch(selectCity(''));
      localStorage.removeItem('selectedCity');
      setShowStates(true);
    } else {
      dispatch(selectState(''));
      dispatch(selectCity(''));
      localStorage.removeItem('selectedState');
      localStorage.removeItem('selectedCity');
      setShowStates(true);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const currentOptions = showStates ? ['Select State', ...states] : ['Select City', ...cities];

  const displayValue = selectedCity
    ? truncateText(selectedCity, 10)
    : selectedState
    ? truncateText(selectedState, 10)
    : showStates
    ? 'Select State'
    : 'Select City';

  return (
    <div className="relative inline-block w-full max-w-xs" ref={dropdownRef} onClick={contryvalid}>
      <div
        className="flex items-center justify-between border-2 rounded-md text-[#73757] font-fifth font-[400] cursor-pointer hover:bg-gray-100 bg-white border-gray-300 m-4 h-10 w-44"
        onClick={toggleDropdown}
        aria-expanded={isOpen}
        aria-haspopup="true"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="text-gray-600 text-xs sm:text-sm md:text-base font-medium flex justify-start">
          <span className="flex justify-start truncate text-xs sm:text-sm md:text-[18px] text-[#737579] font-fifth font-[400] ml-2">
            {displayValue}
          </span>
        </span>

        {isHovered && (selectedState || selectedCity) && (
          <IconButton size="small" onClick={handleCloseSelection}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )}

        <KeyboardArrowDownIcon
          style={{
            transition: 'transform 0.3s ease-in-out',
            transform: selectedCountry ? (isOpen ? 'rotate(180deg)' : 'rotate(0deg)') : 'rotate(0deg)' 
          }}
        />
      </div>
      {selectedCountry && (
        <div
          className={`absolute bg-white border flex flex-col justify-start top-14 border-gray-300 p-1 text-xs sm:text-sm md:text-[18px] font-fifth font-[500] rounded-md shadow-lg w-44 left-4 ${
            isOpen ? 'block' : 'hidden'
          }`}
          aria-expanded={isOpen}
          aria-labelledby="dropdown-button"
        >
          {loading ? (
            <div className="flex items-center justify-center py-2">
              <CircularProgress size={20} />
            </div>
          ) : currentOptions.length > 0 ? (
            currentOptions.map((option) => (
              <div
                key={option}
                className={`p-2 cursor-pointer hover:bg-gray-200 text-sm sm:text-sm md:text-[18px] font-fifth font-[500] rounded-md`}
                onClick={() => {
                  if (option === 'Select State') {
                    setShowStates(true);
                  } else if (option === 'Select City') {
                    setShowStates(false);
                  } else {
                    handleSelect(option);
                  }
                }}
              >
                {option}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500 text-sm">No options available</div>
          )}
        </div>
      )}

<Snackbar
  key={alertMessage} 
  open={alertOpen}
  autoHideDuration={1000}
  onClose={handleAlertClose}
  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
  <MuiAlert elevation={6} variant="filled" severity="warning">
    {alertMessage}
  </MuiAlert>
</Snackbar>

    </div>
  );
};

export default StateAndCitySelect;
