import React from 'react';

import { Link, useLocation } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MembershipData } from './Cookiesdata';

const Membership = () => {
  const location = useLocation();
  const pathnameWithoutSlash = location.pathname.replace(/^\//, '');

  return (
    <div>
      <div className=" bg-[url('https://squarebric-media-files.squarebric.com/membership.jpeg')] mx-auto max-w-7xl bg-cover bg-center h-[550px] w-full flex flex-col mt-20 ">
        <ul className="flex p-5">
          <li className="">
            <Link to="/" className="text-[#1E2858] font-semibold capitalize ">
              Home <KeyboardArrowRightIcon />
              <span className=" text-[#1E2858] text-[14px] font-[500]"> {pathnameWithoutSlash}</span>
            </Link>
          </li>
        </ul>
      
        <h1 className="text-[25px] w-[78%] md:w-[37%] md:text-[45px] ml-10 md:ml-[30px] xl:ml-[70px] font-[700] capitalize font-fifth mt-[350px] bg-slate-300/80  md:mt-[280px] md:bg-slate-300/80 md:inline-block text-black">
  Membership Agreement
</h1>

      </div>

      <div className="mt-5 mx-auto max-w-7xl p-4 md:p-10">
        <p className="text-[#1E2858] font-[500] text-[16px] md:text-[23px] md:leading-[30px] ">
          Welcome to SquareBric! By becoming a member of our platform, you agree to the following terms and conditions.
          Please read them carefully.
        </p>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">1. Introduction</h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2  md:mt-5 ">
            This Membership Agreement ("Agreement") sets forth the terms and conditions under which you (the "Member")
            may access and use the services provided by SquareBric ("we," "our," or "us"). By registering as a Member,
            you agree to comply with and be bound by these terms.
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            2. Membership Eligibility
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            To become a Member, you must be at least 18 years old and have the legal capacity to enter into a contract.
            By registering as a Member, you represent and warrant that you meet these requirements.{' '}
          </p>
        </div>

        <div className="">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10 flex">
            3. Registration and Account
          </h1>
          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Account Creation: </span> To
            register, you must provide accurate and complete information. You are responsible for maintaining the
            confidentiality of your account credentials and for all activities that occur under your account.
          </p>

          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-3 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Account Security: </span> You agree
            to notify us immediately of any unauthorized use of your account or any other breach of security. SquareBric
            will not be liable for any loss or damage arising from your failure to protect your account information.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            4. Membership Benefits
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5">
            As a Member, you will have access to various features and services provided by SquareBric, including but not
            limited to:
          </p>
          <ul className="text-[#2E4374] md:font-[500] list-disc ml-4">
            <li>Exclusive property listings</li>
            <li>Market insights and reports</li>
            <li>Access to real estate professionals</li>
            <li>Personalized property recommendations</li>
          </ul>
        </div>

        <div className="">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10 flex">
            5. Fees and Payment
          </h1>
          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
          <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Membership Fees: </span> Details
            regarding membership fees, if applicable, will be provided on the website. Fees are subject to change, and
            you will be notified of any changes before they take effect.
          </p>

          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-3 ">
          <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Payment Terms: </span> Payments for
            membership fees, if any, must be made through our designated payment methods. All fees are non-refundable,
            except as required by law.
          </p>
        </div>

        <div className="">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10 flex">
            6. Use of Services
          </h1>
          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Permitted Use: </span> You agree to
            use our services solely for personal, non-commercial purposes unless otherwise explicitly authorized by
            SquareBric.
          </p>

          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-3 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Prohibited Use: </span> You may not
            use our services to engage in unlawful activities, harassment, or any actions that could damage or disrupt
            our platform or services.
          </p>
        </div>

        <div className="">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-5 flex">
            7. Termination and Suspension
          </h1>
          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Termination: </span> We reserve the
            right to terminate or suspend your membership at our discretion, with or without cause, including but not
            limited to violations of this Agreement.
          </p>

          <p className="text-[#1E2858] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-3 ">
            <span className="text-[#1E2858] font-[500] text-[16px] md:text-[18px]">Member’s Rights: </span> You may
            cancel your membership at any time through your account settings. No refunds will be provided for any fees
            paid prior to cancellation.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            8. Intellectual Property
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            All content, trademarks, and other intellectual property on the SquareBric website are owned by or licensed
            to us. You may not use, reproduce, or distribute any of our intellectual property without our prior written
            consent.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            9. Limitation of Liability
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            To the fullest extent permitted by law, SquareBric shall not be liable for any indirect, incidental,
            special, or consequential damages arising out of or in connection with your use of our services, even if we
            have been advised of the possibility of such damages.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            10. Modifications
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            We reserve the right to modify this Agreement at any time. Any changes will be effective immediately upon
            posting to our website. Your continued use of our services constitutes your acceptance of the modified
            Agreement.
          </p>
        </div>

        <div className="flex flex-col">
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">
            11. Governing Law
          </h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2 md:mt-5 ">
            This Agreement shall be governed by and construed in accordance with the laws of our Country without regard
            to its conflict of law principles.
          </p>
        </div>

        <div>
          <h1 className="text-[#1E2858] font-[600] text-[16px] md:text-[23px] leading-[20px] mt-10">12. Contact Us</h1>
          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-2   md:mt-5">
            If you have any questions or concerns regarding this Agreement, please contact us at:
          </p>
          <ul className="mt-2 md:font-[500] text-[#2E4374]">
            <li className="text-[#2E4374] font-[700] text-[16px]"> SquareBric Support Team:</li>
            <li> Email: squarebric.com</li>
            <li> Phone: [Your Contact Number]</li>
            <li> Address: [Your Address]</li>
          </ul>

          <p className="text-[#2E4374] md:font-[500]  lg:text-[16px] sm:text-[14px] md:text-[15px] md:leading-[25px] leading-[24px] text-justify mt-3">
            By registering as a Member, you acknowledge that you have read, understood, and agree to be bound by this
            Membership Agreement.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Membership;
