import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SinglePropertyPage from './SinglePropertyPage';
import MoreServices from './MoreServices';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import growth from '../../../../assets/fluent.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';

const PropertyCard = ({ service, index, onClick }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isShared, setIsShared] = useState(false);

  const handleLikeClick = (event) => {
    event.stopPropagation();
    setIsLiked(!isLiked);
  };

  const handleShareClick = (event) => {
    event.stopPropagation();
    setIsShared(!isShared);
  };

  return (
    <div
      className="flex flex-col lg:flex-row justify-around items-center py-4 p-4 border mb-10"
      style={{ maxWidth: '100%', overflow: 'hidden' }}
      onClick={() => onClick(index)}
    >
      <div className="flex flex-col lg:flex-row rounded-md mb-4 lg:w-3/4 w-full ml-6">
        <div className="lg:mr-4 flex-1">
          <h1 className="lg:font-bold mb-1 text-[#2E4374] font-thirdCustom lg:text-2xl text-xl capitalize">
            {service.service_provider_name}
          </h1>
          <h2 className="lg:font-bold text-[#2E4374] font-SecondCustom lg:text-sm text-sm capitalize">
            {service.service_type}
          </h2>
          <div className="flex justify-start">
            <p className="text-yellow-500 flex text-2xl">&#9733;</p>
            <span className="font-semibold text-[#2E4374] font-thirdCustom text-sm lg:text-sm flex ml-2 underline">
              {service?.average_rating?.toFixed(1)} ({service.enquired}
              <p className="ml-1 font-thirdCustom justify-start">Reviews</p>)
            </span>
          </div>
          <p className="flex justify-start mt-1">
            <img src="https://squarebric-media-files.squarebric.com/fluent.svg" alt="growth" className="w-6 h-6" />
            <p className="ml-2 text-sm text-[#4BA3DB] font-semibold">{service.enquired} People recently enquired</p>
          </p>
          <p
            className="text-[#2E4374] font-thirdCustom capitalize mb-2 w-full lg:w-3/4 md:w-3/4 sm:w-full"
            style={{ fontSize: '15px' }}
          >
            {service.service_description}
          </p>
          <div className="flex justify-start space-x-2">
            <button
              className="font-semibold text-[#2E4374] font-thirdCustom text-sm gap-2 capitalize mb-2 mt-2 flex items-center"
              onClick={handleLikeClick}
            >
              {isLiked ? (
                <FavoriteIcon style={{ color: 'red', width: '20px' }} />
              ) : (
                <FavoriteBorderIcon style={{ width: '20px' }} />
              )}
              <span className="ml-1">Like</span>
            </button>
            <button
              className="flex font-semibold text-[#2E4374] font-thirdCustom text-sm gap-2 ml-2 items-center"
              onClick={handleShareClick}
            >
              <ShareIcon style={{ color: isShared ? 'blue' : 'inherit', width: '20px' }} />
              Share
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center lg:w-2/4 w-full relative">
        <div className="border-4 lg:m-4 m-0 lg:p-4 p-2 rounded-xl border-[#e0f2fe] w-full lg:w-auto">
          <div className="relative">
            <img
              src="https://squarebric-media-files.squarebric.com/serviceImg1.png"
              alt="Service"
              className="w-full lg:w- lg:h-36 h-auto object-cover border-2 rounded-lg border-[#4BA3DB]"
              style={{ maxHeight: '12rem' }}
            />
            {service.verified && (
              <div className="absolute top-0 left-0 bg-green-200 text-[#3C8E51] px-2 py-1 rounded-tr-md flex items-center">
                Verified
                <CheckCircleIcon style={{ color: 'green', width: '15px', marginLeft: '5px' }} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AllPropertyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const selectedCountry = queryParams.get('country');
  const selectedState = queryParams.get('state');
  const selectedCity = queryParams.get('city');
  const selectedUniqueService = queryParams.get('service_type');
  const [allServiceData, setAllServiceData] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedServiceUuid, setSelectedServiceUuid] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const cardsPerPage = 10; // Number of cards per page

  useEffect(() => {
    if (selectedCountry && selectedState && selectedCity && selectedUniqueService) {
      fetchData();
    }
  }, [selectedCountry, selectedState, selectedCity, selectedUniqueService, currentPage]); // Include currentPage in dependency array

  useEffect(() => {
    if (isPopupOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isPopupOpen]);

  const fetchData = async () => {
    const skip = (currentPage - 1) * cardsPerPage;
    const url = `https://square-bric-backend-api.squarebric.com/home-service/home-service-search?country=${encodeURIComponent(
      selectedCountry
    )}&state=${encodeURIComponent(selectedState)}&sub_city=${encodeURIComponent(
      selectedCity
    )}&service_type=${encodeURIComponent(selectedUniqueService)}&skip=${skip}&limit=${cardsPerPage}`;

    console.log('Fetching data from URL:', url);

    try {
      const response = await fetch(url);

      if (!response.ok) {
        console.error(`Network response was not ok: ${response.statusText}`);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched data:', data);
      setAllServiceData(data.documents);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCardClick = (index) => {
    const serviceUuid = allServiceData[index].service_uuid;
    setSelectedCardIndex(index);
    setSelectedServiceUuid(serviceUuid);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const pageCount = Math.ceil(allServiceData.length / cardsPerPage);
  const renderPaginationButtons = () => {
    const maxVisiblePages = 5;
    const pageNumbers = Math.min(maxVisiblePages, pageCount);
    const pagesBeforeEllipsis = Math.min(currentPage - 1, Math.floor((maxVisiblePages - 1) / 2));
    const pagesAfterEllipsis = Math.min(pageCount - currentPage, Math.floor((maxVisiblePages - 1) / 2));
    const startPage = Math.max(1, currentPage - pagesBeforeEllipsis);
    const endPage = Math.min(pageCount, currentPage + pagesAfterEllipsis);

    const buttons = [];

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <li key={i} className="mx-1">
          <button
            onClick={() => paginate(i)}
            className={`px-3 py-1 rounded ${
              currentPage === i ? ' text-white rounded-full bg-[#2e4374]' : 'bg-gray-200'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }

    return (
      <>
        {startPage > 1 && (
          <li className="mx-1">
            <button
              onClick={() => paginate(1)}
              className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              1
            </button>
          </li>
        )}
        {startPage > 2 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {buttons}
        {endPage < pageCount - 1 && (
          <li className="mx-1">
            <button className="text-4xl -mt-4">....</button>
          </li>
        )}
        {endPage < pageCount && (
          <li className="mx-1">
            <button
              onClick={() => paginate(pageCount)}
              className={`px-3 py-1 rounded ${currentPage === pageCount ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              {pageCount}
            </button>
          </li>
        )}
      </>
    );
  };

  return (
    <>
      <div className="flex justify-center items-center overflow-hidden ">
        <div className="flex md:flex-row mt-44 md:ml-20 ml-10 justify-center flex-col">
          <div className="relative  transform p-4 md:w-3/5 w-11/12">
            <div className="flex mb-4 px-4 md:px-0 ">
              <div className=" flex font-fifth font-semibold text-xs gap-2 flex-wrap justify-start ml-0 items-center">
                <div
                  onClick={() => handleNavigate('/')}
                  className={`cursor-pointer ${window.location.pathname === '/' ? 'underline' : ''}`}
                >
                  Home
                </div>
                <div className="flex items-center">
                  <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />} </span>
                  <div
                    onClick={() => handleNavigate('/ServicePage')}
                    className={`cursor-pointer ${window.location.pathname === '/ServicePage' ? 'underline' : ''}`}
                  >
                     home Service
                  </div>
                </div>
                <div className="flex items-center">
                  <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />}</span>
                  <div
                    onClick={() => handleNavigate(`/ServicePage/${selectedUniqueService}`)}
                    className={`cursor-pointer ${
                      window.location.pathname.includes(`/ServicePage/${selectedUniqueService}`) ? 'underline' : ''
                    }`}
                  >
                    {selectedUniqueService}
                  </div>
                </div>
                <span>{<ArrowForwardIcon style={{ width: '15px', height: '15px' }} />}</span>
                <div>Total Results: {allServiceData.length}</div>
              </div>
            </div>
            {allServiceData.map((service, index) => (
              <div key={index}>
                <PropertyCard service={service} index={index} onClick={handleCardClick} />
              </div>
            ))}
          </div>
          <div className=" right-0">
            {isPopupOpen && selectedServiceUuid && (
              <SinglePropertyPage serviceUuid={selectedServiceUuid} onClose={closePopup} />
            )}
          </div>
          <MoreServices />
        </div>
      </div>

      <div className="flex justify-center items-center mt-4">
      <button
          onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-1 mx-1 text-lg font-bold mr-2 ${currentPage === 1 ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &lt;&lt;
        </button>
        <ul className="flex">{renderPaginationButtons()}</ul>
        <button
          onClick={() => setCurrentPage(currentPage === pageCount ? pageCount : currentPage + 1)}
          disabled={currentPage === pageCount}
          className={`px-3 py-1 mx-1 text-lg font-bold ml-2 ${currentPage === pageCount ? 'bg-[#2e4374] text-white rounded-full' : 'text-[#071C35] bg-gray-200 rounded-full'}`}
        >
          &gt;&gt;
        </button>
      </div>
    </>
  );
};

export default AllPropertyPage;
