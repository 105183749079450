import React from 'react';
import Map from './Map';

const Location = () => {
  return (
    <div className="bg-[#C5755B]  mt-20 h-auto p-5">
      <div className=" grid grid-cols-1 md:grid-cols-2">
        <div className="flex justify-center items-center flex-col w-full">
          <h1 className="text-white font-[600] text-[25px] leading-[20px] p-5  mt-4">Find Us Here</h1>
          <div className="mt-4 p-4">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.032937138182!2d77.59559597585715!3d12.969744214917345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1643f07e7685%3A0x629aaec70fb2fc90!2sSival%20Devops!5e0!3m2!1sen!2sin!4v1725348751484!5m2!1sen!2sin" 
           allowfullscreen="" className='w-[100%] h-[50%' loading="lazy" referrerpolicy="no-referrer-when-downgrade"   style={{ height: '170px', width: '370px', zIndex: '0' }}></iframe>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col w-full" >
          <h1 className="text-white font-[300] text-[25px] leading-[25px] p-5 mt-4">
            Sival Devops Software Pvt Ltd
          </h1>
          <p className="text-white font-[300]  lg:text-[16px] 2xl:text-[16px]  sm:text-[14px] md:text-[15px]  leading-[25px] text-justify ">
            Property no 15, HIG first main road, RMV 2nd stage,<br/>
             dollars colony, Sanjay Nagar Bengaluru Karnataka 560094,
            India
          </p>
        </div>
      </div>
    </div>
  );
};

export default Location;
