// src/redux/bedroomSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  minValue: 0,
  maxValue: 0,
};

const bedroomSlice = createSlice({
  name: 'bedroom',
  initialState,
  reducers: {
    incrementMinValue(state) {
      if (state.minValue < 19) {
        state.minValue += 1;
        if (state.minValue >= state.maxValue) {
          state.maxValue = state.minValue + 1;
        }
      }
    },
    decrementMinValue(state) {
      if (state.minValue > 1) {
        state.minValue -= 1;
      }
    },
    incrementMaxValue(state) {
      if (state.maxValue < 20) {
        state.maxValue += 1;
      }
    },
    decrementMaxValue(state) {
      if (state.maxValue > 2) {
        state.maxValue -= 1;
        if (state.maxValue - 1 < state.minValue) {
          state.minValue = state.maxValue - 1;
        }
      }
    },
    setMinValue(state, action) {
      state.minValue = action.payload;
    },
    setMaxValue(state, action) {
      state.maxValue = action.payload;
    },
    resetValues(state) {
      state.minValue = 0;
      state.maxValue = 0;
    },
    clearMinValueBed(state) {
      state.minValue = 0; // or set to null if you prefer
    },
    clearMaxValueBed(state) {
      state.maxValue = 0; // or set to null if you prefer
    },
  },
});

export const {
  incrementMinValue,
  decrementMinValue,
  incrementMaxValue,
  decrementMaxValue,
  setMinValue,
  setMaxValue,
  resetValues,
  clearMinValueBed,
  clearMaxValueBed,
} = bedroomSlice.actions;

export default bedroomSlice.reducer;
