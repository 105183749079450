import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const SignUpForm = () => {
  const [formData, setFormData] = useState({
    email: ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = 'Invalid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted:', formData);
      // Perform the form submission, API request, etc.
    }
  };

  return (
    <div className=" relative ">
      <img
        src={'https://squarebric-media-files.squarebric.com/login7.png'}
        alt="loginimage"
        className={`absolute top-20 right-0 w-[20%] h-auto`}
      />

      <div className="flex justify-center items-center min-h-screen pt-28">
        <form className="bg-white shadow-md  rounded px-8 pt-6 pb-8 mb-4 w-full max-w-md" onSubmit={handleSubmit}>
          <h2 className="text-center   lg:text-[20px] md:text-[20px] sm:text-[16px] xl:text-[20px] font-[400] leading-normal text-[#2E4374] font-SecondCustom capitalize mb-4">
            Forgot password ?
          </h2>
          <p className="text-center font-roboto text-[16px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-6">
            Enter your email address & we will send you instructions to reset your password
          </p>

          {/* Email Field */}
          <div className="mb-4">
            <label
              className="block font-roboto text-[12px] font-[400] leading-[18.619px] tracking[-0.081px] capitalize mb-2"
              htmlFor="email"
            ></label>
            <div className="relative">
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="Email"
              />
            </div>
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
          </div>

          {/* Submit Button */}
          <div className="flex items-center justify-between">
            <button
              type="submit"
              className=" bg-[#2e4374] lg:text-[16px] md:text-[16px] sm:text-[14px] xl:text-[16px] font-[600] leading-normal text-white font-SecondCustom capitalize w-[100%] py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Request Reset Link
            </button>
          </div>

          <div className="text-center mt-3">
            <label className="ml-2 text-[#9A9A9A] relative bottom-1 font-thirdCustom text-[16] font-[400] leading-[119.231%] tracking-[0.139px] ">
              Back to
              <Link to="/login" className="hover:underline underline-offset-2 text-blue-500">
                 Login
              </Link>
            </label>
          </div>
        </form>
      </div>
      <img
        src={'https://squarebric-media-files.squarebric.com/login6.png'}
        alt="logonimage"
        className="absolute bottom-[-56px] left-0  w-[20%] h-auto"
      />
    </div>
  );
};

export default SignUpForm;
