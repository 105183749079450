import React, { useState } from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const properties = [
  { img: 'https://squarebric-media-files.squarebric.com/bestProperty2.png', label: 'Queens Building Flats', text: 'Know More' },
  { img: 'https://squarebric-media-files.squarebric.com/bestProperty.png', label: 'Apartment in Avcilar', text: 'Know More' },
  { img: 'https://squarebric-media-files.squarebric.com/bestProperty4.png', label: 'Passcode Big Life City', text: 'Know More' },
  { img: 'https://squarebric-media-files.squarebric.com/bestProperty1.png', label: 'Sky Scrapper Flats', text: 'Know More' }
];

const BestProperty = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSmallScreen = useMediaQuery('(max-width:650px)');

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, properties.length - 1));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  return (
    <div className="mt-5 sm:mt-5 md:mt-10 mx-auto ml-2">
      <h2 className="relative inline-block mb-4 pb-2 text-sm sm:text-sm text-center sm:text-center md:text-left md:text-2xl lg:text-3xl font-bold text-[#2E4374] font-fifth  ml-1 md:ml-6 uppercase mt-10">
        <span className='underlineresponsive'>Our Best Commer</span>cial Properties
      </h2>

      {/* Grid view for larger screens */}
      <div className="hidden md:grid grid-cols-2 gap-6 mt-4">
        {properties.map((property, index) => (
          <div key={index} className="relative">
            <img
              src={property.img}
              alt={`bestproperty${index}`}
              className={`w-full object-cover ${index === properties.length - 3 ? 'h-[200px]' : 'max-h-[300px]'}`}
              style={{ marginTop: index === properties.length - 1 ? '-5rem' : '1rem' }} 
            />
            <div
              className="absolute top-10 left-10 text-white p-4"
              style={{ marginTop: index === properties.length - 1 ? '-3rem' : '1rem' }}
            >
              <h2 className="font-fifth text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-3xl 2xl:text-3xl 3xl:text-4xl">
                {property.label}
              </h2>
              <button className="text-white text-sm border-2 p-2 rounded-full mt-14 w-32  hover:scale-105 hover:bg-white hover:text-[#2E4374] hover:border hover:border-[#2e4374] transition-transform transform hover:-translate-y-1 transition-all duration-300">{property.text}</button>
            </div>
          </div>
        ))}
      </div>

      {/* Carousel view for smaller screens */}
      <div className="md:hidden relative">
        <div className="flex items-center justify-center">
          {currentIndex > 0 && (
            <button
              onClick={prevSlide}
              className="absolute -left-3 p-2 bg-white text-[#2E4374] shadow-lg opacity-60 -mt-10"
              style={{ zIndex: 20 }}
            >
              <ArrowBackIosNewIcon />
            </button>
          )}
          
          <div className="w-full overflow-hidden relative">
            <div
              className="flex transition-transform duration-300"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {properties.map((property, index) => (
                <div key={index} className="w-full flex-shrink-0 relative">
                  <div className="relative">
                    <img
                      src={property.img}
                      alt={`bestproperty${index}`}
                      className="w-full h-auto object-cover"
                      style={{ marginBottom: index === properties.length - 1 ? 0 : '1rem' }}
                    />
                    <div className="absolute top-5 left-7 text-white p-4 sm:top-5 sm:left-7 md:top-10 md:left-10">
                      <h2 className="text-2xl font-fifth mt-8 ml-4 sm:text-3xl">{property.label}</h2>
                      <button className="text-white text-sm border-2 p-2 rounded-full mt-5 w-32 hover:scale-105 hover:bg-white hover:text-[#2E4374] hover:border hover:border-[#2e4374] transition-transform transform hover:-translate-y-1 transition-all duration-300 ml-4">
                        {property.text}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {currentIndex < properties.length - 1 && (
            <button onClick={nextSlide} className="absolute -right-3 p-2 bg-white text-[#2E4374] opacity-60 -mt-10">
              <ArrowForwardIosIcon />
            </button>
          )}
        </div>
      </div>

      {/* View All button */}
      <div className="flex justify-center items-center">
        <button className="hidden md:block font-thirdCustom text-center text-[#2E4374] px-4 py-2 text-2xl ml-96 -mt-20 mb-0 font-semibold transition-transform transform hover:-translate-y-1 transition-all duration-300 hover:text-[#1BA3F9]">
          View More
          <span className="ml-2">
            <ArrowRightAltIcon />
          </span>
        </button>
      </div>
    </div>
  );
};

export default BestProperty;
