import React from 'react';
import { MoreServicedata } from './DemandingServiceData';
import './Property.css';

const MoreServices = () => {
  return (
    // <div className='w-full'>

      <div className="w-5/6 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4 md:ml-0 ml-0 rounded-md flex flex-col mt-8">
        <div className="border rounded-md p-4">
          <h2 className="text-[#1E2858] font-thirdCustom font-semibold mt-4 ml-4">Select for more service</h2>
          <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-2 mt-5">
            {MoreServicedata.map((item, index) => (
              <div key={index} className="transition transform hover:scale-105 hover:shadow-lg">
                <img
                  src={item.image}
                  className="rounded w-full object-cover"
                  style={{ maxWidth: '100%' }}
                  alt={`Service ${index + 1}`}
                />
                <div className="flex justify-center text-center mt-2" style={{ fontSize: '10px' }}>
                  {item.name}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="border p-4 rounded-md mt-8">
          <h1 className="uppercase text-[#2E4374] text-center font-thirdCustom font-semibold">Squarebric Promise</h1>
          <ul className="custom-tick-list mt-2 text-left md:ml-2 ml-6 text-[#2E4374] font-normal font-thirdCustom leading-7 tracking-wide">
            <li>Verified well trained Professionals</li>
            <li>Best Quality of Service</li>
            <li>Quick & Reliable Service</li>
          </ul>
        </div>
      </div>

    // </div>
  );
};

export default MoreServices;
